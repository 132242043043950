import { gql } from '@apollo/client';

const GET_REF_ARCH_QUERY = gql`
  query getRefArch {
    getRefArch {
      status
      code
      count
      response {
        id
        name
        ready
        shortOfferingName
        vnetPeered
        isEphemeral
        ephemeralRGSupported
        category
        repoUrl
        version
        description
        type
        components {
          id
          name
          description
        }
        applicationList
        autoselectOfferings
        dependencies {
          shortOfferingName
          version
          alternatives {
            shortOfferingName
            version
          }
        }
        conflicts {
          shortOfferingName
          version
        }
      }
    }
  }
`;

export default GET_REF_ARCH_QUERY;
