import {
  Application,
  ReferenceArchitecture,
} from '@costco-service-catalog/bff-types';
import { Upgrade } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import useStyles from './MultiselectAddToAppModal.styles';

interface MultiselectAddToAppModalPropsType {
  open: boolean;
  toggleOpen: () => void;
  deployedApplication: Application | undefined;
  selectedOfferings: ReferenceArchitecture[] | undefined;
  selectedUpdates: ReferenceArchitecture[] | undefined;
  handleSubmitAddToApplication: () => void;
  addToAppLoading: boolean;
}

const MultiselectAddToAppModal = (props: MultiselectAddToAppModalPropsType) => {
  const {
    open,
    toggleOpen,
    deployedApplication,
    selectedOfferings,
    selectedUpdates,
    handleSubmitAddToApplication,
    addToAppLoading,
  } = props;

  const { classes } = useStyles();

  return (
    <Dialog onClose={toggleOpen} open={open}>
      <div className={classes.DialogContainer}>
        <DialogTitle>
          <Typography variant="h4" component="p">
            Confirm Request
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div>
            <Divider sx={{ mb: '1rem' }} />
            <Typography variant="body1" sx={{ mb: '1rem' }}>
              Here are the options that you have selected:
            </Typography>
            <Typography variant="body2">Application Name:</Typography>
            <Typography sx={{ mb: '1rem' }}>
              {deployedApplication?.applicationName}
            </Typography>
            {selectedOfferings && selectedOfferings.length > 0 ? (
              <div>
                <Typography variant="body2">
                  Selected Starter Kit(s) to be Deployed:
                </Typography>
                <ul>
                  {selectedOfferings?.map((o) => (
                    <li key={o.id}>
                      <Typography>
                        {o?.category} - {o?.name}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </div>
            ) : undefined}
            {selectedUpdates && selectedUpdates.length > 0 ? (
              <div>
                <Typography variant="body2">
                  Selected Update(s) to be Applied:
                </Typography>
                <ul>
                  {selectedUpdates?.map((update) => (
                    <li key={update.id}>
                      <Typography>
                        {update?.category} - {update?.name}
                      </Typography>
                      <Typography display="flex" alignContent="center">
                        <Upgrade fontSize="small" sx={{ marginX: '2px' }} /> v(
                        {update?.version})
                      </Typography>
                    </li>
                  ))}
                </ul>
              </div>
            ) : undefined}
            <Typography sx={{ mt: '2rem' }}>
              Pressing submit will start the request process for the selected
              options above.
            </Typography>
          </div>
        </DialogContent>
      </div>
      <DialogActions className={classes.ButtonContainer}>
        <Button onClick={() => toggleOpen()}>Cancel</Button>
        <Button
          variant="contained"
          disabled={addToAppLoading}
          endIcon={
            addToAppLoading ? (
              <div className={classes.ButtonSpinnerContainer}>
                <CircularProgress
                  size="1rem"
                  className={classes.SpinnerStyling}
                />
              </div>
            ) : undefined
          }
          onClick={() => handleSubmitAddToApplication()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MultiselectAddToAppModal;
