import { gql } from '@apollo/client';

const GET_ADO_ORGS_QUERY = gql`
  query getAdoOrgs {
    getAdoOrgs {
      code
      count
      response
    }
  }
`;

export default GET_ADO_ORGS_QUERY;
