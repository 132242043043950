import { createPath } from 'history';
import { useAuth } from 'react-oidc-context';
import { Navigate, useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useGetUserPermission } from '../../hooks';
import { useGlobalState } from '../../globalState/useGlobalState';

interface ProtectedRouteProps {
  outlet: JSX.Element;
  isRestricted?: boolean;
}

const ProtectedRoute = ({ outlet, isRestricted }: ProtectedRouteProps) => {
  const { appSettings } = useGlobalState();
  const auth = useAuth();
  const bypassAuth = appSettings.bypassAuth.get();
  const { data } = useGetUserPermission();

  if (!auth.isAuthenticated && bypassAuth === true) {
    // If bypassAuth is true then just go to outlet
    return outlet;
  }
  if (!auth.isAuthenticated) {
    const location = useLocation();

    const intentedRoute = createPath(location);

    sessionStorage.setItem('IntendedRoute', intentedRoute);

    return <Navigate to="/signin" />;
  }

  if (!data) {
    return <CircularProgress />;
  }

  if (data && isRestricted && !data?.isAdmin) {
    return <Navigate to=":error" />;
  }
  return outlet;
};

ProtectedRoute.defaultProps = {
  isRestricted: false,
};

export default ProtectedRoute;
