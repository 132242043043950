import { useEffect, useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { object, string } from 'yup';
import {
  AddToApplicationRequestInput,
  Application,
  StarterKitEnvironment,
} from '@costco-service-catalog/bff-types';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../../../globalState/useGlobalState';
import {
  useEnqueueSnackbar,
  useListApplications,
  useSubmitAddToApplication,
} from '../../../../hooks';
import { ROUTES } from '../../../../constants';
import { utilitiesPayloadType } from '../../../../pages/ApplicationSummaryPage/useApplicationSummaryPage';

type defaultAddVnetFormValuesType = {
  selectedApplication?: Application;
  selectedEnvironment?: StarterKitEnvironment;
  selectedRegion?: string;
  selectedCidr?: string;
};

const defaultAddVnetValidationSchema = () =>
  object({
    selectedApplication: object().required('You must select an Application.'),
    selectedEnvironment: object().required('You must select an environment.'),
    selectedRegion: string().required('You must select a target region.'),
    selectedCidr: string().required('You must select a CIDR block range.'),
  });

const useAddVnetOrRegionModal = (
  handleModalClose: () => void,
  popup: utilitiesPayloadType | undefined
) => {
  const { error, loading, data } = useListApplications();
  const { submitAddToApplication } = useSubmitAddToApplication();
  const { pushSnackbar } = useEnqueueSnackbar();
  const navigate = useNavigate();

  const [applicationEnvironments, setApplicationEnvironments] = useState<
    StarterKitEnvironment[]
  >([]);
  const [primaryZone, setPrimaryZone] = useState<string>();
  const { appSettings } = useGlobalState();

  const filteredApplications = data.filter((x) => {
    if (x.isEphemeral || x.hasRunningPipelines || !x.starterKitRequests) {
      return false;
    }
    const landingZone = x.starterKitRequests
      .map(
        (starterKit) =>
          starterKit?.offerings?.find(
            (offering) => offering?.category === 'LandingZone'
          )
      )
      .filter((offering) => offering?.category === 'LandingZone');
    return landingZone && landingZone[0]?.status === 'Completed';
  });

  const getRegionGroupArray = (regions: string) => {
    if (typeof regions === 'string' && regions.length > 0) {
      const regionArr = regions.split(',').map((r) => r.trim());
      return regionArr;
    }
    return [];
  };

  const azureRegionGroups = [
    {
      zone: 'APAC',
      regions: getRegionGroupArray(appSettings.azureRegionGroupAPAC.get()),
    },
    {
      zone: 'CA',
      regions: getRegionGroupArray(appSettings.azureRegionGroupCA.get()),
    },
    {
      zone: 'EU',
      regions: getRegionGroupArray(appSettings.azureRegionGroupEU.get()),
    },
    {
      zone: 'US',
      regions: getRegionGroupArray(appSettings.azureRegionGroupUS.get()),
    },
  ];

  const getAvailableCidrBlockSizes = () => {
    const cidrBlockSizes = appSettings.cidrBlockSizes.get();
    if (typeof cidrBlockSizes === 'string' && cidrBlockSizes.length > 0) {
      const cidrSizesArr = cidrBlockSizes.split(',').map((r) => r.trim());
      return cidrSizesArr;
    }
    return [];
  };

  const initialApp = popup ? popup.data : undefined;

  const formik = useFormik({
    initialValues: { selectedApplication: initialApp },
    validationSchema: defaultAddVnetValidationSchema(),
    onSubmit: async (
      values: defaultAddVnetFormValuesType,
      helpers: FormikHelpers<defaultAddVnetFormValuesType>
    ) => {
      const submitAddToApplicationInput: AddToApplicationRequestInput = {
        applicationId: values.selectedApplication?.id || '',
        offeringRequests: [
          {
            additionalParams: [
              {
                key: 'environmentName',
                value: values.selectedEnvironment?.name || '',
              },
              {
                key: 'environmentType',
                value: values.selectedEnvironment?.type || '',
              },
              {
                key: 'region',
                value: values.selectedRegion || '',
              },
              {
                key: 'cidr',
                value: values.selectedCidr || '',
              },
            ],
            category: 'Utility',
            offeringName: 'Additional VNet / Region',
            offeringShortName: 'vnet',
            offeringVersion: 'na',
          },
        ],
      };
      const submitOptions = {
        variables: { input: submitAddToApplicationInput },
        onCompleted: () => {},
      };
      const submitAddToApplicationRes = await submitAddToApplication(
        submitOptions
      );

      if (
        !submitAddToApplicationRes.errors ||
        submitAddToApplicationRes.errors?.length === 0
      ) {
        pushSnackbar('Additional VNet / Region request has been submitted.', {
          variant: 'success',
        });
        handleModalClose();
        navigate(
          `${ROUTES.APPLICATION_SUMMARY}/${
            values.selectedApplication?.id || ''
          }`
        );
      }
    },
  });

  useEffect(() => {
    const envs = formik.values.selectedApplication?.environments?.filter(
      (item): item is StarterKitEnvironment =>
        !!item && !item.notReady && !item.deleteStatus
    );
    if (envs) {
      setApplicationEnvironments([...envs]);
    }
    const appZone = azureRegionGroups.find((grp) =>
      grp.regions.find(
        (regionName) =>
          regionName === formik.values.selectedApplication?.primaryRegion
      )
    )?.zone;
    setPrimaryZone(appZone);
  }, [formik.values.selectedApplication]);

  const selectApplicationFieldError = formik.touched.selectedApplication
    ? Boolean(formik.errors.selectedApplication)
    : false;
  const selectedEnvironmentFieldError = formik.touched.selectedEnvironment
    ? Boolean(formik.errors.selectedEnvironment)
    : false;

  const selectedRegionFieldError = formik.touched.selectedRegion
    ? Boolean(formik.errors.selectedRegion)
    : false;

  const selectedCidrBlockFieldError = formik.touched.selectedCidr
    ? Boolean(formik.errors.selectedCidr)
    : false;

  return {
    formik,
    listApplicationsData: filteredApplications,
    listApplicationsLoading: loading,
    listApplicationsError: error,
    selectApplicationFieldError,
    selectedEnvironmentFieldError,
    selectedRegionFieldError,
    selectedCidrBlockFieldError,
    applicationEnvironments,
    availableRegions: getRegionGroupArray(appSettings.availableRegions.get()),
    azureRegionGroups,
    primaryZone,
    availableCidrBlockSizes: getAvailableCidrBlockSizes(),
  };
};
export default useAddVnetOrRegionModal;
