import { ApolloError, useQuery } from '@apollo/client';
import { AdoOrgsResponse } from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '..';
import { GET_ADO_ORGS_QUERY } from '../../apollo/queries';
import { useGlobalState } from '../../globalState/useGlobalState';
import mockADOOrgsRes from './lookupHooksMocks/useADOOrgs.mock';

const useADOOrgs = () => {
  const { appSettings } = useGlobalState();
  if (appSettings.useUIMocks.get()) {
    return mockADOOrgsRes();
  }

  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getAdoOrgs: AdoOrgsResponse;
  }>(GET_ADO_ORGS_QUERY, {
    onError: (error: ApolloError) => handleErrorResponse(error),
  });
  const [orgs, setOrgs] = useState<string[]>([]);

  useEffect(() => {
    if (!loading && data) {
      const extractGroups: string[] | undefined = data.getAdoOrgs
        .response as string[];
      if (extractGroups) {
        setOrgs(extractGroups);
      }
    }
  }, [loading, data]);

  return {
    error,
    data: orgs,
    loading,
  };
};

export default useADOOrgs;
