import { useQuery } from '@apollo/client';
import {
  AdoBoardsProcess,
  AdoBoardsProcessResponse,
} from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { GET_ADO_BOARDS_PROCESSES } from '../../apollo/queries';

const useGetAdoBoardsProcesses = () => {
  const { loading, error, data } = useQuery<{
    getAdoBoardsProcesses: AdoBoardsProcessResponse;
  }>(GET_ADO_BOARDS_PROCESSES, {});
  const [boards, setBoards] = useState<AdoBoardsProcess[]>([]);

  useEffect(() => {
    const extractGroups: AdoBoardsProcess[] | undefined = data
      ?.getAdoBoardsProcesses?.response as AdoBoardsProcess[] | undefined;
    if (extractGroups) {
      setBoards(extractGroups);
    }
  }, [loading, data]);

  return {
    error,
    data: boards,
    loading,
  };
};

export default useGetAdoBoardsProcesses;
