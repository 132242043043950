import { ApolloError, useMutation } from '@apollo/client';
import { useApolloErrors } from '..';
import { DELETE_EPHEMERAL_RG_MUTATION } from '../../apollo/queries';

const useDeleteEphemeralRg = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [deleteEphemeralRg, { loading: deleteEphemeralRgLoading }] =
    useMutation(DELETE_EPHEMERAL_RG_MUTATION, {
      onError: (error: ApolloError) => handleErrorResponse(error),
    });

  return {
    deleteEphemeralRg,
    loading: deleteEphemeralRgLoading,
  };
};

export default useDeleteEphemeralRg;
