import {
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import useStyles from './Common.styles';

interface CommonSwitchProps {
  label: string;
  offLabel: string;
  onLabel: string;
  checked: boolean;
  helperText: string;
  onChangeHandler: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

const CommonSwitch = (props: CommonSwitchProps) => {
  const { label, offLabel, onLabel, checked, helperText, onChangeHandler } =
    props;

  const { classes } = useStyles();

  return (
    <div className={classes.fieldWrap}>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">{label}</FormLabel>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>{offLabel}</Typography>
          <Switch
            checked={checked}
            onChange={onChangeHandler}
            data-testid={label}
          />
          <Typography>{onLabel}</Typography>
        </Stack>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default CommonSwitch;
