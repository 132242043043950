import { useFormik } from 'formik';
import { object } from 'yup';
import { AadUser, RequesterInput } from '@costco-service-catalog/bff-types';
import useEnqueueSnackbar from '../../hooks/snackbarHooks/useEnqueueSnackbar';
import useUpdateApplication from '../../hooks/applicationHooks/useUpdateApplication';

type defaultFormValuesType = {
  requester?: AadUser;
};

const useRequesterEditModal = (
  handleModalClose: () => void,
  applicationId: string,
  handleApplicationSummaryRefresh: () => void
) => {
  const { pushSnackbar } = useEnqueueSnackbar();
  const { updateApplication } = useUpdateApplication();
  const requesterEditSchema = () =>
    object({
      requester: object().required(
        'You must select a user to be the new application owner.'
      ),
    });

  const formik = useFormik({
    initialValues: { requester: undefined },
    validationSchema: requesterEditSchema(),
    onSubmit: async (values: defaultFormValuesType) => {
      const requesterInput: RequesterInput = {
        lastName: values.requester?.lastName,
        firstName: values.requester?.firstName,
        email: values.requester?.email,
        userName: values.requester?.userPrincipalName?.substring(
          0,
          values.requester.userPrincipalName.indexOf('@')
        ),
      };
      const submtiOptions = {
        variables: {
          applicationId: applicationId,
          updateRequestInput: {
            requester: requesterInput,
          },
        },
      };

      const updateRequester = await updateApplication(submtiOptions);
      if (!updateRequester.errors || updateRequester.errors?.length === 0) {
        pushSnackbar('Application owner has been updated successfully.', {
          variant: 'success',
        });
        handleModalClose();
        handleApplicationSummaryRefresh();
        formik.resetForm();
      }
    },
  });

  const requesterError = formik.touched.requester
    ? Boolean(formik.errors.requester)
    : false;

  return {
    formik,
    requesterError,
  };
};

export default useRequesterEditModal;
