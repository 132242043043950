import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(ROUTES.BASE, { replace: true });
  }, []);

  return <CircularProgress />;
};

export default HomePage;
