import { gql } from '@apollo/client';

const LIST_EPHEMERAL_RG_AVAILABLE_APPLICATIONS = gql`
  query Query {
    listEphemeralRgAvailableApplications {
      code
      count
      status
      response {
        id
        applicationName
        starterKitRequests {
          status
          requestedDateTime
          cartsApprovalRequestId
          offerings {
            status
            category
            offeringName
            offeringVersion
            offeringShortName
          }
          id
        }
        isEphemeral
      }
    }
  }
`;

export default LIST_EPHEMERAL_RG_AVAILABLE_APPLICATIONS;
