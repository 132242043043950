import { useState } from 'react';

import { Box, Divider, Typography, useTheme } from '@mui/material';
import useStyles from './UtilitiesPageLayout.styles';
import UtilityModalContainer from './UtilityModal/UtilityModalContainer';
import { AvailableUtilities } from '../../types/uiTypes';
import UtilityCard from './UtilityCard';
import { utilitiesPayloadType } from '../../pages/ApplicationSummaryPage/useApplicationSummaryPage';

type UtilitiesPageLayoutPropsType = {
  availableUtilities: AvailableUtilities[];
  popup: utilitiesPayloadType | undefined;
};

const UtilitiesPageLayout = (props: UtilitiesPageLayoutPropsType) => {
  const { availableUtilities, popup } = props;
  const theme = useTheme();
  const { classes } = useStyles();
  const [modalOption, setModalOption] = useState(popup ? popup.option : '');
  const [modalOpen, setModalOpen] = useState(Boolean(popup));

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = (variant: string) => {
    setModalOption(variant);
    setModalOpen(true);
  };

  return (
    <Box className={classes.root}>
      <UtilityModalContainer
        popup={popup}
        modalOpen={modalOpen}
        modalOption={modalOption}
        handleModalClose={handleModalClose}
      />
      <Box style={{ maxWidth: '790px' }}>
        <Box>
          <Box className={classes.headingContainer}>
            <Typography variant="h6" fontWeight={500}>
              Modify an existing deployment by selecting an option below
            </Typography>
            <Divider sx={{ paddingTop: '0.25rem' }} />
          </Box>
        </Box>
        <Box className={classes.utilsContainer}>
          {availableUtilities.map((au) => (
            <UtilityCard
              key={au.utilityNameId}
              utility={au}
              handleModalOpen={handleModalOpen}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default UtilitiesPageLayout;
