import { SvgIconComponent } from '@mui/icons-material';
import { Box, CircularProgress, Drawer, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useMatch, useNavigate } from 'react-router-dom';
import { LeftMenuItem } from '../../types';
import { AppBar, AppDrawer, AppHistoryDrawer } from '..';
import { ROUTES } from '../../constants';
import { useSessionStorage } from '../../hooks';
import ColorModeContext from '../../theme/ColorModeContext';
import useStyles from './AppLayout.styles';

interface AppLayoutProps {
  children: JSX.Element | React.ReactNode;
  menu: LeftMenuItem[];
}

const AppLayout = (props: AppLayoutProps) => {
  const { menu, children } = props;

  const sessionStorageHooks = useSessionStorage();
  const navigate = useNavigate();

  const [appDrawerOpen, setAppDrawerOpen] = useState(
    sessionStorageHooks.getAppDrawerPersistence()
  );

  const [appHistoryDrawerOpen, setAppHistoryDrawerOpen] = useState(
    sessionStorageHooks.getAppHistoryDrawerPersistence()
  );

  const [appHistory, setAppHistory] = useState<any[]>([]);

  const appHistoryDrawerData = sessionStorageHooks.getAppHistoryDrawerData();

  const { classes, cx } = useStyles();

  const toggleAppDrawer = () => {
    const handleAppDrawerOpen = () => {
      setAppDrawerOpen(true);
      sessionStorageHooks.setAppDrawerPersistence(true);
    };
    const handleAppDrawerClose = () => {
      setAppDrawerOpen(false);
      sessionStorageHooks.setAppDrawerPersistence(false);
    };
    if (appDrawerOpen) {
      handleAppDrawerClose();
    } else {
      handleAppDrawerOpen();
    }
  };

  const toggleAppHistoryDrawer = () => {
    const handleAppHistoryDrawerOpen = () => {
      setAppHistoryDrawerOpen(true);
      sessionStorageHooks.setAppHistoryDrawerPersistence(true);
    };
    const handleAppHistoryDrawerClose = () => {
      setAppHistoryDrawerOpen(false);
      sessionStorageHooks.setAppHistoryDrawerPersistence(false);
    };
    if (appHistoryDrawerOpen) {
      handleAppHistoryDrawerClose();
    } else {
      handleAppHistoryDrawerOpen();
    }
  };

  const auth = useAuth();
  const isErrorPage = useMatch(`${ROUTES.ERROR}/${ROUTES.ERROR_SLUG}`);

  useEffect(() => {
    if (auth.error && !isErrorPage) {
      navigate(
        `${ROUTES.ERROR}/${encodeURIComponent(
          `Authentication: ${auth.error.name} - ${auth.error.message}`
        )}`,
        { replace: true }
      );
    }
  }, [auth.error, isErrorPage]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      const intentedRoute = sessionStorage.getItem('IntendedRoute');
      if (intentedRoute) {
        sessionStorage.removeItem('IntendedRoute');
        navigate(intentedRoute, { replace: true });
      }
    }
  }, [auth.isAuthenticated]);

  useEffect(() => {
    setAppHistory(appHistoryDrawerData);
  }, []);

  const handleOnClose = (e: any) => {
    sessionStorageHooks.removeAppHistoryDrawerItem(e.currentTarget.id);
  };

  const handleOnClear = () => {
    sessionStorageHooks.removeAppHistoryDrawerData();
  };

  window.addEventListener('AppHistoryUpdated', () => {
    setAppHistory(sessionStorageHooks.getAppHistoryDrawerData());
  });

  return !auth.isLoading && (!auth.error || isErrorPage) ? (
    <Paper className={classes.root}>
      <AppBar
        handleAppDrawerClick={toggleAppDrawer}
        handleAppHistoryDrawerClick={toggleAppHistoryDrawer}
      />
      <Drawer
        variant="permanent"
        classes={{
          paper: cx(
            classes.drawerPaper,
            !appDrawerOpen && classes.drawerPaperClose
          ),
        }}
        open={appDrawerOpen}
      >
        <ColorModeContext.Consumer>
          {({ mode, toggleColorMode }) => (
            <AppDrawer
              menu={menu}
              checked={mode === 'dark'}
              toggleDarkMode={toggleColorMode}
              open={appDrawerOpen}
            />
          )}
        </ColorModeContext.Consumer>
      </Drawer>
      <main className={classes.main}>
        <Box className={classes.page}>{children}</Box>
      </main>
      <Drawer
        variant="permanent"
        classes={{
          paper: cx(
            classes.drawer,
            !appHistoryDrawerOpen && classes.drawerClose
          ),
        }}
        open={appHistoryDrawerOpen}
      >
        <AppHistoryDrawer
          notifications={appHistory}
          onClose={handleOnClose}
          onClear={handleOnClear}
        />
      </Drawer>
    </Paper>
  ) : (
    <CircularProgress />
  );
};

export default AppLayout;
