import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { utilitiesPayloadType } from '../../../../pages/ApplicationSummaryPage/useApplicationSummaryPage';
import { CommonDropdown } from '../../../FormComponents/CommonFields';
import useUnlockStateFileModal from './useUnlockStateFileModal';
import SelectStorageAccount from './UnlockStateFileComponents/SelectStorageAccount';
import SelectContainerName from './UnlockStateFileComponents/SelectContainerName';
import SelectStateFile from './UnlockStateFileComponents/SelectStateFile';

const UnlockStateFileModal = (props: {
  popup: utilitiesPayloadType | undefined;
  handleModalClose: () => void;
}) => {
  const { handleModalClose, popup } = props;
  const {
    formik,
    selectApplicationFieldError,
    selectedSubscriptionNameFieldError,
    selectedStorageAccountNameFieldError,
    selectedContainerNameFieldError,
    selectedStateFileNameFieldError,
    listApplicationsData,
    listApplicationsLoading,
    getSubscriptionOptions,
  } = useUnlockStateFileModal(handleModalClose, popup);
  const { selectedApplication, selectedSubscriptionName } = formik.values;

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Unlock State File</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <Box>
                <Box sx={{ flex: 1 }}>
                  <CommonDropdown
                    componentID="selectedApplication"
                    label="Select an Application"
                    data={listApplicationsData}
                    value={selectedApplication || null}
                    fieldError={selectApplicationFieldError}
                    helperText={
                      selectApplicationFieldError &&
                      formik.errors.selectedApplication
                        ? formik.errors.selectedApplication
                        : 'Select an application.'
                    }
                    fullHelpText="Please select an application from the dropdown menu."
                    loading={listApplicationsLoading}
                    required
                    noOptionsText="No Options Found."
                    onChangeHandler={(e, value) => {
                      formik.setFieldValue(
                        'selectedApplication',
                        value || undefined
                      );
                      formik.setFieldValue(
                        'selectedSubscriptionName',
                        undefined
                      );
                      formik.setFieldValue(
                        'selectedStorageAccountName',
                        undefined
                      );
                      formik.setFieldValue('selectedContainerName', undefined);
                      formik.setFieldValue('selectedStateFileName', undefined);
                    }}
                    onBlurHandler={formik.handleBlur}
                    getOptionLabel={(option) =>
                      option?.applicationName || 'No Application Name'
                    }
                    getOptionSelected={(o, v) =>
                      o.applicationName === v.applicationName
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box>
              <Box>
                <Box sx={{ flex: 1 }}>
                  <CommonDropdown
                    dropdownDisabled={selectedApplication === undefined}
                    componentID="selectedSubscriptionName"
                    label="Select a Subscription"
                    data={getSubscriptionOptions(selectedApplication)}
                    value={selectedSubscriptionName || null}
                    fieldError={selectedSubscriptionNameFieldError}
                    helperText={
                      selectedSubscriptionNameFieldError &&
                      formik.errors.selectedSubscriptionName
                        ? formik.errors.selectedSubscriptionName
                        : 'Select a subscription.'
                    }
                    fullHelpText="Please select a subscription from the dropdown menu."
                    loading={listApplicationsLoading}
                    required
                    noOptionsText="No Options Found."
                    onChangeHandler={(e, value) => {
                      formik.setFieldValue(
                        'selectedSubscriptionName',
                        value || undefined
                      );
                      formik.setFieldValue(
                        'selectedStorageAccountName',
                        undefined
                      );
                      formik.setFieldValue('selectedContainerName', undefined);
                      formik.setFieldValue('selectedStateFileName', undefined);
                    }}
                    onBlurHandler={formik.handleBlur}
                    getOptionLabel={(option) =>
                      option || 'No Subscription Name'
                    }
                    getOptionSelected={(o, v) => o === v}
                  />
                </Box>
              </Box>
            </Box>
            <Box>
              {formik.values.selectedSubscriptionName ? (
                <Box>
                  <SelectStorageAccount
                    selectedSubscriptionName={
                      formik.values.selectedSubscriptionName
                    }
                    errors={formik.errors}
                    handleBlur={formik.handleBlur}
                    setFieldValue={formik.setFieldValue}
                    selectedStorageAccountName={
                      formik.values.selectedStorageAccountName || null
                    }
                    selectedStorageAccountNameFieldError={
                      selectedStorageAccountNameFieldError
                    }
                  />
                </Box>
              ) : (
                <Box />
              )}
            </Box>
            <Box>
              {formik.values.selectedSubscriptionName &&
              formik.values.selectedStorageAccountName ? (
                <Box>
                  <SelectContainerName
                    selectedSubscriptionName={
                      formik.values.selectedSubscriptionName
                    }
                    selectedStorageAccountName={
                      formik.values.selectedStorageAccountName
                    }
                    selectedContainerName={
                      formik.values.selectedContainerName || null
                    }
                    errors={formik.errors}
                    handleBlur={formik.handleBlur}
                    setFieldValue={formik.setFieldValue}
                    selectedContainerNameFieldError={
                      selectedContainerNameFieldError
                    }
                  />
                </Box>
              ) : (
                <Box />
              )}
            </Box>
            <Box>
              {formik.values.selectedSubscriptionName &&
              formik.values.selectedStorageAccountName &&
              formik.values.selectedContainerName ? (
                <Box>
                  <SelectStateFile
                    selectedSubscriptionName={
                      formik.values.selectedSubscriptionName
                    }
                    selectedStorageAccountName={
                      formik.values.selectedStorageAccountName
                    }
                    selectedContainerName={formik.values.selectedContainerName}
                    selectedStateFileName={
                      formik.values.selectedStateFileName || null
                    }
                    selectedStateFileNameFieldError={
                      selectedStateFileNameFieldError
                    }
                    errors={formik.errors}
                    handleBlur={formik.handleBlur}
                    setFieldValue={formik.setFieldValue}
                  />
                </Box>
              ) : (
                <Box />
              )}
            </Box>
          </Box>
          <DialogActions>
            <Button
              disabled={
                !(formik.isValid && formik.dirty) || formik.isSubmitting
              }
              variant="contained"
              type="submit"
              endIcon={
                formik.isSubmitting ? (
                  <CircularProgress size="14px" sx={{ marginLeft: '0.5rem' }} />
                ) : undefined
              }
            >
              Unlock
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Box>
  );
};

export default UnlockStateFileModal;
