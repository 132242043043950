import {
  OfferingCategory,
  ReferenceArchitecture,
} from '@costco-service-catalog/bff-types';
import { InfoRounded } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { sortRefArchByTier } from '../../../hooks/validationHooks/sortRefArchByTier';
import { CompatibilityValidationType } from '../../../hooks/validationHooks/useOfferingMultiValidation';
import useStyles from './SelectedOfferingsInFooter.styles';

type SelectedOfferingsInFooterPropsType = {
  selectedOfferings: ReferenceArchitecture[];
  offeringSelectedValid: CompatibilityValidationType;
};

const SelectedOfferingsInFooter = (
  props: SelectedOfferingsInFooterPropsType
) => {
  const { classes } = useStyles();
  const { selectedOfferings, offeringSelectedValid } = props;

  let displayedOfferings = selectedOfferings.map((o) => ({
    id: o.id,
    name: o.name,
    category: o.category,
    placeholder: false,
  }));

  if (
    offeringSelectedValid.valid === false &&
    offeringSelectedValid.missingDeps &&
    offeringSelectedValid.missingDeps.length > 0
  ) {
    const filterPlaceholdersBy = offeringSelectedValid.missingDeps.map(
      (v) => v.category
    );

    const placeholders: {
      id: string;
      name: string;
      category: OfferingCategory;
      placeholder: boolean;
    }[] = [
      {
        id: 'landing-zone-placeholder',
        name: 'Please Select a Landing Zone',
        category: 'LandingZone',
        placeholder: true,
      },
      {
        id: 'core-infra-placeholder',
        name: 'Please Select a Core Infra',
        category: 'CoreInfra',
        placeholder: true,
      },
      {
        id: 'application-placeholder',
        name: 'Please Select an Application',
        category: 'Application',
        placeholder: true,
      },
    ];
    filterPlaceholdersBy.forEach((category) => {
      const placeholder = placeholders.find((p) => p.category === category);
      if (placeholder) {
        displayedOfferings = [
          ...displayedOfferings
            .concat(placeholder)
            .sort((a, b) => sortRefArchByTier(a, b)),
        ];
      }
    });
  }

  return (
    <div className={classes.selectedOfferingsWithinFooterContainer}>
      {displayedOfferings.map((so) => (
        <div
          data-testid="selected-offerings-footer-bar"
          key={so.id}
          className={
            so.placeholder
              ? classes.selectedOfferingsNeededWithinFooter
              : classes.selectedOfferingsWithinFooter
          }
        >
          <Tooltip title={so.name || ''}>
            <Typography
              fontWeight="bold"
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {so.category}
              <InfoRounded className={classes.infoFooterIcon} />
              <div className={classes.infoFooterIconMargin} />
            </Typography>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default SelectedOfferingsInFooter;
