import {
  AllInclusiveOutlined,
  AssignmentTurnedInOutlined,
  MenuBook,
  Search,
  VideocamOutlined,
} from '@mui/icons-material';
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { PageHeader } from '../../components';
import data from './HowToPage.data';
import useStyles from './HowToPage.styles';
import useHowToPage from './useHowToPage';

interface HowToPageProps {}

const HowToPage = (props: HowToPageProps) => {
  const { classes } = useStyles();

  const {
    title,
    subtitle,
    searchTerm,
    facets,
    handleSearchTermChange,
    handleCheckboxChange,
    isCheckboxChecked,
    checkFilterTags,
  } = useHowToPage();

  return (
    <div className={classes.root}>
      <PageHeader icon={<MenuBook />} title={title} subtitle={subtitle}>
        <Button
          color="primary"
          variant="outlined"
          size="small"
          href="https://docs.google.com/presentation/d/1TbkcEz5jmaksl4j22AtOrZrHsfwa_vPbUnGJ2Orl1hI/edit#slide=id.p4"
          target="_blank"
        >
          <span className={classes.buttonText}>ALM Training</span>
          <AllInclusiveOutlined fontSize="small" />
        </Button>
        <div className={classes.buttonSpacer} />
        <Button
          color="primary"
          variant="outlined"
          size="small"
          href="https://drive.google.com/drive/folders/1JCuDSr58S0rKgxwyQhlStjyb5szntKI9"
          target="_blank"
        >
          <span className={classes.buttonText}>PAAS Videos</span>
          <VideocamOutlined fontSize="small" />
        </Button>
        <div className={classes.buttonSpacer} />
        <Button
          color="primary"
          variant="outlined"
          size="small"
          href="https://drive.google.com/drive/folders/0B7-dIkxD7djfeDc5RzZDZWR0cVU?resourcekey=0-J5jJVKHNpecYlBwRbaf3aA"
          target="_blank"
        >
          <span className={classes.buttonText}>Ranorex Studio</span>
          <AssignmentTurnedInOutlined fontSize="small" />
        </Button>
      </PageHeader>

      <div className={classes.pageLayout}>
        <div className={classes.facetList}>
          <TextField
            id="search-guide"
            fullWidth
            variant="standard"
            label="Search Guides"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search color="primary" fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleSearchTermChange}
            autoFocus
          />
          <br />
          <br />
          <Typography className={classes.facetListTitle}>
            Filter By Tag
          </Typography>
          {facets &&
            facets.map((o, i) => (
              <div key={`how-to-facets-${o}`} className={classes.facetRow}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={o}
                      size="small"
                      checked={isCheckboxChecked(o)}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={<Typography variant="body2">{o}</Typography>}
                />
              </div>
            ))}
        </div>

        <div className={classes.tileGrid}>
          {data &&
            data
              .filter((item) => item?.ready === true)
              .filter(
                (item) =>
                  item?.title
                    ?.toLowerCase()
                    ?.includes(searchTerm?.toLowerCase())
              )
              .filter((item) => checkFilterTags(item?.tags ?? []))
              .map((o, i) => (
                <div
                  key={`how-to-card-tile-${o.title}`}
                  className={classes.tile}
                >
                  <div className={classes.tileContent}>
                    <span className={classes.tileTags}>
                      {o.tags &&
                        o.tags.map((t, idx) => (
                          <div key={`how-to-card-item-${t}`}>
                            {idx !== 0 && <>&nbsp;/&nbsp;</>}
                            <span className={classes.tileTag}>{t}</span>
                          </div>
                        ))}
                    </span>
                    <div className={classes.tileHeader}>
                      <div>
                        <a
                          className={classes.tileTitle}
                          href={o.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {o.title}
                        </a>
                      </div>
                      {o.icon && (
                        <div className={classes.tileIcon}>
                          <img
                            src={o.icon}
                            className={classes.tileIconImage}
                            alt=""
                          />
                        </div>
                      )}
                      <Button
                        href={o.url}
                        className={classes.tileButton}
                        target="_blank"
                        variant="outlined"
                        color="primary"
                        size="small"
                      >
                        <span className={classes.tileButtonText}>
                          View Guide
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default HowToPage;
