import { ApolloError, useQuery } from '@apollo/client';
import {
  Application,
  ApplicationsResponse,
} from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '..';
import { LIST_EPHEMERAL_RG_AVAILABLE_QUERY } from '../../apollo/queries';

const useListEphemeralRgAvailableApplications = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    listEphemeralRgAvailableApplications: ApplicationsResponse;
  }>(LIST_EPHEMERAL_RG_AVAILABLE_QUERY, {
    fetchPolicy: 'network-only',
    onError: (error: ApolloError) => handleErrorResponse(error),
  });
  const [ephemeralApplicationList, setEphemeralApplicationList] = useState<
    Application[]
  >([]);

  useEffect(() => {
    if (!loading && data) {
      const filteredData =
        data.listEphemeralRgAvailableApplications.response?.filter(
          (application) => application?.isEphemeral !== true
        );
      setEphemeralApplicationList((filteredData as Application[]) || undefined);
    }
  }, [loading, data]);

  return {
    error,
    data: ephemeralApplicationList,
    loading,
    refetch,
  };
};

export default useListEphemeralRgAvailableApplications;
