import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

const SigninPage = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.removeUser();
    auth.signinRedirect();
  }, []);

  return <CircularProgress />;
};

export default SigninPage;
