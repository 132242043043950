import { StorageAccountInfo } from '@costco-service-catalog/bff-types';
import { Box } from '@mui/material';
import { FormikErrors } from 'formik';
import { useGetStorageAccountsForSubscription } from '../../../../../hooks';
import { CommonDropdown } from '../../../../FormComponents/CommonFields';
import { defaultUnlockStateFileFormValuesType } from '../useUnlockStateFileModal';

export const getStorageAccounts = (data: StorageAccountInfo[]) =>
  data.map((v) => v.name as string);

const SelectStorageAccount = (props: {
  selectedSubscriptionName: string;
  selectedStorageAccountName: string | null;
  selectedStorageAccountNameFieldError: boolean;
  errors: FormikErrors<defaultUnlockStateFileFormValuesType>;
  setFieldValue: (
    field: string,
    value: string | undefined,
    shouldValidate?: boolean
  ) =>
    | Promise<FormikErrors<defaultUnlockStateFileFormValuesType>>
    | Promise<void>;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
}) => {
  const {
    selectedSubscriptionName,
    selectedStorageAccountName,
    selectedStorageAccountNameFieldError,
    errors,
    setFieldValue,
    handleBlur,
  } = props;
  const { data, loading } = useGetStorageAccountsForSubscription({
    subscriptionName: selectedSubscriptionName,
  });

  return (
    <Box sx={{ flex: 1 }}>
      <CommonDropdown
        componentID="selectedStorageAccountName"
        label="Select a Storage Account"
        data={data ? getStorageAccounts(data) : []}
        value={selectedStorageAccountName || null}
        fieldError={selectedStorageAccountNameFieldError}
        helperText={
          selectedStorageAccountNameFieldError &&
          errors.selectedStorageAccountName
            ? errors.selectedStorageAccountName
            : 'Select a Storage Account Name.'
        }
        fullHelpText="Please select a Storage Account from the dropdown menu."
        loading={loading}
        dropdownDisabled={!data}
        required
        noOptionsText="No Options Found."
        onChangeHandler={(e, value) => {
          setFieldValue('selectedStorageAccountName', value || undefined);
          setFieldValue('selectedContainerName', undefined);
          setFieldValue('selectedStateFileName', undefined);
        }}
        onBlurHandler={handleBlur}
        getOptionLabel={(option) => option || 'No Storage Account Name'}
        getOptionSelected={(o, v) => o === v}
      />
    </Box>
  );
};

export default SelectStorageAccount;
