import { ReferenceArchitecture } from '@costco-service-catalog/bff-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetApplicationDraft } from '../../hooks';
import { CardDetailsType } from '../../types';

const useOfferingRequestPage = () => {
  const [offering, setOffering] = useState<CardDetailsType>();
  const [selectedOfferings, setSelectedOfferings] =
    useState<ReferenceArchitecture[]>();

  let draftData;
  let draftLoading;
  let draftError;

  const { id, draftParams } = useParams();

  if (typeof id === 'string') {
    const draftApp = useGetApplicationDraft({ id });
    draftData = draftApp.data;
    draftLoading = draftApp.loading;
    draftError = draftApp.error;
  }

  const title = 'Offering Request';

  return {
    id,
    title,
    draftData,
    draftLoading,
    draftError,
    offering,
    setOffering,
    selectedOfferings,
    setSelectedOfferings,
  };
};

export default useOfferingRequestPage;
