import { ApolloError } from '@apollo/client/errors';
import { AdoProject } from '@costco-service-catalog/bff-types';

const mockAdoProjects: AdoProject[] = [
  {
    id: '1',
    name: 'ADO Project 1',
  },
  {
    id: '2',
    name: 'ADO Project 2',
  },
];

const mockAdoProjectsRes = () => {
  const error: ApolloError | undefined = undefined;
  const data: AdoProject[] = mockAdoProjects;
  const loading: boolean = false;
  return {
    error,
    data,
    loading,
  };
};

export default mockAdoProjectsRes;
