import {
  EphemeralRg,
  MutationUpdateEphemeralRgExpirationDateArgs,
  MutationDeleteEphemeralRgArgs,
  ReferenceArchitecture,
} from '@costco-service-catalog/bff-types';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useGlobalState } from '../../../globalState/useGlobalState';
import useUpdateEphemeralRgExpirationDateRequest from '../../../hooks/ephemeralRgHooks/useUpdateEphemeralRgExpirationDate';
import useDeleteEphemeralRg from '../../../hooks/ephemeralRgHooks/useDeleteEphemeralRg';
import { useEnqueueSnackbar } from '../../../hooks';

export interface Dates {
  createdDate: dayjs.Dayjs;
  expirationDate: dayjs.Dayjs;
  defaultMaximumDate: dayjs.Dayjs;
}

const useExistingEphemeralResourceGroupsList = (
  offeringsData: ReferenceArchitecture[],
  runEphemeralRgsRefetch: () => Promise<void>
) => {
  const { appSettings } = useGlobalState();
  const { pushSnackbar } = useEnqueueSnackbar();

  const maximumErgExpiration = appSettings.maximumErgExpiration.get();

  const [deleteErgModalOpen, setDeleteErgModalOpen] = useState(false);
  const [extendErgModalOpen, setExtendErgModalOpen] = useState(false);

  const [selectedErg, setSelectedErg] = useState<EphemeralRg>();
  const [selectedOfferingName, setSelectedOfferingName] = useState('');

  const [extensionDate, setExtensionDate] = useState<dayjs.Dayjs | null>(null);
  const [dates, setDates] = useState<Dates>();

  const { updateEphemeralRgExpirationDate } =
    useUpdateEphemeralRgExpirationDateRequest();
  const { deleteEphemeralRg } = useDeleteEphemeralRg();

  const handleChangeExtensionDate = (date: dayjs.Dayjs | null) => {
    setExtensionDate(date);
  };

  const handleDeleteErgModalClose = () => {
    setDeleteErgModalOpen(false);
  };

  const handleExtendErgModalClose = () => {
    setExtendErgModalOpen(false);
    setDates(undefined);
    setExtensionDate(null);
    setSelectedErg(undefined);
  };

  const handleDeleteErgModalOpen = (
    offeringSectionHeaderName: string,
    erg: EphemeralRg
  ) => {
    setSelectedOfferingName(offeringSectionHeaderName);
    setSelectedErg(erg);
    setDeleteErgModalOpen(true);
  };

  const handleExtendErgModalOpen = (
    offeringSectionHeaderName: string,
    erg: EphemeralRg
  ) => {
    setSelectedOfferingName(offeringSectionHeaderName);
    setSelectedErg(erg);
    setExtendErgModalOpen(true);
  };

  const subheaderList = offeringsData
    .filter((ra) => Boolean(ra.ephemeralRGSupported))
    .map((refArch) => ({
      id: refArch.id as string,
      name: refArch.name as string,
      shortOfferingName: refArch.shortOfferingName as string,
    }));

  useEffect(() => {
    const generateDates = () => {
      const createdDate = selectedErg?.createdDateTime
        ? dayjs(selectedErg.createdDateTime)
        : undefined;

      const expirationDate = selectedErg?.expirationDateTime
        ? dayjs(selectedErg.expirationDateTime)
        : undefined;

      if (createdDate && maximumErgExpiration && expirationDate) {
        const defaultMaximumDate = createdDate.add(
          parseInt(maximumErgExpiration, 10),
          'days'
        );

        return {
          createdDate,
          expirationDate,
          defaultMaximumDate,
        };
      }
      return undefined;
    };
    const newDates = generateDates();

    if (newDates) {
      setDates(newDates);
      handleChangeExtensionDate(newDates.expirationDate);
    }
  }, [selectedErg]);

  const handleExtendConfirmation = async () => {
    const updateEphemeralRgExpirationDateRes =
      await updateEphemeralRgExpirationDate({
        variables: {
          ephemeralRgId: selectedErg?.id,
          newExpirationDate: extensionDate?.toISOString(),
        },
      });
    if (
      !updateEphemeralRgExpirationDateRes.errors ||
      updateEphemeralRgExpirationDateRes.errors?.length === 0
    ) {
      pushSnackbar(
        'The update to the ephemeral environment expiration date has been submitted.',
        {
          variant: 'success',
        }
      );
      handleExtendErgModalClose();
      runEphemeralRgsRefetch();
    }

    return updateEphemeralRgExpirationDateRes;
  };

  const handleDeleteConfirmation = async () => {
    const deleteEphemeralRgRes = await deleteEphemeralRg({
      variables: { input: { id: selectedErg?.id } },
    });
    if (
      !deleteEphemeralRgRes.errors ||
      deleteEphemeralRgRes.errors?.length === 0
    ) {
      pushSnackbar(
        'The request to delete the ephemeral environment has been submitted.',
        {
          variant: 'success',
        }
      );
      handleDeleteErgModalClose();
      runEphemeralRgsRefetch();
    }

    return deleteEphemeralRgRes;
  };

  const isDeleteDisabled = (erg: EphemeralRg) => {
    if (erg.status !== 'Completed') {
      return true;
    }
    return false;
  };

  const isExtendDisabled = (erg: EphemeralRg) => {
    if (
      !(
        erg.status === 'Submitted' ||
        erg.status === 'AwaitingAdoRun' ||
        erg.status === 'Completed'
      )
    ) {
      return true;
    }
    return false;
  };

  const extendedTooltipText = (erg: EphemeralRg) => {
    if (
      !(
        erg.status === 'Submitted' ||
        erg.status === 'AwaitingAdoRun' ||
        erg.status === 'Completed'
      )
    ) {
      return '';
    }
    return 'Extend Developer Environment Expiration Date';
  };

  const deleteTooltipText = (erg: EphemeralRg) => {
    if (erg.status !== 'Completed') {
      return 'Please wait for the pending operation to complete before deleting.';
    }
    return 'Delete Developer Environment';
  };

  return {
    subheaderList,
    deleteErgModalOpen,
    handleDeleteErgModalClose,
    handleDeleteErgModalOpen,
    extendErgModalOpen,
    handleExtendErgModalClose,
    handleExtendErgModalOpen,
    selectedErg,
    selectedOfferingName,
    extensionDate,
    handleChangeExtensionDate,
    dates,
    handleExtendConfirmation,
    handleDeleteConfirmation,
    isDeleteDisabled,
    deleteTooltipText,
    isExtendDisabled,
    extendedTooltipText,
  };
};

export default useExistingEphemeralResourceGroupsList;
