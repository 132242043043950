import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { CommonDropdown } from '../../../FormComponents/CommonFields';
import useAddVnetOrRegionModal from './useAddVnetOrRegionModal';
import { utilitiesPayloadType } from '../../../../pages/ApplicationSummaryPage/useApplicationSummaryPage';
import { availableBlockSizesNumber } from '../../../FormComponents/DataDrivenFields/CidrBlockSizeTable';

const AddVnetOrRegionModal = (props: {
  popup: utilitiesPayloadType | undefined;
  handleModalClose: () => void;
}) => {
  const { handleModalClose, popup } = props;
  const {
    formik,
    listApplicationsData,
    listApplicationsLoading,
    listApplicationsError,
    selectApplicationFieldError,
    selectedEnvironmentFieldError,
    selectedRegionFieldError,
    selectedCidrBlockFieldError,
    applicationEnvironments,
    availableRegions,
    azureRegionGroups,
    primaryZone,
    availableCidrBlockSizes,
  } = useAddVnetOrRegionModal(handleModalClose, popup);
  const {
    selectedApplication,
    selectedEnvironment,
    selectedRegion,
    selectedCidr,
  } = formik.values;

  const displayHosts = availableBlockSizesNumber(availableCidrBlockSizes);

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Deploy an Additional VNet</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Here you can submit a request to deploy an extra VNet to improve
            site performance for an application you select.
          </DialogContentText>
          <DialogContentText>
            Please choose an environment, region and CIDR block size for the
            additional VNet.
          </DialogContentText>
          <Box>
            {listApplicationsError ? (
              <Box>
                <Typography>
                  An error occurred while obtaining your deployed applications.
                  Please try again.
                </Typography>
              </Box>
            ) : (
              <CommonDropdown
                componentID="selectedApplication"
                label="Select an Application"
                data={listApplicationsData}
                value={selectedApplication || null}
                fieldError={
                  formik.touched.selectedApplication
                    ? Boolean(formik.errors.selectedApplication)
                    : false
                }
                helperText={
                  selectApplicationFieldError &&
                  formik.errors.selectedApplication
                    ? formik.errors.selectedApplication
                    : 'Select an application.'
                }
                fullHelpText="Please select an application from the dropdown menu."
                loading={listApplicationsLoading}
                required
                noOptionsText="No Options Found."
                onChangeHandler={(e, value) => {
                  formik.setFieldValue(
                    'selectedApplication',
                    value || undefined
                  );
                  formik.setFieldValue('selectedEnvironment', '');
                  formik.setFieldValue('selectedRegion', '');
                  formik.setFieldValue('selectedCidr', '');
                }}
                onBlurHandler={formik.handleBlur}
                getOptionLabel={(option) =>
                  option?.applicationName || 'No Application Name'
                }
                getOptionSelected={(o, v) =>
                  o.applicationName === v.applicationName
                }
              />
            )}
          </Box>
          <Box>
            <CommonDropdown
              dropdownDisabled={formik.values.selectedApplication === undefined}
              componentID="selectedEnvironment"
              label="Select an Environment"
              data={applicationEnvironments}
              value={selectedEnvironment || null}
              fieldError={
                formik.touched.selectedEnvironment
                  ? Boolean(formik.errors.selectedEnvironment)
                  : false
              }
              helperText={
                selectedEnvironmentFieldError &&
                formik.errors.selectedEnvironment
                  ? formik.errors.selectedEnvironment
                  : 'Please select an environment from the dropdown menu'
              }
              fullHelpText="Please select an environment from the dropdown menu"
              loading={false}
              required
              noOptionsText="No Options Found."
              onChangeHandler={(e, value) => {
                formik.setFieldValue('selectedEnvironment', value || undefined);
              }}
              onBlurHandler={formik.handleBlur}
              getOptionLabel={(option) => option.name || 'No Environment Name'}
              getOptionSelected={(o, v) => o.name === v.name}
            />
          </Box>
          <Box>
            <CommonDropdown
              dropdownDisabled={formik.values.selectedApplication === undefined}
              componentID="selectedRegion"
              label="Select a Region"
              data={availableRegions}
              value={selectedRegion || null}
              fieldError={
                formik.touched.selectedRegion
                  ? Boolean(formik.errors.selectedRegion)
                  : false
              }
              helperText={
                selectedRegionFieldError && formik.errors.selectedRegion
                  ? formik.errors.selectedRegion
                  : 'Please select a region from the dropdown menu.'
              }
              fullHelpText="Please select a region from the dropdown menu"
              loading={false}
              required
              noOptionsText="No Options Found."
              onChangeHandler={(e, value) => {
                formik.setFieldValue('selectedRegion', value || undefined);
              }}
              onBlurHandler={formik.handleBlur}
              getOptionLabel={(option) => option || 'No Region Name'}
              getOptionSelected={(o, v) => o === v}
              getOptionDisabled={(option) => {
                const optionZone = azureRegionGroups.find((grp) =>
                  grp.regions.find((regionName) => regionName === option)
                )?.zone;
                if (optionZone !== primaryZone) {
                  return true;
                }
                return false;
              }}
            />
          </Box>
          <Box>
            <CommonDropdown
              dropdownDisabled={formik.values.selectedApplication === undefined}
              componentID="selectedCidr"
              label="Select a CIDR block size"
              data={availableCidrBlockSizes}
              value={selectedCidr || null}
              fieldError={
                formik.touched.selectedCidr
                  ? Boolean(formik.errors.selectedCidr)
                  : false
              }
              helperText={
                selectedCidrBlockFieldError && formik.errors.selectedCidr
                  ? formik.errors.selectedCidr
                  : 'Please select a CIDR block from the dropdown menu'
              }
              fullHelpText="Please select a CIDR block from the dropdown menu"
              loading={false}
              required
              noOptionsText="No Options Found."
              onChangeHandler={(e, value) => {
                formik.setFieldValue('selectedCidr', value || undefined);
              }}
              onBlurHandler={formik.handleBlur}
              getOptionLabel={(option) => {
                const displayHost = displayHosts.find(
                  (v) => `${v.blockSize}` === option
                );
                if (displayHost === undefined) {
                  return 'No CIDR Block Size';
                }
                return `/${displayHost.blockSize} (${displayHost.hosts} hosts)`;
              }}
              getOptionSelected={(o, v) => o === v}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box sx={{ padding: '1rem' }}>
            <Button
              disabled={
                !(formik.isValid && formik.dirty) || formik.isSubmitting
              }
              variant="contained"
              size="large"
              type="submit"
              sx={{ paddingX: '4rem' }}
              endIcon={
                formik.isSubmitting ? (
                  <CircularProgress size="14px" sx={{ marginLeft: '0.5rem' }} />
                ) : undefined
              }
            >
              Submit Request
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Box>
  );
};
export default AddVnetOrRegionModal;
