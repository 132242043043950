import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { EphemeralRg } from '@costco-service-catalog/bff-types';
import { Close } from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';

type DeleteErgModalPropsType = {
  open: boolean;
  handleClose: () => void;
  offeringName: string;
  handleDeleteConfirmation: () => void;
  ephemeralApp?: EphemeralRg;
};

const DeleteErgModal = (props: DeleteErgModalPropsType) => {
  const {
    open,
    handleClose,
    offeringName,
    handleDeleteConfirmation,
    ephemeralApp,
  } = props;
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '5px',
        }}
      >
        <IconButton onClick={() => handleClose()}>
          <Close />
        </IconButton>
      </Box>
      <DialogTitle>Delete Ephemeral Developer Environment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Are you sure that you want to delete this ${offeringName} environment referencing User Story #${ephemeralApp?.storyId}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box sx={{ padding: '5px' }}>
          <Button
            variant="contained"
            endIcon={<Delete />}
            color="error"
            onClick={() => handleDeleteConfirmation()}
          >
            Delete
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

DeleteErgModal.defaultProps = {
  ephemeralApp: undefined,
};

export default DeleteErgModal;
