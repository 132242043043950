enum ROUTES {
  BASE = '/',
  HOME = '/home',
  HEALTH_CHECK = '/health-check',
  HOW_TO = '/how-to',
  OFFERING_MULTISELECT = '/offering-list-multi',
  EPHEMERAL_RG_APPS = '/ephemeral-resource-group-apps',
  NEW_APP_WIZARD = '/new-application-request',
  UTILITIES = '/utilities-page',
  OFFERING_REQUEST = '/offering-request',
  OFFERING_REQUEST_DRAFT_ID = ':id',
  APPLICATION_DRAFTS = '/application-drafts',
  APPLICATION_LIST = '/application-list',
  ALL_APPLICATIONS = '/all-applications',
  APPLICATION_SUMMARY = '/application-summary',
  ECP_ONBOARDING_LIST = '/ecp-onboarding-list',
  APPLICATION_SUMMARY_ID = ':id',
  SIGNIN = '/signin',
  ERROR = '/error',
  ERROR_SLUG = ':error',
}

export default ROUTES;
