import { useEffect } from 'react';
import { useGlobalState } from '../../globalState/useGlobalState';

const useAppSettings = () => {
  const { appSettings } = useGlobalState();

  useEffect(() => {
    fetch('/appsettings.json')
      .then((result) => result.json())
      .then((newSettings) => {
        appSettings.merge({
          loaded: true,
          bffBaseUrl: newSettings.bffBaseUrl,
          pingBaseUrl: newSettings.pingBaseUrl,
          pingClientId: newSettings.pingClientId,
          aiInstrumentationKey: newSettings.aiInstrumentationKey,
          useUIMocks: newSettings.useUIMocks,
          bypassAuth: newSettings.bypassAuth,
          allowHistoryDrawer: newSettings.allowHistoryDrawer === 'true',
          useEphemeralRGPage: newSettings.useEphemeralRGPage === 'true',
          useNewAppWizard: newSettings.useNewAppWizard === 'true',
          addEnvUtilityEnabled: newSettings.addEnvUtilityEnabled === 'true',
          unlockStateFileUtilityEnabled:
            newSettings.unlockStateFileUtilityEnabled === 'true',
          ecpOnboardingUtilitiesEnabled:
            newSettings.ecpOnboardingUtilitiesEnabled === 'true',
          useApplicationPolicySelection:
            newSettings.useApplicationPolicySelection === 'true',
          productionEnvironmentSelectionEnabled:
            newSettings.productionEnvironmentSelectionEnabled === 'true',
          deleteEnvUtilityEnabled:
            newSettings.deleteEnvUtilityEnabled === 'true',
          wizEnabled: newSettings.wizEnabled === 'true',
          baseWizProjectUrl: newSettings.baseWizProjectUrl,
          availableRegions: newSettings.availableRegions,
          azureRegionGroupAPAC: newSettings.azureRegionGroupAPAC,
          azureRegionGroupCA: newSettings.azureRegionGroupCA,
          azureRegionGroupEU: newSettings.azureRegionGroupEU,
          azureRegionGroupUS: newSettings.azureRegionGroupUS,
          cidrBlockSizes: newSettings.cidrBlockSizes,
          autoRefreshInterval: newSettings.autoRefreshInterval,
          maxNpEnvironments: newSettings.maxNpEnvironments,
          maxPdEnvironments: newSettings.maxPdEnvironments,
          CnaMinVersion: newSettings.CnaMinVersion,
          maximumErgExpiration: newSettings.maximumErgExpiration,
          addVNetUtilityDocUrl: newSettings.addVNetUtilityDocUrl,
          addEnvUtilityDocUrl: newSettings.addEnvUtilityDocUrl,
          wizHelpDocUrl: newSettings.wizHelpDocUrl,
          userFeedbackUrl: newSettings.userFeedbackUrl,
          existingProjectPrereqDocUrl: newSettings.existingProjectPrereqDocUrl,
        });
      });
  }, []);
};

export default useAppSettings;
