import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  listBox: {
    flex: 1,
    paddingTop: '4rem',
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '1px solid rgba(0,0,0,0.1)',
  },
  list: {
    minWidth: '14rem',
    padding: '0rem .5rem .5rem .5rem',
  },
  listControls: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0rem 1rem',
  },
  historyItem: {
    padding: '0rem .5rem .5rem .5rem',
  },
  historyDateStamp: {
    whiteSpace: 'normal',
    opacity: 0.8,
    fontSize: '80%',
  },
  historyMessage: {
    whiteSpace: 'pre-line',
  },
  empty: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontStyle: 'italic',
  },
}));

export default useStyles;
