import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ConfirmDialog } from '../../..';
import { utilitiesPayloadType } from '../../../../pages/ApplicationSummaryPage/useApplicationSummaryPage';
import { CommonDropdown } from '../../../FormComponents/CommonFields';
import useDeleteEnvironmentModal from './useDeleteEnvironmentModal';

const DeleteEnvironmentModal = (props: {
  popup: utilitiesPayloadType | undefined;
  handleModalClose: () => void;
}) => {
  const { handleModalClose, popup } = props;
  const {
    formik,
    selectEnvironmentFieldError,
    listApplicationsData,
    listApplicationsLoading,
    selectApplicationFieldError,
    deleteEnvironmentDialogOpen,
    handleDeleteEnvironmentDialogOk,
    handleDeleteEnvironmentDialogCancel,
    handleDeleteEnvironmentClick,
  } = useDeleteEnvironmentModal(handleModalClose, popup);
  const { selectedApplication, selectedEnvironment } = formik.values;

  return (
    <>
      <ConfirmDialog
        title="Delete Environment?"
        message="Are you sure you want to delete this environment? Please note that you will not be able to create an environment with same name again."
        open={deleteEnvironmentDialogOpen}
        handleOk={handleDeleteEnvironmentDialogOk}
        handleCancel={handleDeleteEnvironmentDialogCancel}
      />
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>Delete Environment</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flex: 1 }}>
                <CommonDropdown
                  componentID="selectedApplication"
                  label="Select an Application"
                  data={listApplicationsData}
                  value={selectedApplication || null}
                  fieldError={selectApplicationFieldError}
                  helperText={
                    selectApplicationFieldError &&
                    formik.errors.selectedApplication
                      ? formik.errors.selectedApplication
                      : 'Select an application.'
                  }
                  fullHelpText="Please select an application from the dropdown menu."
                  loading={listApplicationsLoading}
                  required
                  noOptionsText="No Options Found."
                  onChangeHandler={(e, value) => {
                    formik.setFieldValue(
                      'selectedApplication',
                      value || undefined
                    );
                    formik.setFieldValue('selectedEnvironment', null);
                  }}
                  onBlurHandler={formik.handleBlur}
                  getOptionLabel={(option) =>
                    option?.applicationName || 'No Application Name'
                  }
                  getOptionSelected={(o, v) =>
                    o.applicationName === v.applicationName
                  }
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <CommonDropdown
                  dropdownDisabled={
                    formik.values.selectedApplication === undefined
                  }
                  componentID="selectedEnvironment"
                  label="Select an Environment"
                  data={
                    formik.values.selectedApplication?.environments?.filter(
                      (e) => !e?.deleteStatus && !e?.notReady
                    ) ?? []
                  }
                  value={selectedEnvironment || null}
                  fieldError={selectEnvironmentFieldError}
                  helperText={
                    selectEnvironmentFieldError &&
                    formik.errors.selectedEnvironment
                      ? formik.errors.selectedEnvironment
                      : 'Select an environment.'
                  }
                  fullHelpText="Please select an environment from the dropdown menu."
                  loading={false}
                  required
                  noOptionsText="No Options Found."
                  onChangeHandler={(e, value) => {
                    formik.setFieldValue(
                      'selectedEnvironment',
                      value || undefined
                    );
                  }}
                  onBlurHandler={formik.handleBlur}
                  getOptionLabel={(option) =>
                    option?.name || 'No Environment Name'
                  }
                  getOptionSelected={(o, v) => o?.name === v?.name}
                />
              </Box>
            </Box>
            <DialogActions>
              <Button
                disabled={
                  !(
                    formik.isValid &&
                    (formik.dirty || formik.values.selectedApplication)
                  ) || formik.isSubmitting
                }
                variant="contained"
                type="button"
                onClick={handleDeleteEnvironmentClick}
                endIcon={
                  formik.isSubmitting ? (
                    <CircularProgress
                      size="14px"
                      sx={{ marginLeft: '0.5rem' }}
                    />
                  ) : undefined
                }
              >
                Delete
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Box>
    </>
  );
};

export default DeleteEnvironmentModal;
