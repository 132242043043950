import { gql } from '@apollo/client';

const DELETE_APPLICATION_MUTATION = gql`
  mutation deleteApplication($input: EntityParams) {
    deleteApplication(input: $input) {
      status
      code
      response
    }
  }
`;

export default DELETE_APPLICATION_MUTATION;
