import { ApolloError, useMutation } from '@apollo/client';
import { useApolloErrors } from '..';
import { RETRY_DELETE_APPLICATION_MUTATION } from '../../apollo/queries';

const useRetryDeleteApplication = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [retryDeleteApplication, { loading: retryDeleteApplicationLoading }] =
    useMutation(RETRY_DELETE_APPLICATION_MUTATION, {
      onError: (error: ApolloError) => handleErrorResponse(error),
    });

  return {
    retryDeleteApplication,
    loading: retryDeleteApplicationLoading,
  };
};

export default useRetryDeleteApplication;
