import { gql } from '@apollo/client';

const SAVE_APPLICATION_DRAFT_MUTATION = gql`
  mutation saveApplicationRequestDraft($input: ApplicationRequestDraftInput) {
    saveApplicationRequestDraft(input: $input) {
      status
      code
      response {
        id
      }
    }
  }
`;

export default SAVE_APPLICATION_DRAFT_MUTATION;
