import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  cardContainer: {
    padding: 15,
  },
  card: {
    minHeight: 380,
    width: 450,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '&:hover': {
      boxShadow: '0 5px 15px rgb(0 0 0 / 0.25)',
      cursor: 'pointer',
    },
  },
  cardNoHover: {
    minHeight: 380,
    width: 450,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  prevDeployedCardSelected: {
    minHeight: 175,
    width: 450,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '&:hover': {
      boxShadow: '0 5px 15px rgb(0 0 0 / 0.25)',
      cursor: 'pointer',
    },
    borderStyle: 'solid',
    borderWidth: '4px',
    borderColor: !(theme.palette.mode === 'light') ? '#c6dbef' : '#8daac5',
  },
  prevDeployedCardNotSelected: {
    minHeight: 175,
    width: 450,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    '&:hover': {
      boxShadow: '0 5px 15px rgb(0 0 0 / 0.25)',
      cursor: 'pointer',
    },
  },
  cardDisabled: {
    opacity: 0.25,
    minHeight: 350,
    width: 450,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  prevDeployedCardDisabled: {
    opacity: 0.25,
    minHeight: 175,
    width: 450,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  cardTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.mode === 'light' ? '#c6dbef' : '#4a7093',
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontWeight: '500',
  },
  cardTitlePlusCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    fontWeight: '500',
  },
  cardSelectBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },
  cardDescContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexShrink: 1,
    flexGrow: 2,
    backgroundColor: theme.palette.mode === 'light' ? '#dae8f5' : '#3d5c79',
  },
  cardVersionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flex: 1,
  },
  cardPlusContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexShrink: 1,
    flexGrow: 3,
    backgroundColor: theme.palette.mode === 'light' ? '#dae8f5' : '#3d5c79',
  },
  cardPlusContainerCentered: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexShrink: 1,
    flexGrow: 3,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.mode === 'light' ? '#dae8f5' : '#3d5c79',
  },
  cardFeatureContainer: {
    flex: 1,
    backgroundColor: theme.palette.mode === 'light' ? '#e5eef8' : '#30485f',
  },
  chip: {
    margin: '.25rem',
    backgroundColor: theme.palette.background.paper,
    color:
      theme.palette.mode === 'light'
        ? theme.palette.info.dark
        : theme.palette.info.light,
    cursor: 'help',
  },
  vspacer: {
    height: '.75rem',
  },
}));

export default useStyles;
