import { ReferenceArchitecture } from '@costco-service-catalog/bff-types';
import { CheckBox, CheckBoxOutlineBlank, Memory } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { componentIcons } from '../../constants';
import useStyles from './OfferingCardMulti.styles';

interface OfferingCardMultiProps {
  refArch: ReferenceArchitecture;
  selectedReferenceArchitectures: ReferenceArchitecture[];
  handleRefArchSelection: (refArch: ReferenceArchitecture) => void;
}

const OfferingCardMulti = (props: OfferingCardMultiProps) => {
  const { refArch, selectedReferenceArchitectures, handleRefArchSelection } =
    props;

  const { classes } = useStyles();

  return (
    <div className={classes.cardContainer}>
      <Card
        onClick={() => {
          handleRefArchSelection(refArch);
        }}
        className={refArch.ready ? classes.card : classes.cardDisabled}
      >
        <CardContent className={classes.cardTitleContainer}>
          <div className={classes.cardTitle}>{refArch.name}</div>
          <div className={classes.cardSelectBox}>
            <IconButton data-testid="offeringSelectedCheckbox" size="small">
              {selectedReferenceArchitectures.find(
                (r) => r.id === refArch.id
              ) ? (
                <CheckBox data-testid="offeringSelectedCheckbox-selected" />
              ) : (
                <CheckBoxOutlineBlank data-testid="offeringSelectedCheckbox-notselected" />
              )}
            </IconButton>
          </div>
        </CardContent>
        <CardContent className={classes.cardDescContainer}>
          <Typography>{refArch.description}</Typography>
          <div className={classes.cardVersionContainer}>
            <Typography color="primary" variant="subtitle2">
              {`Version: ${refArch.version}`}
            </Typography>
          </div>
        </CardContent>
        <CardContent className={classes.cardFeatureContainer}>
          <Typography>Features</Typography>
          {refArch.components &&
            refArch.components.map((com, idx) => (
              <Tooltip key={com?.id} title={com?.description || ''} arrow>
                <Chip
                  icon={
                    <Box
                      component={
                        com?.name && componentIcons[com.name]
                          ? componentIcons[com.name]
                          : Memory
                      }
                    />
                  }
                  key={com?.id}
                  className={classes.chip}
                  label={com?.name ?? 'unknown'}
                  size="small"
                />
              </Tooltip>
            ))}
        </CardContent>
      </Card>
    </div>
  );
};

export default OfferingCardMulti;
