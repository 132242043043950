import { AadUser } from '@costco-service-catalog/bff-types';
import { CommonZUsersAutocomplete } from '../CommonFields';

interface AADReaderGroupMembersProps {
  value: AadUser[];
  contributorsAreReadersInProdMsg: string;
  onChangeHandler: (event: React.ChangeEvent<{}>, value: AadUser[]) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const AADReaderGroupMembers = (props: AADReaderGroupMembersProps) => {
  const {
    value,
    contributorsAreReadersInProdMsg,
    onChangeHandler,
    onBlurHandler,
  } = props;

  return (
    <CommonZUsersAutocomplete
      componentID="aadReaderGroupMembers"
      label="AAD Reader Group Members"
      value={value}
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      fieldError={false}
      helperText="An AAD group granting read-only access to Azure Resources will be created. Enter '-z' accounts to include them as group members."
      fullHelpText={`An AAD group granting read-only access to Azure Resources in the application's subscriptions will be created with the following '-z' accounts included as group members. ${contributorsAreReadersInProdMsg}`}
    />
  );
};

export default AADReaderGroupMembers;
