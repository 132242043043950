import { useGlobalState } from '../../../globalState/useGlobalState';
import { CommonEnvMultiSelect } from '../CommonFields';
import { ENVIRONMENTS_NAMES } from '../../../constants';

const nonProdEnvironmentsNames = ENVIRONMENTS_NAMES.NON_PROD_ENVIRONMENTS_NAMES;

interface NonProdEnvironmentsPropType {
  value: string[];
  fieldError: boolean;
  errorText?: string;
  onChangeHandler: (
    event: React.ChangeEvent<{}>,
    value: string[] | string | null
  ) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const NonProdEnvironments = (props: NonProdEnvironmentsPropType) => {
  const { appSettings } = useGlobalState();
  const { value, fieldError, errorText, onChangeHandler, onBlurHandler } =
    props;
  const maxNpEnvironments = parseInt(appSettings.maxNpEnvironments.get(), 10);

  return (
    <CommonEnvMultiSelect
      componentID="nonProdEnvironments"
      label={`Non Production Environment${maxNpEnvironments > 1 ? 's' : ''}`}
      data={nonProdEnvironmentsNames}
      value={maxNpEnvironments > 1 ? value : value[0]}
      valueMax={maxNpEnvironments}
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      fieldError={fieldError}
      helperText={
        fieldError && errorText
          ? errorText
          : 'Select from the list of available non production environment names.'
      }
      fullHelpText={
        maxNpEnvironments > 1
          ? `Non production environments to be built. You can have up to ${maxNpEnvironments} non production environments.`
          : 'Non production environment to be built.'
      }
    />
  );
};

NonProdEnvironments.defaultProps = {
  errorText: undefined,
};

export default NonProdEnvironments;
