import { gql } from '@apollo/client';

const LIST_ECP_ONBOARDING_REQUESTS_QUERY = gql`
  query listECPOnboardingRequests {
    listECPOnboardingRequests {
      code
      status
      response {
        id
        requestType
        environmentType
        createdDateTime
        hasRunningPipelines
        cartsApprovalRequestId
        status
        namespaceOnboarding {
          teamName
          namespaceName
        }
        quayRegistryOnboarding {
          organizationName
        }
      }
      count
    }
  }
`;

export default LIST_ECP_ONBOARDING_REQUESTS_QUERY;
