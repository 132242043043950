import { Report } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { PageHeader } from '../../components';
import useStyles from './NotFoundPage.styles';

const NotFoundDisplayMessage =
  "We're sorry, we could not find the page you were looking for.";

const NotFoundPage = () => {
  const { classes } = useStyles();

  return (
    <div>
      <PageHeader icon={<Report />} title="404" subtitle="Page Not Found!" />
      <div className={classes.error}>
        <Typography variant="h5">{NotFoundDisplayMessage}</Typography>
      </div>
    </div>
  );
};

export { NotFoundDisplayMessage };
export default NotFoundPage;
