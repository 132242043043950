import { AadUser } from '@costco-service-catalog/bff-types';
import { CommonNonZUsersAutoComplete } from '../CommonFields';

interface ADOProjectBoardAdministratorsProps {
  value: AadUser[];
  onChangeHandler: (event: React.ChangeEvent<{}>, value: AadUser[]) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const ADOProjectBoardAdministrators = (
  props: ADOProjectBoardAdministratorsProps
) => {
  const { value, onChangeHandler, onBlurHandler } = props;

  return (
    <CommonNonZUsersAutoComplete
      componentID="adoProjectBoardAdministrators"
      label="ADO Board Administrators"
      value={value}
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      fieldError={false}
      helperText="Select from the list or enter a user name and hit the Enter key to add user as ADO Board Administrators."
      fullHelpText="ADO Board Administrators can create/manage workitems on the ADO boards along with added action for creating queries and dashboards. These users will have limited privileges on ADO pipelines."
    />
  );
};

export default ADOProjectBoardAdministrators;
