import { Error } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../../components';
import useStyles from './ErrorPage.styles';

const ErrorPage = () => {
  const { error } = useParams();

  const { classes } = useStyles();

  return (
    <div>
      <PageHeader
        icon={<Error />}
        title="Error"
        subtitle="An error has occurred"
      />
      <div className={classes.error}>
        <Typography variant="h5" sx={{ mb: '1rem' }}>
          We&apos;re sorry, the following error has occured. Please try logging
          out and back in. If the problem persists, please contact support.
        </Typography>
        <Typography>{error}</Typography>
      </div>
    </div>
  );
};

export default ErrorPage;
