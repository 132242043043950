import {
  ApplicationRequestInput,
  EnvironmentRequestInput,
  OfferingRequestInput,
  ProjectMemberInput,
} from '@costco-service-catalog/bff-types';
import { formValuesType } from '..';
import { CardDetailsType } from '../../types';

const generateApplicationRequest = (
  formValues: formValuesType,
  offerings: CardDetailsType[]
): ApplicationRequestInput => {
  const newApplicationRequest: ApplicationRequestInput = {
    applicationName: formValues.applicationName,
    applicationDescription: formValues.applicationDescription,
    requestedEnvironments: formValues.isEphemeral
      ? [{ name: 'EPH', cidrBlockSize: 25, type: 'np' }]
      : (formValues.nonProdEnvironments.concat(
          formValues.prodEnvironments
        ) as EnvironmentRequestInput[]),
    primaryRegion: formValues.primaryRegion,
    secondaryRegion: formValues.isEphemeral ? null : formValues.secondaryRegion,
    supportGroupEmail: formValues.supportGroupEmail,
    managementGroup: {
      displayName: formValues.managementGroup.displayName as string,
      name: formValues.managementGroup.name as string,
    },
    additionalCollaborators: formValues.additionalCollaborators?.map(
      (m) => m.userPrincipalName!
    ),
    ecoSystemNPRequest: formValues.ecoSystemNP,
    ecoSystemPDRequest: formValues.ecoSystemPD,
    aadGroupMembers: formValues.aadGroupMembers?.map((m) => {
      const castToORI: ProjectMemberInput = {
        id: m.id,
        email: m.userPrincipalName,
      };
      return castToORI;
    }),
    aadReaderGroupMembers: formValues.aadReaderGroupMembers?.map((m) => {
      const castToORI: ProjectMemberInput = {
        id: m.id,
        email: m.userPrincipalName,
      };
      return castToORI;
    }),
    wizMembers: formValues.wizMembers?.map((m) => {
      const castToORI: ProjectMemberInput = {
        id: m.id,
        email: m.userPrincipalName,
      };
      return castToORI;
    }),
    applicationRequestDraftId: formValues.starterKitDraftId,
    offeringRequests: offerings.map((offering) => {
      const castToORI: OfferingRequestInput = {
        offeringName: offering.name,
        offeringRepoUrl: offering.repoUrl,
        offeringVersion: offering.version,
        category: offering.category,
        offeringShortName: offering.shortOfferingName,
        offeringVnetPeered: offering.vnetPeered,
        offeringServiceNames: offering.applicationList,
      };
      return castToORI;
    }),
    isEphemeral: formValues.isEphemeral,
  };

  if (formValues.isExistingAdoProject) {
    newApplicationRequest.existingAdoProjectRequest = {
      projectOrg: formValues.existingAdoProjectOrg,
      projectName: formValues.existingAdoProject.name as string,
    };
  } else {
    newApplicationRequest.newAdoProjectRequest = {
      adoProjectAdministrators: {
        members: formValues.adoProjectAdministrators.map((user) => {
          const castToORI: ProjectMemberInput = {
            id: user.id,
            email: user.userPrincipalName,
          };
          return castToORI;
        }),
      },
      adoProjectBoardAdministrators: {
        members: formValues.adoProjectBoardAdministrators?.map((user) => {
          const castToORI: ProjectMemberInput = {
            id: user.id,
            email: user.userPrincipalName,
          };
          return castToORI;
        }),
      },
      adoProjectBoardsProcess: formValues.adoProjectBoardsProcess?.name,
      adoProjectContributors: {
        members: formValues.adoProjectContributors?.map((user) => {
          const castToORI: ProjectMemberInput = {
            id: user.id,
            email: user.userPrincipalName,
          };
          return castToORI;
        }),
      },
    };
  }

  return newApplicationRequest;
};

export default generateApplicationRequest;
