import { object, string } from 'yup';

const addEnvironmentValidationSchema = (isValidEnvironmentName: boolean) =>
  object({
    environmentName: string().required('You must name the new environment.'),
    selectedApplication: object().required('You must select an application'),
    selectedEnvironment: object().required(
      'You must select an environment type'
    ),
    selectedCidr: string().required('You must select a CIDR block range.'),
  });

export default addEnvironmentValidationSchema;
