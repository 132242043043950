import { gql } from '@apollo/client';

const SEARCH_AAD_NONZ_USERS_QUERY = gql`
  query searchAADNonZUsers($searchString: String) {
    searchAADNonZUsers(searchString: $searchString) {
      code
      count
      response {
        name
        id
        userPrincipalName
        firstName
        lastName
        email
      }
    }
  }
`;

export default SEARCH_AAD_NONZ_USERS_QUERY;
