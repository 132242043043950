import { gql } from '@apollo/client';

const GET_ADO_BOARDS_PROCESSES_QUERY = gql`
  query GetAdoBoardsProcesses {
    getAdoBoardsProcesses {
      code
      status
      response {
        id
        name
        displayName
      }
      count
    }
  }
`;

export default GET_ADO_BOARDS_PROCESSES_QUERY;
