import { gql } from '@apollo/client';

const SUBMIT_NEW_ECP_ONBOARDING_REQUEST_MUTATION = gql`
  mutation submitECPOnboardingRequest($input: EcpOnboardingRequestInput) {
    submitECPOnboardingRequest(input: $input) {
      status
      code
      response {
        id
      }
    }
  }
`;

export default SUBMIT_NEW_ECP_ONBOARDING_REQUEST_MUTATION;
