import { ApolloError, useMutation } from '@apollo/client';
import { useApolloErrors } from '..';
import { SETUP_WIZ_INTEGRATION } from '../../apollo/queries';

const useSetupWizIntegration = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [setupWizIntegration, { loading: setupWizIntegrationLoading }] =
    useMutation(SETUP_WIZ_INTEGRATION, {
      onError: (error: ApolloError) => handleErrorResponse(error),
    });

  return {
    setupWizIntegration,
    loading: setupWizIntegrationLoading,
  };
};

export default useSetupWizIntegration;
