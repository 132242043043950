import { AdoBuildStatusItem } from '@costco-service-catalog/bff-types';
import { AccessTime, Cancel, CheckCircleOutline } from '@mui/icons-material';
import { Box, CircularProgress, Tooltip } from '@mui/material';
import BuildStatusPopover from './BuildStatusPopover';

interface BuildStatusItemProps {
  item: AdoBuildStatusItem | null;
  isPopover?: boolean;
}

const BuildStatusItem = (props: BuildStatusItemProps) => {
  const { item, isPopover } = props;

  if (!item) {
    return null;
  }

  switch (item.state) {
    case 'completed':
      return (
        <Tooltip title={item.name}>
          {item.result === 'failed' ? (
            <Cancel fontSize="medium" sx={{ color: 'red' }} />
          ) : (
            <CheckCircleOutline fontSize="medium" sx={{ color: 'green' }} />
          )}
        </Tooltip>
      );
    case 'pending':
      return (
        <Tooltip title={item.name}>
          <AccessTime fontSize="medium" sx={{ color: '#bababa' }} />
        </Tooltip>
      );
    case 'inProgress':
      if (isPopover) {
        return (
          <Tooltip title={item.name}>
            <Box
              sx={{
                position: 'relative',
                display: 'inline-flex',
                top: '-2px',
                margin: '0 3px',
              }}
            >
              <CircularProgress size={20} thickness={5} />
            </Box>
          </Tooltip>
        );
      }
      return <BuildStatusPopover item={item} />;
    default:
      return null;
  }
};

BuildStatusItem.defaultProps = {
  isPopover: false,
};

export default BuildStatusItem;
