import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  headingContainer: {
    paddingLeft: '1vw',
    paddingRight: '6vw',
    paddingTop: '1vh',
    paddingBottom: '1vh',
  },
  utilsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: '2vh',
    paddingBottom: '4vh',
  },
}));

export default useStyles;
