import CloseIcon from '@mui/icons-material/Close';
import { Alert, Badge, Box, Button, IconButton, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import useStyles from './AppHistoryDrawer.styles';

interface AppHistoryDrawerProps {
  notifications: {
    id?: string | number;
    message?: string | number;
    severity: string;
  }[];
  onClose: (e: any) => void;
  onClear: () => void;
}

const AppHistoryDrawer = (props: AppHistoryDrawerProps) => {
  const { notifications, onClear, onClose } = props;
  const theme = useTheme();
  const { classes } = useStyles();
  const [history, setHistory] = useState<any[]>([]);
  const [filteredHistory, setFilteredHistory] = useState<any[]>([]);
  useEffect(() => {
    setHistory(notifications);
  }, [notifications]);

  return (
    <Box className={classes.listBox}>
      <div className={classes.listControls}>
        <h4>Notification History</h4>
        <Button variant="outlined" size="small" onClick={onClear}>
          Clear
        </Button>
      </div>
      <div className={classes.list}>
        {history &&
          history.map((item, val) => (
            <div className={classes.historyItem} key={`${item.id}`}>
              <Alert
                severity={item.severity}
                action={
                  <IconButton
                    id={item.id}
                    color="inherit"
                    size="small"
                    onClick={onClose}
                  >
                    <CloseIcon />
                  </IconButton>
                }
              >
                <div className={classes.historyDateStamp}>
                  <Badge color="primary" variant="dot" invisible={!item.unread}>
                    {new Date(item.id).toLocaleString()}
                  </Badge>
                </div>
                <div className={classes.historyMessage}>{item.message}</div>
              </Alert>
            </div>
          ))}
        {!history.length && <div className={classes.empty}>No history</div>}
      </div>
    </Box>
  );
};

export default AppHistoryDrawer;
