import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: 'none',
  },
  chip: {
    margin: '.25rem',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.info.light,
    cursor: 'pointer',
  },
  chipLight: {
    color: theme.palette.info.dark,
  },
  refreshButton: {
    marginRight: '1rem',
  },
  buttonWrap: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  environmentsTable: {
    width: '100%',
    padding: '.5rem',
    '& th': {
      textAlign: 'left',
    },
    '& td': {
      borderTop: '1px solid rgba(0,0,0,0.15)',
    },
  },
  offeringsTable: {
    width: '100%',
  },
  divider: {
    height: '1px',
    background: 'rgba(0,0,0,0.15)',
    width: '100%',
    marginBottom: '1rem',
  },
  retryButton: {
    marginTop: '1rem',
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignRight: {
    textAlign: 'right',
  },
  starterKitRequestsHeaderContainer: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  starterKitRequestsHeaderText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '10px',
  },
  starterKitRequestsHeaderButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
  },
  adminPaper: {
    paddingTop: '0 !important',
    marginBottom: '2rem',
  },
  ButtonSpinnerContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  SpinnerStyling: {
    color: theme.palette.primary.main,
  },
  subscriptionNameWithWiz: {
    marginRight: 10,
  },
}));

export default useStyles;
