import { ApolloError, useQuery } from '@apollo/client';
import {
  RepoRefs,
  RepoRefsParams,
  RepoRefsResponse,
} from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '..';
import { GET_REPO_REFS_QUERY } from '../../apollo/queries';

const useGetRepoRefs = (params: RepoRefsParams) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getRepoRefs: RepoRefsResponse;
  }>(GET_REPO_REFS_QUERY, {
    variables: { params },
    fetchPolicy: 'network-only',
    onError: (error: ApolloError) => handleErrorResponse(error),
  });
  const [RepoRefList, setRepoRefList] = useState<RepoRefs[]>();

  useEffect(() => {
    if (!loading && data) {
      setRepoRefList((data?.getRepoRefs?.response as RepoRefs[]) || undefined);
    }
  }, [loading, data]);

  return {
    error,
    data: RepoRefList,
    loading,
    refetch,
  };
};

export default useGetRepoRefs;
