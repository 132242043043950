import { ApolloError } from '@apollo/client/errors';
import { AzurePolicy } from '@costco-service-catalog/bff-types';

const mockPolicies = [
  {
    id: '65788158251aea0fffa53909',
    name: 'CloudSecurity_Benchmark',
    displayName: 'Cloud Security Benchmark',
    description: 'Cloud Security Benchmark for Costco.',
  },
  {
    id: '65788158251aea0fffa5390b',
    name: 'GuestConfiguration_WindowsPasswordSettingsAINE',
    displayName: 'Windows Password Settings',
    description:
      'This initiative deploys the policy requirements and audits machines with insecure password security settings. For more information on Guest Configuration policies, please visit https://aka.ms/gcpol',
  },
  {
    id: '65788158251aea0fffa5390d',
    name: 'ASC_AzureDefenderForSql',
    displayName: 'Azure Defender for SQL',
    description:
      'Enable Azure Defender on your SQL Servers and SQL Managed Instances to detect anomalous activities indicating unusual and potentially harmful attempts to access or exploit databases.',
  },
];

const mockGetAzurePoliciesRes = () => {
  const error: ApolloError | undefined = undefined;
  const data: AzurePolicy[] = mockPolicies;
  const loading: boolean = false;
  const handleOnInputChange = () => {};
  const getAzurePolicies = () => {};
  return {
    error,
    data,
    loading,
    handleOnInputChange,
    getAzurePolicies,
  };
};

export default mockGetAzurePoliciesRes;
