import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  pageLayout: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 200,
  },
}));

export default useStyles;
