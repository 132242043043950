import { gql } from '@apollo/client';

const BREAK_TF_STATE_BLOB_LEASE = gql`
  mutation BreakTFStateBlobLease($input: BreakBlobLeaseInput!) {
    breakTFStateBlobLease(input: $input) {
      code
      status
    }
  }
`;

export default BREAK_TF_STATE_BLOB_LEASE;
