import {
  useAADUsers,
  useOfferings,
  useManagementGroups,
  useADOOrgs,
  useADOProjectsForOrg,
} from '../../hooks';

const useHealthCheckPage = () => {
  const title = 'Health Check Page';
  const subtitle = 'Lookup Service Tests';

  const {
    error: AADUsersError,
    loading: AADUsersLoading,
    data: AADUsersData,
  } = useAADUsers();

  const {
    error: offeringsError,
    loading: offeringsLoading,
    data: offeringsData,
  } = useOfferings();

  const {
    error: ManagementGroupsError,
    loading: ManagementGroupsLoading,
    data: ManagementGroupsData,
  } = useManagementGroups();

  const {
    error: ADOOrgsError,
    loading: ADOOrgsLoading,
    data: ADOOrgsData,
  } = useADOOrgs();

  const {
    error: ADOProjectsForOrgError,
    loading: ADOProjectsForOrgLoading,
    data: ADOProjectsForOrgData,
  } = useADOProjectsForOrg('costcocloudops');

  return {
    title,
    subtitle,
    AADUsersError,
    AADUsersLoading,
    AADUsersData,
    offeringsError,
    offeringsLoading,
    offeringsData,
    ManagementGroupsError,
    ManagementGroupsLoading,
    ManagementGroupsData,
    ADOOrgsError,
    ADOOrgsLoading,
    ADOOrgsData,
    ADOProjectsForOrgError,
    ADOProjectsForOrgLoading,
    ADOProjectsForOrgData,
  };
};

export default useHealthCheckPage;
