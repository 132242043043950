import { gql } from '@apollo/client';

const GET_ADO_PROJECTS_FOR_ORG_QUERY = gql`
  query getAdoProjectsForOrg($adoOrg: String) {
    getAdoProjectsForOrg(adoOrg: $adoOrg) {
      code
      count
      response {
        id
        name
        url
      }
    }
  }
`;

export default GET_ADO_PROJECTS_FOR_ORG_QUERY;
