import { gql } from '@apollo/client';

const GET_APPLICATION_DRAFT_QUERY = gql`
  query getApplicationRequestDraft($params: EntityParams) {
    getApplicationRequestDraft(params: $params) {
      status
      code
      response {
        id

        managementGroup {
          name
          displayName
        }
        applicationName
        applicationDescription
        supportGroupEmail
        requestedEnvironments {
          type
          name
          cidrBlockSize
        }
        primaryRegion
        secondaryRegion
        additionalCollaborators {
          id
          name
          userPrincipalName
        }
        aadGroupMembers {
          id
          name
          userPrincipalName
        }
        aadReaderGroupMembers {
          id
          name
          userPrincipalName
        }
        wizMembers {
          id
          name
          userPrincipalName
        }
        isExistingAdoProject
        existingAdoProjectOrg
        existingAdoProject {
          id
          name
        }
        adoProjectContributors {
          id
          name
          userPrincipalName
        }
        adoProjectAdministrators {
          id
          name
          userPrincipalName
        }
        adoProjectBoardAdministrators {
          id
          name
          userPrincipalName
        }
        offeringRequests {
          category
          offeringName
          offeringVersion
          offeringRepoUrl
          offeringShortName
          offeringVnetPeered
          offeringServiceNames
        }
      }
    }
  }
`;

export default GET_APPLICATION_DRAFT_QUERY;
