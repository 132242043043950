import { AadUser } from '@costco-service-catalog/bff-types';
import React from 'react';
import { CommonAutocomplete } from '.';
import { useAADUsers } from '../../../hooks';

interface CommonZUsersAutocompleteProps {
  componentID: string;
  label: string;
  value: AadUser[];
  fieldError: boolean;
  helperText: string;
  fullHelpText: string;
  required?: boolean;
  onChangeHandler: (event: React.ChangeEvent<{}>, value: AadUser[]) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const CommonZUsersAutocomplete = (props: CommonZUsersAutocompleteProps) => {
  const {
    componentID,
    label,
    value,
    fieldError,
    helperText,
    fullHelpText,
    required,
    onChangeHandler,
    onBlurHandler,
  } = props;

  const { data, loading } = useAADUsers();

  return (
    <CommonAutocomplete
      componentID={componentID}
      label={label}
      data={data}
      value={value}
      fieldError={fieldError}
      helperText={helperText}
      fullHelpText={fullHelpText}
      loading={loading}
      required={required}
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      getOptionLabel={(option: AadUser) => (option.name ? option.name : '')}
      getOptionSelected={(o, v) => o.userPrincipalName === v.userPrincipalName}
    />
  );
};

CommonZUsersAutocomplete.defaultProps = {
  required: false,
};

export default CommonZUsersAutocomplete;
