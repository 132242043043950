import ansibleIcon from '../../assets/how-to-page/icons/ansible tower logo.png';
import automationIcon from '../../assets/how-to-page/icons/automation acct.png';
import azureActiveDirectoryIcon from '../../assets/how-to-page/icons/Azure Active Directory.png';
import azureDevOpsIcon from '../../assets/how-to-page/icons/Azure DevOps.png';
import azureSubnetIcon from '../../assets/how-to-page/icons/Azure Subnet.png';
import cloudEdgeIcon from '../../assets/how-to-page/icons/cloud edge 5.png';
import cosmosDBIcon from '../../assets/how-to-page/icons/CosmosDB.png';
import dataBricksIcon from '../../assets/how-to-page/icons/Data Bricks.png';
import dataLakeIcon from '../../assets/how-to-page/icons/Data Lake.png';
import eventHubsIcon from '../../assets/how-to-page/icons/Event Hubs.png';
import githubDevIcon from '../../assets/how-to-page/icons/github developer.jpg';
import highAvailabilityIcon from '../../assets/how-to-page/icons/HADR3.png';
import infosecTaggingIcon from '../../assets/how-to-page/icons/InfosecTagging.png';
import itocIcon from '../../assets/how-to-page/icons/ITOCSupport.png';
import keyFactorIcon from '../../assets/how-to-page/icons/keyfactor.png';
import keyVaultIcon from '../../assets/how-to-page/icons/KeyVault.png';
import liveShareIcon from '../../assets/how-to-page/icons/live share.png';
import networkingIcon from '../../assets/how-to-page/icons/Networking.png';
import puppetWindowsIcon from '../../assets/how-to-page/icons/new3 windows vm puppet.png';
import networkSecurityGroupIcon from '../../assets/how-to-page/icons/NSG.png';
import onPremisesDataGatewayIcon from '../../assets/how-to-page/icons/on prem data gateway.png';
import pathToProdIcon from '../../assets/how-to-page/icons/Path to PROD.png';
import pipelinesLogoWithTFEIcon from '../../assets/how-to-page/icons/pipelinesLogoWithTFE.png';
import privateEndpointIcon from '../../assets/how-to-page/icons/private endpoint.png';
import puppetEnterpriseIcon from '../../assets/how-to-page/icons/puppet ent 2.png';
import puppetLinuxIcon from '../../assets/how-to-page/icons/Puppet Linux VM.png';
import routeTableIcon from '../../assets/how-to-page/icons/Route Table logo.png';
import siteRecoveryIcon from '../../assets/how-to-page/icons/site recovery (recovery vault).png';
import vmAutomationIcon from '../../assets/how-to-page/icons/Start - Stop VMs.png';
import synapseIcon from '../../assets/how-to-page/icons/Synapse.png';
import terraformIcon from '../../assets/how-to-page/icons/Terraform.png';
import azureIcon from '../../assets/how-to-page/icons/v2 azure info.png';

const HowToPageData = [
  {
    category: 'Guide',
    title: 'Terraform - How to Start - User Guide',
    icon: terraformIcon,
    url: 'https://docs.google.com/document/d/1qnMK-4a2BlMVhtPZh6g37Xt3h5r4rys4xWS7CFmv2nQ/edit?usp=sharing',
    tags: ['Terraform'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Encrypting Secrets In Terraform',
    icon: terraformIcon,
    url: 'https://docs.google.com/document/d/18ivXNPoVzQlIPqGSJ-fyomFpqVlnyPzTsmMMMLQbCVk/edit?usp=sharing',
    tags: ['Encryption', 'Secrets', 'Terraform'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Tips and Tricks for Terraform',
    icon: terraformIcon,
    url: 'https://docs.google.com/document/d/1qzIjH3CCW6P8HYIMjDQZiAXzSnotKPfgKBRlSTR3sZc/edit?usp=sharing',
    tags: ['Terraform'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Costco Terraform Enterprise - Error Log',
    icon: terraformIcon,
    url: 'https://docs.google.com/document/d/1AlHdLuUIcvmnHL9EVb3XnWt4TZC9XzNAyeZJ8rjwkVY/edit?usp=sharing',
    tags: ['Terraform'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Github Enterprise Developer Guide',
    icon: githubDevIcon,
    url: 'https://docs.google.com/document/d/1NIPWHWH1bSy5vY9sJmnobe0ZI54IiBW-Z2PXvvNWOE8/edit?usp=sharing',
    tags: ['Github'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Costco Puppet (sc-rt-cwpuppet) Offerings - User Guide',
    icon: puppetEnterpriseIcon,
    url: 'https://docs.google.com/document/d/1Rv_bM2V_mUbM2WN2YQ0uk5oPf3DxCjJiVuvyE05Z3nU/edit?usp=sharing',
    tags: ['Puppet'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Ansible Tower (sc-rt-cwansible) Offerings - User Guide',
    icon: ansibleIcon,
    url: 'https://docs.google.com/document/d/1E0yClXHlbY_A0zdeCtOi1tPjuAfrTG2gXzv_v4sTO04/edit?usp=sharing',
    tags: ['Ansible'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Request Costco Azure DevOps Project',
    icon: azureDevOpsIcon,
    url: 'https://docs.google.com/document/d/1uPikm6lNskasxhN3yMEpo3PBm6J_qiL3iQ5C2oPFPHY/edit?usp=sharing',
    tags: ['Azure'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Path to PROD: Azure Onboarding Guide',
    icon: pathToProdIcon,
    url: 'https://docs.google.com/document/d/1iGuOcWtbqmib3NyIyCoMcFVF9E_5cFMzMJaXMxTBH6M/edit?usp=sharing',
    tags: ['Azure'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Costco Approved Azure Services',
    icon: azureIcon,
    url: 'https://www.google.com/url?q=https%3A%2F%2Fapp.powerbi.com%2Fgroups%2Fme%2Fapps%2F4d361523-74ab-4b51-925c-2349553bde29%2Freports%2F4590445b-d8af-40f8-9b00-c2d065d90520%2FReportSection&sa=D&sntz=1&usg=AFQjCNEFx9vCTJws-j3ImiLo0VXPqh0Vvg',
    tags: ['Azure', 'Microsoft Power BI'],
    ready: true,
  },
  {
    category: 'Guide',
    title: 'Cloud Edge Onboarding Guide',
    icon: cloudEdgeIcon,
    url: 'https://docs.google.com/document/d/15Lun5lBFITYLKm-zo7Z9Qrp5phB2jsJDQQB4q6VNvOk/edit?usp=sharing',
    tags: ['Cloud Edge'],
    ready: false,
  },
  {
    category: 'Azure',
    title: 'How To Guide for Azure Key Vault',
    icon: keyVaultIcon,
    url: 'https://docs.google.com/document/d/11_TJtYUkmAsLhKmu15iLmWIKG_jAUxGsJdOq8HKtTqk/edit?usp=sharing',
    tags: ['Azure', 'Key Vault'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'How-to Guide for Cosmos DB',
    icon: cosmosDBIcon,
    url: 'https://docs.google.com/document/d/15SIevfhfV9dtTaX45XFef5VZ_epC5iLqJHTO_7QCR8A/edit?usp=sharing',
    tags: ['Cosmos DB'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'How-to Guide for Azure Data Lake',
    icon: dataLakeIcon,
    url: 'https://docs.google.com/document/d/1N6C9p3GXEfVKem6P6gBbcW4n9n1ZMBQuF_h-wYTvtmc/edit?usp=sharing',
    tags: ['Azure'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'How-to Guide for Azure Synapse',
    icon: synapseIcon,
    url: 'https://docs.google.com/document/d/1lz0GEQfcbqB7nVLhwibqNfPJ8U0nHAc7RiyovDUJqvQ/edit?usp=sharing',
    tags: ['Azure'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'How-to Guide for Azure Event Hubs',
    icon: eventHubsIcon,
    url: 'https://docs.google.com/document/d/1kZZfetH3R9hlO8ojvOifhhwT9FeW_Q7uV7OoeygOzVU/edit?usp=sharing',
    tags: ['Azure'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'How-to Guide for Azure Data Bricks',
    icon: dataBricksIcon,
    url: 'https://docs.google.com/document/d/13tqEWDblpgthQhp4JjIqJ8j_WeSgZOEpg1kE-mvQKpo/edit?usp=sharing',
    tags: ['Azure'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'High Availability with Costco VMs How-to',
    icon: highAvailabilityIcon,
    url: 'https://docs.google.com/document/d/1LSU4bOGMc-FVU2gkDDtdIvqNOrRVVH0oBy7sKhw8L_A/edit?usp=sharing',
    tags: ['Virtual Machines'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'How-to Guide On-premises Data Gateway',
    icon: onPremisesDataGatewayIcon,
    url: 'https://docs.google.com/document/d/1ZOhdQQmyAAfTwyRbHXPIfmZhWwM65cUPSyTcIIdKKT0/edit?usp=sharing',
    tags: ['On Premises'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'How-to for recovery vault in Azure',
    icon: siteRecoveryIcon,
    url: 'https://docs.google.com/document/d/1yZqBCQaoGqAmfH9cCG4vUdlfgEfuymPQhMgReAJyN24/edit?usp=sharing',
    tags: ['Azure'],
    ready: false,
  },
  {
    category: 'InfoSec',
    title: 'InfoSec Tagging Standards',
    icon: infosecTaggingIcon,
    url: 'https://costcoitrepository.atlassian.net/wiki/spaces/TechArch/pages/1650196547/Azure+Tagging+Standards',
    tags: ['InfoSec', 'Security'],
    ready: true,
  },
  {
    category: 'Guide',
    title: 'Provision Azure Linux VM with Puppet',
    icon: puppetLinuxIcon,
    url: 'https://docs.google.com/document/d/15BJelaaMCoFkh5gktTBUfeaNVOsQJqx2mQ5Kl7Py3tY/edit?usp=sharing',
    tags: ['Azure', 'Linux', 'Puppet', 'Virtual Machine'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Requesting Read Azure Active Directory',
    icon: azureActiveDirectoryIcon,
    url: 'https://docs.google.com/document/d/1VAq6wLvX2hk1DKkk60ZW7zxgCWPyZUmdM1l6dd2yPDI/edit?usp=sharing',
    tags: ['Azure'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Provision Azure Windows VM with Puppet',
    icon: puppetWindowsIcon,
    url: 'https://docs.google.com/document/d/18loOjYHTQkjJlzt-JwYvgl9GVF_oWO-vtlTtC4VPr1w/edit?usp=sharing',
    tags: ['Puppet'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Requesting Route Table Exceptions',
    icon: routeTableIcon,
    url: 'https://docs.google.com/document/d/1KNjoWJxtWflXZJfCql7tRO9Ry15zsM24ihoZC0oZl0o/edit?usp=sharing',
    tags: ['Route Table'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'How-to Guide for Azure Subnet',
    icon: azureSubnetIcon,
    url: 'https://docs.google.com/document/d/18a74g6yyFlHMKL7TaaI59aMc0aDuAzDeZYErxLeKSpQ/edit?usp=sharing',
    tags: ['Azure'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Costco Networking (sc-rt-cwnetworking) Offerings - Users Guide',
    icon: networkingIcon,
    url: 'https://docs.google.com/document/d/1NcqaWvjeL3w9xpRazMRItWN0O_H6l01_M82Ot0PpnPQ/edit?usp=sharing',
    tags: ['sc-rt-cwnetworking', 'Networking'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'How-to Guide for Private Endpoint',
    icon: privateEndpointIcon,
    url: 'https://docs.google.com/document/d/1Adrry1PdJzeHL3lHYs0W4QEjeVBJdt_SaVew0JSvR3Q/edit?usp=sharing',
    tags: ['Endpoint', 'Networking'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Costco IAM (sc-rt-cwiam) Offerings - Users Guide',
    icon: networkingIcon,
    url: 'https://docs.google.com/document/d/1N5a2VADHh1F5S7jwIgqcZpVvMdvi0rwZZg2aVpn7PQo/edit?usp=sharing',
    tags: ['sc-rt-cwiam', 'IAM', 'Networking'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Provision an Automation Account',
    icon: automationIcon,
    url: 'https://docs.google.com/document/d/1OjoD5HGwB_atf_NS-qObK3Svs9HQKK5U710DQuR34gY/edit?usp=sharing',
    tags: ['Provision', 'Automation'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Costco IAM AD/DNS Service Gateways',
    icon: networkingIcon,
    url: 'https://docs.google.com/document/d/1NLGT4v9gvNQI1qMxSk3BFflbDPmN7DTbKbExm3_wbkw/edit?usp=sharing',
    tags: ['Azure', 'sc-rt-cwiam', 'DNS', 'Gateway', 'IAM', 'Networking'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Create and Use Network Security Groups',
    icon: networkSecurityGroupIcon,
    url: 'https://docs.google.com/document/d/1d-P0JA-i_6L7GQ1vM-PPGIfgUBPflGublVVyETZXnlo/edit?usp=sharing',
    tags: ['Networking', 'Security'],
    ready: false,
  },
  {
    category: 'Guide',
    title: 'Live Share: Remote Pair Programming',
    icon: liveShareIcon,
    url: 'https://docs.google.com/presentation/d/1IZEiHDQM0jNURoxpDEPQvYrO-gUY7Uz8eUhJ3QJQyug/edit?usp=sharing',
    tags: ['Live Share', 'Pair Programming'],
    ready: false,
  },
  {
    category: 'Virtual Machine',
    title: 'VM Start/Stop Automation',
    icon: vmAutomationIcon,
    url: 'https://docs.google.com/document/d/1LikPCzcrysWn_rdlStOoeFW4IpKvLIdbcd8luid5vBw/edit?usp=sharing',
    tags: ['Virtual Machine'],
    ready: false,
  },
  {
    category: 'ITOC Support',
    title: '24/7 ITOC Support for Azure Monitor Alerts Guide',
    icon: itocIcon,
    url: 'https://docs.google.com/document/d/1GxE7EYgi_g-cSh88M8rmzDeFXuJsTTZFlRJ2yrudkQM/edit?usp=sharing',
    tags: ['Azure'],
    ready: false,
  },
  {
    category: 'Azure',
    title: 'How-to for ADO Pipeline via TFE API',
    icon: pipelinesLogoWithTFEIcon,
    url: 'https://docs.google.com/document/d/1MGOWpcq7u2VkQTeSqrUagEdVFT5ryAjmhWYNpMQJ5oQ/edit?usp=sharing',
    tags: ['Azure', 'Pipelines', 'ADO', 'TFE'],
    ready: false,
  },
  {
    category: 'Puppet',
    title:
      'Automating PKI Certificate Management with the Keyfactor API & Puppet',
    icon: keyFactorIcon,
    url: 'https://docs.google.com/presentation/d/1__ceJZ0s_REwyovRxhnfkYoc5zBzldtN//edit?usp=sharing',
    tags: ['Puppet', 'Keyfactor', 'PKI'],
    ready: false,
  },
];

export default HowToPageData;
