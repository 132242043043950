import { Application } from '@costco-service-catalog/bff-types';

export interface StarterKitRequestsStatusesType {
  id: string | undefined;
  cartsId: string | undefined;
  time: string | undefined;
  status: string | undefined;
}

export interface OfferingRequestStatusesType {
  name: string | undefined;
  status: string | undefined;
}

interface DeployedAppCompleteResponse {
  completed: boolean;
  notCompleteStarterKits?: StarterKitRequestsStatusesType[];
  notCompleteOfferings?: OfferingRequestStatusesType[];
}

/*
 * deployedApplicationHasCompletedAll checks if
 * 1) there is at least one Starter Kit Request Status
 * 2) there is at least one Offering Request Status
 * 3) there are no starter kits that are not marked as 'Completed'
 * 4) there are no offerings that are not marked as 'Completed'
 */

export const deployedApplicationHasCompletedAll = (data: Application) => {
  let deployedAppComplete: DeployedAppCompleteResponse = { completed: false };

  const starterKitRequestsStatuses = data.starterKitRequests?.map(
    (request) => ({
      id: request?.id,
      cartsId: request?.cartsApprovalRequestId,
      time: request?.requestedDateTime,
      status: request?.status,
    })
  ) as StarterKitRequestsStatusesType[];

  const offeringRequestStatuses = data.starterKitRequests?.flatMap(
    (SKrequest) =>
      SKrequest?.offerings?.map((offeringRequest) => ({
        name: offeringRequest?.offeringName,
        status: offeringRequest?.status,
      }))
  ) as OfferingRequestStatusesType[];

  /*
   * If there are no starter kit requests or offering requests
   * within the application, then we assume that the application is in
   * a clean state and can have a Landing Zone + etc. deployed to it.
   */
  if (
    starterKitRequestsStatuses.length === 0 ||
    offeringRequestStatuses.length === 0
  ) {
    deployedAppComplete = {
      completed: true,
    };
    return deployedAppComplete;
  }

  if (
    starterKitRequestsStatuses &&
    offeringRequestStatuses &&
    starterKitRequestsStatuses.length > 0 &&
    offeringRequestStatuses.length > 0
  ) {
    const notCompleteStarterKits = starterKitRequestsStatuses.filter(
      (sk) => sk.status !== 'Completed'
    );

    const notCompleteOfferings = offeringRequestStatuses.filter(
      (sk) => sk.status !== 'Completed'
    );

    if (
      notCompleteStarterKits.length === 0
      /*
       * Not complete offerings should trigger an incomplete starter kit on the pipeline,
       * but we may want to make this clear to the user in the future.
       *
       * && notCompleteOfferings.length === 0
       */
    ) {
      deployedAppComplete = {
        completed: true,
      };
      return deployedAppComplete;
    }
    deployedAppComplete = {
      completed: false,
      notCompleteStarterKits,
      notCompleteOfferings,
    };
    return deployedAppComplete;
  }
  return deployedAppComplete;
};

// Handles deployedApplicationHasCompletedAll after checking that the selected deployed application has been passed properly.
export const validateDeployedApplicationIsComplete = (
  deployedApplication: Application,
  data: Application | undefined,
  error: string | undefined,
  loading: boolean | undefined
) => {
  if (!loading && deployedApplication) {
    if (!error && data) {
      const validationLogic = deployedApplicationHasCompletedAll(data);
      return validationLogic;
    }
  }

  return { completed: false };
};
