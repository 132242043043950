import { Chip } from '@mui/material';

interface StatusChipProps {
  label: string | null;
}

const StatusChip = (props: StatusChipProps) => {
  const { label } = props;
  switch (label) {
    case 'AwaitingApproval':
    case 'AwaitingAdoRun':
      return (
        <Chip
          size="small"
          label={label && label.replace(/([A-Z])/g, ' $1').trim()}
          color="warning"
        />
      );
    case 'Denied':
    case 'Failed':
    case 'FailedToExecute':
      return (
        <Chip
          size="small"
          label={label && label.replace(/([A-Z])/g, ' $1').trim()}
          color="error"
        />
      );
    case 'Completed':
      return <Chip size="small" label={label} color="success" />;
    case 'Submitted':
      return <Chip size="small" label={label} color="info" />;
    case 'DeletionInProgress':
      return (
        <Chip
          size="small"
          label={label && label.replace(/([A-Z])/g, ' $1').trim()}
          color="error"
        />
      );
    case 'DeletionFailed':
      return (
        <Chip
          size="small"
          label={label && label.replace(/([A-Z])/g, ' $1').trim()}
          color="error"
        />
      );
    default:
      return (
        <Chip
          size="small"
          label={label && label.replace(/([A-Z])/g, ' $1').trim()}
        />
      );
  }
};

export default StatusChip;
