import {
  AadUser,
  ProjectMemberInput,
  SecurityGroupType,
  UserModificationInput,
} from '@costco-service-catalog/bff-types';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import * as React from 'react';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import {
  CommonNonZUsersAutoComplete,
  CommonZUsersAutocomplete,
} from '../FormComponents/CommonFields';
import useStyles from './AdminUsersDialog.styles';

export interface AdminUsersDialogProps {
  title: string;
  colTitle: string;
  userLists: AadUser[];
  handleClose: (event: React.MouseEvent<HTMLElement>) => void;
  handleAddUsers: (
    applicationId: string,
    setMemberList: (users: AadUser[]) => void,
    setLoading: (loading: boolean) => void,
    groupType: SecurityGroupType,
    groupName: string
  ) => void;
  handleUserChange: (event: React.ChangeEvent<{}>, value: AadUser[]) => void;
  handleDeleteUser: (
    userModificationInput: UserModificationInput,
    groupName: string,
    setMemberList: (users: AadUser[]) => void,
    setLoading: (loading: boolean) => void
  ) => void;
  open: any;
  groupType: SecurityGroupType;
  helperText: string;
  fullHelpText: string;
  value: AadUser[];
  landingZoneStatus: string;
  applicationId: string;
  preUserManagement: boolean;
}

const AdminUsersDialog = (props: AdminUsersDialogProps) => {
  const {
    title,
    colTitle,
    userLists,
    open,
    handleClose,
    handleAddUsers,
    handleUserChange,
    handleDeleteUser,
    groupType,
    helperText,
    fullHelpText,
    value,
    landingZoneStatus,
    applicationId,
    preUserManagement,
  } = props;

  const { classes } = useStyles();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [userToBeDeleted, setUserToBeDeleted] = React.useState<AadUser>();
  const [memberLists, setMemberLists] = React.useState<AadUser[]>(userLists);
  const [loading, setLoading] = React.useState<boolean>(false);
  const handleDialogOk = () => {
    setLoading(true);
    const deletedUser: ProjectMemberInput = {
      id: userToBeDeleted?.id,
      email: userToBeDeleted?.email,
    };

    const userModificationInput: UserModificationInput = {
      applicationId: applicationId,
      securityGroupType: groupType,
      users: [deletedUser],
    };

    handleDeleteUser(userModificationInput, title, setMemberLists, setLoading);

    setDialogOpen(false);
  };

  const handleDialogCancel = () => {
    setDialogOpen(false);
  };

  const handleUserDelete = (user: AadUser) => {
    setUserToBeDeleted(user);
    setDialogOpen(true);
  };

  const useZAccountUsers =
    groupType === 'AADContributor' || groupType === 'AADReader';

  let userManagementMsg = '';
  if (!preUserManagement) {
    userManagementMsg =
      'When your project has completed successfully, you will be able to add/remove users';
  }

  const disableDelete = (memberList: AadUser[]) =>
    (groupType === 'AADContributor' ||
      groupType === 'ADOProjectAdmin' ||
      groupType === 'WizMember') &&
    memberList.length === 1;

  const getDeleteTooltipText = (memberList: AadUser[]) =>
    !disableDelete(memberList)
      ? 'Delete'
      : 'At least one member is required in this group';

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        <div className={classes.adminUsersDialog}>
          <Typography variant="h6" component="p">
            {title} {loading && <CircularProgress size={20} />}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className={classes.adminUsersDialogContainer}>
        {landingZoneStatus === 'Completed' && !preUserManagement ? (
          <Card className={classes.adminUserDialogCard}>
            <div className={classes.adminUsersDialogControls}>
              {useZAccountUsers ? (
                <CommonZUsersAutocomplete
                  componentID={title}
                  label={title as string}
                  value={value}
                  fieldError={false}
                  helperText={helperText}
                  fullHelpText={fullHelpText}
                  onChangeHandler={handleUserChange}
                  onBlurHandler={() => {}}
                />
              ) : (
                <CommonNonZUsersAutoComplete
                  componentID={title}
                  label={title as string}
                  value={value}
                  fieldError={false}
                  helperText={helperText}
                  fullHelpText={fullHelpText}
                  onChangeHandler={handleUserChange}
                  onBlurHandler={() => {}}
                />
              )}
              <Button
                disabled={value.length === 0}
                variant="contained"
                color="primary"
                onClick={() => {
                  handleAddUsers(
                    applicationId,
                    setMemberLists,
                    setLoading,
                    groupType,
                    title
                  );
                }}
                className={` add-user-button ${classes.adminUsersDialogButton}`}
              >
                Add User
              </Button>
            </div>
          </Card>
        ) : (
          <p>{userManagementMsg}</p>
        )}

        <div className={classes.adminUsersTableContainer}>
          <TableContainer component={Paper} className={classes.adminUsersTable}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{ paddingLeft: '1.5rem', fontWeight: '700' }}
                  >
                    {colTitle}
                  </TableCell>
                  {landingZoneStatus === 'Completed' && !preUserManagement && (
                    <TableCell
                      align="right"
                      sx={{ paddingLeft: '1.5rem', fontWeight: '700' }}
                    >
                      Action
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {memberLists && memberLists.length > 0 ? (
                  memberLists.map((member: AadUser, index: number) => (
                    <TableRow
                      key={member.id ?? member.email}
                      data-testid="user-row"
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        data-testid={`user-name-cell-${index}`}
                        data-userid={index}
                        data-username={member.email}
                        align="left"
                        sx={{ paddingLeft: '1.5rem' }}
                      >
                        {member.email}
                      </TableCell>
                      {landingZoneStatus === 'Completed' &&
                        !preUserManagement && (
                          <TableCell
                            data-testid={`user-delete-cell-${index}`}
                            align="right"
                            sx={{ paddingLeft: '1.5rem' }}
                          >
                            <Tooltip
                              title={`${getDeleteTooltipText(memberLists)}`}
                            >
                              <Box>
                                <IconButton
                                  disabled={disableDelete(memberLists)}
                                  onClick={() => handleUserDelete(member)}
                                  data-userid={index}
                                  data-testid={`user-delete-icon-${index}`}
                                  className={`delete-icon delete-icon-${index} ${classes.adminUsersDeleteIcon}`}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </Tooltip>
                          </TableCell>
                        )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>The List is empty.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </DialogContent>
      <ConfirmDialog
        title="Delete User"
        message={`Are you sure you want to remove ${userToBeDeleted?.email} from member list?`}
        open={dialogOpen}
        handleOk={handleDialogOk}
        handleCancel={handleDialogCancel}
      />
    </Dialog>
  );
};

export default AdminUsersDialog;
