import { ApolloError, useMutation } from '@apollo/client';
import { ADD_USERS_TO_GROUP } from '../../apollo/queries';
import { useApolloErrors } from '../apolloHooks';

const useAddUsersToGroup = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [addUsers, { loading: addUsersLoading }] = useMutation(
    ADD_USERS_TO_GROUP,
    {
      onError: (error: ApolloError) => handleErrorResponse(error),
    }
  );

  return {
    addUsers,
    loading: addUsersLoading,
  };
};

export default useAddUsersToGroup;
