import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { utilitiesPayloadType } from '../../../../pages/ApplicationSummaryPage/useApplicationSummaryPage';
import { CommonDropdown } from '../../../FormComponents/CommonFields';
import useAddEnvironmentModal from './useAddEnvironmentModal';
import { availableBlockSizesNumber } from '../../../FormComponents/DataDrivenFields/CidrBlockSizeTable';

const AddEnvironmentModal = (props: {
  popup: utilitiesPayloadType | undefined;
  handleModalClose: () => void;
}) => {
  const { handleModalClose, popup } = props;

  const {
    formik,
    environmentNameFieldError,
    listApplicationsData,
    listApplicationsLoading,
    selectApplicationFieldError,
    selectedEnvironmentFieldError,
    availableCidrBlockSizes,
    selectedCidrBlockFieldError,
    prodEnvironmentsNames,
    nonProdEnvironmentsNames,
  } = useAddEnvironmentModal(handleModalClose, popup);
  const {
    environmentName,
    selectedApplication,
    selectedEnvironment,
    selectedCidr,
  } = formik.values;

  const displayHosts = availableBlockSizesNumber(availableCidrBlockSizes);

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Add Environment</DialogTitle>

        <DialogContent>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 1 }}>
              <CommonDropdown
                componentID="selectedApplication"
                label="Select an Application"
                data={listApplicationsData}
                value={selectedApplication || null}
                fieldError={selectApplicationFieldError}
                helperText={
                  selectApplicationFieldError &&
                  formik.errors.selectedApplication
                    ? formik.errors.selectedApplication
                    : 'Select an application.'
                }
                fullHelpText="Please select an application from the dropdown menu."
                loading={listApplicationsLoading}
                required
                noOptionsText="No Options Found."
                onChangeHandler={(e, value) => {
                  formik.setFieldValue(
                    'selectedApplication',
                    value || undefined
                  );
                  formik.setFieldValue('selectedEnvironment', '');
                  formik.setFieldValue('selectedRegion', '');
                  formik.setFieldValue('selectedCidr', '');
                }}
                onBlurHandler={formik.handleBlur}
                getOptionLabel={(option) =>
                  option?.applicationName || 'No Application Name'
                }
                getOptionSelected={(o, v) =>
                  o.applicationName === v.applicationName
                }
              />
            </Box>

            <Box sx={{ flex: 1 }}>
              <CommonDropdown
                dropdownDisabled={
                  formik.values.selectedApplication === undefined
                }
                componentID="selectedEnvironment"
                label="Select an Environment Type"
                data={[
                  { type: 'pd', name: 'Production' },
                  { type: 'np', name: 'Non-Production' },
                ]}
                value={selectedEnvironment || null}
                fieldError={selectedEnvironmentFieldError}
                helperText={
                  selectedEnvironmentFieldError &&
                  formik.errors.selectedEnvironment
                    ? formik.errors.selectedEnvironment
                    : 'Please select an environment type from the dropdown menu'
                }
                fullHelpText="Please select an environment type from the dropdown menu"
                loading={false}
                required
                noOptionsText="No Options Found."
                onChangeHandler={(e, value) => {
                  formik.setFieldValue('environmentName', null);
                  formik.setFieldValue(
                    'selectedEnvironment',
                    value || undefined
                  );
                }}
                onBlurHandler={formik.handleBlur}
                getOptionLabel={(option) =>
                  option.name || 'No Environment Type Name'
                }
                getOptionSelected={(o, v) => o.type === v.type}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ flex: 1 }}>
              <CommonDropdown
                dropdownDisabled={
                  formik.values.selectedApplication === undefined ||
                  formik.values.selectedEnvironment?.type === undefined
                }
                componentID="environmentName"
                label="New Environment Name"
                data={
                  formik.values.selectedEnvironment?.type === 'pd'
                    ? prodEnvironmentsNames
                    : nonProdEnvironmentsNames
                }
                value={environmentName || null}
                fieldError={environmentNameFieldError}
                helperText={
                  environmentNameFieldError && formik.errors.environmentName
                    ? formik.errors.environmentName
                    : 'Enter an environment name. You must choose an environment type first.'
                }
                fullHelpText="Please enter a name for the new environment."
                loading={false}
                required
                getOptionLabel={(opt) => opt}
                getOptionSelected={(opt, val) => opt === val}
                noOptionsText="No Options Found."
                onChangeHandler={(e, value) => {
                  formik.setFieldValue('environmentName', value || undefined);
                }}
                onBlurHandler={formik.handleBlur}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <CommonDropdown
                dropdownDisabled={
                  formik.values.selectedApplication === undefined
                }
                componentID="selectedCidr"
                label="Select a CIDR block size"
                data={availableCidrBlockSizes}
                value={selectedCidr || null}
                fieldError={selectedCidrBlockFieldError}
                helperText={
                  selectedCidrBlockFieldError && formik.errors.selectedCidr
                    ? formik.errors.selectedCidr
                    : 'Please select a CIDR block from the dropdown menu'
                }
                fullHelpText="Please select a CIDR block from the dropdown menu"
                loading={false}
                required
                noOptionsText="No Options Found."
                onChangeHandler={(e, value) => {
                  formik.setFieldValue('selectedCidr', value || undefined);
                }}
                onBlurHandler={formik.handleBlur}
                getOptionLabel={(option) => {
                  const displayHost = displayHosts.find(
                    (v) => `${v.blockSize}` === option
                  );
                  if (displayHost === undefined) {
                    return 'No CIDR Block Size';
                  }
                  return `/${displayHost.blockSize} (${displayHost.hosts} hosts)`;
                }}
                getOptionSelected={(o, v) => o === v}
              />
            </Box>
          </Box>
          <DialogActions>
            <Button
              disabled={
                !(formik.isValid && formik.dirty) || formik.isSubmitting
              }
              variant="contained"
              type="submit"
              endIcon={
                formik.isSubmitting ? (
                  <CircularProgress size="14px" sx={{ marginLeft: '0.5rem' }} />
                ) : undefined
              }
            >
              Submit Request
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Box>
  );
};

export default AddEnvironmentModal;
