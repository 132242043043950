import { ReferenceArchitecture } from '@costco-service-catalog/bff-types';

export const makeRefArchTiers = (refArch: ReferenceArchitecture) => {
  const cat = refArch.category;
  let categoryTierLevel = 0;
  if (cat === 'LandingZone') {
    categoryTierLevel = 1;
  } else if (cat === 'CoreInfra') {
    categoryTierLevel = 2;
  } else if (cat === 'Application') {
    categoryTierLevel = 3;
  }
  return { ...refArch, categoryTierLevel };
};

export const sortRefArchByTier = (
  a: ReferenceArchitecture,
  b: ReferenceArchitecture
) => {
  const aTier = makeRefArchTiers(a).categoryTierLevel;
  const bTier = makeRefArchTiers(b).categoryTierLevel;

  if (aTier < bTier) {
    return -1;
  }
  if (aTier === bTier) {
    return 0;
  }
  if (aTier > bTier) {
    return 1;
  }

  return 0;
};
