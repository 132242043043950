import { ApolloError } from '@apollo/client/errors';
import { AadUser as AADUser } from '@costco-service-catalog/bff-types';

const mockAADUsers = [
  {
    name: 'Mock User One',
    userPrincipalName: 'mockuserone@mock.com',
    id: '1231',
  },
  {
    name: 'Mock User Two',
    userPrincipalName: 'mockusertwo@mock.com',
    id: '1232',
  },
  {
    name: 'Mock User Three',
    userPrincipalName: 'mockuserthree@mock.com',
    id: '1233',
  },
];

const mockSearchAADUsersRes = () => {
  const error: ApolloError | undefined = undefined;
  const data: AADUser[] = mockAADUsers;
  const loading: boolean = false;
  const handleOnInputChange = () => {};
  const getAADNonZUsers = () => {};
  return {
    error,
    data,
    loading,
    handleOnInputChange,
    getAADNonZUsers,
  };
};

export default mockSearchAADUsersRes;
