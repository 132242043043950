import { gql } from '@apollo/client';

const LIST_APPLICATION_DRAFTS_QUERY = gql`
  query listApplicationRequestDrafts {
    listApplicationRequestDrafts {
      status
      count
      response {
        id
        applicationName
        offeringRequests {
          offeringName
        }
      }
    }
  }
`;

export default LIST_APPLICATION_DRAFTS_QUERY;
