import {
  Brightness4,
  Brightness7,
  SvgIconComponent,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconProps,
  Switch,
  Tooltip,
  useTheme,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { AppVersions } from '..';
import useStyles from './AppDrawer.styles';
import { LeftMenuItem } from '../../types';
import { useGlobalState } from '../../globalState/useGlobalState';
import useGetUserPermission from '../../hooks/userManagementHooks/useGetUserPermission';

interface AppDrawerProps {
  open: boolean;
  checked: boolean;
  toggleDarkMode: () => void;
  menu: LeftMenuItem[];
}

const AppDrawer = (props: AppDrawerProps) => {
  const { menu, open, checked, toggleDarkMode } = props;
  const { appSettings } = useGlobalState();
  const useEphemeralRG = appSettings.useEphemeralRGPage.get();
  const userFeedbackUrl = appSettings.userFeedbackUrl.get();
  const ecpOnboardingEnabled = appSettings.ecpOnboardingUtilitiesEnabled.get();

  const theme = useTheme();
  const { classes } = useStyles();
  const { data } = useGetUserPermission();

  return (
    <Box className={classes.listBox}>
      <List className={classes.list}>
        {menu &&
          menu
            .filter(
              (o) =>
                o.isActive === true &&
                (o.isRestricted !== true || data?.isAdmin)
            )
            .filter((o) => {
              if (!useEphemeralRG) {
                return o.label !== 'Ephemeral Apps';
              }
              return true;
            })
            .filter((o) => {
              if (!ecpOnboardingEnabled) {
                return o.label !== 'ECP Onboarding Requests';
              }
              return true;
            })
            .map((o, k) => (
              <RouterLink
                to={o.path || '/'}
                className={classes.routerLink}
                style={{ color: theme.palette.secondary.main }}
                key={o.label}
              >
                <ListItem button className={classes.listItem}>
                  <ListItemIcon>
                    <Tooltip
                      title={open ? '' : o.label}
                      arrow
                      placement="right"
                    >
                      <Box
                        component={o.icon}
                        style={{ color: theme.palette.secondary.main }}
                      />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText primary={o.label} />
                </ListItem>
              </RouterLink>
            ))}
      </List>
      <div
        className={classes.themeItem}
        role="button"
        tabIndex={0}
        onClick={toggleDarkMode}
        onKeyPress={toggleDarkMode}
      >
        <Tooltip
          title={open ? '' : `${checked ? 'Dark' : 'Light'} Theme`}
          arrow
          placement="right"
        >
          {checked ? <Brightness7 /> : <Brightness4 />}
        </Tooltip>
        <span className={classes.themeItemLabel}>
          {checked ? 'Dark' : 'Light'} Theme
        </span>
        <Switch checked={checked} />
      </div>
      <div className={classes.footer}>
        <Divider />
        <Box hidden={!open} className={classes.versions}>
          <AppVersions />
        </Box>
      </div>
      <Divider />
      <div className={classes.feedback}>
        <a href={userFeedbackUrl} target="_blank" rel="noreferrer">
          Feedback
        </a>
      </div>
    </Box>
  );
};

export default AppDrawer;
