import { gql } from '@apollo/client';

const DELETE_USERS_FROM_GROUP = gql`
  mutation deleteUsersFromGroup($input: UserModificationInput) {
    deleteUsersFromGroup(input: $input) {
      code
      status
      response {
        id
        email
      }
    }
  }
`;

export default DELETE_USERS_FROM_GROUP;
