import {
  AadUser,
  AdoBoardsProcess,
  AdoProject,
  AzurePolicy,
  EcoSystemRequestInput,
  EnvironmentRequest,
  ManagementGroup,
} from '@costco-service-catalog/bff-types';

interface defaultFormValuesType {
  starterKitDraftId?: string;
  managementGroup?: ManagementGroup;
  applicationName?: string;
  applicationDescription?: string;
  supportGroupEmail?: string;
  additionalCollaborators?: AadUser[];
  aadGroupMembers?: AadUser[];
  aadReaderGroupMembers?: AadUser[];
  wizMembers?: AadUser[];
  isExistingAdoProject?: boolean;
  existingAdoProjectOrg?: string;
  existingAdoProject?: AdoProject;
  adoProjectContributors?: AadUser[];
  adoProjectAdministrators?: AadUser[];
  adoProjectBoardAdministrators?: AadUser[];
  adoProjectBoardsProcess?: AdoBoardsProcess;
  nonProdEnvironments: EnvironmentRequest[];
  prodEnvironments: EnvironmentRequest[];
  primaryRegion?: string;
  secondaryRegion?: string;
  isEphemeral?: boolean;
  wizEnabled?: boolean;
  ecoSystemNP?: EcoSystemRequestInput;
  ecoSystemPD?: EcoSystemRequestInput;
}

interface formValuesType {
  starterKitDraftId?: string;
  managementGroup: ManagementGroup;
  applicationName: string;
  applicationDescription: string;
  supportGroupEmail: string;
  additionalCollaborators?: AadUser[];
  azurePolicies?: AzurePolicy[];
  aadGroupMembers: AadUser[];
  aadReaderGroupMembers?: AadUser[];
  wizMembers: AadUser[];
  isExistingAdoProject: boolean;
  existingAdoProjectOrg: string;
  existingAdoProject: AdoProject;
  adoProjectContributors: AadUser[];
  adoProjectAdministrators: AadUser[];
  adoProjectBoardAdministrators: AadUser[];
  adoProjectBoardsProcess?: AdoBoardsProcess;
  nonProdEnvironments: EnvironmentRequest[];
  prodEnvironments: EnvironmentRequest[];
  primaryRegion: string;
  secondaryRegion: string;
  isEphemeral?: boolean;
  ecoSystemNP?: EcoSystemRequestInput;
  ecoSystemPD?: EcoSystemRequestInput;
}

const defaultFormValues: defaultFormValuesType = {
  isExistingAdoProject: false,
  primaryRegion: 'eastus2',
  secondaryRegion: 'westus2',
  nonProdEnvironments: [],
  prodEnvironments: [],
  adoProjectAdministrators: [],
  adoProjectContributors: [],
  adoProjectBoardAdministrators: [],
  aadGroupMembers: [],
  aadReaderGroupMembers: [],
  wizMembers: [],
  additionalCollaborators: [],
  adoProjectBoardsProcess: {
    name: 'Agile_Costco_Wholesale_Ancillary_Process',
    displayName: 'Agile Costco Wholesale Ancillary Process',
  },
};

export type { defaultFormValuesType, formValuesType };
export default defaultFormValues;
