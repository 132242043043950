import { ApolloError } from '@apollo/client/errors';

const mockADOOrgs = [
  'CostcoWholesale-ServiceCatalog-Sandbox',
  'CostcoWholesale',
];

const mockADOOrgsRes = () => {
  const error: ApolloError | undefined = undefined;
  const data: string[] = mockADOOrgs;
  const loading: boolean = false;
  return {
    error,
    data,
    loading,
  };
};

export default mockADOOrgsRes;
