import { ApolloError, useMutation } from '@apollo/client';
import { ADD_WIZ_MEMBERS } from '../../apollo/queries';
import { useApolloErrors } from '../apolloHooks';

const useAddWizMembers = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [addWizMembers, { loading: addUsersLoading }] = useMutation(
    ADD_WIZ_MEMBERS,
    {
      onError: (error: ApolloError) => handleErrorResponse(error),
    }
  );

  return {
    addWizMembers,
    loading: addUsersLoading,
  };
};

export default useAddWizMembers;
