import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  footerContainer: {
    backgroundColor: theme.palette.mode === 'light' ? '#d5e5f3' : '#668db2',
    borderTop: `1px solid ${
      theme.palette.mode === 'light'
        ? 'rgba(0,0,0,0.1)'
        : 'rgba(255,255,255,0.25)'
    }`,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    position: 'fixed',
    left: '0',
    bottom: '0',
    width: '100%',
    zIndex: 1300,
    padding: '1rem',
  },
  ghostFooter: {
    display: 'block',
    width: '100%',
  },
}));

export default useStyles;
