import { AadUser } from '@costco-service-catalog/bff-types';
import { CommonNonZUsersAutoComplete } from '../CommonFields';

interface ADOProjectAdministratorsProps {
  value: AadUser[];
  fieldError: boolean;
  errorText?: string;
  onChangeHandler: (event: React.ChangeEvent<{}>, value: AadUser[]) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const ADOProjectAdministrators = (props: ADOProjectAdministratorsProps) => {
  const { value, fieldError, errorText, onChangeHandler, onBlurHandler } =
    props;

  return (
    <CommonNonZUsersAutoComplete
      componentID="adoProjectAdministrators"
      label="ADO Project Administrators"
      value={value}
      required
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      fieldError={fieldError}
      helperText={
        fieldError && errorText
          ? errorText
          : 'Select from the list or enter a user name and hit the Enter key to add user as ADO Administrator.'
      }
      fullHelpText="ADO Project Administrators are responsible for actions that required elevated permissions in ADO such as adding additional members to the project or changing pipeline permissions."
    />
  );
};

ADOProjectAdministrators.defaultProps = {
  errorText: undefined,
};

export default ADOProjectAdministrators;
