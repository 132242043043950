import {
  ApplicationRequestDraft,
  ReferenceArchitecture,
} from '@costco-service-catalog/bff-types';
import { Create } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AzureCloudNativeStarterKit, PageHeader } from '../../components';
import { useOfferings } from '../../hooks';
import { CardDetailsType } from '../../types';
import useStyles from './OfferingRequestPage.styles';
import useOfferingRequestPage from './useOfferingRequestPage';

const DetermineOfferingRequestPageBehavior =
  (offeringRequestPageBehaviorProps: {
    draftLoading: boolean | undefined;
    offering: CardDetailsType | undefined;
    draftData: ApplicationRequestDraft | undefined;
    selectedOfferings: ReferenceArchitecture[] | undefined;
  }) => {
    const offerings = useOfferings();
    const { draftLoading, offering, selectedOfferings, draftData } =
      offeringRequestPageBehaviorProps;
    if (draftLoading) {
      return (
        <Box sx={{ p: '4vw' }}>
          <CircularProgress />
        </Box>
      );
    }
    if (offering && !draftData) {
      return (
        <AzureCloudNativeStarterKit
          offering={offering}
          selectedOfferings={selectedOfferings}
          draftData={draftData}
        />
      );
    }
    if (draftData) {
      if (
        draftData.offeringRequests &&
        draftData.offeringRequests[0]?.offeringName
      ) {
        const findLandingZoneOffering = draftData?.offeringRequests.find(
          (o) => o?.category === 'LandingZone'
        );
        if (findLandingZoneOffering?.offeringName) {
          const matchOffering = offerings.data.find(
            (o) => o.name === findLandingZoneOffering?.offeringName
          );
          const matchOfferingToRefArchs = draftData.offeringRequests.map(
            (offeringRequestsInApplicationReq) => {
              const matchOffering = offerings.data.find(
                (o) => o.name === offeringRequestsInApplicationReq?.offeringName
              );
              return matchOffering;
            }
          );
          const formatSelectedOfferings = matchOfferingToRefArchs.filter(
            (element) => element !== undefined
          ) as ReferenceArchitecture[];

          if (matchOffering) {
            return (
              <AzureCloudNativeStarterKit
                offering={matchOffering as CardDetailsType}
                selectedOfferings={formatSelectedOfferings}
                draftData={draftData}
              />
            );
          }
        }
      }
    }

    return null;
  };

const OfferingRequestPage = () => {
  const {
    id,
    title,
    draftData,
    draftLoading,
    draftError,
    offering,
    setOffering,
    selectedOfferings,
    setSelectedOfferings,
  } = useOfferingRequestPage();

  const { classes } = useStyles();

  const location = useLocation();

  useEffect(() => {
    if (document?.querySelector('body')?.scrollTo) {
      document?.querySelector('body')?.scrollTo(0, 0);
    }
    const selectedOffering = (location?.state as any)?.cardDetails;
    const nonLZ = (location?.state as any)?.nonLZ;
    if (selectedOffering) {
      setOffering(selectedOffering);
    }
    if (nonLZ) {
      setSelectedOfferings(nonLZ);
    }
  }, []);

  if (draftError) {
    return <Navigate to="/404NotFound" />;
  }

  return (
    <div className={classes.root}>
      <PageHeader icon={<Create />} title={title} />
      <Box sx={{ pb: '10rem' }}>
        <DetermineOfferingRequestPageBehavior
          draftLoading={draftLoading}
          offering={offering}
          selectedOfferings={selectedOfferings}
          draftData={draftData}
        />
      </Box>
    </div>
  );
};

export default OfferingRequestPage;
