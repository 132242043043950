import { ReferenceArchitecture } from '@costco-service-catalog/bff-types';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { AddBox, AddBoxOutlined, OpenInNewOutlined } from '@mui/icons-material';
import useStyles from './EphemeralResourceGroupCard.styles';

type EphemeralResourceGroupCardPropsType = {
  refArch: ReferenceArchitecture;
  variant: 'new' | 'existing';
  handleModalOpen: (
    variant: 'new' | 'existing',
    offeringShortName: string | undefined | null,
    refArch: ReferenceArchitecture
  ) => void;
};

const EphemeralResourceGroupCard = (
  props: EphemeralResourceGroupCardPropsType
) => {
  const { refArch, variant, handleModalOpen } = props;
  const theme = useTheme();
  const { classes } = useStyles();

  return (
    <Box
      onClick={() =>
        handleModalOpen(variant, refArch.shortOfferingName, refArch)
      }
    >
      <Paper className={classes.cardStyling}>
        <Box className={classes.cardContentContainer}>
          <Box className={classes.topRightIconContainer}>
            {variant === 'existing' ? (
              <OpenInNewOutlined
                htmlColor={
                  theme.palette.mode === 'light'
                    ? `rgba(61, 92, 121, 1)`
                    : `rgba(255, 255, 255, 1)`
                }
              />
            ) : (
              <AddBoxOutlined
                htmlColor={
                  theme.palette.mode === 'light'
                    ? `rgba(61, 92, 121, 1)`
                    : `rgba(255, 255, 255, 1)`
                }
              />
            )}
          </Box>
          <Box className={classes.cardContentStyling}>
            <Typography>Ephemeral {refArch.name}</Typography>
            <Box className={classes.constructionTapeDivider} />
            <Typography variant="body2">
              Deploy an ephemeral version of {refArch.name}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default EphemeralResourceGroupCard;
