import { gql } from '@apollo/client';

const ADD_USERS_TO_GROUP = gql`
  mutation addUsersToGroup($input: UserModificationInput) {
    addUsersToGroup(input: $input) {
      code
      status
      response {
        id
        email
      }
    }
  }
`;

export default ADD_USERS_TO_GROUP;
