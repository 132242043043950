import { AadUser } from '@costco-service-catalog/bff-types';
import { CommonZUsersAutocomplete } from '../CommonFields';

interface AADGroupMembersProps {
  value: AadUser[];
  fieldError: boolean;
  errorText?: string;
  contributorsAreReadersInProdMsg: string;
  onChangeHandler: (event: React.ChangeEvent<{}>, value: AadUser[]) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const AADGroupMembers = (props: AADGroupMembersProps) => {
  const {
    value,
    fieldError,
    errorText,
    contributorsAreReadersInProdMsg,
    onChangeHandler,
    onBlurHandler,
  } = props;

  return (
    <CommonZUsersAutocomplete
      componentID="aadGroupMembers"
      label="AAD Contributor Group Members"
      value={value}
      required
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      fieldError={fieldError}
      helperText={
        fieldError && errorText
          ? errorText
          : "An AAD group granting access to Azure Resources will be created. Enter '-z' accounts to include them as group members."
      }
      fullHelpText={`An AAD group granting access to Azure Resources in the application's subscriptions will be created with the following '-z' accounts included as group members. ${contributorsAreReadersInProdMsg}`}
    />
  );
};

AADGroupMembers.defaultProps = {
  errorText: undefined,
};

export default AADGroupMembers;
