import { ApolloError, useMutation } from '@apollo/client';
import { useApolloErrors } from '..';
import { RETRY_STARTER_KIT_REQUEST_MUTATION } from '../../apollo/queries';

const useRetryStarterKitRequest = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [retryStarterKitRequest, { loading: retryStarterKitRequestLoading }] =
    useMutation(RETRY_STARTER_KIT_REQUEST_MUTATION, {
      onError: (error: ApolloError) => handleErrorResponse(error),
    });

  return {
    retryStarterKitRequest,
    loading: retryStarterKitRequestLoading,
  };
};

export default useRetryStarterKitRequest;
