import { ApolloError, useQuery } from '@apollo/client';
import {
  ApplicationRequestDraftInput,
  ApplicationRequestDraftResponse,
  EntityParams,
} from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '..';
import { GET_APPLICATION_DRAFT_QUERY } from '../../apollo/queries';

const useGetApplicationDraft = (params: EntityParams) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getApplicationRequestDraft: ApplicationRequestDraftResponse;
  }>(GET_APPLICATION_DRAFT_QUERY, {
    variables: { params },
    fetchPolicy: 'network-only',
    onError: (error: ApolloError) => handleErrorResponse(error),
  });
  const [ApplicationDraft, setApplicationDraft] =
    useState<ApplicationRequestDraftInput>();

  useEffect(() => {
    if (!loading && data) {
      setApplicationDraft(
        (data?.getApplicationRequestDraft
          ?.response as ApplicationRequestDraftInput) || undefined
      );
    }
  }, [loading, data]);

  return {
    error,
    data: ApplicationDraft,
    loading,
    refetch,
  };
};

export default useGetApplicationDraft;
