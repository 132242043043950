import { enqueueSnackbar, OptionsObject } from 'notistack';
import { useSessionStorage } from '..';

const useEnqueueSnackbar = () => {
  const sessionStorageHooks = useSessionStorage();

  const pushSnackbar = (message: string, options?: OptionsObject) => {
    enqueueSnackbar(message, {
      ...options,
      onExited: () => {
        sessionStorageHooks.setAppHistoryDrawerData({
          id: Date.now(),
          message: message,
          severity: options?.variant || 'info',
        });
      },
    });
  };

  return { pushSnackbar };
};

export default useEnqueueSnackbar;
