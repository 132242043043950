import { ApolloError, useMutation } from '@apollo/client';
import { useApolloErrors } from '..';
import { SUBMIT_NEW_ECP_ONBOARDING_REQUEST_MUTATION } from '../../apollo/queries';

const useSubmitECPOnboardingRequest = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [submitECPOnboardingRequest, { loading: submitLoading }] = useMutation(
    SUBMIT_NEW_ECP_ONBOARDING_REQUEST_MUTATION,
    {
      onError: (error: ApolloError) => handleErrorResponse(error),
    }
  );

  return {
    submitECPOnboardingRequest,
    loading: submitLoading,
  };
};

export default useSubmitECPOnboardingRequest;
