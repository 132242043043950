import {
  AadUser,
  Application,
  ApplicationStarterKitRequest,
  ProjectMemberInput,
  SecurityGroupType,
  StarterKitEnvironment,
  StarterKitOffering,
  Status,
  UserModificationInput,
} from '@costco-service-catalog/bff-types';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { useGlobalState } from '../../globalState/useGlobalState';
import {
  defaultFormValues,
  formValuesType,
  useAddAdditionalCollaborators,
  useAddUsersToGroup,
  useAddWizMembers,
  useCancelStarterKitRequest,
  useDeleteApplication,
  useRemoveWizMembers,
  useRetryDeleteApplication,
  useEnqueueSnackbar,
  useGetApplication,
  useRemoveAdditionalCollaborators,
  useRemoveUsersFromGroup,
  useRetryStarterKitRequest,
  useSetupWizIntegration,
} from '../../hooks';
import { validateDeployedApplicationIsComplete } from '../../hooks/validationHooks/deployedApplicationValidation';

const OKStatus: Status = 'OK';

export interface autopopPayloadType {
  autopopulate: boolean;
  from: string;
  data: Application | undefined;
  selection: string[];
}

type utilityOptionType =
  | 'AddVnetOrRegion'
  | 'AddEnvironment'
  | 'UnlockStateFile'
  | 'DeleteEnvironment';

export interface utilitiesPayloadType {
  showPopup: true;
  option: utilityOptionType;
  from: string;
  data: Application | undefined;
  selectedEnvironment?: StarterKitEnvironment | undefined;
}

interface CancelRequest {
  applicationId: string;
  starterKitRequestId: string;
  offeringName?: string;
}

const useApplicationSummaryPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { appSettings } = useGlobalState();

  const autoRefreshInterval = appSettings.autoRefreshInterval.get();
  const addVNetUtilityDocUrl = appSettings.addVNetUtilityDocUrl.get();
  const addEnvUtilityDocUrl = appSettings.addEnvUtilityDocUrl.get();

  const { pushSnackbar } = useEnqueueSnackbar();

  const { error, data, loading, refetch } = useGetApplication({ id: id });
  const { retryStarterKitRequest } = useRetryStarterKitRequest();
  const { cancelStarterKitRequest } = useCancelStarterKitRequest();
  const { deleteApplication } = useDeleteApplication();
  const { retryDeleteApplication } = useRetryDeleteApplication();
  const { deleteUsers } = useRemoveUsersFromGroup();
  const { addUsers } = useAddUsersToGroup();
  const { deleteAdditionalCollaborators } = useRemoveAdditionalCollaborators();
  const { addAdditionalCollaborators } = useAddAdditionalCollaborators();
  const { addWizMembers } = useAddWizMembers();
  const { removeWizMembers } = useRemoveWizMembers();
  const { setupWizIntegration, loading: setupWizIntegrationLoading } =
    useSetupWizIntegration();

  const title = 'Application Summary';
  const subtitle = data?.applicationName || '';

  const [requestToCancel, setRequestToCancel] = useState<CancelRequest>();
  const [cancelRequestDialogOpen, setCancelRequestDialogOpen] = useState(false);
  const [openPolicyModal, setOpenPolicyModal] = useState(false);
  const [policyModalData, setPolicyModalData] = useState({
    environmentName: '',
    environmentType: '',
    enabledPolicies: [] as string[],
    policiesVersion: '',
  });
  const [openEditSupportGrpEmailModal, setOpenEditSupportGrpEmailModal] =
    useState(false);
  const [openEditRequesterModal, setOpenEditRequesterModal] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState(
    new Date().toLocaleString()
  );
  const [deleteApplicationDialogOpen, setDeleteApplicationDialogOpen] =
    useState(false);

  const [formValues, setFormValues] = useState(defaultFormValues);

  const handleApplicationSummaryClose = () => {
    navigate(-1);
  };

  const handleApplicationSummaryRefresh = () => {
    refetch();
    setLastUpdateTime(new Date().toLocaleString());
    pushSnackbar(
      'Refreshing your application status. Please wait while Loading...',
      { variant: 'success' }
    );
  };

  const autoRefresh = async () => {
    await refetch();
    setLastUpdateTime(new Date().toLocaleString());
  };

  useEffect(() => {
    setInterval(
      () => autoRefresh(),
      parseInt(autoRefreshInterval, 10) || 180000
    );
  }, []);

  const handleRetryStarterKitRequestClick = async (
    applicationId: string,
    starterKitRequestId: string
  ) => {
    await retryStarterKitRequest({
      variables: { input: { applicationId, starterKitRequestId } },
      onCompleted: (data: any) => {
        if (data.retryStarterKitRequest.status === OKStatus) {
          pushSnackbar(
            'Successfully retried starter kit request. Refreshing...',
            { variant: 'success' }
          );
          refetch();
          setLastUpdateTime(new Date().toLocaleString());
        }
      },
    });
  };

  const handleCancelStarterKitRequestClick = (
    applicationId: string,
    starterKitRequestId: string,
    offeringName?: string
  ) => {
    setRequestToCancel({ applicationId, starterKitRequestId, offeringName });
    setCancelRequestDialogOpen(true);
  };

  const handleCancelRequestDialogOk = async () => {
    await cancelStarterKitRequest({
      variables: {
        input: {
          applicationId: requestToCancel?.applicationId,
          starterKitRequestId: requestToCancel?.starterKitRequestId,
          offeringName: requestToCancel?.offeringName,
        },
      },
      onCompleted: (data: any) => {
        if (data.cancelStarterKitRequest.status === OKStatus) {
          pushSnackbar(
            'Successfully cancelled starter kit request. Refreshing...',
            { variant: 'success' }
          );
          refetch();
          setLastUpdateTime(new Date().toLocaleString());
        }
      },
    });
    setRequestToCancel(undefined);
    setCancelRequestDialogOpen(false);
  };

  const handleCancelRequestDialogCancel = () => {
    setRequestToCancel(undefined);
    setCancelRequestDialogOpen(false);
  };

  const handleDeleteApplicationClick = () => {
    setDeleteApplicationDialogOpen(true);
  };

  const handleDeleteApplicationDialogOk = async (
    setDeleteLoading: (loading: boolean) => void
  ) => {
    setDeleteLoading(true);
    await deleteApplication({
      variables: {
        input: {
          id: id,
        },
      },
      onCompleted: (data: any) => {
        setDeleteLoading(false);
        if (data.deleteApplication.status === OKStatus) {
          const message =
            data.deleteApplication.response === 'Done'
              ? 'Successfully deleted application.'
              : 'Delete request submitted successfully.';
          pushSnackbar(message, {
            variant: 'success',
          });
          navigate(ROUTES.APPLICATION_LIST);
        }
      },
    });
    setDeleteApplicationDialogOpen(false);
  };

  const handleRetryDeleteApplicationClick = async () => {
    await retryDeleteApplication({
      variables: {
        input: {
          id: id,
        },
      },
      onCompleted: (data: any) => {
        if (data.retryDeleteApplication.status === OKStatus) {
          const message = 'Delete request retried successfully.';
          pushSnackbar(message, {
            variant: 'success',
          });
          navigate(ROUTES.APPLICATION_LIST);
        }
      },
    });
  };

  const handleDeleteApplicationDialogCancel = () => {
    setDeleteApplicationDialogOpen(false);
  };

  const handleSetupWizIntegrationClick = async (applicationId: string) => {
    await setupWizIntegration({
      variables: {
        applicationId: applicationId,
      },
      onCompleted: (data: any) => {
        if (data.setupWizIntegration.status === OKStatus) {
          const message = 'Wiz.io Integration Setup completed successfully.';
          pushSnackbar(message, {
            variant: 'success',
          });
          refetch();
          setLastUpdateTime(new Date().toLocaleString());
        }
      },
    });
  };

  const handleDeleteUsersFromGroup = async (
    userModificationInput: UserModificationInput,
    groupName: string,
    setMemberList: (users: AadUser[]) => void,
    setLoading: (loading: boolean) => void
  ) => {
    await deleteUsers({
      variables: { input: userModificationInput },
      onCompleted: (data: any) => {
        if (data.deleteUsersFromGroup.code === 200) {
          const emailAddresOfUsersDeleted = userModificationInput.users
            .map((user) => user.email)
            .join(', ');
          pushSnackbar(
            `${emailAddresOfUsersDeleted} has been successfully deleted from ${groupName}`,
            { variant: 'success' }
          );
          setMemberList(data.deleteUsersFromGroup.response);
        }
      },
    });
    setLoading(false);
  };

  const handleDeleteWizMembers = async (
    userModificationInput: UserModificationInput,
    groupName: string,
    setMemberList: (users: AadUser[]) => void,
    setLoading: (loading: boolean) => void
  ) => {
    await removeWizMembers({
      variables: {
        applicationId: userModificationInput.applicationId,
        wizMembers: userModificationInput.users,
      },
      onCompleted: (data: any) => {
        if (data.removeWizMembers.code === 200) {
          const emailAddresOfUsersDeleted = userModificationInput.users
            .map((user) => user.email)
            .join(', ');
          pushSnackbar(
            `${emailAddresOfUsersDeleted} has been successfully deleted from ${groupName}`,
            { variant: 'success' }
          );
          setMemberList(data.removeWizMembers.response);
        }
      },
    });
    setLoading(false);
  };

  const handleDeleteAdditionalCollaborators = async (
    userModificationInput: UserModificationInput,
    groupName: string,
    setMemberList: (users: AadUser[]) => void,
    setLoading: (loading: boolean) => void
  ) => {
    await deleteAdditionalCollaborators({
      variables: {
        users: userModificationInput.users.map((user) => user.email),
        applicationId: userModificationInput.applicationId,
      },
      onCompleted: (data: any) => {
        if (data.deleteAdditionalCollaborators.code === 200) {
          const emailAddresOfUsersDeleted = userModificationInput.users
            .map((user) => user.email)
            .join(', ');
          pushSnackbar(
            `${emailAddresOfUsersDeleted} has been successfully deleted from ${groupName}`,
            { variant: 'success' }
          );
          const memeberList = data.deleteAdditionalCollaborators.response.map(
            (email: string) => {
              const aadUser: AadUser = {
                email,
              };
              return aadUser;
            }
          ) as AadUser[];
          setMemberList(memeberList);
        }
      },
    });
    setLoading(false);
  };

  const handleAadGroupMembersChange = (
    event: ChangeEvent<{}>,
    value: AadUser[]
  ) => {
    const values = value
      .filter((u) => typeof u.id === 'string' && typeof u.name === 'string')
      .map((u) => {
        const aadUser = {
          id: u.id as string,
          name: u.name as string,
          userPrincipalName: u.userPrincipalName as string,
        };
        return aadUser;
      });
    setFormValues({ ...formValues, aadGroupMembers: values });
  };

  const handleAadReaderGroupMembersChange = (
    event: ChangeEvent<{}>,
    value: AadUser[]
  ) => {
    const values = value
      .filter((u) => typeof u.id === 'string' && typeof u.name === 'string')
      .map((u) => {
        const aadUser = {
          id: u.id as string,
          name: u.name as string,
          userPrincipalName: u.userPrincipalName as string,
        };
        return aadUser;
      });
    setFormValues({ ...formValues, aadReaderGroupMembers: values });
  };

  const handleWizMembersChange = (event: ChangeEvent<{}>, value: AadUser[]) => {
    const values = value
      .filter((u) => typeof u.id === 'string' && typeof u.name === 'string')
      .map((u) => {
        const aadUser = {
          id: u.id as string,
          name: u.name as string,
          userPrincipalName: u.userPrincipalName as string,
        };
        return aadUser;
      });
    setFormValues({ ...formValues, wizMembers: values });
  };

  const handleAdoProjectContributorsChange = (
    event: ChangeEvent<{}>,
    value: AadUser[]
  ) => {
    const values = value
      .filter((u) => typeof u.id === 'string' && typeof u.name === 'string')
      .map((u) => {
        const aadUser = {
          id: u.id as string,
          name: u.name as string,
          userPrincipalName: u.userPrincipalName as string,
        };
        return aadUser;
      });

    setFormValues({ ...formValues, adoProjectContributors: values });
  };

  const handleAdoProjectAdministratorsChange = (
    event: ChangeEvent<{}>,
    value: AadUser[]
  ) => {
    const values = value
      .filter((u) => typeof u.id === 'string' && typeof u.name === 'string')
      .map((u) => {
        const aadUser = {
          id: u.id as string,
          name: u.name as string,
          userPrincipalName: u.userPrincipalName as string,
        };
        return aadUser;
      });
    setFormValues({ ...formValues, adoProjectAdministrators: values });
  };

  const handleADOProjectBoardAdministratorsChange = (
    event: ChangeEvent<{}>,
    value: AadUser[]
  ) => {
    const values = value
      .filter((u) => typeof u.id === 'string' && typeof u.name === 'string')
      .map((u) => {
        const adoUser = {
          id: u.id as string,
          name: u.name as string,
          userPrincipalName: u.userPrincipalName as string,
        };
        return adoUser;
      });
    setFormValues({ ...formValues, adoProjectBoardAdministrators: values });
  };

  const handleAdditionalCollaboratorsChange = (
    event: ChangeEvent<{}>,
    value: AadUser[]
  ) => {
    const values = value
      .filter((u) => typeof u.id === 'string' && typeof u.name === 'string')
      .map((u) => {
        const adoUser = {
          id: u.id as string,
          name: u.name as string,
          userPrincipalName: u.userPrincipalName as string,
        };
        return adoUser;
      });
    setFormValues({ ...formValues, additionalCollaborators: values });
  };

  const handleAddADOProjectAdministrators = async (
    applicationId: string,
    setMemberList: (users: AadUser[]) => void,
    setLoading: (loading: boolean) => void,
    groupType: SecurityGroupType,
    groupName: string
  ) => {
    setLoading(true);
    const userFormValues = formValues as formValuesType;
    const userModificationInput: UserModificationInput = {
      applicationId: applicationId,
      securityGroupType: groupType,
      users: userFormValues.adoProjectAdministrators.map((users) => {
        const adoProjectAdministrator: ProjectMemberInput = {
          id: users.id,
          email: users.userPrincipalName,
        };
        return adoProjectAdministrator;
      }),
    };

    await addUsers({
      variables: { input: userModificationInput },
      onCompleted: (data: any) => {
        if (data.addUsersToGroup.code === 200) {
          const emailAddresOfUsersAdded = userModificationInput.users
            .map((user) => user.email)
            .join(', ');
          pushSnackbar(
            `${emailAddresOfUsersAdded} has been successfully added to ${groupName}`,
            { variant: 'success' }
          );
          setMemberList(data.addUsersToGroup.response);
          setFormValues({ ...formValues, adoProjectAdministrators: [] });
        }
      },
    });
    setLoading(false);
  };

  const handleAddADOProjectContributors = async (
    applicationId: string,
    setMemberList: (users: AadUser[]) => void,
    setLoading: (loading: boolean) => void,
    groupType: SecurityGroupType,
    groupName: string
  ) => {
    setLoading(true);
    const userFormValues = formValues as formValuesType;
    const userModificationInput: UserModificationInput = {
      applicationId: applicationId,
      securityGroupType: groupType,
      users: userFormValues.adoProjectContributors.map((users) => {
        const adoProjectContributors: ProjectMemberInput = {
          id: users.id,
          email: users.userPrincipalName,
        };
        return adoProjectContributors;
      }),
    };

    await addUsers({
      variables: { input: userModificationInput },
      onCompleted: (data: any) => {
        if (data.addUsersToGroup.code === 200) {
          const emailAddresOfUsersAdded = userModificationInput.users
            .map((user) => user.email)
            .join(', ');
          pushSnackbar(
            `${emailAddresOfUsersAdded} has been successfully added to ${groupName}`,
            { variant: 'success' }
          );
          setMemberList(data.addUsersToGroup.response);
          setFormValues({ ...formValues, adoProjectContributors: [] });
        }
      },
    });
    setLoading(false);
  };

  const handleAddADOProjectBoardAdministrators = async (
    applicationId: string,
    setMemberList: (users: AadUser[]) => void,
    setLoading: (loading: boolean) => void,
    groupType: SecurityGroupType,
    groupName: string
  ) => {
    setLoading(true);
    const userFormValues = formValues as formValuesType;
    const userModificationInput: UserModificationInput = {
      applicationId: applicationId,
      securityGroupType: groupType,
      users: userFormValues.adoProjectBoardAdministrators.map((users) => {
        const adoProjectBoardAdministrators: ProjectMemberInput = {
          id: users.id,
          email: users.userPrincipalName,
        };
        return adoProjectBoardAdministrators;
      }),
    };

    await addUsers({
      variables: { input: userModificationInput },
      onCompleted: (data: any) => {
        if (data.addUsersToGroup.code === 200) {
          const emailAddresOfUsersAdded = userModificationInput.users
            .map((user) => user.email)
            .join(', ');
          pushSnackbar(
            `${emailAddresOfUsersAdded} has been successfully added to ${groupName}`,
            { variant: 'success' }
          );
          setMemberList(data.addUsersToGroup.response);
          setFormValues({ ...formValues, adoProjectBoardAdministrators: [] });
        }
      },
    });
    setLoading(false);
  };

  const handleAddAADReaderGroupMember = async (
    applicationId: string,
    setMemberList: (users: AadUser[]) => void,
    setLoading: (loading: boolean) => void,
    groupType: SecurityGroupType,
    groupName: string
  ) => {
    setLoading(true);
    const userFormValues = formValues as formValuesType;
    const userModificationInput: UserModificationInput = {
      applicationId: applicationId,
      securityGroupType: groupType,
      users:
        userFormValues.aadReaderGroupMembers?.map((users) => {
          const aadReaderGroupMembers: ProjectMemberInput = {
            id: users.id,
            email: users.userPrincipalName,
          };
          return aadReaderGroupMembers;
        }) || [],
    };

    await addUsers({
      variables: { input: userModificationInput },
      onCompleted: (data: any) => {
        if (data.addUsersToGroup.code === 200) {
          const emailAddresOfUsersAdded = userModificationInput.users
            .map((user) => user.email)
            .join(', ');
          pushSnackbar(
            `${emailAddresOfUsersAdded} has been successfully added to ${groupName}`,
            { variant: 'success' }
          );
          setMemberList(data.addUsersToGroup.response);
          setFormValues({ ...formValues, aadReaderGroupMembers: [] });
        }
      },
    });
    setLoading(false);
  };

  const handleAddAADGroupMember = async (
    applicationId: string,
    setMemberList: (users: AadUser[]) => void,
    setLoading: (loading: boolean) => void,
    groupType: SecurityGroupType,
    groupName: string
  ) => {
    setLoading(true);
    const userFormValues = formValues as formValuesType;
    const userModificationInput: UserModificationInput = {
      applicationId: applicationId,
      securityGroupType: groupType,
      users: userFormValues.aadGroupMembers.map((users) => {
        const aadGroupMembers: ProjectMemberInput = {
          id: users.id,
          email: users.userPrincipalName,
        };
        return aadGroupMembers;
      }),
    };

    await addUsers({
      variables: { input: userModificationInput },
      onCompleted: (data: any) => {
        if (data.addUsersToGroup.code === 200) {
          const emailAddresOfUsersAdded = userModificationInput.users
            .map((user) => user.email)
            .join(', ');
          pushSnackbar(
            `${emailAddresOfUsersAdded} has been successfully added to ${groupName}`,
            { variant: 'success' }
          );
          setMemberList(data.addUsersToGroup.response);
          setFormValues({ ...formValues, aadGroupMembers: [] });
        }
      },
    });
    setLoading(false);
  };

  const handleAddWizMembers = async (
    applicationId: string,
    setMemberList: (users: AadUser[]) => void,
    setLoading: (loading: boolean) => void,
    groupType: SecurityGroupType,
    groupName: string
  ) => {
    setLoading(true);
    const userFormValues = formValues as formValuesType;
    const wizMembers = userFormValues.wizMembers.map((users) => {
      const aadGroupMembers: ProjectMemberInput = {
        id: users.id,
        email: users.userPrincipalName,
      };
      return aadGroupMembers;
    });

    await addWizMembers({
      variables: { applicationId, wizMembers },
      onCompleted: (data: any) => {
        if (data.addWizMembers.code === 200) {
          const emailAddresOfUsersAdded = wizMembers
            .map((user) => user.email)
            .join(', ');
          pushSnackbar(
            `${emailAddresOfUsersAdded} has been successfully added to ${groupName}`,
            { variant: 'success' }
          );
          setMemberList(data.addWizMembers.response);
          setFormValues({ ...formValues, wizMembers: [] });
        }
      },
    });
    setLoading(false);
  };

  const handleAddAdditionalCollaborators = async (
    applicationId: string,
    setMemberList: (users: AadUser[]) => void,
    setLoading: (loading: boolean) => void,
    _groupType: SecurityGroupType,
    groupName: string
  ) => {
    setLoading(true);
    const userFormValues = formValues as formValuesType;
    const users = userFormValues.additionalCollaborators?.map(
      (user) => user.userPrincipalName
    );

    await addAdditionalCollaborators({
      variables: { users: users, applicationId: applicationId },
      onCompleted: (data: any) => {
        if (data.addAdditionalCollaborators.code === 200) {
          const emailAddresOfUsersAdded = users?.map((user) => user).join(', ');
          pushSnackbar(
            `${emailAddresOfUsersAdded} has been successfully added to ${groupName}`,
            { variant: 'success' }
          );
          setMemberList(
            data.addAdditionalCollaborators.response.map((email: string) => {
              const additionalCollaborators: AadUser = {
                email: email,
              };
              return additionalCollaborators;
            })
          );
          setFormValues({ ...formValues, additionalCollaborators: [] });
        }
      },
    });
    setLoading(false);
  };

  const handleCheckAddStarterKitToApp = () => {
    if (data) {
      const validate = validateDeployedApplicationIsComplete(
        data,
        data,
        error,
        loading
      );
      if (validate.completed) {
        return true;
      }
    }
    return false;
  };

  const handleAddStarterKitToApp = () => {
    const autopopPayload: autopopPayloadType = {
      autopopulate: true,
      from: ROUTES.APPLICATION_SUMMARY,
      data: data,
      selection: [],
    };
    navigate(ROUTES.OFFERING_MULTISELECT, { state: { autopopPayload } });
  };

  const handleAddVNetRegion = () => {
    const utilitiesPayload: utilitiesPayloadType = {
      showPopup: true,
      option: 'AddVnetOrRegion',
      from: ROUTES.APPLICATION_SUMMARY,
      data: data,
    };
    navigate(ROUTES.UTILITIES, { state: { utilitiesPayload } });
  };

  const handleAddEnvironment = () => {
    const utilitiesPayload: utilitiesPayloadType = {
      showPopup: true,
      option: 'AddEnvironment',
      from: ROUTES.APPLICATION_SUMMARY,
      data: data,
    };
    navigate(ROUTES.UTILITIES, { state: { utilitiesPayload } });
  };

  const handleUnlockStateFile = () => {
    const utilitiesPayload: utilitiesPayloadType = {
      showPopup: true,
      option: 'UnlockStateFile',
      from: ROUTES.APPLICATION_SUMMARY,
      data: data,
    };
    navigate(ROUTES.UTILITIES, { state: { utilitiesPayload } });
  };

  const handleDeleteEnvironment = (
    selectedEnvironment: StarterKitEnvironment
  ) => {
    const utilitiesPayload: utilitiesPayloadType = {
      showPopup: true,
      option: 'DeleteEnvironment',
      from: ROUTES.APPLICATION_SUMMARY,
      data: data,
      selectedEnvironment: selectedEnvironment,
    };
    navigate(ROUTES.UTILITIES, { state: { utilitiesPayload } });
  };

  const handleCheckCanCancelOffering = (
    offering: StarterKitOffering,
    request: ApplicationStarterKitRequest
  ) => {
    if (
      !(
        offering.status === 'Submitted' || offering.status === 'FailedToExecute'
      )
    ) {
      return false;
    }
    if (offering.category === 'Application') {
      return true;
    }
    const otherOfferings = request.offerings?.filter(
      (o) => o?.offeringName !== offering.offeringName
    );
    if (!otherOfferings || otherOfferings.length === 0) {
      return true;
    }
    if (
      offering.category === 'CoreInfra' &&
      otherOfferings.find((o) => o?.category === 'Application')
    ) {
      return false;
    }
    if (
      offering.category === 'LandingZone' &&
      (otherOfferings.find((o) => o?.category === 'CoreInfra') ||
        otherOfferings.find((o) => o?.category === 'Application'))
    ) {
      return false;
    }
    if (
      offering.category === 'Utility' &&
      offering.offeringShortName === 'policyapply'
    ) {
      return false;
    }
    return true;
  };

  const hasHelpDocLink = (offeringShortName: string) => {
    switch (offeringShortName) {
      case 'vnet':
        return true;
      case 'addenv':
        return true;
      default:
        return false;
    }
  };

  const getUtilityHelpUrl = (offeringShortName: string) => {
    switch (offeringShortName) {
      case 'vnet':
        return addVNetUtilityDocUrl;
      case 'addenv':
        return addEnvUtilityDocUrl;
      default:
        return '';
    }
  };

  const handleOpenPolicyModal = ({
    enabledPolicies,
    policiesVersion,
    environmentName,
    environmentType,
  }: {
    enabledPolicies: string[];
    policiesVersion: string;
    environmentName: string;
    environmentType: string;
  }) => {
    setOpenPolicyModal(true);
    setPolicyModalData({
      enabledPolicies,
      policiesVersion,
      environmentName,
      environmentType,
    });
  };

  const handleClosePolicyModal = () => {
    setOpenPolicyModal(false);
  };

  const handleOpenEditSupportGrpEmailModal = () => {
    setOpenEditSupportGrpEmailModal(true);
  };

  const handleCloseEditSupportGrpEmailModal = () => {
    setOpenEditSupportGrpEmailModal(false);
  };

  const handleOpenEditRequesterModal = () => {
    setOpenEditRequesterModal(true);
  };

  const handleCloseEditRequesterModal = () => {
    setOpenEditRequesterModal(false);
  };
  return {
    error,
    loading,
    data,
    title,
    lastUpdateTime,
    subtitle,
    cancelRequestDialogOpen,
    deleteApplicationDialogOpen,
    setupWizIntegrationLoading,
    handleApplicationSummaryClose,
    handleApplicationSummaryRefresh,
    handleRetryStarterKitRequestClick,
    handleCancelStarterKitRequestClick,
    handleCancelRequestDialogOk,
    handleCancelRequestDialogCancel,
    handleDeleteApplicationClick,
    handleDeleteApplicationDialogOk,
    handleDeleteApplicationDialogCancel,
    handleRetryDeleteApplicationClick,
    handleSetupWizIntegrationClick,
    handleDeleteUsersFromGroup,
    handleDeleteAdditionalCollaborators,
    handleDeleteWizMembers,
    handleAadGroupMembersChange,
    handleAadReaderGroupMembersChange,
    handleWizMembersChange,
    handleAdoProjectContributorsChange,
    handleAdoProjectAdministratorsChange,
    handleADOProjectBoardAdministratorsChange,
    handleAdditionalCollaboratorsChange,
    formValues,
    handleAddADOProjectAdministrators,
    handleAddAADGroupMember,
    handleAddADOProjectContributors,
    handleAddADOProjectBoardAdministrators,
    handleAddAADReaderGroupMember,
    handleAddWizMembers,
    handleAddAdditionalCollaborators,
    handleAddStarterKitToApp,
    handleCheckAddStarterKitToApp,
    handleCheckCanCancelOffering,
    handleAddVNetRegion,
    handleAddEnvironment,
    hasHelpDocLink,
    getUtilityHelpUrl,
    handleUnlockStateFile,
    handleDeleteEnvironment,
    openPolicyModal,
    policyModalData,
    handleOpenPolicyModal,
    handleClosePolicyModal,
    handleOpenEditSupportGrpEmailModal,
    handleCloseEditSupportGrpEmailModal,
    openEditSupportGrpEmailModal,
    handleOpenEditRequesterModal,
    handleCloseEditRequesterModal,
    openEditRequesterModal,
  };
};

export default useApplicationSummaryPage;
