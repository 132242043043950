import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { fetch } from 'cross-fetch';
import { User } from 'oidc-client-ts';

const configureHttpLink = (uri: string) =>
  createHttpLink({
    uri: uri,
    fetch,
  });

const getUser = (authority: string, clientId: string) => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${authority}:${clientId}`
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
};

const configureAuthLink = (authority: string, clientId: string) =>
  setContext((_, { headers }) => {
    const user = getUser(authority, clientId);
    const token = user?.access_token;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

const createApolloClient = (
  baseUrl: string,
  authority: string,
  clientId: string
) => {
  const uri = `${baseUrl}/api/graphql`;

  return new ApolloClient({
    uri: uri,
    cache: new InMemoryCache({
      addTypename: false,
    }),
    link: configureAuthLink(authority, clientId).concat(configureHttpLink(uri)),
    credentials: 'include',
  });
};

export default createApolloClient;
