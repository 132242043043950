import {
  ApplicationRequestDraft,
  ReferenceArchitecture,
} from '@costco-service-catalog/bff-types';
import { Help, PrivacyTip } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import { useGlobalState } from '../../../globalState/useGlobalState';
import { CardDetailsType } from '../../../types';
import useStyles from '../CommonFields/Common.styles';
import {
  AADGroupMembers,
  AADReaderGroupMembers,
  ADOOrgDropdown,
  ADOProjectAdministrators,
  ADOProjectBoardAdministrators,
  ADOProjectContributors,
  ADOProjectDropdown,
  AdditionalCollaborators,
  ApplicationDescription,
  ApplicationName,
  AzurePolicyList,
  CidrBlockInfoText,
  CidrBlockSizeTable,
  ManagementGroupDropdown,
  NewOrExistingProject,
  NonProdEnvironments,
  PrimaryRegionDropdown,
  ProdEnvironments,
  SecondaryRegionDropdown,
  SupportGroupEmail,
  WizMembers,
  ADOProjectBoardsProcess,
} from '../DataDrivenFields';
import useAzureCloudNativeStarterKit from './useAzureCloudNativeStarterKit';

interface AzureCloudNativeStarterKitPropType {
  offering: CardDetailsType;
  selectedOfferings: ReferenceArchitecture[] | undefined;
  draftData: ApplicationRequestDraft | undefined;
}

const AzureCloudNativeStarterKit = (
  props: AzureCloudNativeStarterKitPropType
) => {
  const { offering, selectedOfferings, draftData } = props;

  const {
    formik,
    managementGroupData,
    ManagementGroupsLoading,
    adoOrgData,
    ADOOrgsLoading,
    adoProjectData,
    ADOProjectsLoading,
    handleNonProdEnvChange,
    handleProdEnvChange,
    handleCidrBlockSizeChange,
    handleAdditionalCollaboratorChange,
    handleAadGroupMembersChange,
    handleAadReaderGroupMembersChange,
    handleWizMembersChange,
    handleAdoOrgChange,
    handleAdoProjectContributorsChange,
    handleAdoProjectAdministratorsChange,
    handleADOProjectBoardAdministratorsChange,
    handleFormSave,
    contributorsAreReadersInProdMsg,
    existingProjectPrerequisitesMsg,
    existingProjectPrereqDocUrl,
    handlePolicyChange,
    handlePolicyModeChange,
    handleADOProjectBoardsProcessChange,
  } = useAzureCloudNativeStarterKit(offering, selectedOfferings, draftData);

  const { classes } = useStyles();
  const { appSettings } = useGlobalState();

  const getRegionGroupArray = (regions: string) => {
    if (typeof regions === 'string' && regions.length > 0) {
      const regionArr = regions.split(',').map((r) => r.trim());
      return regionArr;
    }
    return [];
  };

  const availableRegions = getRegionGroupArray(
    appSettings.availableRegions.get()
  );

  const azureRegionGroups = [
    {
      zone: 'APAC',
      regions: getRegionGroupArray(appSettings.azureRegionGroupAPAC.get()),
    },
    {
      zone: 'CA',
      regions: getRegionGroupArray(appSettings.azureRegionGroupCA.get()),
    },
    {
      zone: 'EU',
      regions: getRegionGroupArray(appSettings.azureRegionGroupEU.get()),
    },
    {
      zone: 'US',
      regions: getRegionGroupArray(appSettings.azureRegionGroupUS.get()),
    },
  ];

  const availableCidrBlockSizes = () => {
    const cidrBlockSizes = appSettings.cidrBlockSizes.get();
    if (typeof cidrBlockSizes === 'string' && cidrBlockSizes.length > 0) {
      const cidrSizesArr = cidrBlockSizes.split(',').map((r) => r.trim());
      return cidrSizesArr;
    }
    return [];
  };

  const useApplicationPolicySelection =
    appSettings.useApplicationPolicySelection.get();

  const productionEnvironmentSelectionEnabled =
    appSettings.productionEnvironmentSelectionEnabled.get();

  const wizEnabled = appSettings.wizEnabled.get();
  const wizHelpDocUrl = appSettings.wizHelpDocUrl.get();

  return (
    <div>
      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <Typography
          className={classes.fieldHeadingWrap}
          variant="h4"
          sx={{ marginBottom: '1.5rem' }}
        >
          {offering.name}
        </Typography>

        {selectedOfferings && selectedOfferings.length > 1 ? (
          <Box
            className={classes.fieldHeadingWrap}
            sx={{ marginBottom: '1.5rem' }}
          >
            <Typography>
              You have selected multiple starter kits in this request:
            </Typography>
            <div className={classes.skOfferingChipContainer}>
              {selectedOfferings.map((so) => (
                <div className={classes.skOfferingChip} key={so.id}>
                  <div className={classes.skOfferingChipTitle}>{so.name}</div>
                  <div className={classes.skOfferingChipApps}>
                    Includes:&nbsp;
                    {so?.applicationList?.replaceAll(',', ', ') || 'none'}
                  </div>
                </div>
              ))}
            </div>
          </Box>
        ) : undefined}

        <Typography className={classes.fieldHeadingWrap} variant="h5">
          Application Information
        </Typography>

        <ApplicationName
          value={formik.values.applicationName || ''}
          onChangeHandler={formik.handleChange}
          onBlurHandler={formik.handleBlur}
          fieldError={
            formik.touched.applicationName
              ? Boolean(formik.errors.applicationName)
              : false
          }
          errorText={formik.errors.applicationName}
        />
        <ApplicationDescription
          value={formik.values.applicationDescription || ''}
          onChangeHandler={formik.handleChange}
          onBlurHandler={formik.handleBlur}
          fieldError={
            formik.touched.applicationDescription
              ? Boolean(formik.errors.applicationDescription)
              : false
          }
          errorText={formik.errors.applicationDescription}
        />
        <ManagementGroupDropdown
          value={formik.values.managementGroup || null}
          data={managementGroupData}
          loading={ManagementGroupsLoading}
          onChangeHandler={(e, value) => {
            formik.setFieldValue('managementGroup', value || undefined);
          }}
          onBlurHandler={formik.handleBlur}
          fieldError={
            formik.touched.managementGroup
              ? Boolean(formik.errors.managementGroup)
              : false
          }
          errorText={formik.errors.managementGroup}
        />
        {!offering.isEphemeral && (
          <>
            <NonProdEnvironments
              value={formik.values.nonProdEnvironments.map(
                (e) => e.name as string
              )}
              onChangeHandler={handleNonProdEnvChange}
              onBlurHandler={formik.handleBlur}
              fieldError={
                formik.touched.nonProdEnvironments
                  ? Boolean(formik.errors.nonProdEnvironments)
                  : false
              }
              errorText={
                Array.isArray(formik.errors.nonProdEnvironments)
                  ? formik.errors.nonProdEnvironments.join(',')
                  : formik.errors.nonProdEnvironments
              }
            />
            {productionEnvironmentSelectionEnabled && (
              <ProdEnvironments
                value={formik.values.prodEnvironments.map(
                  (e) => e.name as string
                )}
                onChangeHandler={handleProdEnvChange}
                onBlurHandler={formik.handleBlur}
                fieldError={
                  formik.touched.prodEnvironments
                    ? Boolean(formik.errors.prodEnvironments)
                    : false
                }
                errorText={
                  Array.isArray(formik.errors.prodEnvironments)
                    ? formik.errors.prodEnvironments.join(',')
                    : formik.errors.prodEnvironments
                }
              />
            )}
            <CidrBlockInfoText
              selectedOfferings={selectedOfferings}
              selectedEnvs={formik.values.nonProdEnvironments.concat(
                formik.values.prodEnvironments
              )}
            />
            <CidrBlockSizeTable
              selectedEnvs={formik.values.nonProdEnvironments.concat(
                formik.values.prodEnvironments
              )}
              handleCidrBlockSizeChange={handleCidrBlockSizeChange}
              availableBlockSizes={availableCidrBlockSizes()}
            />
          </>
        )}
        <PrimaryRegionDropdown
          value={formik.values.primaryRegion || ''}
          data={availableRegions}
          onChangeHandler={(e, value) => {
            const zone = azureRegionGroups.find((grp) =>
              grp.regions.find((regionName) => regionName === value)
            );
            const secondaryRegionAutoselection = zone?.regions.find(
              (region) =>
                region !== value && availableRegions.indexOf(region) >= 0
            );

            formik
              .setFieldValue('primaryRegion', value || undefined)
              .then(() => {
                formik.setFieldValue(
                  'secondaryRegion',
                  secondaryRegionAutoselection || undefined
                );
              });
          }}
          onBlurHandler={formik.handleBlur}
          fieldError={
            formik.touched.primaryRegion
              ? Boolean(formik.errors.primaryRegion)
              : false
          }
          errorText={formik.errors.primaryRegion}
        />
        {!offering.isEphemeral && (
          <SecondaryRegionDropdown
            value={formik.values.secondaryRegion || ''}
            data={availableRegions}
            onChangeHandler={(e, value) => {
              formik.setFieldValue('secondaryRegion', value || undefined);
            }}
            onBlurHandler={formik.handleBlur}
            fieldError={
              formik.touched.secondaryRegion
                ? Boolean(formik.errors.secondaryRegion)
                : false
            }
            errorText={formik.errors.secondaryRegion}
            primaryRegion={formik.values.primaryRegion}
            azureRegionGroups={azureRegionGroups}
          />
        )}
        <SupportGroupEmail
          value={formik.values.supportGroupEmail || ''}
          onChangeHandler={formik.handleChange}
          onBlurHandler={formik.handleBlur}
          fieldError={
            formik.touched.supportGroupEmail
              ? Boolean(formik.errors.supportGroupEmail)
              : false
          }
          errorText={formik.errors.supportGroupEmail}
        />
        <AdditionalCollaborators
          value={formik.values.additionalCollaborators || []}
          onChangeHandler={handleAdditionalCollaboratorChange}
          onBlurHandler={formik.handleBlur}
        />
        {useApplicationPolicySelection ? (
          <AzurePolicyList
            ecoSystemPD={formik.values.ecoSystemPD}
            ecoSystemNP={formik.values.ecoSystemNP}
            handlePolicyChange={handlePolicyChange}
            handlePolicyModeChange={handlePolicyModeChange}
          />
        ) : undefined}
        <div className={classes.fieldWrap}>
          <Box sx={{ marginTop: '1rem', marginBottom: '1rem' }} />
        </div>
        <Typography className={classes.fieldHeadingWrap} variant="h5">
          ADO Configuration
        </Typography>
        <NewOrExistingProject
          value={formik.values.isExistingAdoProject || false}
          onChangeHandler={(e, checked) =>
            formik.setFieldValue('isExistingAdoProject', checked)
          }
        />
        {formik.values.isExistingAdoProject ? (
          <>
            <div className={classes.fieldWrap}>
              <Typography
                variant="body2"
                fontWeight="normal"
                component="div"
                sx={{ display: 'flex' }}
              >
                <a
                  target="_blank"
                  href={existingProjectPrereqDocUrl}
                  rel="noreferrer"
                >
                  {existingProjectPrerequisitesMsg}
                </a>
              </Typography>
            </div>
            <ADOOrgDropdown
              value={formik.values.existingAdoProjectOrg || null}
              data={adoOrgData}
              loading={ADOOrgsLoading}
              onChangeHandler={handleAdoOrgChange}
              onBlurHandler={formik.handleBlur}
              fieldError={
                formik.touched.existingAdoProjectOrg
                  ? Boolean(formik.errors.existingAdoProjectOrg)
                  : false
              }
              errorText={formik.errors.existingAdoProjectOrg}
            />
            <ADOProjectDropdown
              value={formik.values.existingAdoProject || null}
              data={adoProjectData}
              loading={ADOProjectsLoading}
              onChangeHandler={(e, value) =>
                formik.setFieldValue('existingAdoProject', value || undefined)
              }
              onBlurHandler={formik.handleBlur}
              fieldError={
                formik.touched.existingAdoProject
                  ? Boolean(formik.errors.existingAdoProject)
                  : false
              }
              errorText={formik.errors.existingAdoProject}
            />
          </>
        ) : (
          <>
            <ADOProjectAdministrators
              value={formik.values.adoProjectAdministrators || []}
              onChangeHandler={handleAdoProjectAdministratorsChange}
              onBlurHandler={formik.handleBlur}
              fieldError={
                formik.touched.adoProjectAdministrators
                  ? Boolean(formik.errors.adoProjectAdministrators)
                  : false
              }
              errorText={formik.errors.adoProjectAdministrators}
            />
            <ADOProjectContributors
              value={formik.values.adoProjectContributors || []}
              onChangeHandler={handleAdoProjectContributorsChange}
              onBlurHandler={formik.handleBlur}
            />
            <ADOProjectBoardAdministrators
              value={formik.values.adoProjectBoardAdministrators || []}
              onChangeHandler={handleADOProjectBoardAdministratorsChange}
              onBlurHandler={formik.handleBlur}
            />
            <ADOProjectBoardsProcess
              value={formik.values.adoProjectBoardsProcess || null}
              onChangeHandler={handleADOProjectBoardsProcessChange}
              onBlurHandler={formik.handleBlur}
              fieldError={
                formik.touched.adoProjectBoardsProcess
                  ? Boolean(formik.errors.adoProjectBoardsProcess)
                  : false
              }
              errorText={formik.errors.adoProjectBoardsProcess}
            />
          </>
        )}
        <div className={classes.fieldWrap}>
          <Divider sx={{ marginTop: '1rem', marginBottom: '1rem' }} />
        </div>
        <Typography className={classes.fieldHeadingWrap} variant="h5">
          Azure Configuration
        </Typography>
        <div className={classes.fieldWrap}>
          <Typography
            variant="body2"
            fontWeight="normal"
            component="div"
            sx={{ display: 'flex' }}
          >
            <PrivacyTip
              htmlColor="gray"
              fontSize="small"
              sx={{ marginRight: '5px' }}
            />{' '}
            {contributorsAreReadersInProdMsg}
          </Typography>
        </div>

        <AADGroupMembers
          value={formik.values.aadGroupMembers || []}
          onChangeHandler={handleAadGroupMembersChange}
          onBlurHandler={formik.handleBlur}
          fieldError={
            formik.touched.aadGroupMembers
              ? Boolean(formik.errors.aadGroupMembers)
              : false
          }
          errorText={formik.errors.aadGroupMembers}
          contributorsAreReadersInProdMsg={contributorsAreReadersInProdMsg}
        />
        <AADReaderGroupMembers
          value={formik.values.aadReaderGroupMembers || []}
          contributorsAreReadersInProdMsg={contributorsAreReadersInProdMsg}
          onChangeHandler={handleAadReaderGroupMembersChange}
          onBlurHandler={formik.handleBlur}
        />
        {!offering.isEphemeral && wizEnabled && (
          <>
            <div className={classes.fieldWrap}>
              <Divider sx={{ marginTop: '1rem', marginBottom: '1rem' }} />
            </div>
            <Typography className={classes.fieldHeadingWrap} variant="h5">
              Wiz.io Configuration
              <IconButton href={wizHelpDocUrl} rel="noreferrer" target="_blank">
                <Help color="primary" />
              </IconButton>
            </Typography>
            <WizMembers
              value={formik.values.wizMembers || []}
              onChangeHandler={handleWizMembersChange}
              onBlurHandler={formik.handleBlur}
              fieldError={
                formik.touched.wizMembers
                  ? Boolean(formik.errors.wizMembers)
                  : false
              }
              errorText={formik.errors.wizMembers}
            />
          </>
        )}
        <div className={classes.buttonWrap}>
          <Button
            disabled={
              !(
                formik.isValid &&
                (formik.dirty || formik.values.starterKitDraftId)
              ) || formik.isSubmitting
            }
            variant="contained"
            color="primary"
            type="submit"
            className={classes.submitButton}
          >
            Submit
          </Button>
          <Button
            disabled={!formik.values.applicationName || formik.isSubmitting}
            variant="outlined"
            color="primary"
            onClick={(e) => handleFormSave(e)}
          >
            Save Draft
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AzureCloudNativeStarterKit;
