import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: 'none',
  },
  form: {
    padding: '1rem',
  },
  fieldWrap: {
    padding: '1rem',
    maxWidth: '60rem',
    minWidth: '20rem',
  },
  fieldHeadingWrap: {
    paddingRight: '1rem',
    paddingLeft: '1rem',
    maxWidth: '60rem',
    minWidth: '20rem',
  },
  field: {
    width: '100%',
  },
  submitButton: {
    marginRight: '1rem',
  },
  buttonWrap: {
    padding: '1rem',
    marginTop: '1rem',
  },
  skOfferingList: {
    paddingTop: '1rem',
    paddingRight: '2rem',
    paddingLeft: '2rem',
    maxWidth: '60rem',
    minWidth: '20rem',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  skOfferingChipContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  skOfferingChip: {
    border: `1px solid ${theme.palette.divider}`,
    padding: '1rem',
    borderRadius: '.25rem',
    margin: '1rem 1rem 0 0',
  },
  skOfferingChipTitle: {
    fontWeight: 600,
  },
  skOfferingChipApps: {
    fontSize: '.9rem',
    opacity: '0.8',
  },
  tooltipHover: {
    marginLeft: 10,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
