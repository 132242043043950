interface ProjectInfoStateType {
  id: string;
  name: string;
}

const initialProjectInfoState: ProjectInfoStateType = JSON.parse(
  JSON.stringify({
    id: '4b4317c9-dfa0-4269-8edb-3b39583e7f4b',
    name: 'service-catalog',
  })
);

export type { ProjectInfoStateType };
export default initialProjectInfoState;
