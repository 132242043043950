import { CommonFormTextField } from '../CommonFields';

interface ApplicationNameProps {
  value: string;
  fieldError: boolean;
  errorText?: string;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const ApplicationName = (props: ApplicationNameProps) => {
  const { value, fieldError, errorText, onChangeHandler, onBlurHandler } =
    props;

  return (
    <CommonFormTextField
      componentID="applicationName"
      label="Application Name"
      value={value}
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      fieldError={fieldError}
      helperText={
        fieldError && errorText
          ? errorText
          : 'Give your application a name. This will be used for your ADO project name as well as part of some of your Azure resources.'
      }
      fullHelpText="Application Name must be between 3 and 25 characters with no spaces or special characters. It will be used to name your ADO project as well as some of your Azure resources."
    />
  );
};

ApplicationName.defaultProps = {
  errorText: undefined,
};

export default ApplicationName;
