import { AadUser } from '@costco-service-catalog/bff-types';
import { Help } from '@mui/icons-material';
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useAADNonZUsers } from '../../../hooks';
import useStyles from './Common.styles';

interface CommonNonZUsersAutoCompleteProps {
  componentID: string;
  label: string;
  value: AadUser[];
  fieldError: boolean;
  helperText: string;
  fullHelpText: string;
  required?: boolean;
  onChangeHandler: (event: React.ChangeEvent<{}>, value: AadUser[]) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const CommonNonZUsersAutoComplete = (
  props: CommonNonZUsersAutoCompleteProps
) => {
  const {
    componentID,
    label,
    value,
    fieldError,
    helperText,
    fullHelpText,
    required,
    onChangeHandler,
    onBlurHandler,
  } = props;

  const { error, data, loading, getAADNonZUsers } = useAADNonZUsers();

  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [inputValue, setInputValue] = useState('');
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleinputChange = (inputValue: string) => {
    getAADNonZUsers({ variables: { searchString: inputValue } });
  };

  return (
    <div>
      <div className={classes.fieldWrap}>
        <Autocomplete
          multiple
          id={componentID}
          options={data}
          getOptionLabel={(option: AadUser) => (option.name ? option.name : '')}
          inputValue={inputValue}
          filterOptions={(x) => x}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            if (newInputValue.length >= 3) {
              handleinputChange(newInputValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={fieldError}
              helperText={helperText}
              variant="outlined"
              required={!!required}
              placeholder="Type min 3 character of person's LanID to see options"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <IconButton
                      size="small"
                      aria-label="show help"
                      onClick={handleClick}
                      style={{
                        position: 'absolute',
                        right: 15,
                      }}
                    >
                      <Help />
                    </IconButton>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      params.InputProps.endAdornment
                    )}
                  </>
                ),
              }}
            />
          )}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          value={value}
          isOptionEqualToValue={(o, v) =>
            o.userPrincipalName === v.userPrincipalName
          }
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { maxWidth: 300 },
        }}
      >
        <Typography variant="body2" aria-label="detailed help" sx={{ p: 2 }}>
          {fullHelpText}
        </Typography>
      </Popover>
    </div>
  );
};

CommonNonZUsersAutoComplete.defaultProps = {
  required: false,
};

export default CommonNonZUsersAutoComplete;
