import {
  ApolloQueryResult,
  NetworkStatus,
  OperationVariables,
} from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import {
  Application,
  ApplicationRequestInput,
  ReferenceArchitecture,
  StarterKitEnvironment,
} from '@costco-service-catalog/bff-types';
import { formValuesType } from '../../formHooks';
import { CardDetailsType } from '../../../types';

const mockStarterKitEnvironments: StarterKitEnvironment[] = [
  {
    type: 'np',
    name: 'SBX',
    servicePrincipalName: 'mockServicePrincipalName-SBX',
    subscriptionName: 'mockSubscriptionName-SBX',
    aadGroup: 'mockAadGroup-Contributor-SBX',
    aadReaderGroup: 'mockAadGroup-Reader-SBX',
    cidrBlockSize: 25,
    notReady: false,
  },
  {
    type: 'np',
    name: 'ADT',
    servicePrincipalName: 'mockServicePrincipalName-ADT',
    subscriptionName: 'mockSubscriptionName-ADT',
    aadGroup: 'mockAadGroup-Contributor-ADT',
    aadReaderGroup: 'mockAadGroup-Reader-ADT',
    cidrBlockSize: 25,
    notReady: false,
  },
  {
    type: 'pd',
    name: 'PRD',
    servicePrincipalName: 'mockServicePrincipalName-PRD',
    subscriptionName: 'mockSubscriptionName-PRD',
    aadGroup: 'mockAadGroup-Contributor-PRD',
    aadReaderGroup: 'mockAadGroup-Reader-PRD',
    cidrBlockSize: 25,
    notReady: false,
  },
  {
    type: 'pd',
    name: 'STG',
    servicePrincipalName: 'mockServicePrincipalName-STG',
    subscriptionName: 'mockSubscriptionName-STG',
    aadGroup: 'mockAadGroup-Contributor-STG',
    aadReaderGroup: 'mockAadGroup-Reader-STG',
    cidrBlockSize: 25,
    notReady: false,
  },
];

const mockApplication: Application = {
  createdDateTime: '2022-05-16T18:00:00.959Z',
  updatedDateTime: '2022-05-18T18:00:00.959Z',
  aadGroupMembers: [
    {
      id: 'mockId',
      email: 'mockEmail',
    },
  ],
  aadReaderGroupMembers: [
    {
      id: 'mockId',
      email: 'mockEmail',
    },
  ],
  wizMembers: [
    {
      id: 'mockId',
      email: 'mockEmail',
    },
  ],
  adoProject: {
    isExistingProject: false,
    adoProjectAdministrators: {
      name: 'mockAdoProjectAdministratorsGroupName',
      members: [{ id: 'mockId', email: 'mockEmail' }],
    },
    adoProjectContributors: {
      name: 'mockAdoProjectContributorsGroupName',
      members: [{ id: 'mockId', email: 'mockEmail' }],
    },
    adoProjectBoardAdministrators: {
      name: 'mockAdoProjectBoardAdministratorsGroupName',
      members: [{ id: 'mockId', email: 'mockEmail' }],
    },
    projectName: 'mockProjectName',
    projectUrl: 'https://mockProjectUrl',
  },
  additionalCollaborators: ['mockEmail'],
  applicationDescription: 'mockDescription',
  applicationName: 'mockAppName',
  businessUnitNumber: 635,
  environments: mockStarterKitEnvironments,
  starterKitRequests: [
    {
      id: 'mockRequest1',
      requestedDateTime: '2022-05-17T18:00:00.959Z',
      cartsApprovalRequestId: 'mockCartsId',
      status: 'AwaitingAdoRun',
      offerings: [
        {
          category: 'Application',
          offeringName: 'mockOfferingName1',
          offeringVersion: '1.0.0',
          offeringRepoUrl: 'https://mockOfferingRepoUrl',
          status: 'AwaitingAdoRun',
          offeringShortName: 'cloudapp',
        },
        {
          category: 'CoreInfra',
          offeringName: 'mockOfferingName2',
          offeringVersion: '1.0.0',
          offeringRepoUrl: 'https://mockOfferingRepoUrl',
          status: 'Completed',
          offeringShortName: 'coreinfra',
        },
        {
          category: 'LandingZone',
          offeringName: 'mockOfferingName3',
          offeringVersion: '1.0.0',
          offeringRepoUrl: 'https://mockOfferingRepoUrl',
          status: 'Completed',
          offeringShortName: 'peerland',
        },
      ],
    },
    {
      id: 'mockRequest2',
      requestedDateTime: '2022-05-18T18:00:00.959Z',
      cartsApprovalRequestId: 'mockCartsId',
      status: 'Failed',
      offerings: [
        {
          category: 'LandingZone',
          offeringName: 'mockOfferingName2',
          offeringVersion: '1.0.0',
          offeringRepoUrl: 'https://mockOfferingRepoUrl',
          status: 'Failed',
          offeringShortName: 'peerland',
        },
      ],
    },
    {
      id: 'mockRequest3',
      requestedDateTime: '2022-05-16T18:00:00.959Z',
      cartsApprovalRequestId: 'mockCartsId',
      status: 'Submitted',
      offerings: [
        {
          category: 'LandingZone',
          offeringName: 'mockOfferingName2',
          offeringVersion: '1.0.0',
          offeringRepoUrl: 'https://mockOfferingRepoUrl',
          status: 'AwaitingApproval',
          offeringShortName: 'peerland',
        },
      ],
    },
  ],
  id: 'mock1',
  organizationName: 'mockOrgName',
  hasRunningPipelines: true,
  supportGroupEmail: 'mockEmail@costco.com',
};

const mockApplicationRes = () => {
  const error: ApolloError | undefined = undefined;
  const data: Application = mockApplication;
  const loading: boolean = false;
  const refetch: (
    variables?: Partial<OperationVariables>
  ) => Promise<ApolloQueryResult<Application>> = () =>
    new Promise((resolve) => {
      resolve({
        data: mockApplication,
        loading: false,
        networkStatus: NetworkStatus.ready,
      });
    });
  return {
    error,
    data,
    loading,
    refetch,
  };
};

const mockApplicationRequest: formValuesType = {
  applicationName: 'mockApplicationname',
  applicationDescription: 'mockApplicationDescription',
  isEphemeral: false,
  nonProdEnvironments: [
    {
      name: 'mockNpEnv1',
      cidrBlockSize: 25,
      type: 'np',
    },
  ],
  prodEnvironments: [
    {
      name: 'mockPrdEnv1',
      cidrBlockSize: 25,
      type: 'prd',
    },
  ],
  primaryRegion: 'mockPrimaryRegion',
  secondaryRegion: 'mockSecondaryRegion',
  supportGroupEmail: 'mockSupportGroupEmail',
  managementGroup: {
    displayName: 'mockMgmtGroupDispName',
    name: 'mockMgmtGroupName',
  },
  additionalCollaborators: [
    {
      userPrincipalName: 'mockUserPrincipalname',
    },
  ],
  aadGroupMembers: [
    {
      id: 'mockAadGroupMemberId',
      userPrincipalName: 'mockAadGroupEmail',
    },
  ],
  aadReaderGroupMembers: [
    {
      id: 'mockAadReaderGroupMemberId',
      userPrincipalName: 'mockAadReaderGroupEmail',
    },
  ],
  wizMembers: [
    {
      id: 'mockWizMemberId',
      userPrincipalName: 'mockWizEmail',
    },
  ],
  isExistingAdoProject: false,
  adoProjectAdministrators: [
    {
      id: 'mockAdoProjecAdministratorId',
      userPrincipalName: 'mockAdoProjectAdminEmail',
    },
  ],
  adoProjectBoardAdministrators: [
    {
      id: 'mockAdoProjecBoardAdministratorId',
      userPrincipalName: 'mockAdoProjectBoardAdminEmail',
    },
  ],
  adoProjectContributors: [
    {
      id: 'mockAdoProjecContributorId',
      userPrincipalName: 'mockAdoProjectContributorEmail',
    },
  ],
  existingAdoProjectOrg: '',
  existingAdoProject: {},
};

const mockOfferingsRequest: CardDetailsType[] = [
  {
    id: 'mockOfferingId',
    name: 'mockOfferingName',
    ready: false,
    repoUrl: 'mockRepoURl',
    version: 'mockOfferingVersion',
    shortOfferingName: 'mockOfferingShortName',
    vnetPeered: false,
    description: '',
    type: '',
    targets: [],
    constants: [],
    variables: [],
    components: [],
    category: 'LandingZone',
    applicationList: 'mockApplicationList',
    isEphemeral: false,
  },
];

const mockOffering: ReferenceArchitecture[] = [
  {
    name: 'mockOfferingName',
    repoUrl: 'mockRepoURl',
    version: 'mockOfferingVersion',
    category: 'Application',
    shortOfferingName: 'mockOfferingShortName',
    vnetPeered: true,
    applicationList: 'mockApplicationList',
  },
];

const expectedApplicationRequest: ApplicationRequestInput = {
  applicationName: 'mockApplicationname',
  applicationDescription: 'mockApplicationDescription',
  applicationRequestDraftId: undefined,
  isEphemeral: false,
  requestedEnvironments: [
    {
      name: 'mockNpEnv1',
      cidrBlockSize: 25,
      type: 'np',
    },
    {
      name: 'mockPrdEnv1',
      cidrBlockSize: 25,
      type: 'prd',
    },
  ],
  primaryRegion: 'mockPrimaryRegion',
  secondaryRegion: 'mockSecondaryRegion',
  supportGroupEmail: 'mockSupportGroupEmail',
  managementGroup: {
    displayName: 'mockMgmtGroupDispName',
    name: 'mockMgmtGroupName',
  },
  additionalCollaborators: ['mockUserPrincipalname'],
  aadGroupMembers: [
    {
      id: 'mockAadGroupMemberId',
      email: 'mockAadGroupEmail',
    },
  ],
  aadReaderGroupMembers: [
    {
      id: 'mockAadReaderGroupMemberId',
      email: 'mockAadReaderGroupEmail',
    },
  ],
  wizMembers: [
    {
      id: 'mockWizMemberId',
      email: 'mockWizEmail',
    },
  ],
  newAdoProjectRequest: {
    adoProjectAdministrators: {
      members: [
        {
          id: 'mockAdoProjecAdministratorId',
          email: 'mockAdoProjectAdminEmail',
        },
      ],
    },
    adoProjectBoardAdministrators: {
      members: [
        {
          id: 'mockAdoProjecBoardAdministratorId',
          email: 'mockAdoProjectBoardAdminEmail',
        },
      ],
    },
    adoProjectContributors: {
      members: [
        {
          id: 'mockAdoProjecContributorId',
          email: 'mockAdoProjectContributorEmail',
        },
      ],
    },
  },
  offeringRequests: [
    {
      offeringName: 'mockOfferingName',
      offeringRepoUrl: 'mockRepoURl',
      offeringVersion: 'mockOfferingVersion',
      offeringShortName: 'mockOfferingShortName',
      offeringVnetPeered: false,
      category: 'LandingZone',
      offeringServiceNames: 'mockApplicationList',
    },
  ],
};

export {
  mockApplication,
  mockApplicationRes,
  mockApplicationRequest,
  mockOfferingsRequest,
  expectedApplicationRequest,
  mockOffering,
};
