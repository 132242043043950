import {
  ReferenceArchitecture,
  Application,
} from '@costco-service-catalog/bff-types';

const isRefArch = (
  refArch: ReferenceArchitecture | undefined
): refArch is ReferenceArchitecture => !!refArch;

const extractDeployedRefArchs = (
  deployedApplication: Application | undefined,
  referenceArchitectures: ReferenceArchitecture[]
) => {
  if (deployedApplication && deployedApplication.starterKitRequests) {
    const maybeOfferings = deployedApplication.starterKitRequests.flatMap(
      (sk) => sk?.offerings
    );

    const deployedResources = maybeOfferings
      .filter((offering) => offering?.status === 'Completed')
      .map((resource) => ({
        shortName: resource?.offeringShortName || '',
        version: resource?.offeringVersion || '',
      }));

    // if we target ES6 we can improve time with new Set(a) on last filter.
    const deployedOfferings = deployedResources
      .map((deployedResource) => {
        const correspondingRefArch = referenceArchitectures.find(
          (refArch) => refArch.shortOfferingName === deployedResource.shortName
        );
        if (correspondingRefArch) {
          const correspondingRefArchWithCorrectVersion: ReferenceArchitecture =
            { ...correspondingRefArch, version: deployedResource.version };

          return correspondingRefArchWithCorrectVersion;
        }
        return undefined;
      })
      .filter(isRefArch)
      .filter((item, pos, self) => self.indexOf(item) === pos);

    return deployedOfferings;
  }
  return [] as ReferenceArchitecture[];
};

export default extractDeployedRefArchs;
