import { ScienceRounded } from '@mui/icons-material';
import { Container, Typography, Box, CircularProgress } from '@mui/material';
import { PageHeader } from '../../components';
import useStyles from './EphemeralResourceGroupPage.styles';
import useEphemeralResourceGroupPage from './useEphemeralResourceGroupPage';
import EphemeralResourceGroupLayout from '../../components/EphemeralResourceGroup/EphemeralResourceGroupLayout';

const EphemeralResourceGroupPage = () => {
  const { classes } = useStyles();

  const {
    title,
    subTitle,
    offeringsData,
    offeringsError,
    offeringsLoading,
    ephemeralRgsData,
    ephemeralRgsError,
    ephemeralRgsLoading,
    runEphemeralRgsRefetch,
  } = useEphemeralResourceGroupPage();

  return (
    <div className={classes.root}>
      <PageHeader icon={<ScienceRounded />} title={title} subtitle={subTitle} />
      <Container disableGutters maxWidth={false} className={classes.pageLayout}>
        <div>
          {offeringsLoading || ephemeralRgsLoading ? (
            <Box padding="2rem" display="flex">
              <CircularProgress />
            </Box>
          ) : (
            <div>
              {offeringsError || ephemeralRgsError ? (
                <div>
                  <Typography>Unable to get Reference Architectures</Typography>
                </div>
              ) : (
                <EphemeralResourceGroupLayout
                  offeringsData={offeringsData}
                  existingEphemeralResourceGroups={ephemeralRgsData}
                  runEphemeralRgsRefetch={runEphemeralRgsRefetch}
                />
              )}
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default EphemeralResourceGroupPage;
