import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import LanguageIcon from '@mui/icons-material/Language';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import StorageIcon from '@mui/icons-material/Storage';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { SvgIconProps } from '@mui/material/SvgIcon';

interface ComponentIcons {
  [key: string]: (props: SvgIconProps) => JSX.Element | null;
}

const componentIcons: ComponentIcons = {
  'Azure Blob Storage': StorageIcon,
  'Blob Storage': StorageIcon,
  'Azure Cloud Island Network': CloudCircleIcon,
  'Cloud Island': CloudCircleIcon,
  'Azure Cosmos Database / Core API': StorageIcon,
  'Azure Cosmos Database / Mongo API': StorageIcon,
  'Azure DevOps Pipeline': AutorenewIcon,
  'Azure DevOps Project': FolderSpecialIcon,
  'Azure DevOps Repository': AccountTreeIcon,
  'Azure Front Door': MeetingRoomIcon,
  'Front Door': MeetingRoomIcon,
  'Azure Key Vault': VpnKeyIcon,
  'Key Vault': VpnKeyIcon,
  'Azure Web App': LanguageIcon,
  'Web App': LanguageIcon,
};

export default componentIcons;
