import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  pageHeader: {
    backgroundColor: theme.palette.mode === 'dark' ? '#023859' : '#e6f4fd',
    display: 'flex',
    minHeight: '4rem',
    padding: '1rem',
  },
  pageHeaderIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
  },
  pageHeaderAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  pageHeaderContent: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  pageHeaderLeft: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  pageHeaderRight: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  pageHeaderTitleStack: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  pageHeaderBorder: {
    height: '1px',
    backgroundColor: theme.palette.divider,
  },
}));

export default useStyles;
