import { ApolloError, useQuery } from '@apollo/client';
import {
  AdoProject,
  AdoProjectsResponse,
} from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '..';
import { GET_ADO_PROJECTS_FOR_ORG_QUERY } from '../../apollo/queries';
import { useGlobalState } from '../../globalState/useGlobalState';
import mockAdoProjectsRes from './lookupHooksMocks/useADOProjectsForOrg.mock';

const useADOProjectsForOrg = (adoOrg: string) => {
  const { appSettings } = useGlobalState();
  if (appSettings.useUIMocks.get()) {
    return mockAdoProjectsRes();
  }

  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getAdoProjectsForOrg: AdoProjectsResponse;
  }>(GET_ADO_PROJECTS_FOR_ORG_QUERY, {
    variables: { adoOrg },
    fetchPolicy: 'network-only',
    skip: !adoOrg,
    onError: (error: ApolloError) => handleErrorResponse(error),
  });
  const [projects, setProjects] = useState<AdoProject[]>([]);

  useEffect(() => {
    if (!loading && data) {
      const extractProjects: AdoProject[] | undefined = data
        .getAdoProjectsForOrg.response as AdoProject[];
      if (extractProjects) {
        setProjects(extractProjects);
      }
    } else if (!loading) {
      setProjects([]);
    }
  }, [loading, data]);

  return {
    error,
    data: projects,
    loading,
  };
};

export default useADOProjectsForOrg;
