import { Application } from '@costco-service-catalog/bff-types';
import { useGetRepoRefs } from '../../../../hooks';
import { starterKitOfferingWithRequest } from './useEphemeralResourceGroupModalCreateNew';

const useERGModalCreateNewRepoRef = (
  selectHostingApplication: Application,
  selectTargetApplication: starterKitOfferingWithRequest,
  targetAppOfferingShortName: string
) => {
  const repoRefs = useGetRepoRefs({
    applicationId: selectHostingApplication.id,
    offeringShortName: targetAppOfferingShortName,
    requestId: selectTargetApplication.completedStarterKitId,
  });

  return {
    repoRefsData: repoRefs.data,
    repoRefsError: repoRefs.error,
    repoRefsLoading: repoRefs.loading,
  };
};

export default useERGModalCreateNewRepoRef;
