import { ApolloError, useLazyQuery } from '@apollo/client';
import { AadUser, AadUsersResponse } from '@costco-service-catalog/bff-types';
import { useState } from 'react';
import { useApolloErrors } from '..';
import { SEARCH_AAD_NONZ_USERS_QUERY } from '../../apollo/queries';
import { useGlobalState } from '../../globalState/useGlobalState';
import mockSearchAADUsersRes from './lookupHooksMocks/useAADUsers.mock';

const useAADNonZUsers = () => {
  const { appSettings } = useGlobalState();
  if (appSettings.useUIMocks.get()) {
    return mockSearchAADUsersRes();
  }

  const { handleErrorResponse } = useApolloErrors();
  const [users, setUsers] = useState<AadUser[]>([]);

  const [getAADNonZUsers, { loading, error, data }] = useLazyQuery<{
    searchAADNonZUsers: AadUsersResponse;
  }>(SEARCH_AAD_NONZ_USERS_QUERY, {
    onError: (error: ApolloError) => handleErrorResponse(error),
    onCompleted: (data) => {
      setUsers(data.searchAADNonZUsers.response as AadUser[]);
    },
  });

  return {
    error,
    data: users,
    loading,
    getAADNonZUsers,
  };
};

export default useAADNonZUsers;
