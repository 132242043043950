const setDarkModePersistence = (DarkMode: boolean) => {
  if (DarkMode) {
    localStorage.DarkMode = true;
  } else {
    localStorage.DarkMode = false;
  }
};

const getDarkModePersistence = () => {
  if (localStorage.DarkMode === 'true') {
    return true;
  }
  return false;
};

export const useLocalStorage = () => ({
  setDarkModePersistence,
  getDarkModePersistence,
});

export default useLocalStorage;
