import { ApolloError, useQuery } from '@apollo/client';
import {
  ManagementGroup,
  ManagementGroupsResponse,
} from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '..';
import { GET_MANAGEMENT_GROUPS_QUERY } from '../../apollo/queries';
import { useGlobalState } from '../../globalState/useGlobalState';
import mockManagementGroupsRes from './lookupHooksMocks/useManagementGroups.mock';

const useManagementGroups = () => {
  const { appSettings } = useGlobalState();
  if (appSettings.useUIMocks.get()) {
    return mockManagementGroupsRes();
  }

  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getManagementGroups: ManagementGroupsResponse;
  }>(GET_MANAGEMENT_GROUPS_QUERY, {
    onError: (error: ApolloError) => handleErrorResponse(error),
  });
  const [groups, setGroups] = useState<ManagementGroup[]>([]);

  useEffect(() => {
    if (!loading && data) {
      const extractGroups: ManagementGroup[] | undefined = data
        .getManagementGroups.response as ManagementGroup[];
      if (extractGroups) {
        setGroups(extractGroups);
      }
    }
  }, [loading, data]);

  return {
    error,
    data: groups,
    loading,
  };
};

export default useManagementGroups;
