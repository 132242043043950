import { array, object, ref, string } from 'yup';
import getEphemeralResourceGroupContentMap from './ephemeralResourceGroupContentMap';

const ephemeralResourceGroupModalValidationSchema = (
  selectedERG: string | undefined
) => {
  const ephemeralResourceGroupContentMap =
    getEphemeralResourceGroupContentMap();
  const ephemeralResourceGroupModalContent =
    ephemeralResourceGroupContentMap.find(
      (content) => selectedERG === content.offeringShortName
    );

  const selectTargetApplicationReqString =
    ephemeralResourceGroupModalContent?.content
      .selectTargetApplicationRequiredErrorText ||
    'Target Application cannot be empty.';

  return object({
    adoBoardsUserStory: string()
      .min(6, 'ADO Boards User Story ID must be at least 6 numbers.')
      .max(7, 'ADO Boards User Story ID must not be more than 7 numbers.')
      .matches(/^[0-9]*$/g, 'ADO Boards User Story ID should be 6-7 numbers.')
      .required('ADO Boards User Story ID cannot be empty.'),
    selectHostingApplication: object().required(
      'You must select a hosting application.'
    ),
    selectTargetApplication: object().required(
      selectTargetApplicationReqString
    ),
  });
};

export default ephemeralResourceGroupModalValidationSchema;
