/* eslint-disable react/jsx-no-duplicate-props */
import { Help } from '@mui/icons-material';
import {
  Autocomplete,
  IconButton,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import useStyles from './Common.styles';

interface CommonEnvMultiSelectPropType {
  componentID: string;
  label: string;
  data: string[];
  value: string[] | string | null;
  valueMax: number;
  fieldError: boolean;
  helperText: string;
  fullHelpText: string;
  onChangeHandler: (
    event: React.ChangeEvent<{}>,
    value: string[] | string | null
  ) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const CommonEnvMultiSelect = (props: CommonEnvMultiSelectPropType) => {
  const {
    componentID,
    label,
    data,
    value,
    valueMax,
    fieldError,
    helperText,
    fullHelpText,
    onChangeHandler,
    onBlurHandler,
  } = props;

  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div className={classes.fieldWrap}>
        <Autocomplete
          multiple={valueMax > 1}
          id={componentID}
          options={data}
          value={value}
          getOptionDisabled={() =>
            Array.isArray(value) ? value.length >= valueMax : false
          }
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              error={fieldError}
              helperText={helperText}
              inputProps={{
                ...params.inputProps,
                maxLength: 3,
              }}
              required
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <IconButton
                      size="small"
                      aria-label="show help"
                      onClick={handleClick}
                      style={{
                        position: 'absolute',
                        right: 15,
                      }}
                    >
                      <Help />
                    </IconButton>
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { maxWidth: 300 },
        }}
      >
        <Typography variant="body2" aria-label="detailed help" sx={{ p: 2 }}>
          {fullHelpText}
        </Typography>
      </Popover>
    </div>
  );
};

export default CommonEnvMultiSelect;
