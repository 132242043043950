import {
  Application,
  OfferingCategory,
  ReferenceArchitecture,
  Status,
} from '@costco-service-catalog/bff-types';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { useGlobalState } from '../../globalState/useGlobalState';
import {
  generateAddToApplication,
  useGetApplication,
  useOfferings,
  useSubmitAddToApplication,
} from '../../hooks';
import extractDeployedRefArchs from '../../hooks/validationHooks/extractDeployedRefArchs';
import { sortRefArchByTier } from '../../hooks/validationHooks/sortRefArchByTier';
import { CardDetailsType } from '../../types';
import { autopopPayloadType } from '../ApplicationSummaryPage/useApplicationSummaryPage';

const OKStatus: Status = 'OK';

const useOfferingMultiPage = () => {
  const { appSettings } = useGlobalState();
  const useNewAppWizard = appSettings.useNewAppWizard.get();
  const { submitAddToApplication } = useSubmitAddToApplication();
  const navigate = useNavigate();
  const location = useLocation();

  const multiSelectFooterCategorySlots: OfferingCategory[] = [
    'LandingZone',
    'CoreInfra',
    'Application',
  ];

  const evaluateAutopopulation = () => {
    const locationState = location.state as any;
    if (locationState?.autopopPayload?.autopopulate) {
      return locationState.autopopPayload as autopopPayloadType;
    }
    return undefined;
  };

  const title = 'Service Catalog Multi Select';
  const subTitle =
    'Welcome to the Service Catalog. Get started by selecting the following offerings in the specified sequence: Landing Zone, Core Infrastructure, and Application(s).';

  const [deployedApplication, setDeployedApplication] = useState<Application>();
  const [showAddToAppModal, setShowAddToAppModal] = useState(false);
  const [referenceArchitectures, setReferenceArchitectures] = useState<
    ReferenceArchitecture[]
  >([]);

  const [displayedOfferings, setDisplayedOfferings] = useState<
    ReferenceArchitecture[]
  >([]);

  const [selectedOfferings, setSelectedOfferings] = useState<
    ReferenceArchitecture[]
  >([]);
  const [selectedUpdates, setSelectedUpdates] = useState<
    ReferenceArchitecture[]
  >([]);
  const [multiselectMode, setMultiselectMode] = useState<
    '' | 'new' | 'existing'
  >('');

  const [addToAppLoading, setAddToAppLoading] = useState(false);
  const [previouslyDeployedWindowOpen, setPreviouslyDeployedWindowOpen] =
    useState(false);
  const [selectedPreviouslyDeployed, setSelectedPreviouslyDeployed] =
    useState<string>('');
  const { data, loading } = useOfferings();
  const { data: appData } = useGetApplication({ id: deployedApplication?.id });

  const handleSetDeployedApplication = (
    starterKit: Application | undefined
  ) => {
    setDeployedApplication(starterKit);
  };

  const handleAutoselectFromAutopop = (selections: string[]) => {
    if (
      selections.length > 0 &&
      referenceArchitectures.length > 0 &&
      deployedApplication
    ) {
      const shortOfferingNameSelections = selections.map(
        (shortOfferingName) => ({
          shortOfferingName,
        })
      );

      const refArchSelections = _.intersectionBy(
        referenceArchitectures,
        shortOfferingNameSelections,
        'shortOfferingName'
      );
      const applicationDeploys = extractDeployedRefArchs(
        deployedApplication,
        referenceArchitectures
      );

      refArchSelections.forEach((refArchSelection) => {
        if (
          applicationDeploys.find(
            (deployedApp) =>
              deployedApp.shortOfferingName ===
              refArchSelection.shortOfferingName
          )
        ) {
          // Select Update
          selectedUpdates.push(refArchSelection);
          setSelectedUpdates([...selectedUpdates]);
        } else {
          // Select New
          selectedOfferings.push(refArchSelection);
          setSelectedOfferings([
            ...selectedOfferings.sort((a, b) => sortRefArchByTier(a, b)),
          ]);
        }
      });
    }
  };

  useEffect(() => {
    setSelectedOfferings([]);
    setSelectedUpdates([]);
    const multiselectAutopop = evaluateAutopopulation();

    if (multiselectAutopop?.autopopulate) {
      setMultiselectMode('existing');
      handleSetDeployedApplication(multiselectAutopop.data);
      handleAutoselectFromAutopop(multiselectAutopop.selection);
    }

    if (!loading && data) {
      setReferenceArchitectures(data);
      const newOfferingDisplay = data.filter((r) => r.ready === true);
      setDisplayedOfferings(newOfferingDisplay);
    }

    if (appData && appData?.starterKitRequests) {
      const kitsToHide: string[] = [];
      appData?.starterKitRequests.forEach((request) => {
        if (request?.offerings) {
          request.offerings.forEach((offering) => {
            if (offering) {
              if (
                offering.status === 'Completed' ||
                offering.status === 'Failed'
              ) {
                kitsToHide.push(offering.offeringShortName as string);
              }
            }
          });
        }
      });

      if (kitsToHide.length) {
        const kitSet = new Set(kitsToHide);
        let filteredOfferings: ReferenceArchitecture[] = [];
        displayedOfferings?.forEach((displayedOffering) => {
          if (displayedOffering?.shortOfferingName) {
            if (!kitSet.has(displayedOffering.shortOfferingName)) {
              if (!filteredOfferings.includes(displayedOffering)) {
                filteredOfferings.push(displayedOffering);
              }
            }
          }
        });
        if (filteredOfferings.length) {
          setDisplayedOfferings(filteredOfferings);
          filteredOfferings = [];
        }
      }
    }
  }, [loading, data, appData, deployedApplication]);

  // Start New Application Requests
  const toggleShowAddToAppModal = () => {
    setShowAddToAppModal(!showAddToAppModal);
  };

  const handleContinueToNewAppWizard = (cardDetails: CardDetailsType[]) => {
    const findLZ = cardDetails.find((c) => c.category === 'LandingZone');
    navigate(`${ROUTES.NEW_APP_WIZARD}`, {
      replace: false,
      state: {
        selectedLandingZone: findLZ,
        otherSelectedOfferings: selectedOfferings,
      },
    });
  };

  const handleContinueToLegacyOfferingRequestForm = (
    cardDetails: CardDetailsType[]
  ) => {
    if (deployedApplication) {
      // Handle deployed application flow
      toggleShowAddToAppModal();
    } else if (cardDetails.length > 1) {
      // Multiple cards selected

      const findLZ = cardDetails.find((c) => c.category === 'LandingZone');
      navigate(`${ROUTES.OFFERING_REQUEST}`, {
        replace: false,
        state: { cardDetails: findLZ, nonLZ: selectedOfferings },
      });
    } else if (cardDetails.length === 1 && cardDetails[0].id) {
      // Only one card selected

      navigate(`${ROUTES.OFFERING_REQUEST}`, {
        replace: false,
        state: { cardDetails: cardDetails[0], nonLZ: selectedOfferings },
      });
    }
  };

  const handleContinue = (cardDetails: CardDetailsType[]) => {
    if (useNewAppWizard) {
      handleContinueToNewAppWizard(cardDetails);
    } else {
      handleContinueToLegacyOfferingRequestForm(cardDetails);
    }
  };

  // Add to Application Requests
  const handleSubmitAddToApplication = async () => {
    setAddToAppLoading(true);
    const combinedOfferingsUpdates = selectedOfferings.concat(selectedUpdates);

    if (deployedApplication && combinedOfferingsUpdates) {
      const submitAddToApplicationInput = generateAddToApplication(
        deployedApplication,
        combinedOfferingsUpdates
      );
      const submitOptions = {
        variables: { input: submitAddToApplicationInput },
        onCompleted: () => {},
      };
      const submitAddToApplicationRes = await submitAddToApplication(
        submitOptions
      );

      if (
        submitAddToApplicationRes.data?.submitAddToApplicationRequest
          ?.status === OKStatus
      ) {
        setAddToAppLoading(false);
        navigate(
          `${ROUTES.APPLICATION_SUMMARY}/${submitAddToApplicationRes.data.submitAddToApplicationRequest.response.id}`
        );
      } else {
        setAddToAppLoading(false);
      }
    }
  };

  const unselectAutopopulate = () => {
    const autopopulated = evaluateAutopopulation();
    if (autopopulated?.autopopulate) {
      handleSetDeployedApplication(undefined);
      navigate(ROUTES.OFFERING_MULTISELECT, { replace: true, state: null });
      return true;
    }
    return false;
  };

  const handlePreviouslyDeployedOpen = () => {
    setPreviouslyDeployedWindowOpen(true);
  };

  const handlePreviouslyDeployedClose = () => {
    setPreviouslyDeployedWindowOpen(false);
  };

  const showMultiselect = () => {
    if (multiselectMode === '') {
      return false;
    }
    return true;
  };

  const handleSetMultiselectMode = (mode: '' | 'new' | 'existing') => {
    unselectAutopopulate();
    setSelectedOfferings([]);
    setSelectedUpdates([]);
    if (mode !== 'existing') {
      setSelectedPreviouslyDeployed('');
      setDeployedApplication(undefined);
    }

    setMultiselectMode(mode);
  };

  const handlePreviouslyDeployedSelect = (value: string) => {
    setSelectedPreviouslyDeployed(value);
    if (value === '') {
      handleSetMultiselectMode('');
    } else {
      handleSetMultiselectMode('existing');
    }
  };

  const addSelectedUpdate = (refArch: ReferenceArchitecture) => {
    selectedUpdates.push(refArch);
    setSelectedUpdates([...selectedUpdates]);
  };

  const removeSelectedUpdate = (refArch: ReferenceArchitecture) => {
    const newSelectedUpdates = selectedUpdates.filter(
      (r) => r.id !== refArch.id
    );
    setSelectedUpdates([...newSelectedUpdates]);
  };

  const addSelectedRefArch = (refArch: ReferenceArchitecture) => {
    selectedOfferings.push(refArch);
    setSelectedOfferings([
      ...selectedOfferings.sort((a, b) => sortRefArchByTier(a, b)),
    ]);
  };

  const removeSelectedRefArch = (refArch: ReferenceArchitecture) => {
    const newselectedOfferings = selectedOfferings
      .filter((r) => r.id !== refArch.id)
      .sort((a, b) => sortRefArchByTier(a, b));
    setSelectedOfferings([...newselectedOfferings]);
  };

  const handleAttemptAutoselectMissingDepsByDefaults = (
    refArch: ReferenceArchitecture
  ) => {
    if (multiselectMode === 'new') {
      const { shortOfferingName, autoselectOfferings } = refArch;
      const otherOfferingsSelected = selectedOfferings.filter(
        (so) => so.shortOfferingName !== shortOfferingName
      );
      if (autoselectOfferings && autoselectOfferings.length > 0) {
        const parseAutoselectedOfferings = autoselectOfferings.split(',');
        if (
          parseAutoselectedOfferings &&
          parseAutoselectedOfferings.length > 0 &&
          otherOfferingsSelected.length === 0
        ) {
          parseAutoselectedOfferings.forEach(
            (shortOfferingNameInAutoselect) => {
              const autoselectRefArch = referenceArchitectures.find(
                (b) => b.shortOfferingName === shortOfferingNameInAutoselect
              );
              if (autoselectRefArch) {
                addSelectedRefArch(autoselectRefArch);
              }
            }
          );
        }
      }
    }
  };

  const handleAttemptConflictResolution = (refArch: ReferenceArchitecture) => {
    if (multiselectMode === 'new') {
      const { conflicts } = refArch;

      const conflictsWithSelections = conflicts?.map((conflict) =>
        selectedOfferings.find(
          (selected) =>
            selected.shortOfferingName === conflict?.shortOfferingName
        )
      );
      if (conflictsWithSelections && conflictsWithSelections.length > 0) {
        const conflictToRemove = conflictsWithSelections[0];

        if (conflictToRemove) {
          removeSelectedRefArch(conflictToRemove);
        }
      }
    }
  };

  const handleRefArchSelection = (refArch: ReferenceArchitecture) => {
    if (
      !selectedOfferings.find(
        (selectedRefArch) =>
          selectedRefArch.shortOfferingName === refArch.shortOfferingName
      )
    ) {
      addSelectedRefArch(refArch);
      handleAttemptAutoselectMissingDepsByDefaults(refArch);
      handleAttemptConflictResolution(refArch);
    } else {
      removeSelectedRefArch(refArch);
    }
  };

  return {
    title,
    subTitle,
    handleRefArchSelection,
    handleContinue,
    referenceArchitectures,
    displayedOfferings,
    selectedOfferings,
    selectedUpdates,
    addSelectedUpdate,
    removeSelectedUpdate,
    previouslyDeployedWindowOpen,
    selectedPreviouslyDeployed,
    handlePreviouslyDeployedOpen,
    handlePreviouslyDeployedClose,
    handlePreviouslyDeployedSelect,
    deployedApplication,
    handleSetDeployedApplication,
    showAddToAppModal,
    toggleShowAddToAppModal,
    handleSubmitAddToApplication,
    addToAppLoading,
    multiselectMode,
    handleSetMultiselectMode,
    showMultiselect,
    unselectAutopopulate,
    multiSelectFooterCategorySlots,
  };
};

export default useOfferingMultiPage;
