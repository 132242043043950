import { useState } from 'react';
import {
  EphemeralRg,
  ReferenceArchitecture,
} from '@costco-service-catalog/bff-types';
import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import useStyles from './EphemeralResourceGroupLayout.styles';
import EphemeralResourceGroupCard from './EphemeralResourceGroupCard';

import ExistingEphemeralResourceGroupsList from './ExistingEphemeralResourceGroupsList/ExistingEphemeralResourceGroupsList';
import EphemeralResourceGroupModalCreateNew from './EphRgModalCreateNew/EphemeralResourceGroupModalCreateNew';

type EphemeralResourceGroupLayoutPropsType = {
  offeringsData: ReferenceArchitecture[];
  existingEphemeralResourceGroups: EphemeralRg[];
  runEphemeralRgsRefetch: () => Promise<void>;
};

const EphemeralResourceGroupLayout = (
  props: EphemeralResourceGroupLayoutPropsType
) => {
  const {
    offeringsData,
    existingEphemeralResourceGroups,
    runEphemeralRgsRefetch,
  } = props;
  const theme = useTheme();
  const wideDisplay = useMediaQuery(theme.breakpoints.up('lg'));

  const { classes } = useStyles();
  const [modalOption, setModalOption] = useState<'new' | 'existing'>();
  const [selectedERG, setSelectedERG] = useState<string>();
  const [selectedRefArch, setSelectedRefArch] =
    useState<ReferenceArchitecture>();

  const handleModalClose = () => {
    setModalOption(undefined);
    setSelectedERG(undefined);
  };

  const handleModalOpen = (
    variant: 'new' | 'existing',
    offeringShortName: string | undefined | null,
    refArch: ReferenceArchitecture
  ) => {
    setModalOption(variant);
    setSelectedERG(offeringShortName || undefined);
    setSelectedRefArch(refArch);
  };

  return (
    <Box className={classes.root}>
      <EphemeralResourceGroupModalCreateNew
        handleModalClose={handleModalClose}
        modalOption={modalOption}
        selectedERG={selectedERG}
        selectedRefArch={selectedRefArch}
        refArchArray={offeringsData}
        runEphemeralRgsRefetch={runEphemeralRgsRefetch}
      />
      <Box
        style={{
          display: 'flex',
          flexDirection: wideDisplay ? 'row' : 'column',
          flexWrap: 'wrap',
        }}
      >
        {offeringsData.length > 0 ? (
          <Box className={classes.newErgContainer}>
            <Box className={classes.headingContainer}>
              <Typography variant="h5">
                Deploy a new Ephemeral Developer Environment
              </Typography>
              <Divider sx={{ paddingTop: '0.25rem' }} />
            </Box>
            <Box className={classes.ephemeralResourceGroupsContainer}>
              {offeringsData
                .filter((ra) => Boolean(ra.ephemeralRGSupported))
                .map((ra) => (
                  <EphemeralResourceGroupCard
                    key={ra.id}
                    refArch={ra}
                    variant="new"
                    handleModalOpen={handleModalOpen}
                  />
                ))}
            </Box>
          </Box>
        ) : undefined}
        {existingEphemeralResourceGroups.length > 0 && offeringsData.length ? (
          <Box className={classes.existingErgContainer}>
            <Box className={classes.headingContainer}>
              <Typography variant="h5">
                Existing Ephemeral Developer Environments
              </Typography>
              <Divider sx={{ paddingTop: '0.25rem' }} />
            </Box>
            <Box className={classes.existingErgListContainer}>
              <ExistingEphemeralResourceGroupsList
                existingEphemeralResourceGroups={
                  existingEphemeralResourceGroups
                }
                offeringsData={offeringsData}
                runEphemeralRgsRefetch={runEphemeralRgsRefetch}
              />
            </Box>
          </Box>
        ) : undefined}
      </Box>
    </Box>
  );
};

export default EphemeralResourceGroupLayout;
