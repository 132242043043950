import { ApolloError, useMutation } from '@apollo/client';
import { useApolloErrors } from '..';
import { BREAK_TF_STATE_BLOB_LEASE } from '../../apollo/queries';

const useBreakTFStateBlobLease = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [breakTFStateBlobLease, { loading: breakTFStateBlobLeaseLoading }] =
    useMutation(BREAK_TF_STATE_BLOB_LEASE, {
      onError: (error: ApolloError) => handleErrorResponse(error),
    });

  return { breakTFStateBlobLease, loading: breakTFStateBlobLeaseLoading };
};

export default useBreakTFStateBlobLease;
