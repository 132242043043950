import { gql } from '@apollo/client';

const SUBMIT_ADD_TO_APPLICATION_REQUEST = gql`
  mutation submitAddToApplicationRequest($input: AddToApplicationRequestInput) {
    submitAddToApplicationRequest(input: $input) {
      status
      code
      response {
        id
      }
    }
  }
`;

export default SUBMIT_ADD_TO_APPLICATION_REQUEST;
