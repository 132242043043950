interface AppSettingsSchema {
  loaded: boolean;
  bffBaseUrl: string;
  pingBaseUrl: string;
  pingClientId: string;
  aiInstrumentationKey: string;
  useUIMocks: boolean;
  bypassAuth: boolean;
  allowHistoryDrawer: boolean;
  useEphemeralRGPage: boolean;
  useNewAppWizard: boolean;
  addEnvUtilityEnabled: boolean;
  deleteEnvUtilityEnabled: boolean;
  unlockStateFileUtilityEnabled: boolean;
  ecpOnboardingUtilitiesEnabled: boolean;
  useApplicationPolicySelection: boolean;
  productionEnvironmentSelectionEnabled: boolean;
  wizEnabled: boolean;
  baseWizProjectUrl: string;
  availableRegions: string;
  azureRegionGroupAPAC: string;
  azureRegionGroupCA: string;
  azureRegionGroupEU: string;
  azureRegionGroupUS: string;
  cidrBlockSizes: string;
  autoRefreshInterval: string;
  maxNpEnvironments: string;
  maxPdEnvironments: string;
  CnaMinVersion: string;
  maximumErgExpiration: string;
  addVNetUtilityDocUrl: string;
  addEnvUtilityDocUrl: string;
  wizHelpDocUrl: string;
  userFeedbackUrl: string;
  existingProjectPrereqDocUrl: string;
}

const initialAppSettingsState: AppSettingsSchema = JSON.parse(
  JSON.stringify({
    loaded: false,
    bffBaseUrl: '',
    pingBaseUrl: '',
    pingClientId: '',
    aiInstrumentationKey: '',
    useUIMocks: false,
    bypassAuth: false,
    allowHistoryDrawer: false,
    useEphemeralRGPage: false,
    useNewAppWizard: false,
    addEnvUtilityEnabled: false,
    deleteEnvUtilityEnabled: false,
    unlockStateFileUtilityEnabled: false,
    ecpOnboardingUtilitiesEnabled: false,
    useApplicationPolicySelection: false,
    productionEnvironmentSelectionEnabled: false,
    wizEnabled: false,
    baseWizProjectUrl: '',
    availableRegions: '',
    azureRegionGroupAPAC: '',
    azureRegionGroupCA: '',
    azureRegionGroupEU: '',
    azureRegionGroupUS: '',
    cidrBlockSizes: '',
    autoRefreshInterval: '',
    maxNpEnvironments: '',
    maxPdEnvironments: '',
    CnaMinVersion: '',
    maximumErgExpiration: '',
    addVNetUtilityDocUrl: '',
    addEnvUtilityDocUrl: '',
    wizHelpDocUrl: '',
    userFeedbackUrl: '',
  })
);

export type { AppSettingsSchema };
export default initialAppSettingsState;
