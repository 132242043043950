import { Box, Typography } from '@mui/material';

interface LabelledDataProps {
  label: string | number | null;
  value: string | number | React.ReactNode | null;
}

const LabelledData = (props: LabelledDataProps) => {
  const { label, value } = props;

  return (
    <Box sx={{ p: '0 1rem 1rem 0' }}>
      <Typography variant="body2">
        <strong>{label}</strong>
      </Typography>
      <Typography variant="body2">{value}</Typography>
    </Box>
  );
};

export default LabelledData;
