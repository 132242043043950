import useStyles from './SectionHeader.styles';

interface SectionHeaderProps {
  icon?: JSX.Element;
  subIcon?: JSX.Element;
  title?: string | number;
  subtitle?: string | number;
}

const SectionHeader = (props: SectionHeaderProps) => {
  const { icon, subIcon, title, subtitle } = props;

  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <div className={classes.icon}>{icon}</div>
        <div className={classes.title}>{title}</div>
      </div>
      <div className={classes.subtitleContainer}>
        {subIcon ? <div className={classes.subIcon}>{subIcon}</div> : undefined}
        <div className={classes.subtitle}>{subtitle}</div>
      </div>
    </div>
  );
};

SectionHeader.defaultProps = {
  icon: null,
  subIcon: null,
  title: '',
  subtitle: '',
};

export default SectionHeader;
