import { ApolloError, useMutation } from '@apollo/client';
import { useApolloErrors } from '..';
import { CANCEL_STARTER_KIT_REQUEST_MUTATION } from '../../apollo/queries';

const useCancelStarterKitRequest = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [cancelStarterKitRequest, { loading: cancelStarterKitRequestLoading }] =
    useMutation(CANCEL_STARTER_KIT_REQUEST_MUTATION, {
      onError: (error: ApolloError) => handleErrorResponse(error),
    });

  return {
    cancelStarterKitRequest,
    loading: cancelStarterKitRequestLoading,
  };
};

export default useCancelStarterKitRequest;
