import { ApolloError, useMutation } from '@apollo/client';
import { useApolloErrors } from '..';
import { DELETE_APPLICATION_DRAFT_MUTATION } from '../../apollo/queries';

const useDeleteApplicationDraft = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [deleteApplicationDraft, { loading: deleteApplicationLoading }] =
    useMutation(DELETE_APPLICATION_DRAFT_MUTATION, {
      onError: (error: ApolloError) => handleErrorResponse(error),
    });

  return {
    deleteApplicationDraft,
    loading: deleteApplicationLoading,
  };
};

export default useDeleteApplicationDraft;
