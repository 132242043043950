import {
  AadUser,
  ApplicationStarterKitRequest,
} from '@costco-service-catalog/bff-types';
import {
  Add,
  Cancel,
  ChevronRight,
  Delete,
  Engineering,
  Help,
  Info,
  Receipt,
  Refresh,
  Settings,
} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate } from 'react-router-dom';
import {
  AdminUsersPaper,
  BuildStatus,
  ConfirmDialog,
  LabelledData,
  PageHeader,
  StatusChip,
  WizStatusChip,
} from '../../components';
import PolicyModal from '../../components/PolicyComponents/PolicyModal';
import RequesterEditModal from '../../components/RequesterModalEdit/RequesterEditModal';
import SupportGroupEditModal from '../../components/SupportGroupModalEdit/SupportGroupEditModal';
import { useGlobalState } from '../../globalState/useGlobalState';
import useStyles from './ApplicationSummaryPage.styles';
import useApplicationSummaryPage from './useApplicationSummaryPage';

interface ApplicationSummaryPageProps {}

const formatDate = (dateTimeString: any) =>
  new Date(dateTimeString).toLocaleString();

const sortRequestsByDate = (
  a: Maybe<ApplicationStarterKitRequest>,
  b: Maybe<ApplicationStarterKitRequest>
) =>
  new Date(
    (b as ApplicationStarterKitRequest).requestedDateTime as string
  ).getTime() -
  new Date(
    (a as ApplicationStarterKitRequest).requestedDateTime as string
  ).getTime();

const toTitleCase = (text?: Maybe<string>) => {
  if (!text) return text;
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const ApplicationSummaryPage = (props: ApplicationSummaryPageProps) => {
  const { classes } = useStyles();
  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);
  const {
    error,
    loading,
    data,
    title,
    lastUpdateTime,
    subtitle,
    cancelRequestDialogOpen,
    deleteApplicationDialogOpen,
    setupWizIntegrationLoading,
    handleApplicationSummaryClose,
    handleApplicationSummaryRefresh,
    handleRetryStarterKitRequestClick,
    handleCancelStarterKitRequestClick,
    handleCancelRequestDialogOk,
    handleCancelRequestDialogCancel,
    handleDeleteApplicationClick,
    handleDeleteApplicationDialogOk,
    handleDeleteApplicationDialogCancel,
    handleRetryDeleteApplicationClick,
    handleSetupWizIntegrationClick,
    handleDeleteUsersFromGroup,
    handleDeleteWizMembers,
    handleDeleteAdditionalCollaborators,
    handleAdditionalCollaboratorsChange,
    handleAadGroupMembersChange,
    handleAadReaderGroupMembersChange,
    handleWizMembersChange,
    handleAdoProjectContributorsChange,
    handleAdoProjectAdministratorsChange,
    handleADOProjectBoardAdministratorsChange,
    formValues,
    handleAddADOProjectAdministrators,
    handleAddAADGroupMember,
    handleAddADOProjectContributors,
    handleAddADOProjectBoardAdministrators,
    handleAddAADReaderGroupMember,
    handleAddWizMembers,
    handleAddAdditionalCollaborators,
    handleAddStarterKitToApp,
    handleCheckAddStarterKitToApp,
    handleCheckCanCancelOffering,
    handleAddVNetRegion,
    handleAddEnvironment,
    hasHelpDocLink,
    getUtilityHelpUrl,
    handleUnlockStateFile,
    handleDeleteEnvironment,
    openPolicyModal,
    policyModalData,
    handleOpenPolicyModal,
    handleClosePolicyModal,
    handleOpenEditSupportGrpEmailModal,
    handleCloseEditSupportGrpEmailModal,
    openEditSupportGrpEmailModal,
    handleOpenEditRequesterModal,
    handleCloseEditRequesterModal,
    openEditRequesterModal,
  } = useApplicationSummaryPage();

  const auth = useAuth();
  const { appSettings } = useGlobalState();

  const deleteEnvUtilityEnabled = appSettings.deleteEnvUtilityEnabled.get();
  const unlockStateFileEnabled =
    appSettings.unlockStateFileUtilityEnabled.get();
  const useApplicationPolicySelection =
    appSettings.useApplicationPolicySelection.get();
  const wizEnabled = appSettings.wizEnabled.get();
  const baseWizProjectUrl = appSettings.baseWizProjectUrl.get();
  const wizHelpDocUrl = appSettings.wizHelpDocUrl.get();

  const {
    aadGroupMembers,
    aadReaderGroupMembers,
    wizMembers,
    adoProjectContributors,
    adoProjectAdministrators,
    adoProjectBoardAdministrators,
    additionalCollaborators,
  } = formValues;

  const handleDialogOk = () => {
    handleDeleteApplicationDialogOk(setDeleteLoading);
  };

  if (loading || deleteLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Navigate to="/404NotFound" />;
  }

  const landingZone = data?.starterKitRequests
    ?.map(
      (starterKit) =>
        starterKit?.offerings?.find(
          (offering) => offering?.category === 'LandingZone'
        )
    )
    .filter((offering) => offering?.category === 'LandingZone');

  let landingZoneStatus = '';
  let showSetupWizButton = false;

  if (landingZone) {
    landingZoneStatus = landingZone[0]?.status as string;
    if (
      wizEnabled &&
      landingZoneStatus === 'Completed' &&
      !data?.isEphemeral &&
      !data?.wizProject?.setupStatus
    ) {
      showSetupWizButton = true;
    }
  }

  const showWizMemberManagement = wizEnabled && data?.wizProject?.adGroup;

  const hasRunningPipeline = data && data.hasRunningPipelines;

  return (
    <div className={classes.root}>
      <ConfirmDialog
        title="Cancel Request?"
        message="Are you sure you want to cancel this request?"
        open={cancelRequestDialogOpen}
        handleOk={handleCancelRequestDialogOk}
        handleCancel={handleCancelRequestDialogCancel}
      />
      <ConfirmDialog
        title="Delete Application?"
        message="Are you sure you want to delete this application?"
        open={deleteApplicationDialogOpen}
        handleOk={handleDialogOk}
        handleCancel={handleDeleteApplicationDialogCancel}
      />
      <PolicyModal
        application={data}
        policyModalData={policyModalData}
        openPolicyModal={openPolicyModal}
        handleClosePolicyModal={handleClosePolicyModal}
      />
      <SupportGroupEditModal
        openEditSupportGrpEmailModal={openEditSupportGrpEmailModal}
        handleCloseEditSupportGrpEmailModal={
          handleCloseEditSupportGrpEmailModal
        }
        applicationId={data?.id as string}
        handleApplicationSummaryRefresh={handleApplicationSummaryRefresh}
      />
      <RequesterEditModal
        openEditRequesterModal={openEditRequesterModal}
        handleCloseEditRequesterModal={handleCloseEditRequesterModal}
        applicationId={data?.id as string}
        handleApplicationSummaryRefresh={handleApplicationSummaryRefresh}
      />
      <PageHeader
        icon={<Receipt />}
        title={title}
        subtitle={subtitle}
        sharedProject={
          data &&
          data.requester?.email?.toLowerCase() !==
            auth.user?.profile.email?.toLowerCase()
        }
        deleteStatus={data?.deleteStatus || ''}
      >
        {data &&
          (!data.starterKitRequests ||
            data.starterKitRequests.length === 0 ||
            (data.isEphemeral &&
              !data.deleteStatus &&
              data.starterKitRequests.every(
                (x) => x?.status === 'Completed'
              ))) && (
            <Button
              variant="contained"
              color="error"
              startIcon={<Delete />}
              onClick={() => handleDeleteApplicationClick()}
            >
              Delete Application
            </Button>
          )}
        {data &&
          data.isEphemeral &&
          data.deleteStatus === 'DeletionFailed' &&
          data.starterKitRequests &&
          data.starterKitRequests.every((x) => x?.status === 'Completed') && (
            <Button
              variant="contained"
              color="error"
              startIcon={<Delete />}
              onClick={() => handleRetryDeleteApplicationClick()}
            >
              Failed to Delete - Retry
            </Button>
          )}
        {showSetupWizButton && data && (
          <ButtonGroup>
            <Button
              variant="contained"
              startIcon={<Settings />}
              onClick={() => handleSetupWizIntegrationClick(data.id)}
              disabled={setupWizIntegrationLoading}
              endIcon={
                setupWizIntegrationLoading ? (
                  <div className={classes.ButtonSpinnerContainer}>
                    <CircularProgress
                      size="1rem"
                      className={classes.SpinnerStyling}
                    />
                  </div>
                ) : undefined
              }
            >
              Setup Wiz.io Integration
            </Button>
            <IconButton href={wizHelpDocUrl} rel="noreferrer" target="_blank">
              <Help />
            </IconButton>
          </ButtonGroup>
        )}
      </PageHeader>
      <Box sx={{ m: '1rem' }}>
        <Grid container rowSpacing={2}>
          <Grid item>
            <LabelledData label="Id" value={data?.id} />
          </Grid>
          <Grid item>
            <LabelledData label="Name" value={data?.applicationName} />
          </Grid>
          <Grid item>
            <LabelledData
              label="Date Submitted"
              value={formatDate(data?.createdDateTime)}
            />
          </Grid>
          {data?.isEphemeral && (
            <Grid item>
              <LabelledData
                label="Expiration Date"
                value={formatDate(data?.expirationDateTime)}
              />
            </Grid>
          )}
          <Grid item>
            <LabelledData
              label="Date Updated"
              value={formatDate(data?.updatedDateTime)}
            />
          </Grid>
          <Grid item>
            <LabelledData
              label="Description"
              value={data?.applicationDescription}
            />
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <LabelledData label="Owner" value={data?.requester?.email} />
              <IconButton
                data-testid="editRequester"
                onClick={handleOpenEditRequesterModal}
                size="small"
                sx={{ marginLeft: -2, marginRight: 2 }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ p: 0 }} />
          <Grid item>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <LabelledData
                label="Support Group Email"
                value={data?.supportGroupEmail}
              />
              <IconButton
                data-testid="editSupportGroupEmail"
                onClick={handleOpenEditSupportGrpEmailModal}
                size="small"
                sx={{ marginLeft: -2, marginRight: 2 }}
              >
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Grid>
          <Grid item>
            <LabelledData
              label="Organization Name"
              value={data?.organizationName}
            />
          </Grid>
          <Grid item>
            <LabelledData
              label="Business Unit Number"
              value={data?.businessUnitNumber}
            />
          </Grid>
          <Grid item>
            <LabelledData
              label="Department Financial Dashboard"
              value={
                data?.financialDashboardUrl && (
                  <a
                    target="_blank"
                    href={data.financialDashboardUrl}
                    rel="noreferrer"
                  >
                    Financial Dashboard
                  </a>
                )
              }
            />
          </Grid>
          <Grid item>
            <LabelledData
              label="ADO Project Name"
              value={data?.adoProject?.projectName}
            />
          </Grid>
          <Grid item>
            <LabelledData
              label="ADO Project URL"
              value={
                data?.adoProject?.projectUrl && (
                  <a
                    target="_blank"
                    href={data.adoProject.projectUrl}
                    rel="noreferrer"
                  >
                    {data.adoProject.projectUrl}
                  </a>
                )
              }
            />
          </Grid>
          {data && !data.deleteStatus && (
            <>
              <Grid item xs={12} sx={{ p: '15px 8px' }}>
                <Typography variant="h5">Users Management</Typography>
              </Grid>
              <Grid container>
                {data && (
                  <>
                    <Grid item className={classes.adminPaper}>
                      <AdminUsersPaper
                        title="AAD Contributor Group Members"
                        colTitle="User Name"
                        buttonText="View Members List"
                        userLists={data?.aadGroupMembers as AadUser[]}
                        handleChange={handleAadGroupMembersChange}
                        handleDeleteUser={handleDeleteUsersFromGroup}
                        handleAddUsers={handleAddAADGroupMember}
                        groupType="AADContributor"
                        value={aadGroupMembers || []}
                        landingZoneStatus={landingZoneStatus}
                        applicationId={data?.id as string}
                        preUserManagement={data.preUserManagement as boolean}
                        helperText="An AAD group granting access to Azure Resources will be created. Enter '-z' accounts to include them as group members."
                        fullHelpText="An AAD group granting access to Azure Resources in the application's subscriptions will be created with the following '-z' accounts included as group members."
                      />
                    </Grid>
                    <Grid item className={classes.adminPaper}>
                      <AdminUsersPaper
                        title="AAD Reader Group Members"
                        colTitle="User Name"
                        buttonText="View Members List"
                        userLists={data?.aadReaderGroupMembers as AadUser[]}
                        handleChange={handleAadReaderGroupMembersChange}
                        handleDeleteUser={handleDeleteUsersFromGroup}
                        handleAddUsers={handleAddAADReaderGroupMember}
                        groupType="AADReader"
                        value={aadReaderGroupMembers || []}
                        landingZoneStatus={landingZoneStatus}
                        applicationId={data?.id as string}
                        preUserManagement={data.preUserManagement as boolean}
                        helperText="An AAD group granting read-only access to Azure Resources will be created. Enter '-z' accounts to include them as group members."
                        fullHelpText="An AAD group granting read-only access to Azure Resources in the application's subscriptions will be created with the following '-z' accounts included as group members."
                      />
                    </Grid>
                    {showWizMemberManagement && (
                      <Grid item className={classes.adminPaper}>
                        <AdminUsersPaper
                          title="Wiz.io Access Members"
                          colTitle="User Name"
                          buttonText="View Members List"
                          userLists={data?.wizMembers as AadUser[]}
                          handleChange={handleWizMembersChange}
                          handleDeleteUser={handleDeleteWizMembers}
                          handleAddUsers={handleAddWizMembers}
                          groupType="WizMember"
                          value={wizMembers || []}
                          landingZoneStatus={landingZoneStatus}
                          applicationId={data?.id as string}
                          preUserManagement={data.preUserManagement as boolean}
                          helperText="An AD group granting access to Wiz.io will be created. Enter accounts to include them as group members."
                          fullHelpText="An AD group granting access to Wiz.io for subscriptions created for this Application will be created with the selected accounts included as group members."
                        />
                      </Grid>
                    )}
                  </>
                )}
                {data &&
                  !data.preUserManagement &&
                  !data.adoProject?.isExistingProject && (
                    <>
                      <Grid item className={classes.adminPaper}>
                        <AdminUsersPaper
                          title="ADO Project Administrators"
                          colTitle="User Name"
                          buttonText="View Members List"
                          userLists={
                            data?.adoProject?.adoProjectAdministrators
                              ?.members as AadUser[]
                          }
                          handleChange={handleAdoProjectAdministratorsChange}
                          handleDeleteUser={handleDeleteUsersFromGroup}
                          handleAddUsers={handleAddADOProjectAdministrators}
                          groupType="ADOProjectAdmin"
                          value={adoProjectAdministrators || []}
                          landingZoneStatus={landingZoneStatus}
                          applicationId={data?.id as string}
                          preUserManagement={data.preUserManagement as boolean}
                          helperText="Select from the list or enter a user name and hit the Enter key to add user as ADO Administrator. It can take up to 1 hour for Azure AD group membership permissions to propagate in Azure DevOps. 
                          If a user's having issues that don't resolve immediately, wait for 24 hours to see if they resolve."
                          fullHelpText="ADO Project Administrators are responsible for actions that required elevated permissions in ADO such as adding additional members to the project or changing pipeline permissions."
                        />
                      </Grid>
                      <Grid item className={classes.adminPaper}>
                        <AdminUsersPaper
                          title="ADO Project Contributors"
                          colTitle="User Name"
                          buttonText="View Members List"
                          userLists={
                            data?.adoProject?.adoProjectContributors
                              ?.members as AadUser[]
                          }
                          handleChange={handleAdoProjectContributorsChange}
                          handleDeleteUser={handleDeleteUsersFromGroup}
                          handleAddUsers={handleAddADOProjectContributors}
                          groupType="ADOProjectContributor"
                          value={adoProjectContributors || []}
                          landingZoneStatus={landingZoneStatus}
                          applicationId={data?.id as string}
                          preUserManagement={data.preUserManagement as boolean}
                          helperText="Select from the list or enter a user name and hit the Enter key to add user as ADO contributor. It can take up to 1 hour for Azure AD group membership permissions to propagate in Azure DevOps. 
                          If a user's having issues that don't resolve immediately, wait for 24 hours to see if they resolve."
                          fullHelpText="Project Contributors create / manage work items, perform code changes, open pull requests, and run pipelines, among other tasks. Most users in your project will be Project Contributors."
                        />
                      </Grid>
                      <Grid item className={classes.adminPaper}>
                        <AdminUsersPaper
                          title="ADO Board Administrators"
                          colTitle="User Name"
                          buttonText="View Members List"
                          userLists={
                            data?.adoProject?.adoProjectBoardAdministrators
                              ?.members as AadUser[]
                          }
                          handleChange={
                            handleADOProjectBoardAdministratorsChange
                          }
                          handleDeleteUser={handleDeleteUsersFromGroup}
                          handleAddUsers={
                            handleAddADOProjectBoardAdministrators
                          }
                          groupType="ADOBoardAdministrator"
                          value={adoProjectBoardAdministrators || []}
                          landingZoneStatus={landingZoneStatus}
                          applicationId={data?.id as string}
                          preUserManagement={data.preUserManagement as boolean}
                          helperText="Select from the list or enter a user name and hit the Enter key to add user as ADO Board Administrators. It can take up to 1 hour for Azure AD group membership permissions to propagate in Azure DevOps. 
                          If a user's having issues that don't resolve immediately, wait for 24 hours to see if they resolve."
                          fullHelpText="ADO Board Administrators can create/manage workitems on the ADO boards along with added action for creating queries and dashboards. These users will have limited privileges on ADO pipelines."
                        />
                      </Grid>
                    </>
                  )}
                <Grid item className={classes.adminPaper}>
                  <AdminUsersPaper
                    title="Additional Collaborators"
                    colTitle="User Name"
                    buttonText="View Members List"
                    userLists={
                      data?.additionalCollaborators?.map((email) => {
                        const aadUser: AadUser = {
                          email,
                          userPrincipalName: email as string,
                        };
                        return aadUser;
                      }) as AadUser[]
                    }
                    handleChange={handleAdditionalCollaboratorsChange}
                    handleDeleteUser={handleDeleteAdditionalCollaborators}
                    handleAddUsers={handleAddAdditionalCollaborators}
                    groupType="AdditionalCollaborator"
                    value={additionalCollaborators || []}
                    landingZoneStatus={landingZoneStatus}
                    applicationId={data?.id as string}
                    preUserManagement={false}
                    helperText="Select from the list or enter a user name and hit the Enter key to add user as additional collaborator."
                    fullHelpText="Additional Collaborator will share the same privileges that a requester of an application have to view and manage the application."
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Box>
            {useApplicationPolicySelection && !data?.isEphemeral && (
              <Box>
                <Typography variant="h5">Application Policies</Typography>
                <br />
                <Box>
                  <Typography>Non-Production Policies</Typography>
                  <Box width="12rem">
                    <Button
                      data-testid="NonProdSubButton"
                      onClick={() =>
                        handleOpenPolicyModal({
                          enabledPolicies:
                            data?.ecoSystemNP?.enabledPolicies?.map(
                              (v) => v || ''
                            ) || [],
                          policiesVersion:
                            data?.ecoSystemNP?.policiesVersion || '',
                          environmentName: 'environmentName',
                          environmentType: 'np',
                        })
                      }
                    >
                      {data?.ecoSystemNP?.enabledPolicies?.length || 0}{' '}
                      {`Applied Polic${
                        data?.ecoSystemNP?.enabledPolicies?.length === 1
                          ? 'y'
                          : 'ies'
                      }`}
                    </Button>
                    <Box>
                      {data?.ecoSystemNP?.policiesVersion && (
                        <Typography fontSize="0.8rem">
                          (v{data?.ecoSystemNP?.policiesVersion})
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
                <br />
                <Box>
                  <Typography>Production Policies</Typography>
                  <Box width="12rem">
                    <Button
                      data-testid="ProdSubButton"
                      onClick={() =>
                        handleOpenPolicyModal({
                          enabledPolicies:
                            data?.ecoSystemPD?.enabledPolicies?.map(
                              (v) => v || ''
                            ) || [],
                          policiesVersion:
                            data?.ecoSystemPD?.policiesVersion || '',
                          environmentName: 'environmentName',
                          environmentType: 'pd',
                        })
                      }
                    >
                      {data?.ecoSystemPD?.enabledPolicies?.length || 0}{' '}
                      {`Applied Polic${
                        data?.ecoSystemPD?.enabledPolicies?.length === 1
                          ? 'y'
                          : 'ies'
                      }`}
                    </Button>
                    <Box>
                      {data?.ecoSystemPD?.policiesVersion && (
                        <Typography fontSize="0.8rem">
                          (v{data?.ecoSystemPD?.policiesVersion})
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
                <br />
                <br />
              </Box>
            )}
          </Box>
          <Grid item xs={12} sx={{ p: '15px 8px' }}>
            <Typography variant="h5">Environments</Typography>
          </Grid>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {deleteEnvUtilityEnabled && data && !data.isEphemeral && (
                  <TableCell>Action</TableCell>
                )}
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Region(s)</TableCell>
                <TableCell>CIDR Block Size</TableCell>
                <TableCell>Subscription Name</TableCell>
                <TableCell>Service Principal Name</TableCell>
                <TableCell>AAD Group Names</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.environments
                ?.filter(
                  (e) => e?.notReady === false && e.deleteStatus !== 'Done'
                )
                ?.map((r) => (
                  <TableRow key={`${r?.name}-${r?.type}-${r?.cidrBlockSize}`}>
                    {deleteEnvUtilityEnabled && data && !data.isEphemeral && (
                      <TableCell>
                        <div>
                          <IconButton
                            aria-label="delete"
                            onClick={(event) => {
                              handleDeleteEnvironment(r || {});
                            }}
                            size="large"
                            color="error"
                            disabled={
                              !!(
                                r?.deleteStatus ||
                                landingZoneStatus !== 'Completed' ||
                                hasRunningPipeline
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    )}
                    <TableCell data-testid="envName">{r?.name}</TableCell>
                    <TableCell data-testid="envType">
                      {r?.type === 'pd' ? 'Production' : 'Non Production'}
                    </TableCell>
                    <TableCell data-testid="envRegion">
                      {r?.regions?.join(', ')}
                    </TableCell>
                    <TableCell data-testid="envCidr">{`/${r?.cidrBlockSize}`}</TableCell>
                    <TableCell data-testid="envSubscription">
                      <span
                        className={
                          wizEnabled
                            ? classes.subscriptionNameWithWiz
                            : undefined
                        }
                      >
                        {r?.subscriptionName}
                      </span>
                      {wizEnabled && (
                        <WizStatusChip
                          status={data.wizProject?.setupStatus as string}
                          baseWizProjectUrl={baseWizProjectUrl}
                          wizSubscriptionSlug={r?.wizSubscriptionSlug as string}
                        />
                      )}
                    </TableCell>
                    <TableCell data-testid="envSpn">
                      {r?.servicePrincipalName}
                    </TableCell>
                    <TableCell data-testid="envAadGroupNames">
                      {r && `${r.aadGroup}, ${r.aadReaderGroup}`}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          <Grid item xs={12} sx={{ p: '15px 8px' }}>
            <Box className={classes.starterKitRequestsHeaderContainer}>
              <Box className={classes.starterKitRequestsHeaderText}>
                <Typography variant="h5">Starter Kit Requests</Typography>
              </Box>
              {data && !data.deleteStatus && (
                <Box className={classes.starterKitRequestsHeaderButton}>
                  <Tooltip
                    title={
                      !handleCheckAddStarterKitToApp()
                        ? 'Application is still deploying or may have errors.'
                        : ''
                    }
                  >
                    <Box>
                      <Button
                        disabled={!handleCheckAddStarterKitToApp()}
                        variant="contained"
                        onClick={() => handleAddStarterKitToApp()}
                        endIcon={<Add />}
                      >
                        Start New Request
                      </Button>
                    </Box>
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Grid>

          {data?.starterKitRequests?.sort(sortRequestsByDate).map((r) => (
            <Card key={r?.id} sx={{ m: '0 0 1rem 1rem' }}>
              <CardContent>
                <Box sx={{ display: 'flex' }}>
                  <LabelledData
                    label="Carts+ Request Id"
                    value={r?.cartsApprovalRequestId}
                  />
                  <LabelledData
                    label="Requested Date"
                    value={formatDate(r?.requestedDateTime)}
                  />
                  <StatusChip label={r?.status as string} />
                </Box>
                <Divider />
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Category</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Version</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {r?.offerings?.map((o) => (
                      <TableRow
                        key={`${o?.offeringName}-${o?.adoRunUrl}`}
                        sx={{ border: 0 }}
                      >
                        <TableCell
                          sx={{ borderBottom: 0 }}
                          data-testid="skRequestCategory"
                        >
                          {o?.category}
                          {o?.category === 'Utility' && o.additionalParams && (
                            <Tooltip
                              title={
                                <>
                                  {o.additionalParams.map((p) => (
                                    <div>
                                      {toTitleCase(p?.key)}: {p?.value}
                                    </div>
                                  ))}
                                </>
                              }
                            >
                              <IconButton>
                                <Info fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ borderBottom: 0 }}
                          data-testid="skRequestName"
                        >
                          {o?.offeringName}
                          {o?.offeringShortName &&
                            hasHelpDocLink(o.offeringShortName) && (
                              <IconButton
                                href={getUtilityHelpUrl(o.offeringShortName)}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <Help color="primary" fontSize="small" />
                              </IconButton>
                            )}
                        </TableCell>
                        <TableCell
                          sx={{ borderBottom: 0 }}
                          data-testid="skRequestVersion"
                        >
                          {o?.offeringVersion}
                        </TableCell>
                        <TableCell
                          sx={{ borderBottom: 0 }}
                          data-testid="skRequestStatus"
                        >
                          <BuildStatus offering={o} />
                        </TableCell>
                        <TableCell sx={{ borderBottom: 0 }}>
                          {o && handleCheckCanCancelOffering(o, r) && (
                            <Tooltip title="Cancel">
                              <IconButton
                                color="error"
                                aria-label="Cancel"
                                onClick={() =>
                                  handleCancelStarterKitRequestClick(
                                    data.id,
                                    r.id,
                                    o.offeringName as string
                                  )
                                }
                              >
                                <Cancel />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {r?.status === 'Failed' && (
                  <Button
                    className={classes.retryButton}
                    variant="outlined"
                    disableElevation
                    size="small"
                    color="primary"
                    onClick={() =>
                      handleRetryStarterKitRequestClick(data.id, r.id)
                    }
                  >
                    Retry
                  </Button>
                )}
                {(r?.status === 'Submitted' ||
                  r?.status === 'AwaitingApproval' ||
                  r?.status === 'Denied') && (
                  <Button
                    className={classes.retryButton}
                    variant="outlined"
                    disableElevation
                    size="small"
                    color="error"
                    onClick={() =>
                      handleCancelStarterKitRequestClick(data.id, r.id)
                    }
                  >
                    Cancel
                  </Button>
                )}
              </CardContent>
            </Card>
          ))}
        </Grid>
        <Box sx={{ p: '15px 8px' }}>
          <Box sx={{ paddingBottom: '0.5rem' }}>
            <Typography variant="h5">Application Utilities</Typography>
          </Box>
          <Box sx={{ paddingTop: '1rem' }}>
            <List>
              {data &&
                !data?.isEphemeral &&
                landingZoneStatus === 'Completed' &&
                !hasRunningPipeline && (
                  <>
                    <ListItem>
                      <ListItemIcon>
                        <Engineering />
                      </ListItemIcon>
                      <ListItemText>
                        <Button
                          variant="contained"
                          onClick={handleAddVNetRegion}
                          endIcon={<ChevronRight />}
                        >
                          Add VNet / Region
                        </Button>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <Engineering />
                      </ListItemIcon>
                      <ListItemText>
                        <Button
                          variant="contained"
                          onClick={handleAddEnvironment}
                          endIcon={<ChevronRight />}
                        >
                          Add Environment
                        </Button>
                      </ListItemText>
                    </ListItem>
                  </>
                )}
              {unlockStateFileEnabled && (
                <ListItem>
                  <ListItemIcon>
                    <Engineering />
                  </ListItemIcon>
                  <ListItemText>
                    <Button
                      variant="contained"
                      onClick={handleUnlockStateFile}
                      endIcon={<ChevronRight />}
                    >
                      Unlock State File
                    </Button>
                  </ListItemText>
                </ListItem>
              )}
            </List>
          </Box>
        </Box>
        <Box paddingBottom="2rem" />{' '}
      </Box>
      <div className={classes.buttonWrap}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleApplicationSummaryRefresh}
          className={classes.refreshButton}
          startIcon={<Refresh />}
        >
          Refresh
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleApplicationSummaryClose}
        >
          Close
        </Button>
        <Box sx={{ paddingX: '1vw' }}>
          <Typography variant="body2">
            Last refreshed: {loading ? 'Loading...' : lastUpdateTime}
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export default ApplicationSummaryPage;
