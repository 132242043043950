import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { CommonFormTextField } from '../../../FormComponents/CommonFields';
import useECPQuayOnboardingModal from './useECPQuayOnboardingtModal';

const ECPQuayOnboardingModal = (props: { handleModalClose: () => void }) => {
  const { handleModalClose } = props;
  const {
    formik,
    enterOrgNameFieldError,
    enterADGroupDNFieldError,
    enterTeamEmailFieldError,
    enterQuayQuotaFieldError,
    handleSubmitClick,
  } = useECPQuayOnboardingModal(handleModalClose);
  const { organizationName, adGroupDN, teamEmail, quayQuota } = formik.values;

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>ECP Onboarding - Quay Registry</DialogTitle>
        <DialogContent>
          <Box sx={{ flex: 1 }}>
            <CommonFormTextField
              componentID="organizationName"
              label="Organization Name"
              value={organizationName || ''}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              fieldError={enterOrgNameFieldError}
              helperText={
                enterOrgNameFieldError && formik.errors.organizationName
                  ? formik.errors.organizationName
                  : 'Please provide a unique quay organization name. The name should be of 2 to 20 alphanumeric characters in length, ex: ecp, csat etc'
              }
              fullHelpText="Organization name must be of 2 to 20 alphanumeric characters with no spaces or special characters."
            />
          </Box>
          <Box sx={{ flex: 1, padding: 2 }}>
            <FormLabel id="environment-radio-buttons-group-label">
              Environment
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="environment-radio-buttons-group-label"
              defaultValue="NP"
              name="radio-buttons-group"
              onChange={(e, checked) =>
                formik.setFieldValue('environmentType', checked)
              }
            >
              <FormControlLabel
                value="NP"
                control={<Radio />}
                label="Non-PRD"
              />
              <FormControlLabel value="PD" control={<Radio />} label="PRD" />
            </RadioGroup>
          </Box>
          <Box sx={{ flex: 1 }}>
            <CommonFormTextField
              componentID="adGroupDN"
              label="AD Group DN"
              value={adGroupDN || ''}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              fieldError={enterADGroupDNFieldError}
              helperText={
                enterADGroupDNFieldError && formik.errors.adGroupDN
                  ? formik.errors.adGroupDN
                  : 'Please enter AD Group DN excluding the DC Part. Example: CN=team-ldap-group,OU=Groups,OU=DomainLocal-NP,OU=Enterprise'
              }
              fullHelpText="Example: CN=team-ldap-group,OU=Groups,OU=DomainLocal-NP,OU=Enterprise"
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CommonFormTextField
              componentID="quayQuota"
              label="Quay Quota"
              type="number"
              value={quayQuota || ''}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              fieldError={enterQuayQuotaFieldError}
              helperText={
                enterQuayQuotaFieldError && formik.errors.quayQuota
                  ? formik.errors.quayQuota
                  : 'Please enter Quay Quota Required. Defaults to 50 GB.'
              }
              fullHelpText="Defaults to 50 GB."
              required={false}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CommonFormTextField
              componentID="teamEmail"
              label="Team Email"
              value={teamEmail || ''}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              fieldError={enterTeamEmailFieldError}
              helperText={
                enterTeamEmailFieldError && formik.errors.teamEmail
                  ? formik.errors.teamEmail
                  : 'Please provide unique email address for the quay organization. Your request may end up in failure if the same email address is already associated with another quay organization.'
              }
              fullHelpText="Please provide unique email address for the quay organization. Your request may end up in failure if the same email address is already associated with another quay organization."
            />
          </Box>
          <DialogActions>
            <Button
              disabled={
                !(
                  formik.isValid &&
                  (formik.dirty || formik.values.organizationName)
                ) || formik.isSubmitting
              }
              variant="contained"
              type="button"
              onClick={handleSubmitClick}
              endIcon={
                formik.isSubmitting ? (
                  <CircularProgress size="14px" sx={{ marginLeft: '0.5rem' }} />
                ) : undefined
              }
            >
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Box>
  );
};

export default ECPQuayOnboardingModal;
