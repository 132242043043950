import { gql } from '@apollo/client';

const DELETE_ADDITIONAL_COLLABORATORS = gql`
  mutation deleteAdditionalCollaborators($users: [String], $applicationId: ID) {
    deleteAdditionalCollaborators(
      users: $users
      applicationId: $applicationId
    ) {
      code
      status
      response
    }
  }
`;

export default DELETE_ADDITIONAL_COLLABORATORS;
