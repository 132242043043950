import { CommonSwitch } from '../CommonFields';

type NewOrExistingProjectPropType = {
  value: boolean;
  onChangeHandler: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
};

const NewOrExistingProject = (props: NewOrExistingProjectPropType) => {
  const { value, onChangeHandler } = props;

  return (
    <CommonSwitch
      label="ADO Project"
      offLabel="New"
      onLabel="Existing"
      checked={value}
      onChangeHandler={onChangeHandler}
      helperText="Select if you want Service Catalog to deploy to an existing ADO Project or create a new one."
    />
  );
};

export default NewOrExistingProject;
