import { useGlobalState } from '../../../../globalState/useGlobalState';
import { GenerateReferenceArchitectureParams } from '../../../../hooks/validationHooks/generateReferenceArchitecture';

type EphemeralResourceGroupContentMapType = [
  {
    offeringName: string;
    offeringShortName: string;
    ephemeralDependencies: GenerateReferenceArchitectureParams;
    content: {
      dialogTitle: string;
      dialogContentText: string;
      selectTargetApplicationLabel: string;
      selectTargetApplicationNoOptionsFoundButtonText: string;
      selectTargetApplicationHelperText: string;
      selectTargetApplicationFullHelpText: string;
      selectTargetApplicationRequiredErrorText: string;
      targetApplicationShortOfferingName: string;
    };
  },
];

const getEphemeralResourceGroupContentMap = () => {
  const { appSettings } = useGlobalState();
  const CnaMinVersion = appSettings.CnaMinVersion.get();

  const ephemeralResourceGroupContentMap: EphemeralResourceGroupContentMapType =
    [
      {
        offeringName: 'Cloud Native Application',
        offeringShortName: 'cloudapp',
        ephemeralDependencies: {
          name: 'Ephemeral Cloud Native Application',
          shortOfferingName: 'ephemcna',
          conflicts: [],
          dependencies: [
            {
              shortOfferingName: 'cloudapp',
              version: CnaMinVersion,
              alternatives: [],
            },
          ],
        },
        content: {
          dialogTitle: 'Create a New Ephemeral Cloud Native Application',
          dialogContentText:
            'Select your configuration options for a new ephemeral cloud native app',
          selectTargetApplicationLabel: 'Select Target Cloud Native App',
          selectTargetApplicationNoOptionsFoundButtonText:
            'Create a New Cloud Native Application',
          selectTargetApplicationHelperText:
            'Enter a cloud native application to target.',
          selectTargetApplicationFullHelpText:
            'Please select a cloud native application from the dropdown menu.',
          selectTargetApplicationRequiredErrorText:
            'You must select a cloud native application to target.',
          targetApplicationShortOfferingName: 'cloudapp',
        },
      },
    ];
  return ephemeralResourceGroupContentMap;
};

export default getEphemeralResourceGroupContentMap;
