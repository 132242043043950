import { gql } from '@apollo/client';

const REMOVE_WIZ_MEMBERS = gql`
  mutation removeWizMembers(
    $applicationId: ID!
    $wizMembers: [ProjectMemberInput]!
  ) {
    removeWizMembers(applicationId: $applicationId, wizMembers: $wizMembers) {
      code
      status
      response {
        id
        email
      }
    }
  }
`;

export default REMOVE_WIZ_MEMBERS;
