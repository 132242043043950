import { ChangeEvent, useState } from 'react';
import data from './HowToPage.data';

const useHowToPage = () => {
  const title = 'How To Page';
  const subtitle = 'The Costco Way';
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filterTags, setFilterTags] = useState<string[]>([]);

  const facets: string[] = [];

  const unique = data?.filter((el) => {
    el.tags?.map((o) => {
      if (!facets.includes(o)) {
        facets.push(o);
      }
      return null;
    });
    facets.sort();
    return null;
  });

  const handleSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const isCheckboxChecked = (tagname: string): boolean =>
    filterTags.includes(tagname);

  const handleAddFilterTag = (newFilterTag: string) => {
    if (!filterTags.includes(newFilterTag)) {
      setFilterTags([...filterTags, newFilterTag]);
    }
  };

  const handleRemoveFilterTag = (filterTagToRemove: string) => {
    if (filterTags.includes(filterTagToRemove)) {
      const updatedFilterTagsArray = [...filterTags];
      const index = updatedFilterTagsArray.indexOf(filterTagToRemove);
      if (index > -1) {
        updatedFilterTagsArray.splice(index, 1);
        setFilterTags(updatedFilterTagsArray);
      }
    }
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = event.target;
    if (checked) return handleAddFilterTag(id);
    handleRemoveFilterTag(id);
    return null;
  };

  const checkFilterTags = (itemTags: string[]): boolean => {
    if (filterTags.length) {
      const checkFilterObjs = itemTags.some((r) => filterTags.includes(r));
      return checkFilterObjs;
    }
    return true;
  };

  const resetFields = () => {
    setSearchTerm('');
    setFilterTags([]);
  };

  return {
    title,
    subtitle,
    searchTerm,
    facets,
    unique,
    handleSearchTermChange,
    handleCheckboxChange,
    checkFilterTags,
    isCheckboxChecked,
    resetFields,
  };
};

export default useHowToPage;
