import { gql } from '@apollo/client';

const LIST_APPLICATIONS_QUERY = gql`
  query listStarterKits {
    listApplications {
      code
      status
      response {
        id
        applicationName
        applicationDescription
        primaryRegion
        createdDateTime
        updatedDateTime
        lastAwaitingAdoRunRefreshTime
        hasRunningPipelines
        requester {
          email
        }
        starterKitRequests {
          id
          status
          requester {
            email
          }
          requestedDateTime
          cartsApprovalRequestId
          offerings {
            status
            adoRunUrl
            adoRepoNames
            category
            offeringName
            offeringVersion
            offeringRepoUrl
            offeringShortName
            offeringVnetPeered
            offeringServiceNames
          }
        }
        deleteStatus
        environments {
          name
          type
          subscriptionName
          notReady
          deleteStatus
        }
        isEphemeral
      }
      count
    }
  }
`;

export default LIST_APPLICATIONS_QUERY;
