import { LocalHospital } from '@mui/icons-material';
import { PageHeader } from '../../components';
import ExerciseLookups from './ExerciseLookups';
import useHealthCheckPage from './useHealthCheckPage';

interface HealthCheckPageProps {}

const HealthCheckPage = (props: HealthCheckPageProps) => {
  const { title, subtitle } = useHealthCheckPage();

  return (
    <div>
      <PageHeader icon={<LocalHospital />} title={title} subtitle={subtitle} />
      <ExerciseLookups />
    </div>
  );
};

export default HealthCheckPage;
