import { Create } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { PageHeader } from '../../components';
import useBuildNewApplicationWizardPage from './useBuildNewApplicationWizardPage';

const BuildNewApplicationWizardPage = () => {
  const { title } = useBuildNewApplicationWizardPage();

  return (
    <Box sx={{ backgroundColor: 'none' }}>
      <PageHeader icon={<Create />} title={title} />
      <Box sx={{ pb: '10rem' }}>
        <Typography>Build New Application Wizard Page</Typography>
      </Box>
    </Box>
  );
};

export default BuildNewApplicationWizardPage;
