import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';
import {
  EcpOnboardingRequestInput,
  EnvironmentType,
} from '@costco-service-catalog/bff-types';
import { ROUTES } from '../../../../constants';
import {
  useEnqueueSnackbar,
  useSubmitECPOnboardingRequest,
} from '../../../../hooks';

type defaultFormValuesType = {
  teamName?: string;
  environmentType: EnvironmentType;
  projectName?: string;
  namespaceName?: string;
  adGroup?: string;
  adGroupDN?: string;
  teamEmail?: string;
  cpu?: string;
  ram?: string;
  storage?: string;
  requestReason?: string;
  additionalInformation?: string;
};

const useECPNamespaceOnboardingModal = (handleModalClose: () => void) => {
  const navigate = useNavigate();
  const { submitECPOnboardingRequest } = useSubmitECPOnboardingRequest();
  const { pushSnackbar } = useEnqueueSnackbar();
  const initFormValues: defaultFormValuesType = {
    teamName: undefined,
    environmentType: 'NP',
    cpu: '1',
    ram: '2',
    storage: '25',
  };

  const defaultValidationSchema = () =>
    object({
      teamName: string()
        .min(3, 'Team Acronym must be at least 3 characters.')
        .max(4, 'Team Acronym must not be more than 4 characters.')
        .matches(
          /^[a-zA-Z0-9]*$/g,
          'Team Acronym cannot contain any spaces or special characters.'
        )
        .matches(
          /^((?!\d).)/g,
          'Team Acronym must not start with a numeric character.'
        )
        .required('Team acronym is required.'),
      namespaceName: string()
        .matches(
          /^((?!\d).)/g,
          'Namespace name must not start with a numeric character.'
        )
        .required('Namespace name is required.'),
      adGroup: string().required('AD Group Name is required.'),
      adGroupDN: string().required('AD Group DN is required.'),
      cpu: string().matches(/^[0-9]*$/g, 'Value should be a positive integer.'),
      ram: string()
        .matches(/^[0-9]*$/g, 'Value should be a positive integer.')
        .optional(),
      storage: string()
        .matches(/^[0-9]*$/g, 'Value should be a positive integer.')
        .optional(),
      teamEmail: string()
        .max(100, 'Team email must not be more than 100 characters.')
        .matches(
          /^([A-Za-z0-9_\-.])+@costco.com/,
          'Team email must be a costco email address.'
        )
        .required('Team email is required.'),
      requestReason: string().required('Reason for request is required.'),
    });

  const formik = useFormik({
    initialValues: initFormValues,
    validationSchema: defaultValidationSchema,
    enableReinitialize: Boolean(initFormValues.teamName),
    onSubmit: async (
      values: defaultFormValuesType,
      helpers: FormikHelpers<defaultFormValuesType>
    ) => {
      const input: EcpOnboardingRequestInput = {
        environmentType: values.environmentType,
        teamEmail: values.teamEmail!,
        namespaceOnboarding: {
          teamName: values.teamName!,
          projectName: values.projectName,
          namespaceName: values.namespaceName!,
          adGroup: values.adGroup!,
          adGroupDN: values.adGroupDN!,
          cpu: values.cpu ? parseInt(values.cpu, 10) : 1,
          ram: values.ram ? parseInt(values.ram, 10) : 2,
          storage: values.storage ? parseInt(values.storage, 10) : 25,
          requestReason: values.requestReason!,
          additionalInformation: values.additionalInformation,
        },
        requestType: 'Openshift',
      };
      const submitOptions = {
        variables: {
          input,
        },
        onCompleted: () => {},
      };
      const submitECPOnboardingRequestRes = await submitECPOnboardingRequest(
        submitOptions
      );
      if (
        !submitECPOnboardingRequestRes.errors ||
        submitECPOnboardingRequestRes.errors?.length === 0
      ) {
        pushSnackbar('Onboarding request has been submitted.', {
          variant: 'success',
        });
        handleModalClose();
        navigate(`${ROUTES.UTILITIES}`);
      }
    },
  });

  const handleSubmitClick = async () => {
    formik.submitForm();
  };

  const enterTeamAcronymFieldError = formik.touched.teamName
    ? Boolean(formik.errors.teamName)
    : false;

  const enterProjectNameFieldError = formik.touched.projectName
    ? Boolean(formik.errors.projectName)
    : false;

  const enterNamespaceNameFieldError = formik.touched.namespaceName
    ? Boolean(formik.errors.namespaceName)
    : false;

  const enterADGroupFieldError = formik.touched.adGroup
    ? Boolean(formik.errors.adGroup)
    : false;

  const enterADGroupDNFieldError = formik.touched.adGroupDN
    ? Boolean(formik.errors.adGroupDN)
    : false;

  const enterTeamEmailFieldError = formik.touched.teamEmail
    ? Boolean(formik.errors.teamEmail)
    : false;

  const enterCPUFieldError = formik.touched.cpu
    ? Boolean(formik.errors.cpu)
    : false;

  const enterRAMFieldError = formik.touched.ram
    ? Boolean(formik.errors.ram)
    : false;

  const enterStorageFieldError = formik.touched.storage
    ? Boolean(formik.errors.storage)
    : false;

  const enterRequestReasonFieldError = formik.touched.requestReason
    ? Boolean(formik.errors.requestReason)
    : false;

  return {
    formik,
    enterTeamAcronymFieldError,
    enterProjectNameFieldError,
    enterNamespaceNameFieldError,
    enterADGroupFieldError,
    enterADGroupDNFieldError,
    enterTeamEmailFieldError,
    enterCPUFieldError,
    enterRAMFieldError,
    enterStorageFieldError,
    enterRequestReasonFieldError,
    handleSubmitClick,
  };
};

export default useECPNamespaceOnboardingModal;
