import {
  Application,
  ApplicationStarterKitRequest,
  EphemeralRgRequestInput,
  ReferenceArchitecture,
  RepoVersionInput,
  StarterKitOffering,
} from '@costco-service-catalog/bff-types';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  useListEphemeralRgAvailableApplications,
  useSubmitEphemeralRgRequest,
  useEnqueueSnackbar,
} from '../../../../hooks';
import getEphemeralResourceGroupContentMap from './ephemeralResourceGroupContentMap';
import ephemeralResourceGroupModalValidationSchema from './ephemeralResourceGroupModalValidationSchema';
import generateReferenceArchitecture from '../../../../hooks/validationHooks/generateReferenceArchitecture';
import {
  ValidateSelectedReturnType,
  validateSelected,
} from '../../../../hooks/validationHooks/useOfferingMultiValidation';
import { ROUTES } from '../../../../constants';
import { autopopPayloadType } from '../../../../pages/ApplicationSummaryPage/useApplicationSummaryPage';
import { useGlobalState } from '../../../../globalState/useGlobalState';

export type starterKitOfferingWithRequest = {
  offering: StarterKitOffering;
  completedStarterKitId: string;
  completedStarterKitRequestedDateTime: string;
  completedStarterKitCartsApprovalRequestId: string;
};

type completedAppOfferings = {
  appId: string;
  appName: string;
  appCompleteOfferings: starterKitOfferingWithRequest[];
};

type defaultCreateNewERGFormValuesType = {
  selectHostingApplication?: Application;
  selectTargetApplication?: starterKitOfferingWithRequest;
  adoBoardsUserStory?: string | null;
};

const retrieveCompletedAppOfferings = (data: Application[]) => {
  const allCompletedAppOfferings = data
    .map((app) => {
      const { starterKitRequests } = app;
      if (starterKitRequests && starterKitRequests?.length > 0) {
        const completedStarterKitRequests = starterKitRequests
          .map((starterKitRequest) => {
            if (starterKitRequest?.status === 'Completed') {
              return starterKitRequest;
            }
            return undefined;
          })
          .filter((v): v is ApplicationStarterKitRequest => !!v);
        const completedOfferings2dArr = completedStarterKitRequests
          .map((completedStarterKit) => {
            const { offerings } = completedStarterKit;
            if (offerings && offerings?.length > 0) {
              const filteredOfferings = offerings
                .map((offering) => {
                  if (offering?.status === 'Completed') {
                    return {
                      offering,
                      completedStarterKitId: completedStarterKit.id,
                      completedStarterKitRequestedDateTime:
                        completedStarterKit.requestedDateTime,
                      completedStarterKitCartsApprovalRequestId:
                        completedStarterKit.cartsApprovalRequestId,
                    };
                  }
                  return undefined;
                })
                .filter((v): v is starterKitOfferingWithRequest => !!v);
              return filteredOfferings;
            }
            return undefined;
          })
          .filter((v): v is starterKitOfferingWithRequest[] => !!v);
        const completedOfferings = _.flatten(completedOfferings2dArr);

        return {
          appId: app.id,
          appName: app.applicationName,
          appCompleteOfferings: completedOfferings,
        };
      }
      return undefined;
    })
    .filter((v): v is completedAppOfferings => !!v);
  return allCompletedAppOfferings;
};

const useEphemeralResourceGroupModalCreateNew = (
  selectedERG: string | undefined,
  selectedRefArch: ReferenceArchitecture | undefined,
  refArchArray: ReferenceArchitecture[],
  handleModalClose: () => void,
  runEphemeralRgsRefetch: () => Promise<void>
) => {
  const { appSettings } = useGlobalState();
  const maximumErgExpiration = appSettings.maximumErgExpiration.get();

  const ephemeralResourceGroupContentMap =
    getEphemeralResourceGroupContentMap();
  const ephemeralResourceGroupModalContent =
    ephemeralResourceGroupContentMap.find(
      (content) => selectedERG === content.offeringShortName
    );
  const navigate = useNavigate();
  const { pushSnackbar } = useEnqueueSnackbar();
  const {
    error: listApplicationsError,
    data: listApplicationsData,
    loading: listApplicationsLoading,
  } = useListEphemeralRgAvailableApplications();
  const [expectedRepoArrayLength, setExpectedRepoArrayLength] =
    useState<number>();
  const [repositoryArray, setRepositoryArray] = useState<RepoVersionInput[]>(
    []
  );
  const [expirationDate, setExpirationDate] = useState<dayjs.Dayjs>(
    dayjs().add(14, 'day')
  );
  const defaultMaximumDate = dayjs().add(
    parseInt(maximumErgExpiration, 10),
    'days'
  );
  const [validateMinimumAppVersion, setValidateMinimumAppVersion] =
    useState<ValidateSelectedReturnType>({
      valid: false,
      missingDeps: [],
      conflicts: [],
      invalidReason: '',
    });

  const ephemeralApplicationRefArch: ReferenceArchitecture =
    ephemeralResourceGroupModalContent
      ? generateReferenceArchitecture(
          ephemeralResourceGroupModalContent.ephemeralDependencies
        )
      : {};

  const verifyMinimumApplicationVersion = (
    ephemeralApplicationRefArch: ReferenceArchitecture,
    hostingApplication: Application
  ) => {
    const selectedOfferings: ReferenceArchitecture[] = [
      ephemeralApplicationRefArch,
    ];
    const selectedUpdates: ReferenceArchitecture[] = [];
    const deployedApplication: Application = hostingApplication;
    const referenceArchitectures: ReferenceArchitecture[] = refArchArray;

    const validation = validateSelected(
      selectedOfferings,
      selectedUpdates,
      deployedApplication,
      referenceArchitectures
    );
    setValidateMinimumAppVersion(validation);
  };

  const { submitEphemeralRg } = useSubmitEphemeralRgRequest();

  const formik = useFormik({
    initialValues: {},
    validationSchema: ephemeralResourceGroupModalValidationSchema(selectedERG),
    onSubmit: async (
      values: defaultCreateNewERGFormValuesType,
      helpers: FormikHelpers<defaultCreateNewERGFormValuesType>
    ) => {
      const handleNewEphemeralRgSubmit = async () => {
        const ephRequest: EphemeralRgRequestInput = {
          applicationId: values.selectHostingApplication?.id || '',
          offeringShortName:
            values.selectTargetApplication?.offering.offeringShortName || '',
          requestId:
            values.selectTargetApplication?.completedStarterKitId || '',
          storyId: parseInt(formik.values.adoBoardsUserStory || '', 10),
          expirationDateTime: expirationDate.toISOString(),
          repoVersions: repositoryArray.map((v) => ({
            repoName: v.repoName,
            version: v.version,
          })),
        };

        const submitOptions = {
          variables: { input: ephRequest },
          onCompleted: () => {},
        };

        const submitEphemeralRgRes = await submitEphemeralRg(submitOptions);
        return submitEphemeralRgRes;
      };

      const submitResponse = await handleNewEphemeralRgSubmit();
      helpers.setSubmitting(false);

      if (!submitResponse.errors || submitResponse.errors?.length === 0) {
        pushSnackbar('Ephemeral application request has been submitted.', {
          variant: 'success',
        });
        handleModalClose();
        runEphemeralRgsRefetch();
      }
    },
  });

  const handleChangeExpirationDate = (date: dayjs.Dayjs | null) => {
    if (date) {
      setExpirationDate(date);
    }
  };

  const handleSetExpectedRepoArrayLength = (newLength: number) => {
    setExpectedRepoArrayLength(newLength);
  };

  const handleUpdateRepository = (repo: string, ref: string) => {
    const rowIndex = repositoryArray.findIndex((r) => r.repoName === repo);
    if (rowIndex > -1) {
      repositoryArray[rowIndex] = {
        repoName: repo,
        version: ref,
      };
    } else {
      repositoryArray.push({
        repoName: repo,
        version: ref,
      });
    }
    setRepositoryArray([...repositoryArray]);
  };

  const handleResetRepositoryArr = () => {
    setRepositoryArray([]);
  };

  const validateRepositoryArray = () => {
    const validRepositories = repositoryArray.filter(
      (r) => r.repoName !== '' && r.version !== ''
    );
    const invalidRepositories = repositoryArray.filter(
      (r) => r.repoName === '' || r.version === ''
    );

    if (
      expectedRepoArrayLength &&
      expectedRepoArrayLength > 0 &&
      validRepositories.length === expectedRepoArrayLength &&
      invalidRepositories.length === 0
    ) {
      return true;
    }
    return false;
  };

  const [
    relevantHostingApplicationOfferings,
    setRelevantHostingApplicationOfferings,
  ] = useState<starterKitOfferingWithRequest[]>([]);

  useEffect(() => {
    if (
      formik.values.selectHostingApplication &&
      formik.values.selectTargetApplication &&
      ephemeralApplicationRefArch
    ) {
      verifyMinimumApplicationVersion(
        ephemeralApplicationRefArch,
        formik.values.selectHostingApplication
      );
    }
    if (
      !listApplicationsError &&
      !listApplicationsLoading &&
      listApplicationsData &&
      listApplicationsData.length > 0
    ) {
      const completedAppOfferings =
        retrieveCompletedAppOfferings(listApplicationsData);

      const { selectHostingApplication } = formik.values;
      const relevantAppOfferings = completedAppOfferings.find(
        (completedAppOffering) =>
          completedAppOffering.appId === selectHostingApplication?.id
      );
      if (relevantAppOfferings && ephemeralResourceGroupModalContent) {
        const relevantOfferings =
          relevantAppOfferings.appCompleteOfferings.filter(
            (offering) =>
              offering.offering.offeringShortName ===
              ephemeralResourceGroupModalContent.offeringShortName
          );
        setRelevantHostingApplicationOfferings([...relevantOfferings]);
      } else {
        setRelevantHostingApplicationOfferings([]);
      }
    }
  }, [listApplicationsData, formik.values]);

  const selectHostingApplicationFieldError = formik.touched
    .selectHostingApplication
    ? Boolean(formik.errors.selectHostingApplication)
    : false;

  const selectTargetApplicationFieldError = formik.touched
    .selectTargetApplication
    ? Boolean(formik.errors.selectTargetApplication)
    : false;

  const adoBoardsUserStoryFieldError = formik.touched.adoBoardsUserStory
    ? Boolean(formik.errors.adoBoardsUserStory)
    : false;

  const handleGoToUpdateHostingApp = (
    hostingApplication: Application | undefined
  ) => {
    const buildAutoselectList = () => {
      if (
        validateMinimumAppVersion.conflicts.length === 0 &&
        validateMinimumAppVersion.missingDeps.length > 0 &&
        validateMinimumAppVersion.valid === false
      ) {
        return [
          ...validateMinimumAppVersion.missingDeps
            .map((v) => v.shortOfferingName)
            .filter((v): v is string => !!v),
        ];
      }
      return [];
    };
    if (hostingApplication) {
      const autopopPayload: autopopPayloadType = {
        autopopulate: true,
        from: ROUTES.EPHEMERAL_RG_APPS,
        data: hostingApplication,
        selection: buildAutoselectList(),
      };
      navigate(ROUTES.OFFERING_MULTISELECT, { state: { autopopPayload } });
    } else {
      navigate(ROUTES.OFFERING_MULTISELECT);
    }
  };

  const handleGoToDeployNewTarget = (
    hostingApplication: Application | undefined,
    deployNewTargetShortName: string
  ) => {
    if (hostingApplication) {
      const autopopPayload: autopopPayloadType = {
        autopopulate: true,
        from: ROUTES.EPHEMERAL_RG_APPS,
        data: hostingApplication,
        selection: [deployNewTargetShortName],
      };
      navigate(ROUTES.OFFERING_MULTISELECT, { state: { autopopPayload } });
    } else {
      navigate(ROUTES.OFFERING_MULTISELECT);
    }
  };

  return {
    formik,
    ephemeralResourceGroupModalContent,
    listApplicationsError,
    listApplicationsLoading,
    listApplicationsData,
    selectTargetApplicationData: relevantHostingApplicationOfferings,
    selectHostingApplicationFieldError,
    selectTargetApplicationFieldError,
    adoBoardsUserStoryFieldError,
    repositoryArray,
    handleUpdateRepository,
    handleResetRepositoryArr,
    repositoryArrayIsValid: validateRepositoryArray(),
    handleSetExpectedRepoArrayLength,
    validateMinimumAppVersion,
    handleGoToUpdateHostingApp,
    handleGoToDeployNewTarget,
    expirationDate,
    handleChangeExpirationDate,
    defaultMaximumDate,
  };
};

export default useEphemeralResourceGroupModalCreateNew;
