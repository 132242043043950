import { useEffect, useState } from 'react';
import {
  AddToApplicationRequestInput,
  Application,
} from '@costco-service-catalog/bff-types';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { ROUTES } from '../../constants';
import {
  useAzurePolicies,
  useEnqueueSnackbar,
  useSubmitAddToApplication,
} from '../../hooks';
import { removePolicyModeSuffix } from '../FormComponents/OfferingForms/useAzureCloudNativeStarterKit';
import { useGlobalState } from '../../globalState/useGlobalState';
import { Versions } from '../AppVersions/AppVersions';

const usePolicyModal = (
  policyModalData: {
    environmentType: string;
    enabledPolicies: string[];
  },
  openPolicyModal: boolean,
  application: Application | undefined,
  handleClosePolicyModal: () => void
) => {
  const { data: azurePolicyList } = useAzurePolicies();
  const { submitAddToApplication } = useSubmitAddToApplication();
  const navigate = useNavigate();
  const { pushSnackbar } = useEnqueueSnackbar();

  const [policyKeyList, setPolicyKeyList] = useState(
    policyModalData?.enabledPolicies
  );

  const [
    submitPolicyChangeRequestLoading,
    setSubmitPolicyChangeRequestLoading,
  ] = useState(false);

  const { appSettings } = useGlobalState();
  const [versions, setVersions] = useState<Versions>({});

  const setVersionInfo = () => {
    if (appSettings.useUIMocks.get()) {
      setVersions({
        serviceCatalogApi: '1.0.0',
        starterKitFactoryPipeline: 'refs/heads/main',
        deletionFactoryPipeline: 'refs/heads/main',
        policyFactoryPipeline: 'refs/heads/main',
        backEndForFrontend: '1.0.0',
        client: '1.0.0',
        uiDB: '1.0.0',
        apiDB: '1.0.0',
      });
    } else {
      fetch('/version.json')
        .then((res) => res.json())
        .then((clientData) => {
          const versionsUrl = new URL(
            'api/versions',
            appSettings.value.bffBaseUrl
          );

          fetch(versionsUrl.toString())
            .then((res) => res.json())
            .then((serverData) => {
              const newVersions = serverData as Versions;
              newVersions.client = clientData.version;
              setVersions(newVersions);
            });
        });
    }
  };

  const trimVersionString = () => {
    if (versions.policyFactoryPipeline) {
      const trimIdx = versions.policyFactoryPipeline.lastIndexOf('/');
      if (trimIdx > 0) {
        return versions.policyFactoryPipeline.substring(trimIdx + 1);
      }
    }
    return versions.policyFactoryPipeline;
  };

  useEffect(() => {
    setVersionInfo();
    setPolicyKeyList(policyModalData?.enabledPolicies);
  }, [openPolicyModal]);

  const handleChangePolicySelection = (azurePolicyKey: string) => {
    if (policyKeyList.find((p) => p.includes(azurePolicyKey))) {
      const removePolicy = [
        ...policyKeyList.filter((p) => !p.includes(azurePolicyKey)),
      ];
      setPolicyKeyList(removePolicy);
    } else {
      const addPolicy = [
        ...policyKeyList.concat(azurePolicyKey.concat('Audit')),
      ];
      setPolicyKeyList(addPolicy);
    }
  };

  const handleChangePolicyMode = (azurePolicyKey: string) => {
    const policyKeyListEntryIdx = policyKeyList.findIndex((p) =>
      p.includes(azurePolicyKey || '')
    );

    const newPolicyKeyList = [
      ...policyKeyList.map((policyKeyEntry, i) => {
        if (policyKeyListEntryIdx === i) {
          const keyRoot = removePolicyModeSuffix(policyKeyEntry);
          return keyRoot.concat(
            policyKeyEntry.includes('Audit') ? 'Deny' : 'Audit'
          );
        }
        return policyKeyEntry;
      }),
    ];

    setPolicyKeyList(newPolicyKeyList);
  };

  const handleSubmitPolicyChangeRequest = async () => {
    setSubmitPolicyChangeRequestLoading(true);
    const submitAddToApplicationInput: AddToApplicationRequestInput = {
      applicationId: application?.id || '',
      offeringRequests: [
        {
          additionalParams: [
            {
              key: 'environmentType',
              value: policyModalData?.environmentType,
            },
            {
              key: 'enabledPolicies',
              value: policyKeyList?.toString(),
            },
          ],
          category: 'Utility',
          offeringShortName: 'policyupdate',
          offeringVersion: 'na',
          offeringName: 'Apply Policies',
        },
      ],
    };
    const submitAddToApplicationRes = await submitAddToApplication({
      variables: { input: submitAddToApplicationInput },
    });

    if (
      !submitAddToApplicationRes.errors ||
      submitAddToApplicationRes.errors?.length === 0
    ) {
      setSubmitPolicyChangeRequestLoading(false);
      pushSnackbar('Policy change request has been submitted.', {
        variant: 'success',
      });
      handleClosePolicyModal();
      navigate(`${ROUTES.APPLICATION_SUMMARY}/${application?.id || ''}`);
    } else {
      setSubmitPolicyChangeRequestLoading(false);
    }
  };

  const removedPolicies = _.difference(
    policyModalData?.enabledPolicies,
    policyKeyList
  );
  const addedPolicies = _.difference(
    policyKeyList,
    policyModalData?.enabledPolicies
  );
  return {
    azurePolicyList,
    addedPolicies,
    removedPolicies,
    policyKeyList,
    submitPolicyChangeRequestLoading,
    handleChangePolicySelection,
    handleChangePolicyMode,
    handleSubmitPolicyChangeRequest,
    currentPolicyFactoryPipelineVersion: trimVersionString(),
  };
};

export default usePolicyModal;
