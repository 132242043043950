import { AdoProject } from '@costco-service-catalog/bff-types';
import { CommonDropdown } from '../CommonFields';

interface ADOProjectDropdownPropType {
  data: AdoProject[];
  value: AdoProject | null;
  loading: boolean;
  fieldError: boolean;
  errorText?: string;
  onChangeHandler: (
    event: React.ChangeEvent<{}>,
    value: AdoProject | null
  ) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const ADOProjectDropdown = (props: ADOProjectDropdownPropType) => {
  const {
    data,
    value,
    loading,
    fieldError,
    errorText,
    onChangeHandler,
    onBlurHandler,
  } = props;

  return (
    <CommonDropdown
      componentID="existingAdoProject"
      label="ADO Project"
      data={data}
      value={value}
      loading={loading}
      required
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      getOptionLabel={(option) => option.name as string}
      getOptionSelected={(o, v) => o.id === v.id}
      noOptionsText="Please select an ADO Org"
      fieldError={fieldError}
      helperText={
        fieldError && errorText
          ? errorText
          : 'Select your ADO Project from the list.'
      }
      fullHelpText="Select the ADO Project you would like Service Catalog to deploy this starter kit to."
    />
  );
};

ADOProjectDropdown.defaultProps = {
  errorText: undefined,
};

export default ADOProjectDropdown;
