import { CommonRegionDropdown } from '../CommonFields';

interface PrimaryRegionDropdownPropType {
  data: string[];
  value: string;
  fieldError: boolean;
  errorText?: string;
  onChangeHandler: (event: React.ChangeEvent<{}>, value: string | null) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const PrimaryRegionDropdown = (props: PrimaryRegionDropdownPropType) => {
  const { data, value, fieldError, errorText, onChangeHandler, onBlurHandler } =
    props;

  return (
    <CommonRegionDropdown
      componentID="primaryRegion"
      label="Primary Region"
      data={data}
      value={value}
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      fieldError={fieldError}
      helperText={
        fieldError && errorText
          ? errorText
          : 'Select the primary region for deployment.'
      }
      fullHelpText="Select your primary region from the dropdown. Primary region serves as the central hub for delivering cloud services to users."
    />
  );
};

PrimaryRegionDropdown.defaultProps = {
  errorText: undefined,
};

export default PrimaryRegionDropdown;
