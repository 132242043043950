import { ReactNode } from 'react';
import useStyles from './OfferingMultiFooter.styles';

interface OfferingMultiFooterProps {
  children: ReactNode;
}

const OfferingMultiFooter = (props: OfferingMultiFooterProps) => {
  const { children } = props;
  const { classes } = useStyles();

  return (
    <div>
      <div className={classes.ghostFooter} />
      <div className={classes.footerContainer}>{children}</div>
    </div>
  );
};

export default OfferingMultiFooter;
