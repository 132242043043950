/* eslint-disable react/jsx-no-duplicate-props */
import { Help } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import { useState, JSX } from 'react';
import useStyles from './Common.styles';

interface CommonDropdownPropsType<Type> {
  componentID: string;
  label: string;
  data: Type[];
  value: Type | null;
  fieldError: boolean;
  helperText: string;
  fullHelpText: string;
  loading: boolean;
  required?: boolean;
  noOptionsText?: string;
  noOptionsButton?: JSX.Element;
  onChangeHandler: (event: React.ChangeEvent<{}>, value: Type | null) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
  getOptionLabel: (option: Type) => string;
  getOptionSelected: (option: Type, value: Type) => boolean;
  getOptionDisabled?: (option: Type) => boolean;
  dropdownDisabled?: boolean;
}

const CommonDropdown = <Type,>(props: CommonDropdownPropsType<Type>) => {
  const {
    componentID,
    label,
    data,
    value,
    fieldError,
    helperText,
    fullHelpText,
    loading,
    required,
    noOptionsText,
    noOptionsButton,
    onChangeHandler,
    onBlurHandler,
    getOptionLabel,
    getOptionSelected,
    getOptionDisabled,
    dropdownDisabled,
  } = props;

  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div className={classes.fieldWrap}>
        <Autocomplete
          id={componentID}
          options={data}
          getOptionDisabled={getOptionDisabled}
          disabled={dropdownDisabled}
          value={value}
          noOptionsText={
            <Box>
              <Typography>{noOptionsText}</Typography>
              {noOptionsButton || undefined}
            </Box>
          }
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={getOptionSelected}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              required={!!required}
              label={label}
              error={fieldError}
              helperText={helperText}
              inputProps={{
                ...params.inputProps,
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    <IconButton
                      size="small"
                      aria-label="show help"
                      onClick={handleClick}
                      style={{
                        position: 'absolute',
                        right: 15,
                      }}
                    >
                      <Help />
                    </IconButton>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      params.InputProps.endAdornment
                    )}
                  </>
                ),
              }}
            />
          )}
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { maxWidth: 300 },
        }}
      >
        <Typography variant="body2" aria-label="detailed help" sx={{ p: 2 }}>
          {fullHelpText}
        </Typography>
      </Popover>
    </div>
  );
};

CommonDropdown.defaultProps = {
  required: false,
  noOptionsText: undefined,
  noOptionsButton: undefined,
  getOptionDisabled: () => false,
  dropdownDisabled: false,
};

export default CommonDropdown;
