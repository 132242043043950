import { gql } from '@apollo/client';

const CANCEL_STARTER_KIT_REQUEST_MUTATION = gql`
  mutation cancelStarterKitRequest($input: CancelStarterKitRequestInput) {
    cancelStarterKitRequest(input: $input) {
      status
      code
    }
  }
`;

export default CANCEL_STARTER_KIT_REQUEST_MUTATION;
