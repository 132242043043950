import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './components';
import { ROUTES } from './constants';
import { useGlobalState } from './globalState/useGlobalState';
import {
  AllApplicationsPage,
  ApplicationDraftsPage,
  ApplicationListPage,
  ApplicationSummaryPage,
  BuildNewApplicationWizardPage,
  ECPOnboardingListPage,
  EphemeralResourceGroupPage,
  ErrorPage,
  HealthCheckPage,
  HomePage,
  HowToPage,
  NotFoundPage,
  OfferingMultiPage,
  OfferingRequestPage,
  SigninPage,
  UtilitiesPage,
} from './pages';

const AppPageRouting = () => {
  const { appSettings } = useGlobalState();
  const useEphemeralRG = appSettings.useEphemeralRGPage.get();
  const useNewAppWizard = appSettings.useNewAppWizard.get();
  const ecpOnboardingEnabled = appSettings.ecpOnboardingUtilitiesEnabled.get();

  return (
    <Routes>
      <Route
        path={ROUTES.HOME}
        element={<ProtectedRoute outlet={<HomePage />} />}
      />
      <Route
        path={ROUTES.HEALTH_CHECK}
        element={<ProtectedRoute outlet={<HealthCheckPage />} />}
      />
      <Route
        path={ROUTES.HOW_TO}
        element={<ProtectedRoute outlet={<HowToPage />} />}
      />
      <Route
        path={ROUTES.OFFERING_REQUEST}
        element={<ProtectedRoute outlet={<OfferingRequestPage />} />}
      >
        <Route
          path={ROUTES.OFFERING_REQUEST_DRAFT_ID}
          element={<ProtectedRoute outlet={<OfferingRequestPage />} />}
        />
      </Route>
      <Route
        path={ROUTES.BASE}
        element={<ProtectedRoute outlet={<OfferingMultiPage />} />}
      />
      <Route
        path={ROUTES.OFFERING_MULTISELECT}
        element={<ProtectedRoute outlet={<OfferingMultiPage />} />}
      />
      <Route
        path={ROUTES.APPLICATION_LIST}
        element={<ProtectedRoute outlet={<ApplicationListPage />} />}
      />
      <Route
        path={ROUTES.ALL_APPLICATIONS}
        element={
          <ProtectedRoute outlet={<AllApplicationsPage />} isRestricted />
        }
      />
      <Route path={ROUTES.APPLICATION_SUMMARY}>
        <Route
          path={ROUTES.APPLICATION_SUMMARY_ID}
          element={<ProtectedRoute outlet={<ApplicationSummaryPage />} />}
        />
      </Route>
      <Route
        path={ROUTES.APPLICATION_DRAFTS}
        element={<ProtectedRoute outlet={<ApplicationDraftsPage />} />}
      />
      {useEphemeralRG ? (
        <Route
          path={ROUTES.EPHEMERAL_RG_APPS}
          element={<ProtectedRoute outlet={<EphemeralResourceGroupPage />} />}
        />
      ) : undefined}
      {useNewAppWizard ? (
        <Route
          path={ROUTES.NEW_APP_WIZARD}
          element={
            <ProtectedRoute outlet={<BuildNewApplicationWizardPage />} />
          }
        />
      ) : undefined}
      {ecpOnboardingEnabled ? (
        <Route
          path={ROUTES.ECP_ONBOARDING_LIST}
          element={<ProtectedRoute outlet={<ECPOnboardingListPage />} />}
        />
      ) : undefined}
      <Route
        path={ROUTES.UTILITIES}
        element={<ProtectedRoute outlet={<UtilitiesPage />} />}
      />

      <Route path={ROUTES.SIGNIN} element={<SigninPage />} />
      <Route path={ROUTES.ERROR}>
        <Route path={ROUTES.ERROR_SLUG} element={<ErrorPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppPageRouting;
