import { Requester } from '@costco-service-catalog/bff-types';
import GroupsIcon from '@mui/icons-material/Groups';
import { Box, LinearProgress, Tooltip } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import useStyles from './ApplicationListGrid.styles';

interface ApplicationList {
  id: string;
  createdDateTime: string;
  updatedDateTime: string;
  applicationName: string;
  applicationDescription: string;
  requester: Requester;
  hasRunningPipelines: boolean;
  deleteStatus: string;
}

interface ApplicationListGridProps {
  rows: ApplicationList[];
  onRowClick: (param: GridRowParams, event: any) => void;
}

const ApplicationListGrid = (props: ApplicationListGridProps) => {
  const auth = useAuth();

  const { rows, onRowClick } = props;

  const { classes } = useStyles();

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'updatedDateTime',
      sort: 'desc' as GridSortDirection,
    },
  ]);

  const columns: GridColDef[] = [
    {
      field: 'createdDateTime',
      type: 'dateTime',
      valueGetter: ({ value }) => new Date(value),
      headerName: 'Date Submitted',
      flex: 1,
    },
    {
      field: 'updatedDateTime',
      type: 'dateTime',
      valueGetter: ({ value }) => new Date(value),
      headerName: 'Date Updated',
      flex: 1,
    },
    {
      field: 'applicationName',
      headerName: 'Application Name',
      flex: 1,
      renderCell: (params: GridRenderCellParams<ApplicationList>) =>
        params.row.requester.email?.toLowerCase() !==
        auth.user?.profile.email?.toLowerCase() ? (
          <>
            <Tooltip title="Shared">
              <GroupsIcon />
            </Tooltip>
            <span>&nbsp;{params.value}</span>
          </>
        ) : (
          params.value
        ),
    },
    {
      field: 'applicationDescription',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'hasRunningPipelines',
      headerName: 'Build in Progress',
      flex: 1,
      renderCell: (params: GridRenderCellParams<ApplicationList>) => {
        const color = params.row.deleteStatus ? 'error' : 'primary';
        return params.value ? (
          <Box sx={{ width: 100 }}>
            <LinearProgress color={color} />
          </Box>
        ) : (
          <div />
        );
      },
    },
  ];

  return (
    <div className={classes.container}>
      {rows && (
        <DataGrid
          rows={rows || []}
          columns={columns}
          getCellClassName={(params: GridCellParams) => classes.dataCell}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 20]}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          onRowClick={onRowClick}
          pagination
        />
      )}
    </div>
  );
};

export default ApplicationListGrid;
