import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    minHeight: 440,
    height: 'calc(100vh - 11.4rem)',
    width: '100%',
  },
  dataCell: {
    paddingLeft: '1rem!important',
  },
  noResults: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '1em',
    marginTop: 0,
  },
  error: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '1em',
    marginTop: 0,
    color: theme.palette.error.main,
  },
}));

export default useStyles;
