import { gql } from '@apollo/client';

const SETUP_WIZ_INTEGRATION = gql`
  mutation setupWizIntegration($applicationId: ID!) {
    setupWizIntegration(applicationId: $applicationId) {
      code
      status
    }
  }
`;

export default SETUP_WIZ_INTEGRATION;
