import { gql } from '@apollo/client';

const SEARCH_AAD_USERS_QUERY = gql`
  query searchAADUsers($searchString: String) {
    searchAADUsers(searchString: $searchString) {
      code
      count
      response {
        name
        id
        userPrincipalName
      }
    }
  }
`;

export default SEARCH_AAD_USERS_QUERY;
