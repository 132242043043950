import { Alert, Box, LinearProgress } from '@mui/material';
import useHealthCheckPage from './useHealthCheckPage';

const ExerciseLookups = () => {
  const {
    AADUsersError,
    AADUsersLoading,
    AADUsersData,
    offeringsError,
    offeringsLoading,
    offeringsData,
    ManagementGroupsError,
    ManagementGroupsLoading,
    ManagementGroupsData,
    ADOOrgsError,
    ADOOrgsLoading,
    ADOOrgsData,
    ADOProjectsForOrgError,
    ADOProjectsForOrgLoading,
    ADOProjectsForOrgData,
  } = useHealthCheckPage();

  return (
    <Box sx={{ padding: '1rem 2rem' }}>
      <h3>AAD Users</h3>
      {AADUsersLoading && !AADUsersError && <LinearProgress />}
      {AADUsersError && (
        <Alert severity="error">{AADUsersError.toString()}</Alert>
      )}
      {!AADUsersLoading && AADUsersData && !AADUsersError && (
        <Alert severity="success">Lookup Successful!</Alert>
      )}

      <h3>Offerings</h3>
      {offeringsLoading && !offeringsError && <LinearProgress />}
      {offeringsError && (
        <Alert severity="error">{offeringsError.toString()}</Alert>
      )}
      {!offeringsLoading && offeringsData && !offeringsError && (
        <Alert severity="success">Lookup Successful!</Alert>
      )}

      <h3>Management Groups</h3>
      {ManagementGroupsLoading && !ManagementGroupsError && <LinearProgress />}
      {ManagementGroupsError && (
        <Alert severity="error">{ManagementGroupsError.toString()}</Alert>
      )}
      {!ManagementGroupsLoading &&
        ManagementGroupsData &&
        !ManagementGroupsError && (
          <Alert severity="success">Lookup Successful!</Alert>
        )}

      <h3>ADO Orgs</h3>
      {ADOOrgsLoading && !ADOOrgsError && <LinearProgress />}
      {ADOOrgsError && (
        <Alert severity="error">{ADOOrgsError.toString()}</Alert>
      )}
      {!ADOOrgsLoading && ADOOrgsData && !ADOOrgsError && (
        <Alert severity="success">Lookup Successful!</Alert>
      )}

      <h3>ADO Projects</h3>
      {ADOProjectsForOrgLoading && !ADOProjectsForOrgError && (
        <LinearProgress />
      )}
      {ADOProjectsForOrgError && (
        <Alert severity="error">{ADOProjectsForOrgError.toString()}</Alert>
      )}
      {!ADOProjectsForOrgLoading &&
        ADOProjectsForOrgData &&
        !ADOProjectsForOrgError && (
          <Alert severity="success">Lookup Successful!</Alert>
        )}
    </Box>
  );
};

export default ExerciseLookups;
