import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: 'none',
  },
  pageLayout: {
    display: 'flex',
    flexDirection: 'row',
  },
  facetLabel: {
    cursor: 'pointer',
  },
  facetCheckbox: {
    marginRight: '1rem',
  },
  facetList: {
    width: '14rem',
    padding: '1.5rem',
  },
  facetListTitle: {
    fontSize: '0.8175em',
    opacity: 0.5,
    paddingBottom: '.5rem',
  },
  facetRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tileGrid: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '1rem',
  },
  tile: {
    padding: '.5rem',
  },
  tileContent: {
    width: '14rem',
    height: '16rem',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '.25rem',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  tileHeader: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '.5rem',
  },
  tileIcon: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tileIconImage: {
    width: '5rem',
  },
  tileIconButton: {
    border: `1px solid ${theme.palette.divider}`,
  },
  tileTags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    fontWeight: 500,
    fontSize: '0.8175em',
    opacity: '0.75',
  },
  tileTag: {
    textTransform: 'uppercase',
  },
  tileTitle: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  tileLink: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  tileButton: {
    marginTop: '.75rem',
    textTransform: 'capitalize',
    opacity: '0.75',
  },
  tileButtonIcon: {
    marginRight: '.25rem',
  },
  tileButtonText: {
    fontWeight: 500,
    marginBottom: '-.15rem',
  },
  buttonText: {
    marginBottom: '-.15rem',
    marginRight: '.5rem',
  },
  buttonSpacer: {
    width: '1rem',
    height: '1rem',
  },
}));

export default useStyles;
