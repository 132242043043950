import { formValuesType } from '..';
import { CardDetailsType } from '../../types';
import generateApplicationRequest from './generateApplication';

interface createStarterKitProps {
  formValues: formValuesType;
  offerings: CardDetailsType[];
}

const useCreateApplicationRequest = () => {
  const createApplication = (props: createStarterKitProps) => {
    const { formValues, offerings } = props;
    const applicationRequest = generateApplicationRequest(
      formValues,
      offerings
    );

    return { applicationRequest };
  };
  return {
    createApplication,
  };
};

export default useCreateApplicationRequest;
