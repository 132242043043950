import { gql } from '@apollo/client';

const GET_REPO_REFS_QUERY = gql`
  query GetRepoRefs($params: RepoRefsParams) {
    getRepoRefs(params: $params) {
      code
      status
      response {
        repoName
        refs
      }
    }
  }
`;

export default GET_REPO_REFS_QUERY;
