import {
  ApolloQueryResult,
  NetworkStatus,
  OperationVariables,
} from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import { Application } from '@costco-service-catalog/bff-types';

const mockApplications: Application[] = [
  {
    id: '5e162371-67aa-41aa-9024-a888dcb98406',
    applicationName: 'Crosby Avenue Kentucky',
    applicationDescription:
      'quis enim magna reprehenderit mollit ipsum nostrud non nisi adipisicing nisi nisi tempor culpa et sit veniam officia ad enim pariatur minim sint culpa exercitation adipisicing laborum aliquip tempor reprehenderit velit nulla ut voluptate esse Lorem elit aliqua esse dolore exercitation ut commodo est nostrud',
    createdDateTime: '1978-02-18T13:13:22.215Z',
    updatedDateTime: '2003-02-17T10:51:36.907Z',
    lastAwaitingAdoRunRefreshTime: '2018-02-15T05:48:39.834Z',
    hasRunningPipelines: true,
  },
  {
    id: '09b8b250-c882-43a6-b718-2c9602e0107d',
    applicationName: 'Taylor Street Nebraska',
    applicationDescription:
      'labore nulla quis laboris elit duis non dolor cillum laboris elit ipsum aute ullamco amet quis est aliqua ad tempor deserunt non nostrud commodo aliquip eiusmod esse non dolor incididunt duis consequat consectetur aliqua deserunt consectetur reprehenderit et esse duis commodo aute amet reprehenderit do',
    createdDateTime: '1994-08-30T05:53:31.849Z',
    updatedDateTime: '1981-01-23T12:03:30.647Z',
    lastAwaitingAdoRunRefreshTime: '1989-11-29T09:26:18.194Z',
    hasRunningPipelines: false,
  },
  {
    id: '5f9fc7ca-deda-4e87-a199-e607b64d36b7',
    applicationName: 'Kermit Place District Of Columbia',
    applicationDescription:
      'nulla mollit proident Lorem aliqua sunt minim in duis sunt sint amet nisi ullamco voluptate irure fugiat irure magna enim ea consectetur veniam adipisicing sit in nostrud nostrud occaecat nisi enim anim ullamco dolor eu nisi id Lorem laborum non deserunt cillum officia velit veniam',
    createdDateTime: '2014-10-14T13:41:43.561Z',
    updatedDateTime: '1987-05-20T20:52:27.053Z',
    lastAwaitingAdoRunRefreshTime: '2000-04-22T20:25:26.058Z',
    hasRunningPipelines: true,
  },
];

const mockApplicationsRes = () => {
  const error: ApolloError | undefined = undefined;
  const data: Application[] = mockApplications;
  const loading: boolean = false;
  const refetch: (
    variables?: Partial<OperationVariables>
  ) => Promise<ApolloQueryResult<Application[]>> = () =>
    new Promise((resolve) => {
      resolve({
        data: mockApplications,
        loading: false,
        networkStatus: NetworkStatus.ready,
      });
    });
  return {
    error,
    data,
    loading,
    refetch,
  };
};

export default mockApplicationsRes;
