import {
  Application,
  ReferenceArchitecture,
} from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { deployedApplicationHasCompletedAll } from './deployedApplicationValidation';
import extractDeployedRefArchs from './extractDeployedRefArchs';
import validateOfferingDepsAndConflicts from './validateOfferingDepsAndConflicts';

export interface CompatibilityValidationType {
  valid: boolean;
  invalidReason?: string;
  missingDeps?: ReferenceArchitecture[];
  conflicts?: ReferenceArchitecture[];
}
export type ValidateSelectedReturnType = {
  valid: boolean;
  invalidReason: string;
  missingDeps: ReferenceArchitecture[];
  conflicts: ReferenceArchitecture[];
};

export const validateSelected = (
  selectedOfferings: ReferenceArchitecture[],
  selectedUpdates: ReferenceArchitecture[],
  deployedApplication: Application | undefined,
  referenceArchitectures: ReferenceArchitecture[]
): ValidateSelectedReturnType => {
  if (selectedOfferings.length === 0 && selectedUpdates.length === 0) {
    return {
      valid: false,
      invalidReason: 'None selected.',
      missingDeps: [],
      conflicts: [],
    };
  }

  if (deployedApplication) {
    const app = deployedApplicationHasCompletedAll(deployedApplication);
    if (app.completed === false) {
      return {
        valid: false,
        invalidReason: `The deployed application has deployments pending: 
          ${app.notCompleteStarterKits?.map(
            (sk) => `${sk.cartsId}: ${sk.status} `
          )}
          ${app.notCompleteOfferings?.map((o) => `${o.name}: ${o.status} `)}`,
        missingDeps: [],
        conflicts: [],
      };
    }
  }
  const deployedOfferings = extractDeployedRefArchs(
    deployedApplication,
    referenceArchitectures
  );

  const combinedOfferings = [
    ...deployedOfferings.map((refArch) => ({
      ...refArch,
      variant: 'deployed',
    })),
    ...selectedOfferings.map((refArch) => ({
      ...refArch,
      variant: 'selected',
    })),
    ...selectedUpdates.map((refArch) => ({
      ...refArch,
      variant: 'update',
    })),
  ];

  const validateOfferingDepsConflicts = validateOfferingDepsAndConflicts(
    combinedOfferings,
    referenceArchitectures
  );

  if (validateOfferingDepsConflicts.missingDepArray.length > 0) {
    return {
      valid: false,
      invalidReason: `Missing one or more dependency / dependencies: ${validateOfferingDepsConflicts.missingDepArray.map(
        (d) => {
          const offeringName =
            referenceArchitectures.find(
              (refArch) => refArch.shortOfferingName === d.shortOfferingName
            )?.name || d.shortOfferingName;
          return ` ${offeringName} ${
            d.version === '*' ? `` : ` >= ver. ${d.version}`
          }`;
        }
      )}`,
      missingDeps: validateOfferingDepsConflicts.missingDepArray
        .map((d) =>
          referenceArchitectures.find(
            (refArch) => refArch.shortOfferingName === d.shortOfferingName
          )
        )
        .filter((item): item is ReferenceArchitecture => !!item),
      conflicts: [],
    };
  }

  if (validateOfferingDepsConflicts.conflictArray.length > 0) {
    return {
      valid: false,
      invalidReason: `Conflicts with one or more options: ${validateOfferingDepsConflicts.conflictArray.map(
        (d) => {
          const offeringName =
            referenceArchitectures.find(
              (refArch) => refArch.shortOfferingName === d.shortOfferingName
            )?.name || d.shortOfferingName;
          return ` ${offeringName}${
            d.version === '*' ? `` : ` ver. ${d.version}`
          }`;
        }
      )}`,
      missingDeps: [],
      conflicts: validateOfferingDepsConflicts.conflictArray
        .map((d) =>
          referenceArchitectures.find(
            (refArch) => refArch.shortOfferingName === d.shortOfferingName
          )
        )
        .filter((item): item is ReferenceArchitecture => !!item),
    };
  }

  return {
    valid: true,
    invalidReason: '',
    missingDeps: [],
    conflicts: [],
  };
};

const useOfferingMultiValidation = (
  selectedOfferings: ReferenceArchitecture[],
  selectedUpdates: ReferenceArchitecture[],
  deployedApplication: Application | undefined,
  referenceArchitectures: ReferenceArchitecture[]
): CompatibilityValidationType => {
  const offeringSelectedValid = validateSelected(
    selectedOfferings,
    selectedUpdates,
    deployedApplication,
    referenceArchitectures
  );

  return offeringSelectedValid;
};

export default useOfferingMultiValidation;
