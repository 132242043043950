import {
  AadUser,
  SecurityGroupType,
  UserModificationInput,
} from '@costco-service-catalog/bff-types';
import { Button, Paper, Typography } from '@mui/material';
import React from 'react';
import AdminUsersDialog from '../AdminUsersDialog/AdminUsersDialog';
import useStyles from './AdminUsersPaper.styles';

interface AdminUsersPaperProps {
  title: string;
  colTitle: string;
  buttonText: string;
  userLists: AadUser[];
  handleChange: (event: React.ChangeEvent<{}>, value: AadUser[]) => void;
  handleDeleteUser: (
    UserModificationInput: UserModificationInput,
    groupName: string,
    setMemberList: (users: AadUser[]) => void,
    setLoading: (loading: boolean) => void
  ) => void;
  handleAddUsers: (
    applicationId: string,
    setMemberList: (users: AadUser[]) => void,
    setLoading: (loading: boolean) => void,
    groupType: SecurityGroupType,
    groupName: string
  ) => void;
  groupType: SecurityGroupType;
  value: AadUser[];
  helperText: string;
  fullHelpText: string;
  landingZoneStatus: string;
  applicationId: string;
  preUserManagement: boolean;
}

const AdminUsersPaper = (props: AdminUsersPaperProps) => {
  const {
    title,
    colTitle,
    buttonText,
    userLists,
    handleChange,
    handleDeleteUser,
    handleAddUsers,
    groupType,
    value,
    helperText,
    fullHelpText,
    landingZoneStatus,
    applicationId,
    preUserManagement,
  } = props;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Paper
        elevation={3}
        className={` admin-user-paper admin-user-paper-${title
          .toLowerCase()
          .replace(/ /g, '-')} ${classes.adminUsersPaper}`}
      >
        <Typography
          variant="h6"
          component="p"
          className={classes.adminUsersPaperTitle}
        >
          {title}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          className={` admin-user-button admin-user-button-${title
            .toLowerCase()
            .replace(/ /g, '-')} ${classes.adminUsersPaperButton}`}
        >
          {buttonText}
        </Button>
      </Paper>
      <AdminUsersDialog
        title={title}
        userLists={userLists}
        open={open}
        colTitle={colTitle}
        handleClose={handleClose}
        handleAddUsers={handleAddUsers}
        handleUserChange={handleChange}
        handleDeleteUser={handleDeleteUser}
        groupType={groupType}
        helperText={helperText}
        fullHelpText={fullHelpText}
        value={value}
        landingZoneStatus={landingZoneStatus}
        applicationId={applicationId}
        preUserManagement={preUserManagement}
      />
    </div>
  );
};

export default AdminUsersPaper;
