import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Badge,
  Box,
  IconButton,
  AppBar as MuiAppBar,
  Toolbar,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';
import {
  globalState,
  initialState,
  useGlobalState,
} from '../../globalState/useGlobalState';
import { useSessionStorage } from '../../hooks';
import useStyles from './AppBar.styles';
import LOGO from './logo.svg';

interface AppBarProps {
  handleAppDrawerClick: () => void;
  handleAppHistoryDrawerClick: () => void;
}

const AppBar = (props: AppBarProps) => {
  const { handleAppDrawerClick, handleAppHistoryDrawerClick } = props;

  const { appSettings } = useGlobalState();
  const pingBaseUrl = appSettings.pingBaseUrl.get();
  const allowHistoryDrawer = appSettings.allowHistoryDrawer.get();

  const sessionStorageHooks = useSessionStorage();
  const appHistoryUnreadCount = sessionStorageHooks.getAppHistoryUnreadCount();

  const navigate = useNavigate();

  const appTitle = 'Service Catalog';

  const { classes } = useStyles();

  const handleSignOut = () => {
    globalState.set(initialState);
    window.open(`${pingBaseUrl}/idp/startSLO.ping`);
    window.location.replace(ROUTES.SIGNIN);
  };

  const handleAccessDenied = () => {
    navigate(
      `${ROUTES.ERROR}/${encodeURIComponent(
        'Forbidden: Access Denied - User does not have access to this application.'
      )}`,
      { replace: true }
    );
  };

  React.useEffect(() => {
    const authTokenExpiredHandler = () => handleSignOut();
    document.addEventListener('auth-token-expired', authTokenExpiredHandler);
    return () => {
      document.removeEventListener(
        'auth-token-expired',
        authTokenExpiredHandler
      );
    };
  });

  React.useEffect(() => {
    const accessDeniedHandler = () => handleAccessDenied();
    document.addEventListener('access-denied', accessDeniedHandler);
    return () => {
      document.removeEventListener('access-denied', accessDeniedHandler);
    };
  });

  return (
    <MuiAppBar
      color="primary"
      position="fixed"
      className={classes.appBar}
      enableColorOnDark
    >
      <Toolbar className={classes.toolBar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => handleAppDrawerClick()}
          title="Expand / Collapse Menu"
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Box className={classes.pageLogo}>
          <img alt="Logo" src={LOGO} className={classes.appIcon} />
        </Box>
        <h1 className={classes.appTitle} data-test-id="appTitle">
          {appTitle}
        </h1>
        <div className={classes.fOne} />
        {allowHistoryDrawer && (
          <IconButton
            color="inherit"
            aria-label="Open history drawer"
            edge="start"
            onClick={() => handleAppHistoryDrawerClick()}
            title="Expand / Collapse History Drawer"
            size="large"
          >
            <Badge badgeContent={appHistoryUnreadCount} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        )}
        <IconButton
          color="inherit"
          onClick={handleSignOut}
          data-test-id="sign-out-button"
          title="Sign Out"
          size="large"
        >
          <ExitToAppIcon />
        </IconButton>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
