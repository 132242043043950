import {
  AddToApplicationRequestInput,
  Application,
  StarterKitEnvironment,
} from '@costco-service-catalog/bff-types';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES, ENVIRONMENTS_NAMES } from '../../../../constants';
import { useGlobalState } from '../../../../globalState/useGlobalState';
import {
  useEnqueueSnackbar,
  useListApplications,
  useSubmitAddToApplication,
} from '../../../../hooks';
import { utilitiesPayloadType } from '../../../../pages/ApplicationSummaryPage/useApplicationSummaryPage';
import addEnvironmentValidationSchema from './addEnvironmentValidationSchema';

const initialProdEnvironmentsNames = [
  ...ENVIRONMENTS_NAMES.PROD_ENVIRONMENTS_NAMES,
];
const initialNonProdEnvironmentsNames = [
  ...ENVIRONMENTS_NAMES.NON_PROD_ENVIRONMENTS_NAMES,
];

type defaultFormValuesType = {
  environmentName?: string;
  selectedApplication?: Application;
  selectedEnvironment?: StarterKitEnvironment;
  selectedCidr?: string;
};

const useAddEnvironmentModal = (
  handleModalClose: () => void,
  popup: utilitiesPayloadType | undefined
) => {
  const [prodEnvironmentsNames, setProdEnvironmentsNames] = useState(
    initialProdEnvironmentsNames
  );
  const [nonProdEnvironmentsNames, setNonProdEnvironmentsNames] = useState(
    initialNonProdEnvironmentsNames
  );

  const { appSettings } = useGlobalState();
  const navigate = useNavigate();
  const { submitAddToApplication } = useSubmitAddToApplication();
  const { pushSnackbar } = useEnqueueSnackbar();
  const { data, loading: listApplicationsLoading } = useListApplications();

  const [initialEnvironments, setInitialEnvironments] = useState<
    StarterKitEnvironment[]
  >([]);

  const [isValidEnvironmentName, setIsValidEnvironmentName] = useState(false);

  const initialApp = popup ? popup.data : undefined;

  const listApplicationsData = data.filter((x) => {
    if (x.isEphemeral || x.hasRunningPipelines || !x.starterKitRequests) {
      return false;
    }
    const landingZone = x.starterKitRequests
      .map(
        (starterKit) =>
          starterKit?.offerings?.find(
            (offering) => offering?.category === 'LandingZone'
          )
      )
      .filter((offering) => offering?.category === 'LandingZone');
    return landingZone && landingZone[0]?.status === 'Completed';
  });

  const getAvailableCidrBlockSizes = () => {
    const cidrBlockSizes = appSettings.cidrBlockSizes.get();
    if (typeof cidrBlockSizes === 'string' && cidrBlockSizes.length > 0) {
      return cidrBlockSizes.split(',').map((r) => r.trim());
    }
    return [];
  };

  const validateNewEnvironmentName = (
    name: string | undefined,
    initialEnvironmentList: StarterKitEnvironment[]
  ) => {
    const envNames = initialEnvironmentList.map((env) => env.name);
    if (!envNames.includes(name) && name?.length === 3) {
      setIsValidEnvironmentName(true);
      return true;
    }
    setIsValidEnvironmentName(false);
    return false;
  };

  const formik = useFormik({
    initialValues: { selectedApplication: initialApp },
    validationSchema: addEnvironmentValidationSchema(isValidEnvironmentName),
    onSubmit: async (values: defaultFormValuesType) => {
      const submitAddToApplicationInput: AddToApplicationRequestInput = {
        applicationId: values.selectedApplication?.id || '',
        offeringRequests: [
          {
            additionalParams: [
              {
                key: 'environmentName',
                value: values.environmentName || '',
              },
              {
                key: 'environmentType',
                value: values.selectedEnvironment?.type || '',
              },
              {
                key: 'cidr',
                value: values.selectedCidr || '',
              },
            ],
            category: 'Utility',
            offeringName: 'Add Environment',
            offeringShortName: 'addenv',
            offeringVersion: 'na',
          },
        ],
      };
      const submitOptions = {
        variables: { input: submitAddToApplicationInput },
        onCompleted: () => {},
      };
      const submitAddToApplicationRes = await submitAddToApplication(
        submitOptions
      );

      if (
        !submitAddToApplicationRes.errors ||
        submitAddToApplicationRes.errors?.length === 0
      ) {
        pushSnackbar('Add Environment request has been submitted.', {
          variant: 'success',
        });
        handleModalClose();
        navigate(
          `${ROUTES.APPLICATION_SUMMARY}/${
            values.selectedApplication?.id || ''
          }`
        );
      }
    },
  });

  const selectApplicationFieldError = formik.touched.selectedApplication
    ? Boolean(formik.errors.selectedApplication)
    : false;
  const selectedEnvironmentFieldError = formik.touched.selectedEnvironment
    ? Boolean(formik.errors.selectedEnvironment)
    : false;
  const environmentNameFieldError = formik.touched.environmentName
    ? Boolean(formik.errors.environmentName)
    : false;
  const selectedCidrBlockFieldError = formik.touched.selectedCidr
    ? Boolean(formik.errors.selectedCidr)
    : false;

  const resetApplicationEnvironments = () => {
    const envs = formik.values.selectedApplication?.environments?.filter(
      (item): item is StarterKitEnvironment => !!item && !item.notReady
    );

    if (envs) {
      const validNonProdEnvironmentsNames = nonProdEnvironmentsNames.filter(
        (name) => validateNewEnvironmentName(name, envs)
      );
      const validProdEnvironmentsNames = prodEnvironmentsNames.filter((name) =>
        validateNewEnvironmentName(name, envs)
      );
      setInitialEnvironments([...envs]);
      setProdEnvironmentsNames([...validProdEnvironmentsNames]);
      setNonProdEnvironmentsNames([...validNonProdEnvironmentsNames]);
    }
  };

  // SELECTED APPLICATION
  useEffect(() => {
    const { selectedApplication } = formik.values;
    if (selectedApplication) {
      resetApplicationEnvironments();
    }
  }, [formik.values.selectedApplication]);

  // VALUES
  useEffect(() => {
    if (formik.values) {
      const { environmentName, selectedEnvironment, selectedCidr } =
        formik.values;
      const isValid = validateNewEnvironmentName(
        environmentName,
        initialEnvironments
      );
      if (
        environmentName?.length === 3 &&
        isValid &&
        selectedEnvironment &&
        selectedCidr
      ) {
        resetApplicationEnvironments();
      }
    }
  }, [formik.values]);

  return {
    formik,
    environmentNameFieldError,
    listApplicationsData,
    listApplicationsLoading,
    selectApplicationFieldError,
    selectedEnvironmentFieldError,
    availableCidrBlockSizes: getAvailableCidrBlockSizes(),
    selectedCidrBlockFieldError,
    prodEnvironmentsNames,
    nonProdEnvironmentsNames,
  };
};

export default useAddEnvironmentModal;
