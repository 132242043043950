import { AddCircleOutline } from '@mui/icons-material';
import { Card, CardContent, Typography, useTheme } from '@mui/material';
import useStyles from './OfferingCardMulti.styles';

interface BuildNewApplicationCardProps {
  multiselectMode: '' | 'new' | 'existing';
  handleSetMultiselectMode: (mode: '' | 'new' | 'existing') => void;
}

const BuildNewApplicationCard = (props: BuildNewApplicationCardProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const { multiselectMode, handleSetMultiselectMode } = props;
  return (
    <div className={classes.cardContainer}>
      <Card
        data-testid="BuildNewApplicationCard Card Component"
        className={
          multiselectMode === 'new'
            ? classes.prevDeployedCardSelected
            : classes.prevDeployedCardNotSelected
        }
        onClick={() =>
          multiselectMode === 'new'
            ? handleSetMultiselectMode('')
            : handleSetMultiselectMode('new')
        }
      >
        <CardContent className={classes.cardPlusContainer}>
          <div className={classes.cardPlusContainerCentered}>
            <Typography
              variant="h6"
              fontWeight="normal"
              textAlign="center"
              paddingBottom={2}
              paddingX={5}
            >
              Build a New Application
            </Typography>
            <AddCircleOutline
              fontSize="large"
              htmlColor={
                !(theme.palette.mode === 'light') ? '#c6dbef' : '#4a7093'
              }
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default BuildNewApplicationCard;
