import { StarterKitOffering } from '@costco-service-catalog/bff-types';
import StatusChip from '../StatusChip/StatusChip';
import BuildStatusItem from './BuildStatusItem';

interface BuildStatusProps {
  offering: StarterKitOffering | null;
}

const BuildStatus = (props: BuildStatusProps) => {
  const { offering } = props;

  if (
    !offering ||
    !offering.adoBuildStatus ||
    !offering.adoBuildStatus.items ||
    offering.status !== 'AwaitingAdoRun'
  ) {
    return <StatusChip label={offering?.status as string} />;
  }

  return (
    <div>
      {offering.adoBuildStatus.items
        .filter((i) => i?.result !== 'skipped')
        .map((i) => (
          <BuildStatusItem key={i?.id} item={i} />
        ))}
    </div>
  );
};

export default BuildStatus;
