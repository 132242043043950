import {
  EphemeralRg,
  ReferenceArchitecture,
} from '@costco-service-catalog/bff-types';
import { Delete, MoreTime } from '@mui/icons-material';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import useStyles from './ExistingEphemeralResourceGroupsList.styles';
import useExistingEphemeralResourceGroupsList from './useExistingEphemeralResourceGroupsList';
import DeleteErgModal from './ExistingModalOptions/DeleteErgModal';
import ExtendErgModal from './ExistingModalOptions/ExtendErgModal';

type ExistingEphemeralResourceGroupsListPropsType = {
  existingEphemeralResourceGroups: EphemeralRg[];
  offeringsData: ReferenceArchitecture[];
  runEphemeralRgsRefetch: () => Promise<void>;
};

const ExistingEphemeralResourceGroupsList = (
  props: ExistingEphemeralResourceGroupsListPropsType
) => {
  const {
    existingEphemeralResourceGroups,
    offeringsData,
    runEphemeralRgsRefetch,
  } = props;
  const { classes } = useStyles();
  const theme = useTheme();
  const {
    subheaderList,
    deleteErgModalOpen,
    handleDeleteErgModalClose,
    handleDeleteErgModalOpen,
    extendErgModalOpen,
    handleExtendErgModalClose,
    handleExtendErgModalOpen,
    selectedErg,
    selectedOfferingName,
    extensionDate,
    handleChangeExtensionDate,
    dates,
    handleDeleteConfirmation,
    handleExtendConfirmation,
    deleteTooltipText,
    isDeleteDisabled,
    extendedTooltipText,
    isExtendDisabled,
  } = useExistingEphemeralResourceGroupsList(
    offeringsData,
    runEphemeralRgsRefetch
  );

  return (
    <Box className={classes.root}>
      <DeleteErgModal
        open={deleteErgModalOpen}
        handleClose={handleDeleteErgModalClose}
        offeringName={selectedOfferingName}
        ephemeralApp={selectedErg}
        handleDeleteConfirmation={handleDeleteConfirmation}
      />
      <ExtendErgModal
        open={extendErgModalOpen}
        handleClose={handleExtendErgModalClose}
        offeringName={selectedOfferingName}
        extensionDate={extensionDate}
        handleChangeExtensionDate={handleChangeExtensionDate}
        ephemeralApp={selectedErg}
        dates={dates}
        handleExtendConfirmation={handleExtendConfirmation}
      />
      <List subheader={<li />} className={classes.list}>
        {subheaderList.map((offeringSectionHeader) => (
          <li key={`section-${offeringSectionHeader.id}`}>
            <ul className={classes.ul}>
              <ListSubheader className={classes.listSubheader}>
                {offeringSectionHeader.name}
              </ListSubheader>

              {existingEphemeralResourceGroups
                .filter(
                  (erg) =>
                    erg.offeringShortName ===
                    offeringSectionHeader.shortOfferingName
                )
                .sort((a, b) => {
                  if (a.createdDateTime && b.createdDateTime) {
                    return (
                      new Date(b.createdDateTime).getTime() -
                      new Date(a.createdDateTime).getTime()
                    );
                  }
                  return 0;
                })
                .map((erg, idx) => (
                  <ListItem
                    sx={{
                      display: 'flex',
                    }}
                    key={`item-${offeringSectionHeader.id}-${erg.id}`}
                  >
                    <Box
                      className={classes.listItemContainer}
                      sx={{
                        backgroundColor:
                          idx % 2 ? undefined : 'rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <Box className={classes.listItem}>
                        <ListItemText
                          primary={
                            <Box>
                              <Typography>
                                {`User Story #${erg.storyId}`}
                              </Typography>
                              <Typography variant="subtitle2">
                                Status: {`${erg.status}`}
                              </Typography>
                            </Box>
                          }
                          secondaryTypographyProps={{ component: 'div' }}
                          secondary={
                            <Box component="div">
                              <Typography component="p">
                                {`Created at 
                                ${
                                  erg.createdDateTime
                                    ? new Date(
                                        erg.createdDateTime
                                      ).toLocaleString()
                                    : undefined
                                }`}
                              </Typography>
                              <Typography component="p">
                                {`Expires on 
                                ${
                                  erg.expirationDateTime
                                    ? new Date(
                                        erg.expirationDateTime
                                      ).toLocaleDateString()
                                    : undefined
                                }`}
                              </Typography>
                            </Box>
                          }
                        />
                        <Box>
                          <Tooltip title={extendedTooltipText(erg)}>
                            <span>
                              <IconButton
                                disabled={isExtendDisabled(erg)}
                                onClick={() =>
                                  handleExtendErgModalOpen(
                                    offeringSectionHeader.name,
                                    erg
                                  )
                                }
                              >
                                <MoreTime />
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title={deleteTooltipText(erg)}>
                            <span>
                              <IconButton
                                disabled={isDeleteDisabled(erg)}
                                onClick={() =>
                                  handleDeleteErgModalOpen(
                                    offeringSectionHeader.name,
                                    erg
                                  )
                                }
                              >
                                <Delete />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                ))}
            </ul>
          </li>
        ))}
      </List>
    </Box>
  );
};

export default ExistingEphemeralResourceGroupsList;
