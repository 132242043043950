import { AadUser } from '@costco-service-catalog/bff-types';
import { CommonNonZUsersAutoComplete } from '../CommonFields';

interface ADOProjectContributorsProps {
  value: AadUser[];
  onChangeHandler: (event: React.ChangeEvent<{}>, value: AadUser[]) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const ADOProjectContributors = (props: ADOProjectContributorsProps) => {
  const { value, onChangeHandler, onBlurHandler } = props;

  return (
    <CommonNonZUsersAutoComplete
      componentID="adoProjectContributors"
      label="ADO Project Contributors"
      value={value}
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      fieldError={false}
      helperText="Select from the list or enter a user name and hit the Enter key to add user as ADO contributor."
      fullHelpText="Project Contributors create / manage work items, perform code changes, open pull requests, and run pipelines, among other tasks. Most users in your project will be Project Contributors."
    />
  );
};

export default ADOProjectContributors;
