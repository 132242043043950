import { History } from '@mui/icons-material';
import { ECPOnboardingListGrid, PageHeader } from '../../components';
import useStyles from './ECPOnboardingListPage.styles';
import useECPOnboardingListPage from './useECPOnboardingListPage';

const ECPOnboardingListPage = () => {
  const { classes } = useStyles();

  const { title, data, loading, count } = useECPOnboardingListPage();

  const formatCount = (count: any) => {
    let formattedCount = count;
    if (formattedCount === undefined) {
      formattedCount = 0;
    }
    return formattedCount;
  };

  return (
    <div className={classes.root}>
      <PageHeader
        icon={<History />}
        title={title}
        subtitle={`Total: ${loading ? '...' : formatCount(count)}`}
      />
      <div>
        <ECPOnboardingListGrid rows={data} />
      </div>
    </div>
  );
};

export default ECPOnboardingListPage;
