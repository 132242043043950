import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import useSupportGroupEditModal from './useSupportGroupEditModal';
import CommonFormTextField from '../FormComponents/CommonFields/CommonFormTextField';

interface SupportGroupEditModalPropsType {
  openEditSupportGrpEmailModal: boolean;
  handleCloseEditSupportGrpEmailModal: () => void;
  applicationId: string;
  handleApplicationSummaryRefresh: () => void;
}

const SupportGroupEditModal = (props: SupportGroupEditModalPropsType) => {
  const {
    openEditSupportGrpEmailModal,
    handleCloseEditSupportGrpEmailModal,
    applicationId,
    handleApplicationSummaryRefresh,
  } = props;

  const { formik, supportGroupEmailError } = useSupportGroupEditModal(
    handleCloseEditSupportGrpEmailModal,
    applicationId,
    handleApplicationSummaryRefresh
  );

  const { supportGroupEmail } = formik.values;

  return (
    <Dialog
      onClose={() => {
        handleCloseEditSupportGrpEmailModal();
        formik.resetForm();
      }}
      open={openEditSupportGrpEmailModal}
      fullWidth
      maxWidth="sm"
    >
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            <Typography variant="h6" component="p">
              Update Support Group email
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box>
              <CommonFormTextField
                componentID="supportGroupEmail"
                label="Support Group Email"
                value={supportGroupEmail || ''}
                onChangeHandler={formik.handleChange}
                onBlurHandler={formik.handleBlur}
                fieldError={supportGroupEmailError}
                helperText={
                  supportGroupEmailError && formik.errors.supportGroupEmail
                    ? formik.errors.supportGroupEmail
                    : 'Enter new support group email.'
                }
                fullHelpText="Please enter the new support group email."
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={
                !(formik.isValid && formik.dirty) || formik.isSubmitting
              }
              variant="contained"
              type="submit"
              endIcon={
                formik.isSubmitting ? (
                  <CircularProgress size="14px" sx={{ marginLeft: '0.5rem' }} />
                ) : undefined
              }
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

export default SupportGroupEditModal;
