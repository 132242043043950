import {
  DependencyBaseSchema,
  DependencyInfoSchema,
  ReferenceArchitecture,
} from '@costco-service-catalog/bff-types';
import { compare } from 'compare-versions';
import _ from 'lodash';
import { sortRefArchByTier } from './sortRefArchByTier';

interface CheckMissingDependenciesReturnType extends DependencyInfoSchema {
  matched: boolean;
}

type ValidateOfferingDepsAndConflictsReturnType = {
  missingDepArray: CheckMissingDependenciesReturnType[];
  conflictArray: DependencyBaseSchema[];
};

interface ValidateOfferingDepsAndConflictsInput extends ReferenceArchitecture {
  variant: string;
}

const validateOfferingDepsAndConflicts = (
  combinedOfferings: ValidateOfferingDepsAndConflictsInput[],
  referenceArchitectures: ReferenceArchitecture[]
): ValidateOfferingDepsAndConflictsReturnType => {
  const availableOfferings: DependencyInfoSchema[] = combinedOfferings
    .sort((a, b) => sortRefArchByTier(a, b))
    .map((o) => ({
      shortOfferingName: o.shortOfferingName,
      version: o.version,
    }));
  let missingDependenciesArray: CheckMissingDependenciesReturnType[] = [];

  const checkMissingDependencies = (
    missingDepAccumulator: CheckMissingDependenciesReturnType[],
    offeringArg1?: ReferenceArchitecture
  ) => {
    missingDependenciesArray = missingDepAccumulator;

    if (offeringArg1) {
      const { dependencies } = offeringArg1;

      dependencies?.map((dependency) => {
        const findMatchedDep = (availableDependency: DependencyInfoSchema) => {
          const matchedDepEvaluator = (
            dependency: DependencyInfoSchema | DependencyBaseSchema | null,
            availableDependency: DependencyInfoSchema
          ) =>
            dependency?.shortOfferingName ===
              availableDependency.shortOfferingName &&
            (dependency?.version === '*' ||
              compare(
                availableDependency.version || '',
                dependency?.version || '',
                '>='
              ));

          const isValidAlt = (dep: boolean | undefined): dep is boolean =>
            !!dep;

          const primaryMatch = matchedDepEvaluator(
            dependency,
            availableDependency
          );

          const altMatch = dependency?.alternatives
            ?.map((alt) => matchedDepEvaluator(alt, availableDependency))
            .filter(isValidAlt);

          if (primaryMatch) {
            return primaryMatch;
          }
          if (altMatch) {
            const matchAltMatch = altMatch.find((m) => m === true) || false;
            return matchAltMatch;
          }
          return false;
        };

        const matchedDep = availableOfferings.find(findMatchedDep);
        const recursiveRefArchDependency = referenceArchitectures.find(
          (rf) => rf.shortOfferingName === dependency?.shortOfferingName
        );

        const recursiveMissingDepArray = [
          ...missingDepAccumulator,
          { ...dependency, matched: Boolean(matchedDep) },
        ];

        const arr = checkMissingDependencies(
          recursiveMissingDepArray,
          recursiveRefArchDependency
        );
        return arr;
      });
      return missingDepAccumulator;
    }
    return missingDepAccumulator;
  };

  combinedOfferings.forEach((offering) => {
    checkMissingDependencies([], offering);
  });

  const checkForConflicts = () => {
    const offeringConflictsWith = combinedOfferings.flatMap((o) => o.conflicts);
    const conflictsCheckCombinedOfferings = combinedOfferings.map((o) => {
      const potentialConflict = offeringConflictsWith.find(
        (c) => c?.shortOfferingName === o.shortOfferingName
      );
      return {
        shortOfferingName: o.shortOfferingName,
        version: potentialConflict?.version === '*' ? '*' : o.version,
      };
    });
    const conflictIntersect = _.intersectionWith(
      conflictsCheckCombinedOfferings,
      offeringConflictsWith,
      _.isEqual
    );

    return conflictIntersect;
  };

  const conflictArray = checkForConflicts();
  const missingDepArray = missingDependenciesArray.filter(
    (dep) => dep.matched !== true
  );

  return {
    missingDepArray,
    conflictArray,
  };
};

export default validateOfferingDepsAndConflicts;
