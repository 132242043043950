import { Application } from '@costco-service-catalog/bff-types';
import { CheckBox, HistoryOutlined } from '@mui/icons-material';
import {
  Card,
  CardContent,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect } from 'react';
import { useListApplications } from '../../hooks';
import useStyles from './OfferingCardMulti.styles';

interface SelectPrevDeployedCardProps {
  deployedApplication: Application | undefined;
  handleSetDeployedApplication: (starterKit: Application | undefined) => void;
  selectedPreviouslyDeployed: string;
  handlePreviouslyDeployedSelect: (value: string) => void;
  handlePreviouslyDeployedOpen: () => void;
  multiselectMode: '' | 'new' | 'existing';

  unselectAutopopulate: () => boolean;
}

const DisplaySK = (props: { deployedApplication: Application }) => {
  const { deployedApplication } = props;

  const { classes } = useStyles();

  return (
    <div>
      <Typography>
        <strong>Id: </strong>
        {deployedApplication.id}
      </Typography>
      <div className={classes.vspacer} />
      <Typography>
        <strong>Description: </strong>
        {deployedApplication.applicationDescription}
      </Typography>
    </div>
  );
};

const SelectPrevDeployedCard = (props: SelectPrevDeployedCardProps) => {
  const {
    deployedApplication,
    handleSetDeployedApplication,
    selectedPreviouslyDeployed,
    handlePreviouslyDeployedSelect,
    handlePreviouslyDeployedOpen,
    multiselectMode,

    unselectAutopopulate,
  } = props;

  const { data } = useListApplications();

  const { classes } = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if (selectedPreviouslyDeployed) {
      const sk = data.find((s) => s.id === selectedPreviouslyDeployed);
      if (sk) {
        handleSetDeployedApplication(sk);
      } else {
        handleSetDeployedApplication(undefined);
      }
    } else {
      handleSetDeployedApplication(undefined);
    }
  }, [selectedPreviouslyDeployed]);

  const evalClassName = () => {
    if (multiselectMode === 'existing') {
      return classes.prevDeployedCardSelected;
    }
    return classes.prevDeployedCardNotSelected;
  };

  return (
    <div className={classes.cardContainer}>
      <Card
        data-testid="SelectPrevDeployedCard Card Component"
        className={evalClassName()}
        onClick={() => {
          const autopopulated = unselectAutopopulate();
          if (!autopopulated) {
            handlePreviouslyDeployedOpen();
          }
        }}
      >
        {deployedApplication ? (
          <CardContent className={classes.cardTitleContainer}>
            <div className={classes.cardTitlePlusCard}>
              {deployedApplication.applicationName ||
                'Deployed Application Selected'}
              <div className={classes.cardSelectBox}>
                <IconButton
                  onClick={(e) => {
                    unselectAutopopulate();
                    handlePreviouslyDeployedSelect('');
                    e.stopPropagation();
                  }}
                  size="small"
                >
                  <CheckBox data-testid="offeringSelectedCheckBox-selected" />
                </IconButton>
              </div>
            </div>
          </CardContent>
        ) : undefined}
        <CardContent className={classes.cardPlusContainer}>
          {deployedApplication ? (
            <DisplaySK deployedApplication={deployedApplication} />
          ) : (
            <div className={classes.cardPlusContainerCentered}>
              <Typography
                variant="h6"
                fontWeight="normal"
                textAlign="center"
                paddingBottom={2}
                paddingX={5}
              >
                Click this card to select from previously deployed applications.{' '}
              </Typography>

              <HistoryOutlined
                fontSize="large"
                htmlColor={
                  !(theme.palette.mode === 'light') ? '#c6dbef' : '#4a7093'
                }
              />
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default SelectPrevDeployedCard;
