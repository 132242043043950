import { CommonFormTextField } from '../CommonFields';

interface SupportGroupEmailProps {
  value: string;
  fieldError: boolean;
  errorText?: string;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const SupportGroupEmail = (props: SupportGroupEmailProps) => {
  const { value, fieldError, errorText, onChangeHandler, onBlurHandler } =
    props;

  return (
    <CommonFormTextField
      componentID="supportGroupEmail"
      label="Support Group Email"
      value={value}
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      fieldError={fieldError}
      helperText={
        fieldError && errorText
          ? errorText
          : 'The email alias that should be contacted when there is an issue with resources related to your project.'
      }
      fullHelpText="This email address will be contacted by the incident response team when one of your production environments is down, or if there is another issue that needs to be resolved with any of your resources."
    />
  );
};

SupportGroupEmail.defaultProps = {
  errorText: undefined,
};

export default SupportGroupEmail;
