import { compareVersions } from 'compare-versions';
import { Application } from '@costco-service-catalog/bff-types';
import { Add, InfoOutlined, Remove } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  Divider,
  Fade,
  List,
  ListItem,
  ListItemText,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';

import usePolicyModal from './usePolicyModal';

// 1) hide the v. when no policies have been applied.
// 2) strip refs/tags or refs/head from the start of the version

interface PolicyModalPropsType {
  application: Application | undefined;
  policyModalData: {
    environmentType: string;
    enabledPolicies: string[];
    policiesVersion: string;
  };
  openPolicyModal: boolean;
  handleClosePolicyModal: () => void;
}

const PolicyModal = (props: PolicyModalPropsType) => {
  const {
    application,
    policyModalData,
    openPolicyModal,
    handleClosePolicyModal,
  } = props;

  const {
    azurePolicyList,
    addedPolicies,
    removedPolicies,
    policyKeyList,
    submitPolicyChangeRequestLoading,
    handleChangePolicySelection,
    handleSubmitPolicyChangeRequest,
    handleChangePolicyMode,
    currentPolicyFactoryPipelineVersion,
  } = usePolicyModal(
    policyModalData,
    openPolicyModal,
    application,
    handleClosePolicyModal
  );

  return (
    <Dialog
      open={openPolicyModal}
      onClose={() => handleClosePolicyModal()}
      fullWidth
      maxWidth="xl"
    >
      <Box sx={{ paddingY: '2rem', paddingX: '4rem' }}>
        {application && (
          <>
            <Box>
              <Typography fontSize="1.75rem">
                {application.applicationName}
              </Typography>
              <Typography>
                Policy information for all{' '}
                {policyModalData?.environmentType === 'pd'
                  ? 'production'
                  : 'non-production'}{' '}
                environments:
              </Typography>
              <Divider sx={{ marginTop: '0.5rem', marginRight: '4rem' }} />
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flex: 1, padding: '1rem' }}>
                <Typography>
                  <b>Applied Policy List:</b>
                </Typography>
                {policyModalData?.policiesVersion ? (
                  <Typography fontSize="0.8rem">
                    (v{policyModalData.policiesVersion})
                  </Typography>
                ) : (
                  <Box paddingTop="0.8rem" />
                )}

                <Box paddingTop="0.25rem">
                  <List
                    disablePadding
                    sx={{
                      maxHeight: '25rem',
                      overflowY: 'auto',
                    }}
                  >
                    {azurePolicyList.map((azurePolicy) => (
                      <ListItem
                        key={azurePolicy.key}
                        disablePadding
                        disableGutters
                      >
                        <Box
                          sx={{
                            flex: 1,
                            display: 'flex',
                            flexdirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Box>
                            <Checkbox
                              onClick={() =>
                                handleChangePolicySelection(
                                  azurePolicy?.key || ''
                                )
                              }
                              checked={Boolean(
                                policyKeyList.find((p) =>
                                  p.includes(azurePolicy.key || '')
                                )
                              )}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{
                                'aria-labelledby': `${azurePolicy.key}-checkbox-input`,
                              }}
                            />
                          </Box>
                          <Box flex="1">
                            <ListItemText
                              id={azurePolicy?.key || ''}
                              primary={azurePolicy?.displayName}
                            />
                          </Box>

                          <Box>
                            <Fade
                              in={Boolean(
                                policyKeyList.find((p) =>
                                  p.includes(azurePolicy.key || '')
                                )
                              )}
                            >
                              <Box
                                sx={{
                                  paddingX: '1rem',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <Typography variant="body2">Audit</Typography>
                                <Switch
                                  checked={Boolean(
                                    policyKeyList
                                      .find((p) =>
                                        p.includes(azurePolicy.key || '')
                                      )
                                      ?.includes('Deny')
                                  )}
                                  onClick={() => {
                                    handleChangePolicyMode(
                                      azurePolicy.key || ''
                                    );
                                  }}
                                />
                                <Typography variant="body2">Deny</Typography>
                              </Box>
                            </Fade>
                          </Box>
                          <Box>
                            <Tooltip
                              title={azurePolicy.description}
                              placement="top-start"
                            >
                              <span>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginRight: '0.5rem',
                                  }}
                                >
                                  <InfoOutlined />
                                </Box>
                              </span>
                            </Tooltip>
                          </Box>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
              <Box sx={{ flex: 1, padding: '1rem' }}>
                <Typography fontWeight="bold">Pending Changes:</Typography>
                <Box paddingTop="0.8rem" />
                <Box paddingTop="0.25rem">
                  <Box
                    sx={{
                      maxHeight: '25rem',
                      overflowY: 'auto',
                    }}
                  >
                    <Box>
                      {addedPolicies.length === 0 &&
                      removedPolicies.length === 0 &&
                      currentPolicyFactoryPipelineVersion &&
                      policyModalData.policiesVersion &&
                      compareVersions(
                        currentPolicyFactoryPipelineVersion,
                        policyModalData.policiesVersion
                      ) === 1 ? (
                        <Box>
                          <Box paddingTop="1rem" paddingBottom="0.5rem">
                            <Button
                              data-testid="resubmit-button"
                              variant="outlined"
                              onClick={() => handleSubmitPolicyChangeRequest()}
                              disabled={submitPolicyChangeRequestLoading}
                              endIcon={
                                submitPolicyChangeRequestLoading ? (
                                  <CircularProgress size="1rem" />
                                ) : undefined
                              }
                            >
                              Re-submit with latest Policy Factory version (
                              {currentPolicyFactoryPipelineVersion})
                            </Button>
                          </Box>
                          <Typography variant="body2">
                            Use the button above to reapply all of the currently
                            applied policies for the entire application. Be
                            aware that this action will update all policies to
                            latest ({currentPolicyFactoryPipelineVersion}) for{' '}
                            <b>all</b> Subscriptions.
                          </Typography>
                        </Box>
                      ) : (
                        <Box>
                          {addedPolicies.length === 0 &&
                          removedPolicies.length === 0 ? (
                            <Typography>
                              No pending changes to apply to this environment.
                            </Typography>
                          ) : undefined}
                        </Box>
                      )}
                    </Box>
                    <Box>
                      {removedPolicies.map((added) => (
                        <Box key={added}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}
                          >
                            <Remove color="error" />
                            <Typography
                              sx={{ paddingX: '0.5rem', paddingY: '0.1rem' }}
                            >
                              {azurePolicyList
                                .find((v) => added.includes(v.key || ''))
                                ?.displayName?.concat(
                                  added?.includes('Audit')
                                    ? ' (Audit)'
                                    : ' (Deny)'
                                )}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                    <Box>
                      {addedPolicies.map((added) => (
                        <Box key={added}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}
                          >
                            <Add color="success" />
                            <Typography
                              sx={{ paddingX: '0.5rem', paddingY: '0.1rem' }}
                            >
                              {azurePolicyList
                                .find((v) => added.includes(v.key || ''))
                                ?.displayName?.concat(
                                  added?.includes('Audit')
                                    ? ' (Audit)'
                                    : ' (Deny)'
                                )}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <br />
            <DialogActions>
              <Button
                data-testid="submit-button"
                disabled={
                  submitPolicyChangeRequestLoading ||
                  (removedPolicies.length === 0 && addedPolicies.length === 0)
                }
                variant="contained"
                onClick={() => handleSubmitPolicyChangeRequest()}
                endIcon={
                  submitPolicyChangeRequestLoading ? (
                    <CircularProgress size="1rem" />
                  ) : undefined
                }
              >
                Submit Policy Change Request
              </Button>
            </DialogActions>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default PolicyModal;
