const setAppDrawerPersistence = (AppDrawerOpen: boolean) => {
  if (AppDrawerOpen) {
    sessionStorage.AppDrawerOpen = true;
  } else {
    sessionStorage.AppDrawerOpen = false;
  }
};

const getAppDrawerPersistence = () => {
  if (sessionStorage.AppDrawerOpen === 'true') {
    return true;
  }
  return false;
};

const setAllAppHistoryToRead = () => {
  const storedDataString = sessionStorage.getItem('AppHistoryDrawerData');
  if (storedDataString) {
    const parsedDataArray = JSON.parse(storedDataString as string);
    parsedDataArray
      .filter((d: any) => d.unread)
      .forEach((parsedData: any) => {
        // eslint-disable-next-line no-param-reassign
        parsedData.unread = false;
      });
    const parsedDataString = JSON.stringify(parsedDataArray);
    sessionStorage.setItem('AppHistoryDrawerData', parsedDataString);
    window.dispatchEvent(new CustomEvent('AppHistoryUpdated'));
  }
};

const setAppHistoryDrawerPersistence = (AppHistoryDrawerOpen: boolean) => {
  if (AppHistoryDrawerOpen) {
    setTimeout(setAllAppHistoryToRead, 1000);
    sessionStorage.AppHistoryDrawerOpen = true;
  } else {
    sessionStorage.AppHistoryDrawerOpen = false;
  }
};

const getAppHistoryDrawerPersistence = () => {
  if (sessionStorage.AppHistoryDrawerOpen === 'true') {
    return true;
  }
  return false;
};

const setAppHistoryDrawerData = (historyItem: object) => {
  const newHistoryItem: any = historyItem;
  newHistoryItem.unread = sessionStorage.AppHistoryDrawerOpen !== 'true';
  if (!sessionStorage.getItem('AppHistoryDrawerData')) {
    const newDataArray = [];
    newDataArray.push(newHistoryItem);
    const newDataString = JSON.stringify(newDataArray);
    sessionStorage.setItem('AppHistoryDrawerData', newDataString);
  } else {
    const storedDataString = sessionStorage.getItem('AppHistoryDrawerData');
    const parsedDataArray = JSON.parse(storedDataString as string);
    parsedDataArray.push(newHistoryItem);
    const parsedDataString = JSON.stringify(parsedDataArray);
    sessionStorage.setItem('AppHistoryDrawerData', parsedDataString);
  }
  window.dispatchEvent(new CustomEvent('AppHistoryUpdated'));
};

const getAppHistoryDrawerData = () => {
  if (sessionStorage.AppHistoryDrawerData) {
    const storedDataString = sessionStorage.getItem('AppHistoryDrawerData');
    const history = JSON.parse(storedDataString as string);
    return history.sort((objA: any, objB: any) => objB.id - objA.id);
  }
  return [];
};

const getAppHistoryUnreadCount = () => {
  const historyData = getAppHistoryDrawerData();
  return historyData.filter((d: any) => d.unread).length;
};

const removeAppHistoryDrawerItem = (id: string | number) => {
  const storedDataString = sessionStorage.getItem('AppHistoryDrawerData');
  const parsedDataArray = JSON.parse(storedDataString as string);
  const filteredHistory = parsedDataArray.filter(
    (item: any) => item.id.toString() !== id
  );
  const parsedDataString = JSON.stringify(filteredHistory);
  sessionStorage.setItem('AppHistoryDrawerData', parsedDataString);
  window.dispatchEvent(new CustomEvent('AppHistoryUpdated'));
};

const removeAppHistoryDrawerData = () => {
  sessionStorage.removeItem('AppHistoryDrawerData');
  window.dispatchEvent(new CustomEvent('AppHistoryUpdated'));
};

const useSessionStorage = () => ({
  setAppDrawerPersistence,
  getAppDrawerPersistence,
  setAppHistoryDrawerPersistence,
  getAppHistoryDrawerPersistence,
  setAppHistoryDrawerData,
  getAppHistoryDrawerData,
  getAppHistoryUnreadCount,
  removeAppHistoryDrawerItem,
  removeAppHistoryDrawerData,
});

export default useSessionStorage;
