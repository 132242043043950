import { ReferenceArchitecture } from '@costco-service-catalog/bff-types';
import {
  formValuesType,
  useCreateApplicationDraft,
  useCreateApplicationRequest,
  useSaveApplicationDraft,
  useSubmitApplication,
} from '..';
import { CardDetailsType } from '../../types';

const useFormSubmit = () => {
  const { createApplication } = useCreateApplicationRequest();
  const { submitApplication } = useSubmitApplication();
  const { createApplicationDraft } = useCreateApplicationDraft();
  const { saveApplicationDraft } = useSaveApplicationDraft();

  const handleNewApplicationSubmit = async (
    formValues: formValuesType,
    offerings: CardDetailsType[]
  ) => {
    const { applicationRequest } = createApplication({
      formValues,
      offerings,
    });

    const submitOptions = {
      variables: { input: applicationRequest },
      onCompleted: () => {},
    };

    const submitNewApplicationRes = await submitApplication(submitOptions);

    return { submitNewApplicationRes };
  };

  const handleApplicationSaveDraft = async (
    formValues: formValuesType,
    selectedOfferings: ReferenceArchitecture[]
  ) => {
    const { ApplicationDraft } = createApplicationDraft({
      formValues,
      selectedOfferings,
    });

    const saveOptions = {
      variables: { input: ApplicationDraft },
      onCompleted: () => {},
    };

    const response = await saveApplicationDraft(saveOptions);

    return { saveApplicationDraftResponse: response };
  };

  return {
    handleNewApplicationSubmit,
    handleApplicationSaveDraft,
  };
};

export default useFormSubmit;
