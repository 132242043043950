import { alpha, Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: 'none',
  },
  pageLayout: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 200,
  },
  filterBarContainer: {
    backgroundColor: theme.palette.mode === 'light' ? '#c6dbef' : '#3d5c79',
    paddingLeft: '1vw',
    paddingRight: '1vw',
    paddingTop: 2,
    paddingBottom: 2,
    height: '8vh',
    minHeight: 80,
    maxHeight: 120,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  filterBarSearchContainer: {
    flex: 1,
    marginRight: 10,
    marginLeft: 10,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  filterBarSearch: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 5,
    marginLeft: 5,
  },
  closeButton: {
    padding: 5,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 5,
    marginRight: 5,
  },
  Search: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    borderRadius: 8,
    padding: 2.5,
    backgroundColor: alpha(theme.palette.common.white, 0.75),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.9),
    },
  },
  SearchIconWrapper: {
    padding: 5,
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  StyledInputBaseSearch: {
    color: '#000000de',
    '& .MuiInputBase-input': {
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('md')]: {
        width: '30ch',
        '&:focus': {
          width: '50ch',
        },
      },
    },
  },
  filterBarElementContainer: {
    flex: 1,
    marginRight: 10,
    marginLeft: 10,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  filterBarElement: {
    marginRight: 5,
    marginLeft: 5,
  },
  offeringsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '1.5rem 1rem 1rem 1rem',
  },
  offeringSectionHeader: {
    marginLeft: '3vw',
    marginRight: '3vw',
  },
  divider: {
    margin: '1.5rem 1rem',
  },
  offeringSection: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '1rem 0 0 0',
  },
  continueButtonStyle: {
    paddingTop: 2,
    paddingLeft: 10,
    paddingRight: 5,
    paddingBottom: 2,
  },
  continueTextStyle: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 2,
    paddingLeft: 20,
    paddingRight: 15,
    paddingBottom: 2,
  },
  selectedOfferingTextbox: {
    paddingLeft: 15,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  infoFooterIcon: {
    paddingLeft: 5,
    color: theme.palette.mode === 'dark' ? 'whitesmoke' : 'gray',
  },
  footerBarContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  selectedOfferingsWithinFooterContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

export default useStyles;
