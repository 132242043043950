import { FormikHelpers, useFormik } from 'formik';
import { object, string } from 'yup';
import useEnqueueSnackbar from '../../hooks/snackbarHooks/useEnqueueSnackbar';
import useUpdateApplication from '../../hooks/applicationHooks/useUpdateApplication';

type defaultFormValuesType = {
  supportGroupEmail: string;
};

const useSupportGroupEditModal = (
  handleModalClose: () => void,
  applicationId: string,
  handleApplicationSummaryRefresh: () => void
) => {
  const { pushSnackbar } = useEnqueueSnackbar();

  const { updateApplication } = useUpdateApplication();

  const supportGroupEditSchema = () =>
    object({
      supportGroupEmail: string()
        .max(100, 'Support group email must not be more than 100 characters.')
        .matches(
          /^([A-Za-z0-9_\-.])+@costco.com/,
          'Support group email must be a costco email address.'
        )
        .required('Support group email cannot be empty.'),
    });

  const formik = useFormik({
    initialValues: { supportGroupEmail: '' },
    validationSchema: supportGroupEditSchema(),
    onSubmit: async (
      values: defaultFormValuesType,
      helpers: FormikHelpers<defaultFormValuesType>
    ) => {
      const submtiOptions = {
        variables: {
          applicationId: applicationId,
          updateRequestInput: {
            supportGroupEmail: values.supportGroupEmail,
          },
        },
      };

      const updateSupportGroupEmail = await updateApplication(submtiOptions);
      if (
        !updateSupportGroupEmail.errors ||
        updateSupportGroupEmail.errors?.length === 0
      ) {
        pushSnackbar('Support group email has been updated successfully.', {
          variant: 'success',
        });
        handleModalClose();
        handleApplicationSummaryRefresh();
        formik.resetForm();
      }
    },
  });

  const supportGroupEmailError = formik.touched.supportGroupEmail
    ? Boolean(formik.errors.supportGroupEmail)
    : false;

  return {
    formik,
    supportGroupEmailError,
  };
};

export default useSupportGroupEditModal;
