import { useListEphemeralRgs, useOfferings } from '../../hooks';

const useEphemeralResourceGroupPage = () => {
  const {
    data: offeringsData,
    error: offeringsError,
    loading: offeringsLoading,
  } = useOfferings();
  const {
    data: ephemeralRgsData,
    error: ephemeralRgsError,
    loading: ephemeralRgsLoading,
    refetch: ephemeralRgsRefetch,
  } = useListEphemeralRgs();
  const title = 'Ephemeral Applications';
  const subTitle =
    'Ephemeral Application Starter Kits are available to use as temporary environments intended for development.';
  const runEphemeralRgsRefetch = async () => {
    await ephemeralRgsRefetch();
  };

  return {
    title,
    subTitle,
    offeringsData,
    offeringsError,
    offeringsLoading,
    ephemeralRgsData,
    ephemeralRgsError,
    ephemeralRgsLoading,
    runEphemeralRgsRefetch,
  };
};

export default useEphemeralResourceGroupPage;
