import { ApolloError, useQuery } from '@apollo/client';
import {
  EphemeralRg,
  EphemeralRgsResponse,
} from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '..';
import { LIST_EPHEMERAL_RGS_QUERY } from '../../apollo/queries';

const useListEphemeralRgs = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    listEphemeralRgs: EphemeralRgsResponse;
  }>(LIST_EPHEMERAL_RGS_QUERY, {
    fetchPolicy: 'network-only',
    onError: (error: ApolloError) => handleErrorResponse(error),
  });
  const [ephemeralRgsList, setEphemeralRgsList] = useState<EphemeralRg[]>([]);

  useEffect(() => {
    if (!loading && data) {
      setEphemeralRgsList(
        (data?.listEphemeralRgs?.response as EphemeralRg[]) || undefined
      );
    }
  }, [loading, data]);

  return {
    error,
    data: ephemeralRgsList,
    loading,
    refetch,
  };
};

export default useListEphemeralRgs;
