import { ApolloError, useQuery } from '@apollo/client';
import {
  EcpOnboarding,
  EcpOnboardingsResponse,
} from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '..';
import { LIST_ECP_ONBOARDING_REQUESTS_QUERY } from '../../apollo/queries';

const useListECPOnboardingRequests = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    listECPOnboardingRequests: EcpOnboardingsResponse;
  }>(LIST_ECP_ONBOARDING_REQUESTS_QUERY, {
    fetchPolicy: 'network-only',
    onError: (error: ApolloError) => handleErrorResponse(error),
  });
  const [ecpRequestsList, setEcpRequestsList] = useState<EcpOnboarding[]>([]);

  useEffect(() => {
    if (!loading && data) {
      setEcpRequestsList(
        (data?.listECPOnboardingRequests?.response as EcpOnboarding[]) ||
          undefined
      );
    }
  }, [loading, data]);

  return {
    error,
    data: ecpRequestsList,
    loading,
    refetch,
  };
};

export default useListECPOnboardingRequests;
