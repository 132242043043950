import { ApolloError, useMutation } from '@apollo/client';
import { REMOVE_WIZ_MEMBERS } from '../../apollo/queries';
import { useApolloErrors } from '../apolloHooks';

const useRemoveWizMembers = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [removeWizMembers, { loading: addUsersLoading }] = useMutation(
    REMOVE_WIZ_MEMBERS,
    {
      onError: (error: ApolloError) => handleErrorResponse(error),
    }
  );

  return {
    removeWizMembers,
    loading: addUsersLoading,
  };
};

export default useRemoveWizMembers;
