import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.05)',
  },
  appBar: {
    zIndex: 1300,
    color: theme.palette.primary.contrastText,
  },
  appIcon: {
    width: '8rem',
    marginLeft: '-.25rem',
  },
  toolBar: {
    display: 'flex',
    alignItems: 'center',
  },
  pageLogo: {
    margin: '.25rem .5rem 0 1rem',
  },
  appTitle: {
    marginLeft: '1rem',
    fontWeight: 300,
    fontSize: '1.15em',
  },
  fOne: {
    flex: '1',
  },
}));

export default useStyles;
