import {
  Application,
  RepoRefs,
  RepoVersionInput,
} from '@costco-service-catalog/bff-types';
import { ChevronRight } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useEffect } from 'react';
import { starterKitOfferingWithRequest } from './useEphemeralResourceGroupModalCreateNew';
import useERGModalCreateNewRepoRef from './useERGModalCreateNewRepoRef';

type ERGModalCreateNewRepoRefPropsType = {
  selectHostingApplication: Application;
  selectTargetApplication: starterKitOfferingWithRequest;
  targetAppOfferingShortName: string;
  repositoryArray: RepoVersionInput[];
  handleUpdateRepository: (repo: string, ref: string) => void;
  handleResetRepositoryArr: () => void;
  handleSetExpectedRepoArrayLength: (newLength: number) => void;
};

const RepoRefRow = (props: {
  row: RepoRefs;
  repoRefsData: RepoRefs[];
  repositoryArray: RepoVersionInput[];
  handleUpdateRepository: (repo: string, ref: string) => void;
}) => {
  const { row, repoRefsData, repositoryArray, handleUpdateRepository } = props;
  const rowRefSelection =
    repositoryArray.find((v) => v.repoName === row.repoName)?.version || '';

  const handleRefSelection = (ref: string) => {
    handleUpdateRepository(row.repoName as string, ref);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexGrow: 2,
        }}
      >
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          {row.repoName}{' '}
          <ChevronRight sx={{ marginY: '0.1rem', marginX: '2rem' }} />
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flex: 2,
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Select a Branch/Tag
          </InputLabel>
          <Select
            labelId="select-repo-label"
            id="repo-select"
            value={rowRefSelection}
            label="Repository Selection"
            onChange={(e) => handleRefSelection(e.target.value)}
          >
            {repoRefsData
              .find((repoRef) => repoRef.repoName === row.repoName)
              ?.refs?.map((ref) => (
                <MenuItem value={ref as string} key={ref}>
                  {ref}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

const ERGModalCreateNewRepoRef = (props: ERGModalCreateNewRepoRefPropsType) => {
  const {
    selectHostingApplication,
    selectTargetApplication,
    targetAppOfferingShortName,
    repositoryArray,
    handleUpdateRepository,
    handleResetRepositoryArr,
    handleSetExpectedRepoArrayLength,
  } = props;

  const { repoRefsData, repoRefsError, repoRefsLoading } =
    useERGModalCreateNewRepoRef(
      selectHostingApplication,
      selectTargetApplication,
      targetAppOfferingShortName
    );

  useEffect(() => {
    if (repoRefsData) {
      handleSetExpectedRepoArrayLength(repoRefsData.length);
    }
  }, [repoRefsData]);

  useEffect(() => {
    handleResetRepositoryArr();
  }, [
    selectHostingApplication,
    selectTargetApplication,
    targetAppOfferingShortName,
  ]);

  if (repoRefsError) {
    return <div />;
  }

  return (
    <Box>
      <Box sx={{ paddingX: '1rem' }}>
        {repoRefsLoading ? (
          <Box>
            <Typography>
              Retrieving repositories... <CircularProgress size="1rem" />
            </Typography>
          </Box>
        ) : undefined}
        {repoRefsData ? (
          <Box>
            {repoRefsData.map((r) => (
              <Box key={r.repoName} paddingY="0.5rem">
                <RepoRefRow
                  row={r}
                  repoRefsData={repoRefsData}
                  repositoryArray={repositoryArray}
                  handleUpdateRepository={handleUpdateRepository}
                />
              </Box>
            ))}
          </Box>
        ) : undefined}
      </Box>
    </Box>
  );
};

export default ERGModalCreateNewRepoRef;
