import { gql } from '@apollo/client';

const GET_LOCKED_TF_STATE_BLOBS = gql`
  query GetLockedTFStateBlobs(
    $subscriptionName: String!
    $storageAccountName: String!
    $containerName: String!
  ) {
    getLockedTFStateBlobs(
      subscriptionName: $subscriptionName
      storageAccountName: $storageAccountName
      containerName: $containerName
    ) {
      code
      status
      response
    }
  }
`;

export default GET_LOCKED_TF_STATE_BLOBS;
