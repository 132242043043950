import { ReferenceArchitecture } from '@costco-service-catalog/bff-types';
import { formValuesType } from '..';
import generateApplicationDraft from './generateApplicationDraft';

interface createApplicationDraftProps {
  formValues: formValuesType;
  selectedOfferings: ReferenceArchitecture[];
}

const useCreateApplicationDraft = () => {
  const createApplicationDraft = (props: createApplicationDraftProps) => {
    const ApplicationDraft = generateApplicationDraft(
      props.formValues,
      props.selectedOfferings
    );

    return { ApplicationDraft: ApplicationDraft };
  };
  return {
    createApplicationDraft,
  };
};

export default useCreateApplicationDraft;
