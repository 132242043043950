import { gql } from '@apollo/client';

const GET_USER_PERMISSION_QUERY = gql`
  query getUserPermission {
    getUserPermission {
      code
      response {
        isAdmin
      }
    }
  }
`;

export default GET_USER_PERMISSION_QUERY;
