import { ApolloError, useMutation } from '@apollo/client';
import { useApolloErrors } from '..';
import { SUBMIT_NEW_APPLICATION_REQUEST_MUTATION } from '../../apollo/queries';

const useSubmitApplication = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [submitApplication, { loading: submitApplicationLoading }] =
    useMutation(SUBMIT_NEW_APPLICATION_REQUEST_MUTATION, {
      onError: (error: ApolloError) => handleErrorResponse(error),
    });

  return {
    submitApplication,
    loading: submitApplicationLoading,
  };
};

export default useSubmitApplication;
