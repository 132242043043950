import { useQuery } from '@apollo/client';
import {
  StorageAccountInfo,
  StorageAccountInfoResponse,
  QueryGetStorageAccountsForSubscriptionArgs,
} from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { GET_STORAGE_ACCOUNTS_FOR_SUBSCRIPTION } from '../../apollo/queries';

const useGetStorageAccountsForSubscription = (
  params: QueryGetStorageAccountsForSubscriptionArgs
) => {
  const { loading, error, data } = useQuery<{
    getStorageAccountsForSubscription: StorageAccountInfoResponse;
  }>(GET_STORAGE_ACCOUNTS_FOR_SUBSCRIPTION, {
    variables: { ...params },
  });
  const [storageAccountsForSubscription, setStorageAccountsForSubscription] =
    useState<StorageAccountInfo[]>();

  useEffect(() => {
    setStorageAccountsForSubscription(
      data?.getStorageAccountsForSubscription?.response as StorageAccountInfo[]
    );
  }, [loading, data]);

  return {
    error,
    data: storageAccountsForSubscription,
    loading,
  };
};

export default useGetStorageAccountsForSubscription;
