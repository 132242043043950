import { ApolloError, useQuery } from '@apollo/client';
import {
  UserPermission,
  UserPermissionResponse,
} from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '..';
import { GET_USER_PERMISSION_QUERY } from '../../apollo/queries';

const useGetUserPermission = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, data } = useQuery<{
    getUserPermission: UserPermissionResponse;
  }>(GET_USER_PERMISSION_QUERY, {
    onError: (error: ApolloError) => handleErrorResponse(error),
  });

  const [userPermission, setUserPermission] = useState<UserPermission>();

  useEffect(() => {
    if (!loading && data) {
      setUserPermission(
        (data?.getUserPermission?.response as UserPermission) || undefined
      );
    }
  }, [loading, data]);

  return {
    loading: loading,
    data: userPermission,
  };
};

export default useGetUserPermission;
