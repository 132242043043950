import { gql } from '@apollo/client';

const ADD_ADDITIONAL_COLLABORATORS = gql`
  mutation addAdditionalCollaborators($users: [String], $applicationId: ID) {
    addAdditionalCollaborators(users: $users, applicationId: $applicationId) {
      code
      response
      status
    }
  }
`;

export default ADD_ADDITIONAL_COLLABORATORS;
