import {
  ReferenceArchitecture,
  EnvironmentRequestInput,
  ManagementGroupInput,
  ApplicationRequestDraftInput,
  OfferingRequestInput,
  OfferingCategory,
} from '@costco-service-catalog/bff-types';
import { formValuesType } from '..';

const generateStartKitDraft = (
  formValues: formValuesType,
  offerings: ReferenceArchitecture[]
): ApplicationRequestDraftInput => {
  const ApplicationDraft: ApplicationRequestDraftInput = {
    id:
      formValues.starterKitDraftId !== undefined
        ? formValues.starterKitDraftId
        : '',
    managementGroup: formValues.managementGroup as ManagementGroupInput,
    applicationName: formValues.applicationName as string,
    applicationDescription: formValues.applicationDescription as string,
    requestedEnvironments: formValues.nonProdEnvironments.concat(
      formValues.prodEnvironments
    ) as EnvironmentRequestInput[],
    primaryRegion: formValues.primaryRegion as string,
    secondaryRegion: formValues.secondaryRegion as string,
    aadGroupMembers: formValues.aadGroupMembers,
    aadReaderGroupMembers: formValues.aadReaderGroupMembers,
    wizMembers: formValues.wizMembers,
    isExistingAdoProject: formValues.isExistingAdoProject as boolean,
    existingAdoProjectOrg: formValues.existingAdoProjectOrg as string,
    existingAdoProject: formValues.existingAdoProject,
    adoProjectAdministrators: formValues.adoProjectAdministrators,
    adoProjectContributors: formValues.adoProjectContributors,
    adoProjectBoardAdministrators: formValues.adoProjectBoardAdministrators,
    supportGroupEmail: formValues.supportGroupEmail as string,
    additionalCollaborators: formValues.additionalCollaborators,
    organizationName: formValues.existingAdoProjectOrg as string,
    offeringRequests: offerings.map((offering) => {
      const castToORI: OfferingRequestInput = {
        offeringName: offering.name as string,
        offeringRepoUrl: offering.repoUrl as string,
        offeringVersion: offering.version as string,
        category: offering.category as OfferingCategory,
        offeringShortName: offering.shortOfferingName as string,
        offeringVnetPeered: offering.vnetPeered as boolean,
        offeringServiceNames: offering.applicationList as string,
      };
      return castToORI;
    }),
  };

  return ApplicationDraft;
};

export default generateStartKitDraft;
