import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '100%',
    marginLeft: '1rem',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '1.25rem',
    marginRight: '1rem',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '500',
  },
  subIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '0.5rem',
  },
  subtitle: {
    fontSize: '1rem',
  },
}));

export default useStyles;
