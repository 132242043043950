import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '100%',
  },
  adminUsersDialog: {
    width: '500px',
  },
  adminUsersTableContainer: {
    overflow: 'auto',
  },
  adminUsersTable: {
    display: 'table',
  },
  adminUsersDialogButton: {
    padding: '.5rem 2rem',
    marginLeft: '1rem',
    marginBottom: '1rem',
  },
  adminUsersDialogControls: {
    width: '100%',
  },
  adminUsersDialogContainer: {
    width: '100%',
  },
  adminUsersAddButton: {
    display: 'block',
    textAlign: 'right',
    marginTop: '1rem',
  },
  adminUsersDeleteIcon: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
  },
  adminUsersSearchInput: {
    '& .MuiOutlinedInput-root': {
      '& .MuiAutocomplete-endAdornment': {
        right: '9px !important',
      },
    },
  },
  adminUserDialogCard: {
    marginBottom: '5px',
  },
}));

export default useStyles;
