import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { useListAllApplications } from '../../hooks';

const useAllApplicationsPage = () => {
  const title = 'All Applications';
  const { error, data, loading } = useListAllApplications();
  const [tableData, setTableData] = useState<any>(null);

  const navigate = useNavigate();

  let isSubscribed = true;
  useEffect(() => {
    if (data && isSubscribed && !loading) {
      setTableData(
        data.map(
          ({
            id,
            requester,
            createdDateTime,
            updatedDateTime,
            applicationName,
            organizationName,
            supportGroupEmail,
          }) => ({
            id,
            requester,
            key: id,
            createdDateTime,
            updatedDateTime,
            applicationName,
            organizationName,
            supportGroupEmail,
          })
        )
      );
    }
    return () => {
      isSubscribed = false;
    };
  }, [data]);

  const handleRowClick = (dataRow: any) => {
    const id = dataRow?.row.id;
    navigate(`${ROUTES.APPLICATION_SUMMARY}/${id}`);
  };

  return {
    title,
    data: tableData,
    loading: loading,
    count: tableData?.length,
    handleRowClick,
  };
};

export default useAllApplicationsPage;
