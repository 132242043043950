import { Application } from '@costco-service-catalog/bff-types';
import { ArrowLeft, ArrowRight, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  StarterKitRequestsStatusesType,
  deployedApplicationHasCompletedAll,
} from '../../hooks/validationHooks/deployedApplicationValidation';
import useStyles from './MultiselectPreviouslyDeployed.styles';

interface MultiselectPDTableRowProps {
  onSelect: (value: string) => void;
  selectedValue: string;
  data: Application;
}

const MultiselectPDTableRow = (props: MultiselectPDTableRowProps) => {
  const { data, onSelect, selectedValue } = props;
  const readyStatus = deployedApplicationHasCompletedAll(data);
  const { classes } = useStyles();
  const [kitErrorArray, setKitErrorArray] = useState<
    StarterKitRequestsStatusesType[]
  >([]);
  const [kitError, setKitError] = useState<StarterKitRequestsStatusesType>();
  const [notCompleteStarterKitsLength, setNotCompleteStarterKitsLength] =
    useState(0);
  const [notCompleteStarterKitsPage, setNotCompleteStarterKitsPage] =
    useState(0);

  useEffect(() => {
    if (
      readyStatus?.notCompleteStarterKits &&
      readyStatus.notCompleteStarterKits.length > 0
    ) {
      setKitErrorArray(readyStatus.notCompleteStarterKits);
      setKitError(readyStatus.notCompleteStarterKits[0]);
      setNotCompleteStarterKitsLength(
        readyStatus.notCompleteStarterKits.length - 1
      );
      setNotCompleteStarterKitsPage(0);
    }
  }, []);

  return (
    <TableRow
      key={data.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      className={
        !readyStatus.completed ? classes.inactiveRow : classes.activeRow
      }
    >
      <TableCell align="left" sx={{ paddingLeft: '2rem' }}>
        <FormControlLabel
          disabled={!readyStatus.completed as boolean | undefined}
          value={data.id}
          control={
            <Radio
              sx={{
                opacity: (!readyStatus.completed as boolean | undefined)
                  ? 0.25
                  : 1,
              }}
            />
          }
          label=""
        />
      </TableCell>
      <TableCell>{data.applicationName}</TableCell>
      <TableCell>
        <Typography
          noWrap
          maxWidth="15vw"
          textOverflow="ellipsis"
          variant="body2"
          className={!readyStatus.completed ? classes.unbold : classes.bold}
        >
          {data.applicationDescription}
        </Typography>
      </TableCell>
      <TableCell>
        {data.createdDateTime
          ? new Date(data.createdDateTime).toLocaleString()
          : ''}
      </TableCell>
      <TableCell>
        {data.updatedDateTime
          ? new Date(data.updatedDateTime).toLocaleString()
          : ''}
      </TableCell>
      <TableCell align="right" sx={{ paddingRight: '1.5rem' }}>
        {!readyStatus.completed ? (
          <Accordion elevation={0} disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              Building / Problems Detected
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Box>
                  <Box>
                    <Box width="230px" maxWidth="280px">
                      <Paper
                        elevation={2}
                        sx={{ padding: '10px', margin: '2px' }}
                      >
                        <Typography variant="subtitle2" fontWeight="normal">
                          Carts+ ID:
                        </Typography>
                        <Typography variant="subtitle2" fontWeight="normal">
                          {kitError?.cartsId}
                        </Typography>
                        <Typography fontWeight="bold">Status:</Typography>
                        <Typography fontWeight="bold">
                          {kitError?.status}
                        </Typography>
                        <Divider sx={{ width: '100%', paddingTop: '5px' }} />
                        <Box
                          sx={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <IconButton
                            disabled={notCompleteStarterKitsPage === 0}
                            onClick={() => {
                              setNotCompleteStarterKitsPage(
                                notCompleteStarterKitsPage - 1
                              );
                              setKitError(
                                kitErrorArray[notCompleteStarterKitsPage - 1]
                              );
                            }}
                          >
                            <ArrowLeft />
                          </IconButton>
                          <Typography>
                            {notCompleteStarterKitsPage + 1} of{' '}
                            {notCompleteStarterKitsLength + 1}
                          </Typography>
                          <IconButton
                            disabled={
                              notCompleteStarterKitsPage ===
                              notCompleteStarterKitsLength
                            }
                            onClick={() => {
                              setNotCompleteStarterKitsPage(
                                notCompleteStarterKitsPage + 1
                              );
                              setKitError(
                                kitErrorArray[notCompleteStarterKitsPage + 1]
                              );
                            }}
                          >
                            <ArrowRight />
                          </IconButton>
                        </Box>
                      </Paper>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        ) : (
          <Typography variant="body2" color="green">
            Ready
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

export default MultiselectPDTableRow;
