import { useListApplications } from '../../hooks';

const useMultiselectPreviouslyDeployed = () => {
  const { error, data, loading } = useListApplications();

  data.sort(
    (a, b) =>
      new Date(b.updatedDateTime as string).getTime() -
      new Date(a.updatedDateTime as string).getTime()
  );

  return {
    error,
    data,
    loading,
  };
};

export default useMultiselectPreviouslyDeployed;
