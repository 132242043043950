import { ApolloError, useQuery } from '@apollo/client';
import {
  Application,
  ApplicationResponse,
  EntityParams,
} from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '..';
import { GET_APPLICATION_QUERY } from '../../apollo/queries';
import { useGlobalState } from '../../globalState/useGlobalState';
import { mockApplicationRes } from './applicationHooksMocks/useApplication.mock';

const useGetApplication = (params: EntityParams) => {
  const { appSettings } = useGlobalState();
  if (appSettings.useUIMocks.get()) {
    return mockApplicationRes();
  }

  const { handleErrorResponse } = useApolloErrors();
  let error;
  const { loading, data, refetch } = useQuery<{
    getApplication: ApplicationResponse;
  }>(GET_APPLICATION_QUERY, {
    fetchPolicy: 'network-only',
    variables: { params },
    onError: (error: ApolloError) => handleErrorResponse(error),
  });
  const [application, setApplication] = useState<Application>();

  if (!loading && !data?.getApplication?.response) {
    error = 'Error Fetching Application';
  }

  useEffect(() => {
    if (!loading && data) {
      setApplication(
        (data?.getApplication?.response as Application) || undefined
      );
    }
  }, [loading, data]);

  return {
    error,
    data: application,
    loading,
    refetch,
  };
};

export default useGetApplication;
