import { EnvironmentRequest } from '@costco-service-catalog/bff-types';
import { array, object, ref, string } from 'yup';

const checkEnvNames = (values: EnvironmentRequest[] | undefined) => {
  if (!values || values.length === 0) {
    return true;
  }
  const result = values.filter((env: EnvironmentRequest) => {
    if (!env || !env.name) {
      return false;
    }
    return env.name.match(/^[A-Za-z]+$/) && env.name.length === 3;
  });
  return result.length === values.length;
};

const azureCloudNativeStarterKitValidationSchema = (
  maxNpEnvironments: number,
  maxPdEnvironments: number,
  productionEnvironmentSelectionEnabled: boolean
) =>
  object({
    applicationName: string()
      .min(3, 'Application name must be at least 3 characters.')
      .max(25, 'Application name must not be more than 25 characters.')
      .matches(
        /^[a-zA-Z0-9]*$/g,
        'Application name cannot contain any spaces or special characters.'
      )
      .matches(
        /^((?!\d).)/g,
        'Application Name must not start with a numeric character.'
      )
      .matches(
        /^((?!sctest).)*$/gi,
        'Application name cannot contain the string "sctest" (Case Insensitive).'
      )
      .required('Application name cannot be empty.'),
    applicationDescription: string()
      .max(800, 'Application description must not be more than 800 characters.')
      .required('Application description cannot be empty.'),
    managementGroup: object().required('You must select a department.'),
    nonProdEnvironments: array().when('isEphemeral', {
      is: false,
      then: (schema) =>
        schema
          .required('You must have at least one non production environment.')
          .min(1, 'You must have at least one non production environment.')
          .max(
            maxNpEnvironments,
            `You can have a maximum of ${maxNpEnvironments} non production environments.`
          )
          .test(
            'npEnvNames',
            'Non production environment names must be 3 ASCII alphabet characters.',
            checkEnvNames
          ),
    }),
    prodEnvironments: productionEnvironmentSelectionEnabled
      ? array().when('isEphemeral', {
          is: false,
          then: (schema) =>
            schema
              .required('You must have at least one production environment.')
              .min(1, 'You must have at least one production environment.')
              .max(
                maxPdEnvironments,
                `You can have a maximum of ${maxPdEnvironments} production environments.`
              )
              .test(
                'pdEnvNames',
                'Production environment names must be 3 ASCII alphabet characters.',
                checkEnvNames
              )
              .when(
                'nonProdEnvironments',
                ([nonProdEnvironments]: EnvironmentRequest[][], schema) =>
                  schema.test(
                    'envNameIntersection',
                    'Production environments cannot share names with non production environments.',
                    (values: EnvironmentRequest[]) =>
                      values.filter((v) =>
                        nonProdEnvironments.find((ov) => ov.name === v.name)
                      ).length === 0
                  )
              ),
        })
      : array(),
    primaryRegion: string()
      .required('You must select a primary region.')
      .when('isEphemeral', {
        is: false,
        then: (schema) =>
          schema.notOneOf(
            [ref('secondaryRegion')],
            'Primary region cannot be the same as secondary region.'
          ),
      }),

    secondaryRegion: string().when('isEphemeral', {
      is: false,
      then: (schema) =>
        schema
          .notOneOf(
            [ref('primaryRegion')],
            'Secondary region cannot be the same as primary region.'
          )
          .required('You must select a secondary region.'),
    }),
    supportGroupEmail: string()
      .max(100, 'Support group email must not be more than 100 characters.')
      .matches(
        /^([A-Za-z0-9_\-.])+@costco.com/,
        'Support group email must be a costco email address.'
      )
      .required('Support group email cannot be empty.'),
    adoProjectAdministrators: array().when('isExistingAdoProject', {
      is: false,
      then: (schema) =>
        schema
          .required('You must have at least one ADO project administrator.')
          .min(1, 'You must have at least one ADO project administrator.'),
    }),
    adoProjectBoardsProcess: object().when('isExistingAdoProject', {
      is: false,
      then: (schema) =>
        schema.required(
          'You must have an ADO Project Boards Process assigned to your project.'
        ),
    }),
    existingAdoProjectOrg: string().when('isExistingAdoProject', {
      is: true,
      then: (schema) => schema.required('You must select an ADO Org.'),
    }),
    existingAdoProject: object().when('isExistingAdoProject', {
      is: true,
      then: (schema) => schema.required('You must select an ADO Project.'),
    }),
    aadGroupMembers: array()
      .required('You must have at least one AAD contributor group member.')
      .min(1, 'You must have at least one AAD contributor group member.'),
    wizMembers: array().when('isEphemeral', {
      is: false,
      then: (schema) =>
        schema.when('wizEnabled', {
          is: true,
          then: (schema) =>
            schema
              .required('You must have at least one Wiz.io access member.')
              .min(1, 'You must have at least one Wiz.io access member.'),
        }),
    }),
  });

export default azureCloudNativeStarterKitValidationSchema;
