import { gql } from '@apollo/client';

const GET_APPLICATION_QUERY = gql`
  query getApplication($params: EntityParams) {
    getApplication(params: $params) {
      status
      code
      response {
        id
        environments {
          name
          type
          cidrBlockSize
          subscriptionName
          servicePrincipalName
          aadGroup
          aadReaderGroup
          regions
          notReady
          deleteStatus
          wizSubscriptionSlug
        }
        primaryRegion
        secondaryRegion
        adoProject {
          isExistingProject
          projectOrg
          projectName
          projectUrl
          adoProjectContributors {
            members {
              id
              email
            }
          }
          adoProjectAdministrators {
            members {
              id
              email
            }
          }
          adoProjectBoardAdministrators {
            members {
              id
              email
            }
          }
        }
        starterKitRequests {
          id
          status
          requester {
            userName
            firstName
            lastName
            email
          }
          requestedDateTime
          cartsApprovalRequestId
          offerings {
            status
            adoRunUrl
            adoBuildStatus {
              items {
                id
                name
                state
                result
                children {
                  id
                  name
                  state
                  result
                  percentCompleted
                }
                percentCompleted
              }
            }
            adoRepoNames
            category
            offeringShortName
            offeringName
            offeringVersion
            additionalParams {
              key
              value
            }
          }
        }
        createdDateTime
        updatedDateTime
        hasRunningPipelines
        requester {
          userName
          firstName
          lastName
          email
        }
        businessUnitNumber
        applicationName
        applicationDescription
        aadGroupMembers {
          id
          email
        }
        aadReaderGroupMembers {
          id
          email
        }
        wizMembers {
          id
          email
        }
        supportGroupEmail
        additionalCollaborators
        organizationName
        preUserManagement
        financialDashboardUrl
        deleteStatus
        isEphemeral
        expirationDateTime
        wizProject {
          adGroup {
            name
          }
          setupStatus
        }
        ecoSystemNP {
          enabledPolicies
          policiesVersion
        }
        ecoSystemPD {
          enabledPolicies
          policiesVersion
        }
      }
    }
  }
`;

export default GET_APPLICATION_QUERY;
