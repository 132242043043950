import { gql } from '@apollo/client';

const GET_STORAGE_ACCOUNTS_FOR_SUBSCRIPTION = gql`
  query GetStorageAccountsForSubscription($subscriptionName: String!) {
    getStorageAccountsForSubscription(subscriptionName: $subscriptionName) {
      code
      status
      response {
        name
        blobContainers
      }
    }
  }
`;

export default GET_STORAGE_ACCOUNTS_FOR_SUBSCRIPTION;
