import { AadUser } from '@costco-service-catalog/bff-types';
import { CommonNonZUsersAutoComplete } from '../CommonFields';

interface WizMemberProps {
  value: AadUser[];
  fieldError: boolean;
  errorText?: string;
  onChangeHandler: (event: React.ChangeEvent<{}>, value: AadUser[]) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const WizMembers = (props: WizMemberProps) => {
  const { value, fieldError, errorText, onChangeHandler, onBlurHandler } =
    props;

  return (
    <CommonNonZUsersAutoComplete
      componentID="wizMembers"
      label="Wiz.io Access Members"
      value={value}
      required
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      fieldError={fieldError}
      helperText={
        fieldError && errorText
          ? errorText
          : 'An AD group granting access to Wiz.io will be created. Enter accounts to include them as group members.'
      }
      fullHelpText="An AD group granting access to Wiz.io for subscriptions created for this Application will be created with the selected accounts included as group members."
    />
  );
};

WizMembers.defaultProps = {
  errorText: undefined,
};

export default WizMembers;
