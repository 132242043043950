import { Requester } from '@costco-service-catalog/bff-types';
import GroupsIcon from '@mui/icons-material/Groups';
import { Tooltip } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridSortDirection,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import useStyles from './AllApplicationsGrid.styles';

interface ApplicationList {
  id: string;
  createdDateTime: string;
  updatedDateTime: string;
  applicationName: string;
  requester: Requester;
  organizationName: string;
}

interface AllApplicationsGridProps {
  rows: ApplicationList[];
  onRowClick: (param: GridRowParams, event: any) => void;
}

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarExport />
  </GridToolbarContainer>
);

const AllApplicationsGrid = (props: AllApplicationsGridProps) => {
  const auth = useAuth();

  const { rows, onRowClick } = props;

  const { classes } = useStyles();

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'updatedDateTime',
      sort: 'desc' as GridSortDirection,
    },
  ]);

  const columns: GridColDef[] = [
    {
      field: 'createdDateTime',
      type: 'dateTime',
      valueGetter: ({ value }) => new Date(value),
      headerName: 'Date Submitted',
      flex: 1,
    },
    {
      field: 'updatedDateTime',
      type: 'dateTime',
      valueGetter: ({ value }) => new Date(value),
      headerName: 'Date Updated',
      flex: 1,
    },
    {
      field: 'applicationName',
      headerName: 'Application Name',
      flex: 1,
      renderCell: (params: GridRenderCellParams<ApplicationList>) =>
        params.row.requester.email?.toLowerCase() !==
        auth.user?.profile.email?.toLowerCase() ? (
          <>
            <Tooltip title="Shared">
              <GroupsIcon />
            </Tooltip>
            <span>&nbsp;{params.value}</span>
          </>
        ) : (
          params.value
        ),
    },
    {
      field: 'requester',
      headerName: 'Requester',
      flex: 1,
      valueGetter: ({ value }) => value.userName,
    },
    {
      field: 'organizationName',
      headerName: 'Department',
      flex: 1,
    },
    {
      field: 'supportGroupEmail',
      headerName: 'Support Group Email',
      flex: 1,
    },
  ];

  return (
    <div className={classes.container}>
      {rows && (
        <DataGrid
          rows={rows || []}
          columns={columns}
          getCellClassName={(params: GridCellParams) => classes.dataCell}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 20]}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          onRowClick={onRowClick}
          pagination
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      )}
    </div>
  );
};

export default AllApplicationsGrid;
