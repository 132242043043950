import { ApolloError, useQuery } from '@apollo/client';
import { AadUser, AadUsersResponse } from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '..';
import { SEARCH_AAD_USERS_QUERY } from '../../apollo/queries';
import { useGlobalState } from '../../globalState/useGlobalState';
import mockSearchAADUsersRes from './lookupHooksMocks/useAADUsers.mock';

const useAADUsers = () => {
  const { appSettings } = useGlobalState();
  if (appSettings.useUIMocks.get()) {
    return mockSearchAADUsersRes();
  }

  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    searchAADUsers: AadUsersResponse;
  }>(SEARCH_AAD_USERS_QUERY, {
    onError: (error: ApolloError) => handleErrorResponse(error),
  });
  const [users, setUsers] = useState<AadUser[]>([]);

  useEffect(() => {
    if (!loading && data) {
      const extractUsers: AadUser[] | undefined = data.searchAADUsers
        .response as AadUser[];
      if (extractUsers) {
        setUsers(extractUsers);
      }
    }
  }, [loading, data]);

  return {
    error,
    data: users,
    loading,
  };
};

export default useAADUsers;
