import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import '@fontsource/roboto';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  AppInsightsContext,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import {
  CircularProgress,
  createTheme,
  CssBaseline,
  ThemeProvider,
} from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { UserManagerSettings } from 'oidc-client-ts';
import React, { useEffect, useState } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { BrowserRouter } from 'react-router-dom';
import createApolloClient from './apollo/apollo-client';
import './App.css';
import createTelemetryService from './appInsights/app-insights';
import AppPageRouting from './AppPageRouting';
import { AppLayout, Snackbar } from './components';
import { menu } from './constants';
import { useGlobalState } from './globalState/useGlobalState';
import { useAppSettings, useLocalStorage } from './hooks';
import ColorModeContext, { ColorMode } from './theme/ColorModeContext';
import getDesignTokens from './theme/getDesignTokens';

const App = () => {
  useAppSettings();

  const localStorageHooks = useLocalStorage();

  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [authConfig, setAuthConfig] = useState<UserManagerSettings>();
  const [reactPlugin, setReactPlugin] = useState<ReactPlugin>();

  const { appSettings } = useGlobalState();

  const appLayoutProps = {
    title: 'Service Catalog',
    menu: menu,
  };

  const [colorMode, setColorMode] = React.useState<ColorMode>({
    mode: localStorageHooks.getDarkModePersistence() ? 'dark' : 'light',
    toggleColorMode: () => {
      setColorMode((prevColorMode) => ({
        ...colorMode,
        mode: prevColorMode.mode === 'light' ? 'dark' : 'light',
      }));
      localStorageHooks.setDarkModePersistence(
        !localStorageHooks.getDarkModePersistence()
      );
    },
  });

  const theme = React.useMemo(
    () => createTheme(getDesignTokens(colorMode.mode)),
    [colorMode]
  );

  useEffect(() => {
    if (appSettings.loaded.get()) {
      setClient(
        createApolloClient(
          appSettings.bffBaseUrl.get(),
          appSettings.pingBaseUrl.get(),
          appSettings.pingClientId.get()
        )
      );
      setAuthConfig({
        authority: appSettings.pingBaseUrl.get(),
        client_id: appSettings.pingClientId.get(),
        redirect_uri: `${window.location.origin}/home`,
        response_type: 'code',
        monitorSession: true,
        scope: 'openid profile email address',
        metadata: {
          authorization_endpoint: `${appSettings.pingBaseUrl.get()}/as/authorization.oauth2`,
          token_endpoint: `${appSettings.bffBaseUrl.get()}/api/authorize`,
          code_challenge_methods_supported: ['plain', 'S256'],
        },
      });
      setReactPlugin(
        createTelemetryService(appSettings.aiInstrumentationKey.get())
      );
    }
  }, [appSettings.loaded.get()]);

  return authConfig && client ? (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <AppInsightsContext.Provider value={reactPlugin as ReactPlugin}>
          <AuthProvider {...authConfig}>
            <ApolloProvider client={client}>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                Components={{
                  default: Snackbar,
                  success: Snackbar,
                  error: Snackbar,
                  info: Snackbar,
                  warning: Snackbar,
                }}
              />
              <BrowserRouter>
                <ColorModeContext.Provider value={colorMode}>
                  <AppLayout {...appLayoutProps}>
                    <AppPageRouting />
                  </AppLayout>
                </ColorModeContext.Provider>
              </BrowserRouter>
            </ApolloProvider>
          </AuthProvider>
        </AppInsightsContext.Provider>
      </LocalizationProvider>
    </ThemeProvider>
  ) : (
    <CircularProgress />
  );
};

export default App;
