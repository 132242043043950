import { CommonDropdown } from '.';

type CommonRegionDropdownPropType = {
  componentID: string;
  label: string;
  data: string[];
  value: string;
  fieldError: boolean;
  helperText: string;
  fullHelpText: string;
  onChangeHandler: (event: React.ChangeEvent<{}>, value: string | null) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
  getOptionDisabled?: (v: string) => boolean;
};

const CommonRegionDropdown = (props: CommonRegionDropdownPropType) => {
  const {
    componentID,
    label,
    data,
    value,
    fieldError,
    helperText,
    fullHelpText,
    onChangeHandler,
    onBlurHandler,
    getOptionDisabled,
  } = props;

  return (
    <CommonDropdown
      componentID={componentID}
      label={label}
      data={data}
      value={value}
      fieldError={fieldError}
      helperText={helperText}
      fullHelpText={fullHelpText}
      loading={false}
      required
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      getOptionLabel={(option) => option as string}
      getOptionSelected={(o, v) => o === v}
      getOptionDisabled={getOptionDisabled}
    />
  );
};

CommonRegionDropdown.defaultProps = {
  getOptionDisabled: undefined,
};

export default CommonRegionDropdown;
