import { ApolloError } from '@apollo/client/errors';
import { ManagementGroup } from '@costco-service-catalog/bff-types';

const mockManagementGroups = [
  {
    name: 'management-group-1',
    displayName: '001 Mock Management Group 1',
  },
  {
    name: 'management-group-2',
    displayName: '002 Mock Management Group 2',
  },
  {
    name: 'management-group-3',
    displayName: '003 Mock Management Group 3',
  },
];

const mockManagementGroupsRes = () => {
  const error: ApolloError | undefined = undefined;
  const data: ManagementGroup[] = mockManagementGroups;
  const loading: boolean = false;
  return {
    error,
    data,
    loading,
  };
};

export default mockManagementGroupsRes;
