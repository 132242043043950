import {
  EnvironmentType,
  EcpRequestType,
} from '@costco-service-catalog/bff-types';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import React from 'react';
import { StatusChip } from '../..';
import useStyles from './ECPOnboardingListGrid.styles';

interface ECPOnboardingList {
  id: string;
  requestType: EcpRequestType;
  environmentType: EnvironmentType;
  teamName: string;
  namespaceName: string;
  organizationName: string;
  createdDateTime: string;
  cartsApprovalRequestId: string;
  status: string;
}

interface ECPOnboardingListProps {
  rows: ECPOnboardingList[];
}

const ECPOnboardingListGrid = (props: ECPOnboardingListProps) => {
  const { rows } = props;

  const { classes } = useStyles();

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'createdDateTime',
      sort: 'desc' as GridSortDirection,
    },
  ]);

  const columns: GridColDef[] = [
    {
      field: 'createdDateTime',
      type: 'dateTime',
      valueGetter: ({ value }) => new Date(value),
      headerName: 'Date Submitted',
      flex: 1,
    },
    {
      field: 'requestType',
      headerName: 'Request Type',
      valueGetter: ({ value }) => value.replace(/([A-Z])/g, ' $1'),
      flex: 1,
    },
    {
      field: 'environmentType',
      headerName: 'Environment',
      flex: 1,
    },
    {
      field: 'teamName',
      headerName: 'Team Name',
      flex: 1,
    },
    {
      field: 'namespaceName',
      headerName: 'Namespace Name',
      flex: 1,
    },
    {
      field: 'organizationName',
      headerName: 'Organization Name',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params: GridRenderCellParams<ECPOnboardingList>) =>
        params.value ? <StatusChip label={params.value as string} /> : <div />,
    },
    {
      field: 'cartsApprovalRequestId',
      headerName: 'Carts+ Request Id',
      flex: 1,
    },
  ];

  return (
    <div className={classes.container}>
      {rows && (
        <DataGrid
          rows={rows || []}
          columns={columns}
          getCellClassName={(params: GridCellParams) => classes.dataCell}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 20]}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          pagination
          rowSelection={false}
        />
      )}
    </div>
  );
};

export default ECPOnboardingListGrid;
