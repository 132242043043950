import { gql } from '@apollo/client';

const DELETE_EPHEMERAL_RG_MUTATION = gql`
  mutation deleteEphemeralRg($input: EntityParams) {
    deleteEphemeralRg(input: $input) {
      code
      status
    }
  }
`;

export default DELETE_EPHEMERAL_RG_MUTATION;
