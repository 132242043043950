import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '100%',
  },
  adminUsersPaper: {
    marginRight: '3rem',
    padding: '1rem',
    display: 'block',
    textAlign: 'center',
    width: '18rem',
  },
  adminUsersPaperTitle: {
    textAlign: 'center',
    margin: '0 0 .5rem',
    fontSize: '1rem',
  },
  adminUsersPaperButton: {
    textAlign: 'center',
  },
}));

export default useStyles;
