import { hookstate, useHookstate } from '@hookstate/core';
import initialAppSettingsState, {
  AppSettingsSchema,
} from './appSettings.state';
import initialProjectInfoState, {
  ProjectInfoStateType,
} from './projectInfo.state';

interface GlobalStateSchema {
  error?: Error;
  loadingMessage: string;
  appSettings: AppSettingsSchema;
  projectInfo: ProjectInfoStateType;
  loading: boolean;
  lastRoute: string;
}

const initialState: GlobalStateSchema = JSON.parse(
  JSON.stringify({
    error: null,
    loadingMessage: '',
    appSettings: initialAppSettingsState,
    projectInfo: initialProjectInfoState,
    loading: false,
    lastRoute: '',
  })
);

const globalState = hookstate(initialState);

const useGlobalState = () => useHookstate(globalState);

export { initialState, globalState, useGlobalState };
