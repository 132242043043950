import { gql } from '@apollo/client';

const UPDATE_EPHEMERAL_RG_EXPIRATION_DATE_MUTATION = gql`
  mutation UpdateEphemeralRgExpirationDate(
    $ephemeralRgId: ID
    $newExpirationDate: String
  ) {
    updateEphemeralRgExpirationDate(
      ephemeralRgId: $ephemeralRgId
      newExpirationDate: $newExpirationDate
    ) {
      status
      code
    }
  }
`;

export default UPDATE_EPHEMERAL_RG_EXPIRATION_DATE_MUTATION;
