import { ApolloError, useMutation } from '@apollo/client';
import { useApolloErrors } from '..';
import { SUBMIT_ADD_TO_APPLICATION_REQUEST } from '../../apollo/queries';

const useSubmitAddToApplication = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [submitAddToApplication, { loading: submitAddToApplicationLoading }] =
    useMutation(SUBMIT_ADD_TO_APPLICATION_REQUEST, {
      onError: (error: ApolloError) => handleErrorResponse(error),
    });

  return {
    submitAddToApplication,
    loading: submitAddToApplicationLoading,
  };
};

export default useSubmitAddToApplication;
