import { ApolloError, useQuery } from '@apollo/client';
import {
  Application,
  ApplicationsResponse,
} from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { LIST_ALL_APPLICATIONS_QUERY } from '../../apollo/queries';
import { useApolloErrors } from '..';
import { useGlobalState } from '../../globalState/useGlobalState';
import mockApplications from './applicationHooksMocks/useApplications.mock';

const useListAllApplications = () => {
  const { appSettings } = useGlobalState();
  if (appSettings.useUIMocks.get()) {
    return mockApplications();
  }

  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getAllApplications: ApplicationsResponse;
  }>(LIST_ALL_APPLICATIONS_QUERY, {
    fetchPolicy: 'network-only',
    onError: (error: ApolloError) => handleErrorResponse(error),
  });
  const [applicationList, setApplicationList] = useState<Application[]>([]);

  useEffect(() => {
    if (!loading && data) {
      setApplicationList(
        (data?.getAllApplications?.response as Application[]) || undefined
      );
    }
  }, [loading, data]);

  return {
    error,
    data: applicationList,
    loading,
    refetch,
  };
};

export default useListAllApplications;
