import { Box } from '@mui/material';
import { FormikErrors } from 'formik';
import CommonDropdown from '../../../../FormComponents/CommonFields/CommonDropdown';
import { useGetLockedTFStateBlobs } from '../../../../../hooks';
import { defaultUnlockStateFileFormValuesType } from '../useUnlockStateFileModal';

const SelectStateFile = (props: {
  selectedSubscriptionName: string;
  selectedStorageAccountName: string;
  selectedContainerName: string;
  selectedStateFileName: string | null;
  selectedStateFileNameFieldError: boolean;
  errors: FormikErrors<defaultUnlockStateFileFormValuesType>;
  setFieldValue: (
    field: string,
    value: string | undefined,
    shouldValidate?: boolean
  ) =>
    | Promise<FormikErrors<defaultUnlockStateFileFormValuesType>>
    | Promise<void>;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
}) => {
  const {
    selectedSubscriptionName,
    selectedStorageAccountName,
    selectedContainerName,
    selectedStateFileName,
    selectedStateFileNameFieldError,
    errors,
    setFieldValue,
    handleBlur,
  } = props;
  const { data, loading } = useGetLockedTFStateBlobs({
    subscriptionName: selectedSubscriptionName,
    storageAccountName: selectedStorageAccountName,
    containerName: selectedContainerName,
  });

  return (
    <Box sx={{ flex: 1 }}>
      <CommonDropdown
        componentID="selectedStateFileName"
        label="Select the state file to unlock"
        data={data || []}
        value={selectedStateFileName || null}
        fieldError={selectedStateFileNameFieldError}
        helperText={
          selectedStateFileNameFieldError && errors.selectedStateFileName
            ? errors.selectedStateFileName
            : 'Select a tf state file to unlock.'
        }
        fullHelpText="Please select a tf state file to unlock from the dropdown menu."
        loading={loading}
        dropdownDisabled={!data}
        required
        noOptionsText="No Options Found."
        onChangeHandler={(e, value) => {
          setFieldValue('selectedStateFileName', value || undefined);
        }}
        onBlurHandler={handleBlur}
        getOptionLabel={(option) => option || 'No State File Name'}
        getOptionSelected={(o, v) => o === v}
      />
    </Box>
  );
};
export default SelectStateFile;
