import { ApolloError, useQuery } from '@apollo/client';
import {
  ReferenceArchitecture,
  ReferenceArchitecturesResponse,
} from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '..';
import { GET_REF_ARCH_QUERY } from '../../apollo/queries';
import { useGlobalState } from '../../globalState/useGlobalState';
import mockOfferingsRes from './lookupHooksMocks/useOfferings.mock';

const useOfferings = () => {
  const { appSettings } = useGlobalState();
  if (appSettings.useUIMocks.get()) {
    return mockOfferingsRes();
  }

  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getRefArch: ReferenceArchitecturesResponse;
  }>(GET_REF_ARCH_QUERY, {
    onError: (error: ApolloError) => handleErrorResponse(error),
  });
  const [refArchData, setRefArchData] = useState<ReferenceArchitecture[]>([]);

  useEffect(() => {
    if (!loading && data) {
      setRefArchData(
        (data?.getRefArch?.response as ReferenceArchitecture[]) || undefined
      );
    }
  }, [loading, data]);

  return {
    error,
    data: refArchData,
    loading,
  };
};

export default useOfferings;
