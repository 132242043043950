import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    paddingTop: '1rem',
    paddingLeft: '1rem',
  },
  newErgContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    minWidth: '620px',
    maxWidth: '990px',
    paddingRight: '1vw',
  },
  existingErgContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    minWidth: '600px',
    paddingRight: '1vw',
  },
  headingContainer: {
    paddingLeft: '1vw',
    paddingTop: '1vh',
    paddingBottom: '1vh',
  },
  existingErgListContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    paddingLeft: '1vw',
    paddingTop: '1vh',
  },
  ephemeralResourceGroupsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: '2vh',
    paddingBottom: '4vh',
  },
}));

export default useStyles;
