import { ApolloError, useMutation } from '@apollo/client';
import { useApolloErrors } from '..';
import { UPDATE_APPLICATION } from '../../apollo/queries';

const useUpdateApplication = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [updateApplication, { loading: updateApplicationLoading }] =
    useMutation(UPDATE_APPLICATION, {
      onError: (error: ApolloError) => handleErrorResponse(error),
    });

  return { updateApplication, loading: updateApplicationLoading };
};

export default useUpdateApplication;
