import { PaletteMode } from '@mui/material';
import React from 'react';

interface ColorMode {
  mode: PaletteMode;
  toggleColorMode: () => void;
}

const ColorModeContext = React.createContext<ColorMode>({
  mode: 'light',
  toggleColorMode: () => {},
});

export type { ColorMode };
export default ColorModeContext;
