import { ApolloError, useMutation } from '@apollo/client';
import { ADD_ADDITIONAL_COLLABORATORS } from '../../apollo/queries';
import { useApolloErrors } from '../apolloHooks';

const useAddAdditionalCollaborators = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [addAdditionalCollaborators, { loading: addUsersLoading }] =
    useMutation(ADD_ADDITIONAL_COLLABORATORS, {
      onError: (error: ApolloError) => handleErrorResponse(error),
    });

  return {
    addAdditionalCollaborators,
    loading: addUsersLoading,
  };
};

export default useAddAdditionalCollaborators;
