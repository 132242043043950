import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import useRequesterEditModal from './useRequesterEditModal';
import RequesterEditAutoComplete from './RequesterEditAutoComplete';

interface RequesterEditModalPropsType {
  openEditRequesterModal: boolean;
  handleCloseEditRequesterModal: () => void;
  applicationId: string;
  handleApplicationSummaryRefresh: () => void;
}

const RequesterEditModal = (props: RequesterEditModalPropsType) => {
  const {
    openEditRequesterModal,
    handleCloseEditRequesterModal,
    applicationId,
    handleApplicationSummaryRefresh,
  } = props;

  const { formik, requesterError } = useRequesterEditModal(
    handleCloseEditRequesterModal,
    applicationId,
    handleApplicationSummaryRefresh
  );

  const { requester } = formik.values;

  return (
    <Dialog
      onClose={() => {
        handleCloseEditRequesterModal();
        formik.resetForm();
      }}
      open={openEditRequesterModal}
      fullWidth
      maxWidth="sm"
    >
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            <Typography variant="h6" component="p">
              Update application owner
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box>
              <RequesterEditAutoComplete
                componentID="requester"
                label="Application Owner"
                value={requester || null}
                fieldError={requesterError}
                helperText={
                  requesterError && formik.errors.requester
                    ? formik.errors.requester
                    : 'Select a user to be new application Owner'
                }
                fullHelpText="Application owner are the person that have admin privileage"
                onChangeHandler={(e, value) => {
                  formik.setFieldValue('requester', value || undefined);
                }}
                onBlurHandler={formik.handleBlur}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={
                !(formik.isValid && formik.dirty) || formik.isSubmitting
              }
              variant="contained"
              type="submit"
              endIcon={
                formik.isSubmitting ? (
                  <CircularProgress size="14px" sx={{ marginLeft: '0.5rem' }} />
                ) : undefined
              }
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
};

export default RequesterEditModal;
