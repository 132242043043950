import { AdoBuildStatusItem } from '@costco-service-catalog/bff-types';
import { Box, CircularProgress, Popover, Typography } from '@mui/material';
import React from 'react';
import BuildStatusItem from './BuildStatusItem';
import useStyles from './BuildStatusPopover.styles';

interface BuildStatusPopoverProps {
  item: AdoBuildStatusItem;
}

const BuildStatusPopover = (props: BuildStatusPopoverProps) => {
  const { item } = props;

  const [openedPopover, setOpenedPopover] = React.useState(false);
  const popoverAnchor = React.useRef(null);

  const { classes } = useStyles();

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          display: 'inline-flex',
          top: '-2px',
          margin: '0 3px',
        }}
      >
        <CircularProgress
          ref={popoverAnchor}
          size={20}
          variant="determinate"
          thickness={5}
          value={item.percentCompleted ?? 0}
          onMouseEnter={popoverEnter}
          onMouseLeave={popoverLeave}
        />
      </Box>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: { onMouseEnter: popoverEnter, onMouseLeave: popoverLeave },
        }}
      >
        <Box sx={{ padding: '5px' }}>
          <Typography variant="body2">{item.name}</Typography>
          {item.children &&
            item.children
              .filter((c) => c?.result !== 'skipped')
              .map((c) => <BuildStatusItem key={c?.id} item={c} isPopover />)}
        </Box>
      </Popover>
    </>
  );
};

export default BuildStatusPopover;
