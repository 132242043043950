import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useGlobalState } from '../../globalState/useGlobalState';

export interface Versions {
  serviceCatalogApi?: string;
  starterKitFactoryPipeline?: string;
  deletionFactoryPipeline?: string;
  policyFactoryPipeline?: string;
  backEndForFrontend?: string;
  client?: string;
  uiDB?: string;
  apiDB?: string;
}

const AppVersions = () => {
  const { appSettings } = useGlobalState();
  const [versions, setVersions] = useState<Versions>({});

  useEffect(() => {
    if (appSettings.useUIMocks.get()) {
      setVersions({
        serviceCatalogApi: '1.0.0',
        starterKitFactoryPipeline: 'refs/heads/main',
        deletionFactoryPipeline: 'refs/heads/main',
        policyFactoryPipeline: 'refs/heads/main',
        backEndForFrontend: '1.0.0',
        client: '1.0.0',
        uiDB: '1.0.0',
        apiDB: '1.0.0',
      });
    } else {
      fetch('/version.json')
        .then((res) => res.json())
        .then((clientData) => {
          const versionsUrl = new URL(
            'api/versions',
            appSettings.value.bffBaseUrl
          );

          fetch(versionsUrl.toString())
            .then((res) => res.json())
            .then((serverData) => {
              const newVersions = serverData as Versions;
              newVersions.client = clientData.version;
              setVersions(newVersions);
            });
        });
    }
  }, []);

  return (
    <div>
      <Typography variant="body2">
        SKFP: {versions.starterKitFactoryPipeline}
      </Typography>
      <Typography variant="body2">
        DFP: {versions.deletionFactoryPipeline}
      </Typography>
      <Typography variant="body2">
        PFP: {versions.policyFactoryPipeline}
      </Typography>
      <Typography variant="body2">
        SCAPI: {versions.serviceCatalogApi}
      </Typography>
      <Typography variant="body2">SCAPI DB: {versions.apiDB}</Typography>
      <Typography variant="body2">
        BFF: {versions.backEndForFrontend}
      </Typography>
      <Typography variant="body2">UI: {versions.client}</Typography>
      <Typography variant="body2">UI DB: {versions.uiDB}</Typography>
    </div>
  );
};

export default AppVersions;
