import { ApolloError, useMutation } from '@apollo/client';
import { useApolloErrors } from '..';
import { SUBMIT_NEW_EPHEMERAL_RG_REQUEST_MUTATION } from '../../apollo/queries';

const useSubmitEphemeralRgRequest = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [submitEphemeralRg, { loading: submitEphemeralRgLoading }] =
    useMutation(SUBMIT_NEW_EPHEMERAL_RG_REQUEST_MUTATION, {
      onError: (error: ApolloError) => handleErrorResponse(error),
    });

  return {
    submitEphemeralRg,
    loading: submitEphemeralRgLoading,
  };
};

export default useSubmitEphemeralRgRequest;
