import { ApolloError, useMutation } from '@apollo/client';
import { useApolloErrors } from '..';
import { DELETE_APPLICATION_MUTATION } from '../../apollo/queries';

const useDeleteApplication = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [deleteApplication, { loading: deleteApplicationLoading }] =
    useMutation(DELETE_APPLICATION_MUTATION, {
      onError: (error: ApolloError) => handleErrorResponse(error),
    });

  return {
    deleteApplication,
    loading: deleteApplicationLoading,
  };
};

export default useDeleteApplication;
