import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';
import {
  EcpOnboardingRequestInput,
  EnvironmentType,
} from '@costco-service-catalog/bff-types';
import { ROUTES } from '../../../../constants';
import {
  useEnqueueSnackbar,
  useSubmitECPOnboardingRequest,
} from '../../../../hooks';

type defaultFormValuesType = {
  organizationName?: string;
  environmentType: EnvironmentType;
  adGroupDN?: string;
  teamEmail?: string;
  quayQuota?: string;
};

const useECPQuayOnboardingModal = (handleModalClose: () => void) => {
  const navigate = useNavigate();
  const { submitECPOnboardingRequest } = useSubmitECPOnboardingRequest();
  const { pushSnackbar } = useEnqueueSnackbar();
  const initFormValues: defaultFormValuesType = {
    organizationName: undefined,
    environmentType: 'NP',
    quayQuota: '50',
  };

  const defaultValidationSchema = () =>
    object({
      organizationName: string()
        .min(2, 'Organization Name must be at least 2 characters.')
        .max(20, 'Organization Name must not be more than 20 characters.')
        .matches(
          /^[a-zA-Z0-9]*$/g,
          'Organization Name cannot contain any spaces or special characters.'
        )
        .matches(
          /^((?!\d).)/g,
          'Organization Name must not start with a numeric character.'
        )
        .required('Organization Name is required.'),
      adGroupDN: string().required('AD Group DN is required.'),
      quayQuota: string().matches(
        /^[0-9]*$/g,
        'Value should be a positive integer.'
      ),
      teamEmail: string()
        .max(100, 'Team email must not be more than 100 characters.')
        .matches(
          /^([A-Za-z0-9_\-.])+@costco.com/,
          'Team email must be a costco email address.'
        )
        .required('Team email is required.'),
    });

  const formik = useFormik({
    initialValues: initFormValues,
    validationSchema: defaultValidationSchema,
    enableReinitialize: Boolean(initFormValues.organizationName),
    onSubmit: async (
      values: defaultFormValuesType,
      helpers: FormikHelpers<defaultFormValuesType>
    ) => {
      const input: EcpOnboardingRequestInput = {
        environmentType: values.environmentType,
        teamEmail: values.teamEmail!,
        quayRegistryOnboarding: {
          organizationName: values.organizationName!,
          adGroupDN: values.adGroupDN!,
          quayQuota: values.quayQuota ? parseInt(values.quayQuota, 10) : 50,
        },
        requestType: 'Quay',
      };
      const submitOptions = {
        variables: {
          input,
        },
        onCompleted: () => {},
      };
      const submitECPOnboardingRequestRes = await submitECPOnboardingRequest(
        submitOptions
      );
      if (
        !submitECPOnboardingRequestRes.errors ||
        submitECPOnboardingRequestRes.errors?.length === 0
      ) {
        pushSnackbar('Onboarding request has been submitted.', {
          variant: 'success',
        });
        handleModalClose();
        navigate(`${ROUTES.UTILITIES}`);
      }
    },
  });

  const handleSubmitClick = async () => {
    formik.submitForm();
  };

  const enterOrgNameFieldError = formik.touched.organizationName
    ? Boolean(formik.errors.organizationName)
    : false;

  const enterADGroupDNFieldError = formik.touched.adGroupDN
    ? Boolean(formik.errors.adGroupDN)
    : false;

  const enterTeamEmailFieldError = formik.touched.teamEmail
    ? Boolean(formik.errors.teamEmail)
    : false;

  const enterQuayQuotaFieldError = formik.touched.quayQuota
    ? Boolean(formik.errors.quayQuota)
    : false;

  return {
    formik,
    enterOrgNameFieldError,
    enterADGroupDNFieldError,
    enterQuayQuotaFieldError,
    enterTeamEmailFieldError,
    handleSubmitClick,
  };
};

export default useECPQuayOnboardingModal;
