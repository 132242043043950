import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  cardStyling: {
    backgroundColor:
      theme.palette.mode === 'light'
        ? `rgba(61, 92, 121, 0.1)`
        : `rgba(61, 92, 121, 0.8)`,
    border: 'solid',
    borderColor:
      theme.palette.mode === 'light'
        ? `rgba(61, 92, 121, 0.4)`
        : `rgba(255, 255, 255, 0.4)`,
    borderWidth: '2px',
    borderRadius: '4px',
    boxShadow:
      theme.palette.mode === 'light'
        ? `1px 1px 5px ${theme.palette.grey[600]}`
        : `1px 1px 5px ${theme.palette.grey[300]}`,
    margin: '1rem',
    height: '230px',
    width: '230px',
    '&:hover': {
      cursor: 'pointer',
      boxShadow:
        theme.palette.mode === 'light'
          ? `2.5px 5px 10px ${theme.palette.grey[700]}`
          : `2.5px 5px 10px ${theme.palette.grey[200]}`,
      borderColor:
        theme.palette.mode === 'light'
          ? `rgba(61, 92, 121, 1)`
          : `rgba(255, 255, 255, 1)`,
    },
  },
  cardContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  topLeftIconContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '0.75rem',
    paddingLeft: '0.75rem',
  },
  cardContentStyling: {
    padding: '1rem',
    flex: 1,
  },
  constructionTapeDivider: {
    marginTop: '0.4rem',
    marginBottom: '0.6rem',
    height: '0.1rem',
    borderRadius: '1rem',
    background: '#141617',
  },
}));

export default useStyles;
