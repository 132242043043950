import { ApolloError, useQuery } from '@apollo/client';
import {
  ApplicationRequestDraft,
  ApplicationRequestDraftsResponse,
} from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '..';
import { LIST_APPLICATION_DRAFTS_QUERY } from '../../apollo/queries';

const useListApplicationDrafts = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    listApplicationRequestDrafts: ApplicationRequestDraftsResponse;
  }>(LIST_APPLICATION_DRAFTS_QUERY, {
    fetchPolicy: 'network-only',
    onError: (error: ApolloError) => handleErrorResponse(error),
  });
  const [ApplicationDrafts, setApplicationDrafts] = useState<
    ApplicationRequestDraft[]
  >([]);

  useEffect(() => {
    if (!loading && data) {
      setApplicationDrafts(
        (data?.listApplicationRequestDrafts
          ?.response as ApplicationRequestDraft[]) || undefined
      );
    }
  }, [loading, data]);

  return {
    error,
    data: ApplicationDrafts,
    loading,
    refetch,
  };
};

export default useListApplicationDrafts;
