import {
  ArrowRight,
  ChevronRightOutlined,
  DoneAll,
  InfoOutlined,
  InfoRounded,
  SystemUpdateAlt,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Collapse,
  Container,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  MultiselectAddToAppModal,
  MultiselectPreviouslyDeployed,
  OfferingMultiFooter,
  PageHeader,
  SectionHeader,
  SelectPrevDeployedCard,
} from '../../components';
import SelectedOfferingsInFooter from '../../components/MultiselectFooter/SelectedOfferings/SelectedOfferingsInFooter';
import MultiselectStarterKitSection from '../../components/MultiselectStarterKitSection/MultiselectStarterKitSection';
import BuildNewApplicationCard from '../../components/OfferingCardMulti/BuildNewApplicationCard';
import useOfferingMultiValidation from '../../hooks/validationHooks/useOfferingMultiValidation';
import { CardDetailsType } from '../../types';
import useStyles from './OfferingMultiPage.styles';
import useOfferingMultiPage from './useOfferingMultiPage';

const OfferingMultiPage = () => {
  const { classes } = useStyles();

  const {
    title,
    subTitle,
    handleRefArchSelection,
    handleContinue,
    referenceArchitectures,
    displayedOfferings,
    selectedOfferings,
    selectedUpdates,
    addSelectedUpdate,
    removeSelectedUpdate,
    previouslyDeployedWindowOpen,
    selectedPreviouslyDeployed,
    handlePreviouslyDeployedOpen,
    handlePreviouslyDeployedClose,
    handlePreviouslyDeployedSelect,
    deployedApplication,
    handleSetDeployedApplication,
    showAddToAppModal,
    toggleShowAddToAppModal,
    handleSubmitAddToApplication,
    addToAppLoading,
    multiselectMode,
    handleSetMultiselectMode,
    showMultiselect,
    unselectAutopopulate,
    multiSelectFooterCategorySlots,
  } = useOfferingMultiPage();
  const offeringSelectedValid = useOfferingMultiValidation(
    selectedOfferings,
    selectedUpdates,
    deployedApplication,
    referenceArchitectures
  );
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <MultiselectPreviouslyDeployed
        onSelect={handlePreviouslyDeployedSelect}
        onClose={handlePreviouslyDeployedClose}
        selectedValue={selectedPreviouslyDeployed}
        open={previouslyDeployedWindowOpen}
      />
      <MultiselectAddToAppModal
        open={showAddToAppModal}
        toggleOpen={toggleShowAddToAppModal}
        deployedApplication={deployedApplication}
        selectedOfferings={selectedOfferings}
        selectedUpdates={selectedUpdates}
        handleSubmitAddToApplication={handleSubmitAddToApplication}
        addToAppLoading={addToAppLoading}
      />
      <PageHeader icon={<DoneAll />} title={title} subtitle={subTitle} />
      <Container disableGutters maxWidth={false} className={classes.pageLayout}>
        <div className={classes.offeringsContainer}>
          <SectionHeader
            icon={<ChevronRightOutlined />}
            subIcon={
              <InfoOutlined
                htmlColor={theme.palette.text.secondary}
                fontSize="small"
              />
            }
            title="Build a New Application / Select a Previously Deployed Application"
            subtitle="To modify Landing Zones (ex. add a VNet / Region) select Utilities from the left navigation."
          />
          <div className={classes.offeringSection}>
            <BuildNewApplicationCard
              multiselectMode={multiselectMode}
              handleSetMultiselectMode={handleSetMultiselectMode}
            />
            <SelectPrevDeployedCard
              deployedApplication={deployedApplication}
              handleSetDeployedApplication={handleSetDeployedApplication}
              handlePreviouslyDeployedSelect={handlePreviouslyDeployedSelect}
              selectedPreviouslyDeployed={selectedPreviouslyDeployed}
              handlePreviouslyDeployedOpen={handlePreviouslyDeployedOpen}
              multiselectMode={multiselectMode}
              unselectAutopopulate={unselectAutopopulate}
            />
          </div>
        </div>
        <Collapse in={showMultiselect()}>
          <MultiselectStarterKitSection
            referenceArchitectures={referenceArchitectures}
            deployedApplication={deployedApplication}
            displayedOfferings={displayedOfferings}
            selectedOfferings={selectedOfferings}
            handleRefArchSelection={handleRefArchSelection}
            selectedUpdates={selectedUpdates}
            addSelectedUpdate={addSelectedUpdate}
            removeSelectedUpdate={removeSelectedUpdate}
          />
        </Collapse>
        {(selectedOfferings.length > 0 || deployedApplication) &&
        !showAddToAppModal ? (
          <OfferingMultiFooter data-testid="multiselect-footer-bar">
            <div className={classes.footerBarContainer}>
              <div className={classes.continueTextStyle}>
                <div className={classes.selectedOfferingTextbox}>
                  {deployedApplication ? (
                    <Tooltip title={deployedApplication?.applicationName || ''}>
                      <Typography
                        fontWeight="bold"
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        Deployed Application
                        <InfoRounded className={classes.infoFooterIcon} />
                      </Typography>
                    </Tooltip>
                  ) : undefined}
                </div>
                <div className={classes.selectedOfferingTextbox}>
                  {selectedUpdates.length > 0 ? (
                    <Tooltip
                      title={
                        selectedUpdates?.map((s, i) => (
                          <div key={s.shortOfferingName}>
                            <Typography variant="caption">
                              {i + 1}) {s.name} v({s.version})
                            </Typography>
                          </div>
                        )) || ''
                      }
                    >
                      <Typography
                        fontWeight="bold"
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        Selected Updates ({selectedUpdates.length})
                        <SystemUpdateAlt className={classes.infoFooterIcon} />
                      </Typography>
                    </Tooltip>
                  ) : undefined}
                </div>
                <div className={classes.selectedOfferingsWithinFooterContainer}>
                  <SelectedOfferingsInFooter
                    offeringSelectedValid={offeringSelectedValid}
                    selectedOfferings={selectedOfferings}
                  />
                </div>
              </div>
              <div className={classes.continueButtonStyle}>
                <Tooltip
                  title={
                    !offeringSelectedValid.valid
                      ? offeringSelectedValid.invalidReason
                      : ''
                  }
                >
                  <Box>
                    <Button
                      disabled={!offeringSelectedValid.valid}
                      endIcon={<ArrowRight />}
                      variant="contained"
                      onClick={() =>
                        handleContinue(selectedOfferings as CardDetailsType[])
                      }
                    >
                      <Typography className={classes.continueButtonStyle}>
                        Continue
                      </Typography>
                    </Button>
                  </Box>
                </Tooltip>
              </div>
            </div>
          </OfferingMultiFooter>
        ) : undefined}
      </Container>
    </div>
  );
};

export default OfferingMultiPage;
