import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  facetListTitle: {
    fontSize: '0.8175em',
    opacity: 0.5,
    paddingBottom: '.5rem',
  },
  facetRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export default useStyles;
