import { ApolloError, useMutation } from '@apollo/client';
import { useApolloErrors } from '..';
import { SAVE_APPLICATION_DRAFT_MUTATION } from '../../apollo/queries';

const useSaveApplicationDraft = () => {
  const { handleErrorResponse: handleApolloErrorResponse } = useApolloErrors();

  const [saveApplicationDraft, { loading: saveApplicationDraftLoading }] =
    useMutation(SAVE_APPLICATION_DRAFT_MUTATION, {
      onError: (error: ApolloError) => handleApolloErrorResponse(error),
    });

  return {
    saveApplicationDraft,
    loading: saveApplicationDraftLoading,
  };
};

export default useSaveApplicationDraft;
