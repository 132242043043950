import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ReferenceArchitecture } from '@costco-service-catalog/bff-types';
import {
  CommonDropdown,
  CommonFormTextField,
} from '../../FormComponents/CommonFields';
import useEphemeralResourceGroupModalCreateNew from './EphRgModalCreateNewComponents/useEphemeralResourceGroupModalCreateNew';
import ERGModalCreateNewRepoRef from './EphRgModalCreateNewComponents/ERGModalCreateNewRepoRef';

type EphemeralResourceGroupModalCreateNewPropsType = {
  handleModalClose: () => void;
  selectedERG: string | undefined;
  selectedRefArch: ReferenceArchitecture | undefined;
  refArchArray: ReferenceArchitecture[];
  runEphemeralRgsRefetch: () => Promise<void>;
  modalOption: 'new' | 'existing' | undefined;
};

const EphemeralResourceGroupModalCreateNew = (
  props: EphemeralResourceGroupModalCreateNewPropsType
) => {
  const {
    modalOption,
    handleModalClose,
    selectedERG,
    selectedRefArch,
    refArchArray,
    runEphemeralRgsRefetch,
  } = props;
  const {
    formik,
    ephemeralResourceGroupModalContent,
    listApplicationsError,
    listApplicationsLoading,
    listApplicationsData,
    selectTargetApplicationData,
    selectHostingApplicationFieldError,
    selectTargetApplicationFieldError,
    adoBoardsUserStoryFieldError,
    repositoryArray,
    handleUpdateRepository,
    handleResetRepositoryArr,
    repositoryArrayIsValid,
    handleSetExpectedRepoArrayLength,
    validateMinimumAppVersion,
    handleGoToUpdateHostingApp,
    handleGoToDeployNewTarget,
    expirationDate,
    handleChangeExpirationDate,
    defaultMaximumDate,
  } = useEphemeralResourceGroupModalCreateNew(
    selectedERG,
    selectedRefArch,
    refArchArray,
    handleModalClose,
    runEphemeralRgsRefetch
  );

  if (ephemeralResourceGroupModalContent) {
    const {
      dialogTitle,
      dialogContentText,
      selectTargetApplicationLabel,
      selectTargetApplicationNoOptionsFoundButtonText,
      selectTargetApplicationHelperText,
      selectTargetApplicationFullHelpText,
      targetApplicationShortOfferingName,
    } = ephemeralResourceGroupModalContent.content;
    const {
      adoBoardsUserStory,
      selectHostingApplication,
      selectTargetApplication,
    } = formik.values;

    const open = Boolean(modalOption);

    return (
      <Dialog open={open} onClose={handleModalClose} maxWidth="md" fullWidth>
        <Box>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <DialogContentText>{dialogContentText}</DialogContentText>
              <Box>
                <Container>
                  <Box>
                    {listApplicationsError ? (
                      <Box>
                        <Typography>
                          An error occurred while obtaining your deployed
                          applications. Please try again.
                        </Typography>
                      </Box>
                    ) : (
                      <CommonDropdown
                        componentID="selectHostingApplication"
                        label="Select Hosting Application"
                        data={listApplicationsData}
                        value={selectHostingApplication || null}
                        fieldError={
                          formik.touched.selectHostingApplication
                            ? Boolean(formik.errors.selectHostingApplication)
                            : false
                        }
                        helperText={
                          selectHostingApplicationFieldError &&
                          formik.errors.selectHostingApplication
                            ? formik.errors.selectHostingApplication
                            : 'Select a hosting application.'
                        }
                        fullHelpText="Please select a hosting application from the dropdown menu."
                        loading={listApplicationsLoading}
                        required
                        noOptionsText="No Options Found."
                        onChangeHandler={(e, value) => {
                          formik.setFieldValue(
                            'selectHostingApplication',
                            value || undefined
                          );
                          formik.setFieldValue('selectTargetApplication', '');
                        }}
                        onBlurHandler={formik.handleBlur}
                        getOptionLabel={(option) =>
                          option.applicationName as string
                        }
                        getOptionSelected={(o, v) =>
                          o.applicationName === v.applicationName
                        }
                      />
                    )}
                  </Box>
                  <Box>
                    <CommonDropdown
                      componentID="selectTargetApplication"
                      label={selectTargetApplicationLabel}
                      data={selectTargetApplicationData}
                      value={selectTargetApplication || null}
                      fieldError={
                        formik.touched.selectTargetApplication
                          ? Boolean(formik.errors.selectTargetApplication)
                          : false
                      }
                      helperText={
                        selectTargetApplicationFieldError &&
                        formik.errors.selectTargetApplication
                          ? formik.errors.selectTargetApplication
                          : selectTargetApplicationHelperText
                      }
                      fullHelpText={selectTargetApplicationFullHelpText}
                      loading={false}
                      required
                      noOptionsText="No Options Found."
                      noOptionsButton={
                        selectHostingApplication ? (
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleGoToDeployNewTarget(
                                selectHostingApplication,
                                targetApplicationShortOfferingName
                              )
                            }
                          >
                            {selectTargetApplicationNoOptionsFoundButtonText}
                          </Button>
                        ) : undefined
                      }
                      onChangeHandler={(e, value) => {
                        formik.setFieldValue(
                          'selectTargetApplication',
                          value || undefined
                        );
                      }}
                      onBlurHandler={formik.handleBlur}
                      getOptionLabel={(option) =>
                        `${
                          option.completedStarterKitCartsApprovalRequestId
                        } - ${option.offering.offeringName} v.${
                          option.offering.offeringVersion
                        } at ${new Date(
                          option.completedStarterKitRequestedDateTime
                        ).toLocaleString()}`
                      }
                      getOptionSelected={(o, v) =>
                        o.completedStarterKitId === v.completedStarterKitId
                      }
                    />
                  </Box>
                  <Box>
                    <CommonFormTextField
                      componentID="adoBoardsUserStory"
                      label="ADO Boards User Story ID"
                      value={adoBoardsUserStory || ''}
                      onChangeHandler={formik.handleChange}
                      onBlurHandler={formik.handleBlur}
                      fieldError={
                        formik.touched.adoBoardsUserStory
                          ? Boolean(formik.errors.adoBoardsUserStory)
                          : false
                      }
                      helperText={
                        adoBoardsUserStoryFieldError &&
                        formik.errors.adoBoardsUserStory
                          ? formik.errors.adoBoardsUserStory
                          : 'Enter ADO Boards User Story ID.'
                      }
                      fullHelpText="(6-7 char., numeric-only): Enter ADO Boards User Story ID"
                    />
                  </Box>
                  <Box
                    sx={{
                      padding: '1rem',
                      maxWidth: '60rem',
                      minWidth: '20rem',
                    }}
                  >
                    <DatePicker
                      label="Environment Expiration Date"
                      value={expirationDate}
                      onChange={(v) => {
                        handleChangeExpirationDate(v);
                      }}
                      maxDate={defaultMaximumDate}
                      minDate={dayjs().add(1, 'day')}
                    />
                  </Box>
                  <Box>
                    {selectHostingApplication?.id &&
                    selectTargetApplication?.completedStarterKitId &&
                    selectTargetApplication?.offering?.offeringShortName ? (
                      <Box>
                        {validateMinimumAppVersion.valid ? (
                          <ERGModalCreateNewRepoRef
                            selectHostingApplication={selectHostingApplication}
                            selectTargetApplication={selectTargetApplication}
                            targetAppOfferingShortName={
                              selectTargetApplication.offering.offeringShortName
                            }
                            repositoryArray={repositoryArray}
                            handleUpdateRepository={handleUpdateRepository}
                            handleResetRepositoryArr={handleResetRepositoryArr}
                            handleSetExpectedRepoArrayLength={
                              handleSetExpectedRepoArrayLength
                            }
                          />
                        ) : (
                          <Box>
                            <Container>
                              <Typography>
                                {validateMinimumAppVersion.invalidReason}
                              </Typography>
                              {validateMinimumAppVersion.missingDeps.length >
                              0 ? (
                                <Box>
                                  <Typography>
                                    You must deploy a(n){' '}
                                    {
                                      ephemeralResourceGroupModalContent.offeringName
                                    }{' '}
                                    that is this version or higher to create an
                                    ephemeral resource group.
                                  </Typography>

                                  <br />
                                  <Button
                                    variant="contained"
                                    onClick={() =>
                                      handleGoToUpdateHostingApp(
                                        formik.values.selectHostingApplication
                                      )
                                    }
                                  >
                                    Update{' '}
                                    {
                                      ephemeralResourceGroupModalContent.offeringName
                                    }
                                  </Button>
                                </Box>
                              ) : undefined}
                            </Container>
                          </Box>
                        )}
                      </Box>
                    ) : undefined}
                  </Box>
                </Container>
                <Container>
                  <Box sx={{ paddingX: '2rem', maxWidth: '950px' }} />
                </Container>
              </Box>
            </DialogContent>
            <DialogActions sx={{ margin: '1rem' }}>
              <Button
                size="large"
                onClick={handleModalClose}
                sx={{ paddingX: '2rem' }}
              >
                Cancel
              </Button>
              <Button
                disabled={
                  !(formik.isValid && formik.dirty) ||
                  formik.isSubmitting ||
                  !repositoryArrayIsValid
                }
                variant="contained"
                size="large"
                type="submit"
                sx={{ paddingX: '4rem' }}
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
    );
  }
  if (!(modalOption === undefined)) {
    return (
      <Box>
        <DialogTitle>Please define an entry for ERG modal content.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Define an entry in the Ephemeral Developer Environment Content Map
            array for this starter kit.
          </DialogContentText>
          <DialogContentText>
            No matching entry was found{' '}
            {selectedERG
              ? `for ${selectedERG}.`
              : `. Selected Ephemeral Developer Environment is undefined.`}
          </DialogContentText>
        </DialogContent>
      </Box>
    );
  }
  return <div />;
};

export default EphemeralResourceGroupModalCreateNew;
