import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Tooltip, Typography, Box } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowParams,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import React from 'react';
import useStyles from './ApplicationDraftsGrid.styles';

interface ApplicationDrafts {
  id: string;
  applicationName: string;
  offeringRequests: { offeringName: string }[];
}

interface ApplicationDraftsGridProps {
  rows: ApplicationDrafts[];
  onRowClick: (param: GridRowParams, event: any) => void;
  onDeleteClick: (param: GridCellParams, event: React.MouseEvent) => void;
}

const ApplicationDraftsGrid = (props: ApplicationDraftsGridProps) => {
  const { rows, onRowClick, onDeleteClick } = props;

  const { classes } = useStyles();

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'applicationName',
      sort: 'asc' as GridSortDirection,
    },
  ]);

  const columns: GridColDef[] = [
    {
      field: 'applicationName',
      headerName: 'Application Name',
      flex: 1,
    },
    {
      field: 'offeringRequests',
      headerName: 'Offering Name(s)',
      flex: 1,
      renderCell: (cellParams) => {
        const cellValue = cellParams.value.map(
          (cellParamValue: any, idx: any) =>
            `${cellParamValue.offeringName}${
              idx === cellParams.value.length - 1 ? '' : ', '
            }`
        );
        return (
          <Box>
            <Tooltip title={cellValue}>
              <Typography>{cellValue}</Typography>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (cellParams) => (
        <div className={classes.actionsContainer}>
          <Tooltip title="Delete">
            <IconButton
              aria-label="delete"
              onClick={(event) => {
                onDeleteClick(cellParams, event);
              }}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className={classes.container}>
      {rows && (
        <DataGrid
          rows={rows || []}
          columns={columns}
          getCellClassName={(params: GridCellParams) => classes.dataCell}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 20]}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          onRowClick={onRowClick}
          pagination
        />
      )}
    </div>
  );
};

export default ApplicationDraftsGrid;
