import { EnvironmentRequest } from '@costco-service-catalog/bff-types';
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import useStyles from '../CommonFields/Common.styles';

interface CidrBlockSizeTablePropsType {
  selectedEnvs: EnvironmentRequest[] | undefined;
  handleCidrBlockSizeChange: (
    envName: string,
    event: SelectChangeEvent<number | null>
  ) => void;
  availableBlockSizes: string[];
}

export const availableBlockSizesNumber = (availableBlockSizes: string[]) =>
  availableBlockSizes.map((v) => {
    const blockSize = parseInt(v, 10);
    const hosts = 2 ** (32 - blockSize) - 2;
    return { blockSize, hosts };
  });

const CidrBlockSizeTable = (props: CidrBlockSizeTablePropsType) => {
  const { selectedEnvs, handleCidrBlockSizeChange, availableBlockSizes } =
    props;

  const { classes } = useStyles();

  return selectedEnvs && selectedEnvs?.length > 0 ? (
    <div className={classes.fieldWrap}>
      <Box sx={{ px: 1 }}>
        <TableContainer>
          <Table size="small" aria-label="cidr-block-size-table">
            <TableHead>
              <TableRow>
                <TableCell>Environment Name</TableCell>
                <TableCell>Environment Type</TableCell>
                <TableCell>CIDR Block Size</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedEnvs?.map((row) => (
                <TableRow key={row.name}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {row.type === 'pd' ? 'Production' : 'Non Production'}
                  </TableCell>
                  <TableCell>
                    <Select
                      labelId="cidr-select-small"
                      id="cidr-select-small"
                      value={row.cidrBlockSize}
                      label="CIDR Block Size"
                      onChange={(e) => {
                        handleCidrBlockSizeChange(row.name || '', e);
                      }}
                    >
                      {availableBlockSizesNumber(availableBlockSizes).map(
                        (opt) => (
                          <MenuItem
                            key={opt.blockSize.toString()}
                            value={opt.blockSize.toString()}
                          >{`/${opt.blockSize} (${opt.hosts} hosts)`}</MenuItem>
                        )
                      )}
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  ) : (
    <div />
  );
};

export default CidrBlockSizeTable;
