import {
  Application,
  Maybe,
  OfferingCategory,
  ReferenceArchitecture,
  StarterKitOffering,
} from '@costco-service-catalog/bff-types';
import {
  Check,
  Close,
  SystemUpdateAlt,
  WarningOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Tooltip,
  Typography,
} from '@mui/material';
import { compare, validate, compareVersions } from 'compare-versions';
import { useState } from 'react';
import useStyles from './OfferingCardMulti.styles';

interface DeployedRefArchComponentCardProps {
  refArch: ReferenceArchitecture;
  request: { status?: string; cartsId?: string };
  completedOffering: StarterKitOffering | undefined | null;
  selectedUpdates: ReferenceArchitecture[];
  addSelectedUpdate: (refArch: ReferenceArchitecture) => void;
  removeSelectedUpdate: (refArch: ReferenceArchitecture) => void;
  category: string;
  priorVersions: string[];
}

const removeDuplicatesDeployedApplication = (
  deployedApplication: Application
) => {
  const offerings: StarterKitOffering[] = [];
  deployedApplication.starterKitRequests?.forEach((starterKitRequest) => {
    if (
      starterKitRequest?.offerings &&
      starterKitRequest.offerings?.length > 0
    ) {
      offerings.push(...(starterKitRequest.offerings as StarterKitOffering[]));
    }
  });

  const foundOfferingShortNames = offerings.map((o) => o.offeringShortName);
  const uniqueOfferingShortNames = foundOfferingShortNames.filter(
    (item, index) => foundOfferingShortNames.indexOf(item) === index
  );

  const offeringsVersionMatrix = uniqueOfferingShortNames.map((shortName) => {
    const shortNameOfferings = offerings.filter(
      (o) => o.offeringShortName === shortName
    );
    const sortRowForRecentVersions = shortNameOfferings.sort((b, a) => {
      if (
        a.offeringVersion &&
        b.offeringVersion &&
        validate(a.offeringVersion) &&
        validate(b.offeringVersion)
      ) {
        const v = compareVersions(a.offeringVersion, b.offeringVersion);
        return v;
      }
      return 0;
    });
    return { shortName: shortName || '', offerings: sortRowForRecentVersions };
  });

  return offeringsVersionMatrix;
};

const checkDuplicate = (
  offeringsVersionMatrix: {
    shortName: string;
    offerings: StarterKitOffering[];
  }[],
  duplicateOffering: StarterKitOffering
) => {
  const multipleOfferings = offeringsVersionMatrix.find(
    (v) => v.shortName === duplicateOffering.offeringShortName
  )?.offerings;
  if (multipleOfferings && multipleOfferings.length < 2) {
    return false;
  }
  if (multipleOfferings && multipleOfferings.length >= 2) {
    const mostRecentOffering = multipleOfferings[0];
    if (
      mostRecentOffering.offeringVersion !== duplicateOffering.offeringVersion
    ) {
      return true;
    }
  }
  return false;
};

const UpdateEndIcon = (props: { hover: boolean }) => {
  const { hover } = props;
  return hover ? <Close /> : <Check />;
};

const UpdateButtonComponent = (props: {
  refArch: ReferenceArchitecture;
  category: string;
  updatedVersion: string;
  selectedUpdates: ReferenceArchitecture[];
  addSelectedUpdate: (refArch: ReferenceArchitecture) => void;
  removeSelectedUpdate: (refArch: ReferenceArchitecture) => void;
}) => {
  const {
    refArch,
    category,
    updatedVersion,
    selectedUpdates,
    addSelectedUpdate,
    removeSelectedUpdate,
  } = props;
  const [hover, setHover] = useState(false);

  const isUpdateSelected = Boolean(
    selectedUpdates.find(
      (s) => s.shortOfferingName === refArch.shortOfferingName
    )
  );

  const handleUpdateButtonClick = () => {
    if (isUpdateSelected) {
      removeSelectedUpdate(refArch);
    } else {
      addSelectedUpdate(refArch);
    }
  };

  return (
    <div>
      <div>
        <Tooltip
          enterDelay={150}
          leaveDelay={0}
          leaveTouchDelay={0}
          enterTouchDelay={150}
          enterNextDelay={500}
          onOpen={() => setHover(true)}
          onClose={() => setHover(false)}
          title={
            !isUpdateSelected
              ? `Update Deployed ${category} to ${updatedVersion}`
              : `Remove Pending Update`
          }
          placement={!isUpdateSelected ? 'top-start' : 'bottom-end'}
        >
          <span>
            <Button
              variant="outlined"
              style={{ textTransform: 'none' }}
              startIcon={!isUpdateSelected ? <SystemUpdateAlt /> : undefined}
              endIcon={
                isUpdateSelected ? <UpdateEndIcon hover={hover} /> : undefined
              }
              onClick={() => handleUpdateButtonClick()}
            >
              {!isUpdateSelected
                ? `New Version Available (${updatedVersion})`
                : `Update to v(${updatedVersion}) selected.`}
            </Button>
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

const UpdateCheck = (props: {
  completedOffering: StarterKitOffering | null | undefined;
  refArch: ReferenceArchitecture;
  category: string;
  currentVersion: Maybe<string> | undefined;
  updatedVersion: Maybe<string> | undefined;
  selectedUpdates: ReferenceArchitecture[];
  addSelectedUpdate: (refArch: ReferenceArchitecture) => void;
  removeSelectedUpdate: (refArch: ReferenceArchitecture) => void;
}) => {
  const { classes } = useStyles();
  const {
    completedOffering,
    refArch,
    category,
    currentVersion,
    updatedVersion,
    selectedUpdates,
    addSelectedUpdate,
    removeSelectedUpdate,
  } = props;
  return (
    <div>
      {category !== 'LandingZone' ? (
        <div>
          {!completedOffering ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
              }}
            >
              <WarningOutlined sx={{ marginX: '4px' }} fontSize="small" />
              <Typography fontSize="small">
                Currently unable to check for updates
              </Typography>
            </Box>
          ) : (
            <div>
              {currentVersion &&
              updatedVersion &&
              compare(currentVersion, updatedVersion, '<') ? (
                <div>
                  <div className={classes.vspacer} />

                  <UpdateButtonComponent
                    refArch={refArch}
                    category={category}
                    updatedVersion={updatedVersion}
                    selectedUpdates={selectedUpdates}
                    addSelectedUpdate={addSelectedUpdate}
                    removeSelectedUpdate={removeSelectedUpdate}
                  />
                </div>
              ) : undefined}
            </div>
          )}
        </div>
      ) : undefined}
    </div>
  );
};

const DeployedRefArchComponentCard = (
  props: DeployedRefArchComponentCardProps
) => {
  const {
    refArch,
    request,
    completedOffering,
    category,
    selectedUpdates,
    addSelectedUpdate,
    removeSelectedUpdate,
    priorVersions,
  } = props;
  const displayedPriorVersions = priorVersions.slice(1);

  const currentVersion = completedOffering?.offeringVersion;
  const updatedVersion = refArch?.version;
  const { classes } = useStyles();

  return (
    <div>
      <div className={classes.cardContainer}>
        <Card className={classes.cardNoHover}>
          <CardContent className={classes.cardTitleContainer}>
            <div>Deployed {category}</div>
          </CardContent>
          <CardContent className={classes.cardPlusContainer}>
            <div>
              <UpdateCheck
                completedOffering={completedOffering}
                refArch={refArch}
                category={category}
                currentVersion={currentVersion}
                updatedVersion={updatedVersion}
                selectedUpdates={selectedUpdates}
                addSelectedUpdate={addSelectedUpdate}
                removeSelectedUpdate={removeSelectedUpdate}
              />
              <div className={classes.vspacer} />
              <Typography>
                <strong>Name: </strong>
                {completedOffering?.offeringName}
              </Typography>
              <Typography>
                <strong>Status: </strong>
                {completedOffering?.status}
              </Typography>
              <Typography>
                <strong>Version: </strong>
                {completedOffering?.offeringVersion}
              </Typography>
              {displayedPriorVersions.length > 0 ? (
                <Typography component="div">
                  <strong>Prior Versions: </strong>
                  {displayedPriorVersions.map((v) => (
                    <div key={v}>{v}</div>
                  ))}
                </Typography>
              ) : undefined}
              <div className={classes.vspacer} />
              <Typography>
                <strong>Related CARTS+ Ticket: </strong>
                {request?.cartsId} ({request?.status})
              </Typography>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export const DeployedRefArchComponentCards = (
  deployedApplication: Application,
  category: OfferingCategory,
  refArchs: ReferenceArchitecture[],
  selectedUpdates: ReferenceArchitecture[],
  addSelectedUpdate: (refArch: ReferenceArchitecture) => void,
  removeSelectedUpdate: (refArch: ReferenceArchitecture) => void
) => {
  const offeringsVersionMatrix =
    removeDuplicatesDeployedApplication(deployedApplication);
  const watchRefArchs = [...refArchs];
  if (watchRefArchs && watchRefArchs.length > 0) {
    return deployedApplication?.starterKitRequests?.map((request) => {
      const cartsId = request?.cartsApprovalRequestId || '';
      const status = request?.status || '';
      const preIndexOfferings = request?.offerings?.map((offering, i) => ({
        ...offering,
        idx: i,
      }));
      return preIndexOfferings?.map((offering) => {
        if (offering?.category === category) {
          const findBaseRefArch =
            watchRefArchs.find(
              (ra) => offering.offeringShortName === ra.shortOfferingName
            ) || {};

          const determineDuplicate = checkDuplicate(
            offeringsVersionMatrix,
            offering
          );
          if (determineDuplicate) {
            return <div key={offering.idx} />;
          }
          return (
            <DeployedRefArchComponentCard
              refArch={findBaseRefArch}
              key={offering.idx}
              request={{ status, cartsId }}
              completedOffering={offering}
              category={category}
              selectedUpdates={selectedUpdates}
              addSelectedUpdate={addSelectedUpdate}
              removeSelectedUpdate={removeSelectedUpdate}
              priorVersions={
                offeringsVersionMatrix
                  .find((v) => v.shortName === offering.offeringShortName)
                  ?.offerings?.map((o) => o.offeringVersion || '') || []
              }
            />
          );
        }
        return undefined;
      });
    });
  }
  return <div />;
};

export default DeployedRefArchComponentCard;
