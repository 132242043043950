import { ApplicationRequestDraftResponse } from '@costco-service-catalog/bff-types';
import { ApolloError } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants';
import {
  useDeleteApplicationDraft,
  useEnqueueSnackbar,
  useListApplicationDrafts,
} from '../../hooks';

const useApplicationDraftsPage = () => {
  const title = 'Saved Application Request Drafts';

  const { pushSnackbar } = useEnqueueSnackbar();
  const { error, data, loading, refetch } = useListApplicationDrafts();
  const { deleteApplicationDraft } = useDeleteApplicationDraft();
  const [tableData, setTableData] = useState<any>(null);
  const [refetchInFlight, setRefetchInFlight] = useState<boolean>();
  const navigate = useNavigate();

  let isSubscribed = true;
  useEffect(() => {
    if (data && isSubscribed && !loading && !refetchInFlight) {
      setTableData(
        data.map(({ id, applicationName, offeringRequests }) => ({
          id,
          key: id,
          applicationName,
          offeringRequests,
        }))
      );
    }
    return () => {
      isSubscribed = false;
    };
  }, [data, refetchInFlight]);

  const handleRowClick = (dataRow: any) => {
    navigate(`${ROUTES.OFFERING_REQUEST}/${dataRow?.row.id}`, {
      replace: false,
    });
  };

  const handleDeleteApplicationDraftClick = async (id: string) => {
    setRefetchInFlight(true);
    await deleteApplicationDraft({
      variables: { input: { id } },
      onCompleted: () => {
        pushSnackbar('Successfully deleted draft. List will be refreshed.', {
          variant: 'success',
        });
      },
    });
    await refetch();
    setRefetchInFlight(false);
  };

  return {
    title,
    data: tableData,
    loading: loading,
    count: tableData?.length,
    handleRowClick,
    handleDeleteApplicationDraftClick,
    refetchInFlight,
    setRefetchInFlight,
  };
};

export default useApplicationDraftsPage;
