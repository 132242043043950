import { gql } from '@apollo/client';

const GET_MANAGEMENT_GROUPS_QUERY = gql`
  query getManagementGroups {
    getManagementGroups {
      code
      count
      response {
        name
        displayName
      }
    }
  }
`;

export default GET_MANAGEMENT_GROUPS_QUERY;
