import { useQuery } from '@apollo/client';
import {
  LockedTfStateBlobsResponse,
  QueryGetLockedTfStateBlobsArgs,
} from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { GET_LOCKED_TF_STATE_BLOBS } from '../../apollo/queries';

const useGetLockedTFStateBlobs = (params: QueryGetLockedTfStateBlobsArgs) => {
  const { loading, error, data } = useQuery<{
    getLockedTFStateBlobs: LockedTfStateBlobsResponse;
  }>(GET_LOCKED_TF_STATE_BLOBS, {
    fetchPolicy: 'network-only',
    variables: { ...params },
  });
  const [lockedTfStateBlobs, setLockedTfStateBlobs] = useState<string[]>();

  useEffect(() => {
    setLockedTfStateBlobs(data?.getLockedTFStateBlobs?.response as string[]);
  }, [loading, data]);

  return {
    error,
    data: lockedTfStateBlobs,
    loading,
  };
};

export default useGetLockedTFStateBlobs;
