import { gql } from '@apollo/client';

const ADD_WIZ_MEMBERS = gql`
  mutation addWizMembers(
    $applicationId: ID!
    $wizMembers: [ProjectMemberInput]!
  ) {
    addWizMembers(applicationId: $applicationId, wizMembers: $wizMembers) {
      code
      status
      response {
        id
        email
      }
    }
  }
`;

export default ADD_WIZ_MEMBERS;
