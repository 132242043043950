import { gql } from '@apollo/client';

const SUBMIT_NEW_EPHEMERAL_RG_REQUEST_MUTATION = gql`
  mutation SubmitEphemeralRgRequest($input: EphemeralRgRequestInput) {
    submitEphemeralRgRequest(input: $input) {
      code
      status
      response {
        id
        status
        createdDateTime
        updatedDateTime
        lastAwaitingAdoRunRefreshTime
        requester {
          userName
          firstName
          lastName
          email
        }
        applicationId
        requestId
        offeringShortName
        storyId
        repoVersions {
          repoName
          version
        }
      }
    }
  }
`;

export default SUBMIT_NEW_EPHEMERAL_RG_REQUEST_MUTATION;
