import { gql } from '@apollo/client';

const UPDATE_APPLICATION = gql`
  mutation updateApplication(
    $applicationId: ID!
    $updateRequestInput: UpdateApplicationRequestInput
  ) {
    updateApplication(
      applicationId: $applicationId
      updateRequestInput: $updateRequestInput
    ) {
      status
      code
    }
  }
`;

export default UPDATE_APPLICATION;
