import { gql } from '@apollo/client';

const UNLOCK_STATE_FILE_MUTATION = gql`
  mutation unlockStateFile($input: unlockStateFileInput) {
    unlockStateFile(input: $input) {
      status
      code
    }
  }
`;

export default UNLOCK_STATE_FILE_MUTATION;
