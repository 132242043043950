import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  DialogContainer: { padding: '2rem' },
  ButtonContainer: { padding: '1rem' },
  ButtonSpinnerContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  SpinnerStyling: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
