import { Status } from '@costco-service-catalog/bff-types';
import DraftIcon from '@mui/icons-material/Save';
import { GridCellParams } from '@mui/x-data-grid';
import React, { useState } from 'react';
import {
  ConfirmDialog,
  PageHeader,
  ApplicationDraftsGrid,
} from '../../components';

import useStyles from './ApplicationDraftsPage.styles';
import useApplicationDraftsPage from './useApplicationDraftsPage';

const OKStatus: Status = 'OK';

const ApplicationDraftsPage = () => {
  const { classes } = useStyles();

  const [draftIdToDelete, setDraftIdToDelete] = useState<string>('');
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const {
    title,
    data,
    loading,
    count,
    handleRowClick,
    handleDeleteApplicationDraftClick,
    refetchInFlight,
  } = useApplicationDraftsPage();

  const handleDeleteClick = (
    param: GridCellParams,
    event: React.MouseEvent
  ) => {
    event.preventDefault();
    event.stopPropagation();

    setDraftIdToDelete(param.id as string);
    setDialogOpen(true);
  };

  const handleDialogOk = async () => {
    await handleDeleteApplicationDraftClick(draftIdToDelete);
    setDialogOpen(false);
  };

  const handleDialogCancel = () => {
    setDraftIdToDelete('');
    setDialogOpen(false);
  };

  const formatCount = (count: any) => {
    let formattedCount = count;
    if (formattedCount === undefined) {
      formattedCount = 0;
    }
    return formattedCount;
  };

  return (
    <div className={classes.root}>
      <ConfirmDialog
        title="Delete draft?"
        message="Are you sure you want to delete this draft?"
        open={dialogOpen}
        handleOk={handleDialogOk}
        handleCancel={handleDialogCancel}
      />
      <PageHeader
        icon={<DraftIcon />}
        title={title}
        subtitle={`Total: ${loading ? '...' : formatCount(count)}`}
      />
      {refetchInFlight ? (
        <div />
      ) : (
        <div>
          <ApplicationDraftsGrid
            rows={data}
            onRowClick={handleRowClick}
            onDeleteClick={handleDeleteClick}
          />
        </div>
      )}
    </div>
  );
};

export default ApplicationDraftsPage;
