import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  listBox: {
    flex: 1,
    paddingTop: '4rem',
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    minWidth: '16rem',
  },
  listItem: {
    paddingLeft: '1.5rem',
  },
  routerLink: {
    textDecoration: 'none',
  },
  themeItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 .5rem 0 1.5rem',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
  themeItemLabel: {
    flex: '1',
    marginLeft: '2rem',
  },
  footer: {
    marginTop: 'auto',
  },
  versions: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    padding: 10,
  },
  feedback: {
    whiteSpace: 'normal',
    padding: 10,
    fontSize: '.667rem',
  },
}));

export default useStyles;
