import { Engineering } from '@mui/icons-material';
import { Container, Typography } from '@mui/material';
import { PageHeader } from '../../components';
import useStyles from './UtilitiesPage.styles';
import useUtilitiesPage from './useUtilitiesPage';
import UtilitiesPageLayout from '../../components/UtilitiesComponents/UtilitiesPageLayout';

const UtilitiesPage = () => {
  const { classes } = useStyles();
  const { title, subTitle, availableUtilities, popup } = useUtilitiesPage();

  return (
    <div>
      <PageHeader icon={<Engineering />} title={title} subtitle={subTitle} />
      <Container disableGutters maxWidth={false} className={classes.pageLayout}>
        <div>
          <UtilitiesPageLayout
            availableUtilities={availableUtilities}
            popup={popup}
          />
        </div>
      </Container>
    </div>
  );
};

export default UtilitiesPage;
