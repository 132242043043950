import { gql } from '@apollo/client';

const DELETE_APPLICATION_DRAFT_MUTATION = gql`
  mutation deleteApplicationRequestDraft($input: EntityParams) {
    deleteApplicationRequestDraft(input: $input) {
      status
      code
    }
  }
`;

export default DELETE_APPLICATION_DRAFT_MUTATION;
