import { useEffect, useState } from 'react';
import { useListECPOnboardingRequests } from '../../hooks';

const useECPOnboardingListPage = () => {
  const title = 'My ECP Onboarding Requests';
  const { data, loading } = useListECPOnboardingRequests();
  const [tableData, setTableData] = useState<any>(null);

  let isSubscribed = true;
  useEffect(() => {
    if (data && isSubscribed && !loading) {
      setTableData(
        data.map(
          ({
            id,
            environmentType,
            requestType,
            namespaceOnboarding,
            quayRegistryOnboarding,
            cartsApprovalRequestId,
            createdDateTime,
            hasRunningPipelines,
            status,
          }) => ({
            id,
            environmentType,
            requestType,
            teamName: namespaceOnboarding?.teamName,
            namespaceName: namespaceOnboarding?.namespaceName,
            organizationName: quayRegistryOnboarding?.organizationName,
            key: id,
            cartsApprovalRequestId,
            createdDateTime,
            hasRunningPipelines,
            status,
          })
        )
      );
    }
    return () => {
      isSubscribed = false;
    };
  }, [data]);

  return {
    title,
    data: tableData,
    loading: loading,
    count: tableData?.length,
  };
};

export default useECPOnboardingListPage;
