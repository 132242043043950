import { ManagementGroup } from '@costco-service-catalog/bff-types';
import { CommonDropdown } from '../CommonFields';

interface ManagementGroupDropdownPropType {
  data: ManagementGroup[];
  value: ManagementGroup | null;
  loading: boolean;
  fieldError: boolean;
  errorText?: string;
  onChangeHandler: (
    event: React.ChangeEvent<{}>,
    value: ManagementGroup | null
  ) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const ManagementGroupDropdown = (props: ManagementGroupDropdownPropType) => {
  const {
    data,
    value,
    loading,
    fieldError,
    errorText,
    onChangeHandler,
    onBlurHandler,
  } = props;
  return (
    <CommonDropdown
      componentID="managementGroup"
      label="Department"
      data={data}
      value={value}
      loading={loading}
      required
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      getOptionLabel={(option) => option.displayName as string}
      getOptionSelected={(o, v) => o.name === v.name}
      fieldError={fieldError}
      helperText={
        fieldError && errorText
          ? errorText
          : 'Select your department from the list.'
      }
      fullHelpText="Your Department selection will control the Management Group in Azure that your resources with be deployed. It will also help with naming and tagging of resources in Azure."
    />
  );
};

ManagementGroupDropdown.defaultProps = {
  errorText: undefined,
};

export default ManagementGroupDropdown;
