import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  infoFooterIcon: {
    paddingLeft: 5,
    color: theme.palette.mode === 'dark' ? 'whitesmoke' : 'gray',
  },
  selectedOfferingsWithinFooterContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  selectedOfferingsWithinFooter: {
    border: 'solid',
    borderColor: theme.palette.mode === 'dark' ? 'whitesmoke' : 'gray',
    borderWidth: 1,
    borderRadius: 6,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 10,
    margin: 5,
    boxShadow: '1px 1px 2px gray',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selectedOfferingsNeededWithinFooter: {
    border: 'dashed',
    borderColor: theme.palette.mode === 'dark' ? 'whitesmoke' : 'gray',
    borderWidth: 1,
    borderRadius: 6,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 15,
    paddingRight: 10,
    margin: 5,
    '&:hover': {
      cursor: 'pointer',
    },
    opacity: 0.65,
  },
  infoFooterIconMargin: { marginLeft: 5 },
}));

export default useStyles;
