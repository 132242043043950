import { ApolloError } from '@apollo/client/errors';
import { ReferenceArchitecture as OfferingsType } from '@costco-service-catalog/bff-types';

export const mockOfferings: OfferingsType[] = [
  {
    id: '1',
    name: 'Azure Cloud Native Starter Kit - Basic',
    category: 'LandingZone',
    repoUrl:
      'https://dev.azure.com/costcocloudops/service-catalog/_git/sc-sk-azure-cloud-desert-island',
    version: '1.0.8',
    ready: true,
    shortOfferingName: 'cloudland',
    vnetPeered: false,
    description:
      'Just the Basics :Resource Group, Virtual Network, Storage Account, KeyVault.',
    type: 'RefArch',
    components: [
      {
        id: 'Component.Network.CloudIsland.1.0',
        name: 'Azure Cloud Island',
        description:
          'Multisubscription Azure Cloud environment for hosting your workloads, preprovisioned through code that accounts for scalability, elasticity, security, networking and identity',
      },
      {
        id: 'Component.Storage.Blob.1.0',
        name: 'Azure Blob Storage',
        description:
          'Object storage solution for cloud to stores huge unstructured object data for distributed access ',
      },
    ],
  },
  {
    id: '2',
    name: 'Azure Peered Landing Zone - Basic',
    category: 'LandingZone',
    repoUrl:
      'https://dev.azure.com/costcocloudops/service-catalog/_git/sc-sk-azure-peered-landing-zone',
    version: '1.0.4',
    ready: true,
    shortOfferingName: 'peerland',
    vnetPeered: true,
    description:
      'A peered Azure Landing Zone with just the basics: Resource Group, Peered Virtual Network, Storage Account, KeyVault.',
    type: 'RefArch',
    components: [
      {
        id: 'Component.Network.PeeredLandingZone.1.0',
        name: 'Peered Landing Zone',
        description:
          'Azure environments with peered virtual network, resources in either virtual network can directly connect with resources in the peered virtual network',
      },
      {
        id: 'Component.Storage.Blob.1.0',
        name: 'Azure Blob Storage',
        description:
          'Object storage solution for cloud to stores huge unstructured object data for distributed access ',
      },
    ],
  },
  {
    id: '3',
    name: 'Azure Core Infrastructure',
    category: 'CoreInfra',
    repoUrl:
      'https://dev.azure.com/costcocloudops/service-catalog/_git/sc-sk-coreinfra',
    version: '1.0.0',
    ready: true,
    shortOfferingName: 'coreinfra',
    description:
      'An additive starter kit to be applied on top of landing zone starter kit, adding: ADO agents, subnets, app gateway, app service plans, key vault, and Front Door.',
    type: 'RefArch',
    components: [
      {
        id: 'Component.Azure.Agent.1.0',
        name: 'ADO Agents',
        description:
          "To build your code or deploy your software using Azure Pipelines, you need at least one agent. As you add more code and people, you'll eventually need more.",
      },
      {
        id: 'Component.Azure.Subnet.1.0',
        name: 'Azure Subnet',
        description:
          "Subnets enable you to segment the virtual network into one or more sub-networks and allocate a portion of the virtual network's address space to each subnet. You can then deploy Azure resources in a specific subnet.",
      },
      {
        id: 'Component.Azure.AppGateway.1.0',
        name: 'Azure App Gateway',
        description:
          'Azure Application Gateway is a web traffic load balancer that enables you to manage traffic to your web applications. Traditional load balancers operate at the transport layer (OSI layer 4 - TCP and UDP) and route traffic based on source IP address and port, to a destination IP address and port.',
      },
      {
        id: 'Component.Azure.Subscription.1.0',
        name: 'Azure Subscription',
        description:
          'A unit of billing for use of all of your Azure services that rolls up to a tenant that owns a instance of Microsoft cloud services.',
      },
      {
        id: 'Component.Secret.KeyVault.1.0',
        name: 'Azure Key Vault',
        description:
          'Recommended service for the storage of application runtime secrets.',
      },
      {
        id: 'Component.Firewall.FrontDoor.1.0',
        name: 'Front Door',
        description:
          'Scalable and secured entry point for fast delivery of global available applications with load balance and route traffic capabilities.',
      },
    ],
    applicationList: 'coreinfra,kvinject',
  },
  {
    id: '4',
    name: 'Cloud Native Application',
    category: 'Application',
    repoUrl:
      'https://dev.azure.com/costcocloudops/service-catalog/_git/sc-sk-cloud-native-app',
    version: '0.0.2',
    ready: true,
    shortOfferingName: 'cloudapp',
    description:
      'An additive starter kit to be applied on top of a starter kit that has had coreinfra added to it, adding: a basic hello world app deployable to the infrastructure created by coreinfra.',
    type: 'RefArch',
    components: [
      {
        id: 'Component.Azure.AzureFunctionApp.1.0',
        name: 'Azure Function App',
        description:
          'Preprovisioned Azure DevOps project for managing the planned work and constructing a build and deployment pipeline to deploy Azure services and solutions',
      },
      {
        id: 'Component.Azure.WebApp.1.0',
        name: 'Azure Web App',
        description:
          'App Service Web Apps lets you quickly build, deploy, and scale enterprise-grade web, mobile, and API apps running on any platform. Meet rigorous performance, scalability, security and compliance requirements while using a fully managed platform to perform infrastructure maintenance.',
      },
      {
        id: 'Component.Azure.CosmosDB.1.0',
        name: 'Azure Cosmos DB',
        description:
          'Azure Cosmos DB is a fully managed, globally-distributed, horizontally scalable in storage and throughput, multi-model database service backed up by comprehensive SLAs. Includes boilerplate for MongoDB API',
      },
      {
        id: 'Component.Azure.UI.1.0',
        name: 'React SPA',
        description:
          'Boilerplate to create single-page React applications. It offers a modern build setup with prebuilt JS build tooling and JS transcompiler configuration.',
      },
    ],
    applicationList: 'api,bff,ui,db',
  },
];

const mockOfferingsRes = () => {
  const error: ApolloError | undefined = undefined;
  const data: OfferingsType[] = mockOfferings;
  const loading: boolean = false;
  return {
    error,
    data,
    loading,
  };
};

export default mockOfferingsRes;
