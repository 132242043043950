import { gql } from '@apollo/client';

const RETRY_STARTER_KIT_REQUEST_MUTATION = gql`
  mutation retryStarterKitRequest($input: RetryStarterKitRequestInput) {
    retryStarterKitRequest(input: $input) {
      status
      code
    }
  }
`;

export default RETRY_STARTER_KIT_REQUEST_MUTATION;
