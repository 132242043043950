import { gql } from '@apollo/client';

const LIST_AZURE_POLICIES_QUERY = gql`
  query GetAzurePolicies {
    getAzurePolicies {
      code
      status
      response {
        id
        name
        key
        displayName
        description
      }
      count
    }
  }
`;

export default LIST_AZURE_POLICIES_QUERY;
