import AppsIcon from '@mui/icons-material/Apps';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DraftIcon from '@mui/icons-material/Save';
import ScienceRoundedIcon from '@mui/icons-material/ScienceRounded';
import GridViewIcon from '@mui/icons-material/GridView';
import { LeftMenuItem } from '../types';
import ROUTES from './routes.enum';

const menu: LeftMenuItem[] = [
  {
    icon: DoneAllIcon,
    label: 'Service Multi Select',
    path: ROUTES.OFFERING_MULTISELECT,
    isActive: true,
  },
  {
    icon: AppsIcon,
    label: 'My Applications',
    path: ROUTES.APPLICATION_LIST,
    isActive: true,
  },
  {
    icon: AdminPanelSettingsIcon,
    label: 'All Applications',
    path: ROUTES.ALL_APPLICATIONS,
    isActive: true,
    isRestricted: true,
  },
  {
    icon: DraftIcon,
    label: 'Application Drafts',
    path: ROUTES.APPLICATION_DRAFTS,
    isActive: true,
  },
  {
    icon: GridViewIcon,
    label: 'ECP Onboarding Requests',
    path: ROUTES.ECP_ONBOARDING_LIST,
    isActive: true,
  },
  {
    icon: ScienceRoundedIcon,
    label: 'Ephemeral Apps',
    path: ROUTES.EPHEMERAL_RG_APPS,
    isActive: true,
  },
  {
    icon: EngineeringIcon,
    label: 'Utilities Page',
    path: ROUTES.UTILITIES,
    isActive: true,
  },
  {
    icon: MenuBookIcon,
    label: 'How To',
    path: ROUTES.HOW_TO,
    isActive: true,
  },
  {
    icon: LocalHospitalIcon,
    label: 'Health Check',
    path: ROUTES.HEALTH_CHECK,
    isActive: true,
  },
];

export default menu;
