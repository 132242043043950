import { gql } from '@apollo/client';

const RETRY_DELETE_APPLICATION_MUTATION = gql`
  mutation retryDeleteApplication($input: EntityParams) {
    retryDeleteApplication(input: $input) {
      status
      code
      response
    }
  }
`;

export default RETRY_DELETE_APPLICATION_MUTATION;
