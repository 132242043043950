import {
  AadUser,
  AdoBoardsProcess,
  ApplicationRequestDraft,
  EnvironmentRequest,
  ReferenceArchitecture,
} from '@costco-service-catalog/bff-types';
import { SelectChangeEvent } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import { useGlobalState } from '../../../globalState/useGlobalState';
import {
  defaultFormValues,
  defaultFormValuesType,
  formValuesType,
  useADOOrgs,
  useADOProjectsForOrg,
  useEnqueueSnackbar,
  useFormSubmit,
  useManagementGroups,
} from '../../../hooks';
import { CardDetailsType } from '../../../types';
import azureCloudNativeStarterKitValidationSchema from './Validation/azureCloudNativeStarterKitValidationSchema';

const parseFormValuesFromDraft = (draft: ApplicationRequestDraft) => {
  const createDraft: defaultFormValuesType = {
    starterKitDraftId: draft.id ? draft.id.toString() : undefined,
    managementGroup: draft.managementGroup || undefined,
    applicationName: draft.applicationName || undefined,
    applicationDescription: draft.applicationDescription || undefined,
    nonProdEnvironments: draft.requestedEnvironments
      ? (draft.requestedEnvironments.filter(
          (e) => e?.type === 'np'
        ) as EnvironmentRequest[])
      : [],
    prodEnvironments: draft.requestedEnvironments
      ? (draft.requestedEnvironments.filter(
          (e) => e?.type === 'pd'
        ) as EnvironmentRequest[])
      : [],
    primaryRegion: draft.primaryRegion || undefined,
    secondaryRegion: draft.secondaryRegion || undefined,
    supportGroupEmail: draft.supportGroupEmail || undefined,
    additionalCollaborators:
      (draft.additionalCollaborators as AadUser[]) || undefined,
    aadGroupMembers: (draft.aadGroupMembers as AadUser[]) || undefined,
    aadReaderGroupMembers:
      (draft.aadReaderGroupMembers as AadUser[]) || undefined,
    wizMembers: (draft.wizMembers as AadUser[]) || undefined,
    isExistingAdoProject: draft.isExistingAdoProject || false,
    existingAdoProjectOrg: draft.existingAdoProjectOrg || undefined,
    existingAdoProject: draft.existingAdoProject || undefined,
    adoProjectContributors:
      (draft.adoProjectContributors as AadUser[]) || undefined,
    adoProjectAdministrators:
      (draft.adoProjectAdministrators as AadUser[]) || undefined,
    adoProjectBoardAdministrators:
      (draft.adoProjectBoardAdministrators as AadUser[]) || undefined,
  };
  return createDraft;
};

export const removePolicyModeSuffix = (policyKey: string) => {
  if (policyKey.includes('Audit')) {
    return policyKey.replace('Audit', '');
  }
  if (policyKey.includes('Deny')) {
    return policyKey.replace('Deny', '');
  }
  return policyKey;
};

const useAzureCloudNativeStarterKit = (
  offering: CardDetailsType,
  selectedOfferings: ReferenceArchitecture[] | undefined,
  draft: ApplicationRequestDraft | undefined
) => {
  const navigate = useNavigate();
  const { pushSnackbar } = useEnqueueSnackbar();
  const { appSettings } = useGlobalState();

  const initFormValues = draft
    ? parseFormValuesFromDraft(draft)
    : defaultFormValues;

  const { handleNewApplicationSubmit, handleApplicationSaveDraft } =
    useFormSubmit();

  const evalOfferings = (
    offering: CardDetailsType,
    selectedOfferings: ReferenceArchitecture[] | undefined
  ) => {
    const finalOfferings: CardDetailsType[] = [];

    if (offering && !selectedOfferings) {
      finalOfferings.push(offering);
      return finalOfferings;
    }
    if (offering && selectedOfferings) {
      selectedOfferings.forEach((so) =>
        finalOfferings.push(so as CardDetailsType)
      );
      return finalOfferings;
    }
    return finalOfferings;
  };
  initFormValues.isEphemeral = offering.isEphemeral || false;
  initFormValues.wizEnabled = appSettings.wizEnabled.get() || false;

  const maxNpEnvironments = parseInt(appSettings.maxNpEnvironments.get(), 10);
  const maxPdEnvironments = parseInt(appSettings.maxPdEnvironments.get(), 10);
  const productionEnvironmentSelectionEnabled =
    appSettings.productionEnvironmentSelectionEnabled.get();

  const formik = useFormik({
    initialValues: initFormValues,
    validationSchema: azureCloudNativeStarterKitValidationSchema(
      maxNpEnvironments,
      maxPdEnvironments,
      productionEnvironmentSelectionEnabled
    ),
    validateOnMount: Boolean(initFormValues.starterKitDraftId),
    onSubmit: async (
      values: defaultFormValuesType,
      helpers: FormikHelpers<defaultFormValuesType>
    ) => {
      if (offering) {
        const submitResponse = await handleNewApplicationSubmit(
          values as formValuesType,
          evalOfferings(offering, selectedOfferings)
        );

        if (
          submitResponse.submitNewApplicationRes.data?.submitApplicationRequest
            ?.code === 200
        ) {
          navigate(
            `${ROUTES.APPLICATION_SUMMARY}/${submitResponse.submitNewApplicationRes.data.submitApplicationRequest.response.id}`
          );
        }
      }
      helpers.setSubmitting(false);
    },
  });

  useEffect(() => {
    if (initFormValues.starterKitDraftId) {
      Object.keys(initFormValues).forEach((key) => {
        const val = initFormValues[key as keyof defaultFormValuesType];
        switch (typeof val) {
          case 'boolean':
            formik.setFieldTouched(key, true);
            break;
          case 'string':
            formik.setFieldTouched(key, val.length > 0);
            break;
          case 'object':
            if (Array.isArray(val)) {
              formik.setFieldTouched(key, val.length > 0);
              break;
            }
            formik.setFieldTouched(key, Boolean(val));
            break;
          default:
            break;
        }
      });
    }
  }, []);

  const {
    error: ManagementGroupsError,
    loading: ManagementGroupsLoading,
    data: ManagementGroupsData,
  } = useManagementGroups();

  const {
    error: ADOOrgsError,
    loading: ADOOrgsLoading,
    data: ADOOrgsData,
  } = useADOOrgs();

  const {
    error: ADOProjectsError,
    loading: ADOProjectsLoading,
    data: ADOProjectsData,
  } = useADOProjectsForOrg(formik.values.existingAdoProjectOrg || '');

  const managementGroupData = ManagementGroupsData;
  const adoOrgData = ADOOrgsData;
  const adoProjectData = ADOProjectsData;

  const handleFormSave = async (event: any) => {
    formik.setSubmitting(true);
    if (selectedOfferings) {
      const saveDraftResponse = await handleApplicationSaveDraft(
        formik.values as formValuesType,
        selectedOfferings
      );
      event.preventDefault();

      if (
        saveDraftResponse.saveApplicationDraftResponse.data
          ?.saveApplicationRequestDraft?.code === 200
      ) {
        formik.setFieldValue(
          'starterKitDraftId',
          saveDraftResponse.saveApplicationDraftResponse.data
            ?.saveApplicationRequestDraft?.response.id
        );
        pushSnackbar('Successfully saved application draft', {
          variant: 'success',
        });
      }
    }
    formik.setSubmitting(false);
  };

  const handleNonProdEnvChange = (
    event: React.ChangeEvent<{}>,
    value: string[] | string | null
  ) => {
    if (Array.isArray(value)) {
      const nonProdEnvs = value.map((s) => {
        const existingEnv = formik.values.nonProdEnvironments.find(
          (e) => e.name === s.toUpperCase()
        );
        if (existingEnv) {
          return existingEnv;
        }
        return {
          type: 'np',
          name: s.toUpperCase(),
          cidrBlockSize: 25,
        };
      });
      formik.setFieldValue('nonProdEnvironments', nonProdEnvs);
      return nonProdEnvs;
    }
    const nonProdEnvs = value
      ? [{ type: 'np', name: value.toUpperCase(), cidrBlockSize: 25 }]
      : [];
    formik.setFieldValue('nonProdEnvironments', nonProdEnvs);
    return nonProdEnvs;
  };

  const handleProdEnvChange = (
    event: React.ChangeEvent<{}>,
    value: string[] | string | null
  ) => {
    if (Array.isArray(value)) {
      const prodEnvs = value.map((s) => {
        const existingEnv = formik.values.prodEnvironments.find(
          (e) => e.name === s.toUpperCase()
        );
        if (existingEnv) {
          return existingEnv;
        }
        return {
          type: 'pd',
          name: s.toUpperCase(),
          cidrBlockSize: 25,
        };
      });
      formik.setFieldValue('prodEnvironments', prodEnvs);
      return prodEnvs;
    }
    const prodEnvs = value
      ? [{ type: 'pd', name: value.toUpperCase(), cidrBlockSize: 25 }]
      : [];
    formik.setFieldValue('prodEnvironments', prodEnvs);
    return prodEnvs;
  };

  const handleCidrBlockSizeChange = (
    envName: string,
    event: SelectChangeEvent<number | null>
  ) => {
    const updateNonProdEnvs: EnvironmentRequest[] | undefined =
      formik.values.nonProdEnvironments.map((env) => {
        if (env.name === envName) {
          return {
            ...env,
            cidrBlockSize: parseInt(event.target.value as string, 10),
          };
        }
        return { ...env };
      });

    const updateProdEnvs: EnvironmentRequest[] | undefined =
      formik.values.prodEnvironments.map((env) => {
        if (env.name === envName) {
          return {
            ...env,
            cidrBlockSize: parseInt(event.target.value as string, 10),
          };
        }
        return { ...env };
      });

    formik.setFieldValue('nonProdEnvironments', updateNonProdEnvs);
    formik.setFieldValue('prodEnvironments', updateProdEnvs);
  };

  const handleAdditionalCollaboratorChange = (
    event: React.ChangeEvent<{}>,
    value: AadUser[]
  ) => {
    const values = value
      .filter((u) => typeof u.id === 'string' && typeof u.name === 'string')
      .map((u) => {
        const aadUser = {
          id: u.id as string,
          name: u.name as string,
          userPrincipalName: u.userPrincipalName as string,
        };
        return aadUser;
      });

    formik.setFieldValue('additionalCollaborators', values);
  };

  const handleAadGroupMembersChange = (
    event: React.ChangeEvent<{}>,
    value: AadUser[]
  ) => {
    const values = value
      .filter((u) => typeof u.id === 'string' && typeof u.name === 'string')
      .map((u) => {
        const aadUser = {
          id: u.id as string,
          name: u.name as string,
          userPrincipalName: u.userPrincipalName as string,
        };
        return aadUser;
      });
    formik.setFieldValue('aadGroupMembers', values);
  };

  const handleAadReaderGroupMembersChange = (
    event: React.ChangeEvent<{}>,
    value: AadUser[]
  ) => {
    const values = value
      .filter((u) => typeof u.id === 'string' && typeof u.name === 'string')
      .map((u) => {
        const aadUser = {
          id: u.id as string,
          name: u.name as string,
          userPrincipalName: u.userPrincipalName as string,
        };
        return aadUser;
      });
    formik.setFieldValue('aadReaderGroupMembers', values);
  };

  const handleWizMembersChange = (
    event: React.ChangeEvent<{}>,
    value: AadUser[]
  ) => {
    const values = value
      .filter((u) => typeof u.id === 'string' && typeof u.name === 'string')
      .map((u) => {
        const aadUser = {
          id: u.id as string,
          name: u.name as string,
          userPrincipalName: u.userPrincipalName as string,
        };
        return aadUser;
      });
    formik.setFieldValue('wizMembers', values);
  };

  const handleAdoOrgChange = (
    event: React.ChangeEvent<{}>,
    value: string | null
  ) => {
    if (value) {
      formik.setFieldValue('existingAdoProjectOrg', value);
      formik.setFieldValue('existingAdoProject', undefined);
    } else {
      formik.setFieldValue('existingAdoProjectOrg', undefined);
      formik.setFieldValue('existingAdoProject', undefined);
    }
  };

  const handleAdoProjectContributorsChange = (
    event: React.ChangeEvent<{}>,
    value: AadUser[]
  ) => {
    const values = value
      .filter((u) => typeof u.id === 'string' && typeof u.name === 'string')
      .map((u) => {
        const aadUser = {
          id: u.id as string,
          name: u.name as string,
          userPrincipalName: u.userPrincipalName as string,
        };
        return aadUser;
      });

    formik.setFieldValue('adoProjectContributors', values);
  };

  const handleAdoProjectAdministratorsChange = (
    event: React.ChangeEvent<{}>,
    value: AadUser[]
  ) => {
    const values = value
      .filter((u) => typeof u.id === 'string' && typeof u.name === 'string')
      .map((u) => {
        const aadUser = {
          id: u.id as string,
          name: u.name as string,
          userPrincipalName: u.userPrincipalName as string,
        };
        return aadUser;
      });

    formik.setFieldValue('adoProjectAdministrators', values);
  };

  const handleADOProjectBoardAdministratorsChange = (
    event: React.ChangeEvent<{}>,
    value: AadUser[]
  ) => {
    const values = value
      .filter((u) => typeof u.id === 'string' && typeof u.name === 'string')
      .map((u) => {
        const adoUser = {
          id: u.id as string,
          name: u.name as string,
          userPrincipalName: u.userPrincipalName as string,
        };
        return adoUser;
      });

    formik.setFieldValue('adoProjectBoardAdministrators', values);
  };

  const handleADOProjectBoardsProcessChange = (
    event: React.ChangeEvent<{}>,
    value: AdoBoardsProcess | null
  ) => {
    formik.setFieldValue('adoProjectBoardsProcess', value);
  };

  const contributorsAreReadersInProdMsg = `In Production-type environments all users will be restricted to Reader.`;

  const existingProjectPrerequisitesMsg = `View prerequisites to use an existing project here.`;

  const existingProjectPrereqDocUrl =
    appSettings.existingProjectPrereqDocUrl.get();

  const handlePolicyChange = (type: 'pd' | 'np', policyKey?: string) => {
    if (policyKey) {
      let { ecoSystemPD, ecoSystemNP } = formik.values;

      if (ecoSystemPD === undefined) {
        ecoSystemPD = { enabledPolicies: [] };
      }

      if (ecoSystemNP === undefined) {
        ecoSystemNP = { enabledPolicies: [] };
      }

      const pdPolicies = ecoSystemPD.enabledPolicies as string[];
      const npPolicies = ecoSystemNP.enabledPolicies as string[];
      const pdPoliciesNoMode = [...pdPolicies].map((v) =>
        removePolicyModeSuffix(v)
      );
      const npPoliciesNoMode = [...npPolicies].map((v) =>
        removePolicyModeSuffix(v)
      );

      const modifyPolicyChange = (
        policiesNoMode: string[],
        policies: string[],
        ecoSystem: 'ecoSystemPD' | 'ecoSystemNP'
      ) => {
        const remove = policiesNoMode.findIndex((v) => v === policyKey);
        if (remove > -1) {
          const newPolicies = {
            enabledPolicies: policies.filter((v, i) => i !== remove),
          };
          formik.setFieldValue(ecoSystem, { ...newPolicies });
        } else {
          const newPolicies = {
            enabledPolicies: policies.concat(policyKey.concat('Audit')),
          };
          formik.setFieldValue(ecoSystem, { ...newPolicies });
        }
      };

      if (type === 'pd') {
        modifyPolicyChange(pdPoliciesNoMode, pdPolicies, 'ecoSystemPD');
      } else {
        modifyPolicyChange(npPoliciesNoMode, npPolicies, 'ecoSystemNP');
      }
    }
  };

  const handlePolicyModeChange = (
    type: 'pd' | 'np',
    mode: 'Audit' | 'Deny',
    policyKey?: string
  ) => {
    if (policyKey) {
      let { ecoSystemPD, ecoSystemNP } = formik.values;

      if (ecoSystemPD === undefined) {
        ecoSystemPD = { enabledPolicies: [] };
      }

      if (ecoSystemNP === undefined) {
        ecoSystemNP = { enabledPolicies: [] };
      }

      const pdPolicies = ecoSystemPD.enabledPolicies as string[];
      const npPolicies = ecoSystemNP.enabledPolicies as string[];
      const pdPoliciesNoMode = [...pdPolicies].map((v) =>
        removePolicyModeSuffix(v)
      );
      const npPoliciesNoMode = [...npPolicies].map((v) =>
        removePolicyModeSuffix(v)
      );

      const modifyPolicyModeChange = (
        policiesNoMode: string[],
        policies: string[],
        ecoSystem: 'ecoSystemPD' | 'ecoSystemNP'
      ) => {
        const policyKeyIndex = policiesNoMode.findIndex(
          (v) => v === removePolicyModeSuffix(policyKey)
        );
        const updatedPolicies = [...policies].map((v, i) => {
          if (i !== policyKeyIndex) {
            return v;
          }
          return removePolicyModeSuffix(v).concat(mode);
        });
        const newPolicies = {
          enabledPolicies: updatedPolicies,
        };
        formik.setFieldValue(ecoSystem, { ...newPolicies });
      };

      if (type === 'pd') {
        modifyPolicyModeChange(pdPoliciesNoMode, pdPolicies, 'ecoSystemPD');
      } else {
        modifyPolicyModeChange(npPoliciesNoMode, npPolicies, 'ecoSystemNP');
      }
    }
  };

  return {
    formik,
    managementGroupData,
    ManagementGroupsLoading,
    adoOrgData,
    ADOOrgsLoading,
    adoProjectData,
    ADOProjectsLoading,
    handleNonProdEnvChange,
    handleProdEnvChange,
    handleCidrBlockSizeChange,
    handleAdditionalCollaboratorChange,
    handleAadGroupMembersChange,
    handleAadReaderGroupMembersChange,
    handleWizMembersChange,
    handleAdoOrgChange,
    handleAdoProjectContributorsChange,
    handleAdoProjectAdministratorsChange,
    handleADOProjectBoardAdministratorsChange,
    handleFormSave,
    contributorsAreReadersInProdMsg,
    existingProjectPrerequisitesMsg,
    existingProjectPrereqDocUrl,
    handlePolicyChange,
    handlePolicyModeChange,
    handleADOProjectBoardsProcessChange,
  };
};

export default useAzureCloudNativeStarterKit;
