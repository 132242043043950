import { ApolloError, useMutation } from '@apollo/client';
import { DELETE_USERS_FROM_GROUP } from '../../apollo/queries';
import { useApolloErrors } from '../apolloHooks';

const useRemoveUsersFromGroup = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [deleteUsers, { loading: deleteUsersLoading }] = useMutation(
    DELETE_USERS_FROM_GROUP,
    {
      onError: (error: ApolloError) => handleErrorResponse(error),
    }
  );

  return {
    deleteUsers,
    loading: deleteUsersLoading,
  };
};

export default useRemoveUsersFromGroup;
