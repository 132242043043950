import { Box, Dialog, Typography } from '@mui/material';
import { utilitiesPayloadType } from '../../../pages/ApplicationSummaryPage/useApplicationSummaryPage';
import AddVnetOrRegionModal from './AddVnet/AddVnetOrRegionModal';
import AddEnvironmentModal from './AddEnvironment/AddEnvironmentModal';
import UnlockStateFileModal from './UnlockStateFile/UnlockStateFileModal';
import DeleteEnvironmentModal from './DeleteEnvironment/DeleteEnvironmentModal';
import ECPNamespaceOnboardingModal from './ECPNamespaceOnboarding/ECPNamespaceOnboardingModal';
import ECPQuayOnboardingModal from './ECPQuayOnboarding/ECPQuayOnboardingModal';

type UtilityModalContainerPropsType = {
  popup: utilitiesPayloadType | undefined;
  modalOpen: boolean;
  modalOption: string;
  handleModalClose: () => void;
};

const UtilityModalToOpen = (props: {
  popup: utilitiesPayloadType | undefined;
  utilityNameId: string;
  handleModalClose: () => void;
}) => {
  const { utilityNameId, handleModalClose, popup } = props;
  switch (utilityNameId) {
    case 'AddVnetOrRegion':
      return (
        <AddVnetOrRegionModal
          popup={popup}
          handleModalClose={handleModalClose}
        />
      );
    case 'AddEnvironment':
      return (
        <AddEnvironmentModal
          popup={popup}
          handleModalClose={handleModalClose}
        />
      );
    case 'UnlockStateFile':
      return (
        <UnlockStateFileModal
          popup={popup}
          handleModalClose={handleModalClose}
        />
      );
    case 'DeleteEnvironment':
      return (
        <DeleteEnvironmentModal
          popup={popup}
          handleModalClose={handleModalClose}
        />
      );

    case 'ECPNamespaceOnboarding':
      return (
        <ECPNamespaceOnboardingModal handleModalClose={handleModalClose} />
      );

    case 'ECPQuayOnboarding':
      return <ECPQuayOnboardingModal handleModalClose={handleModalClose} />;

    default:
      return (
        <Box
          sx={{ paddingX: '2rem', paddingTop: '2rem', paddingBottom: '4rem' }}
        >
          <Typography>
            {utilityNameId} has no modal component created yet.
          </Typography>
        </Box>
      );
  }
};

const UtilityModalContainer = (props: UtilityModalContainerPropsType) => {
  const { modalOpen, modalOption, handleModalClose, popup } = props;
  const open = modalOpen;
  return (
    <Dialog open={open} onClose={handleModalClose} maxWidth="md" fullWidth>
      <UtilityModalToOpen
        popup={popup}
        utilityNameId={modalOption}
        handleModalClose={handleModalClose}
      />
    </Dialog>
  );
};

export default UtilityModalContainer;
