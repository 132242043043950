import { Help } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import useStyles from './Common.styles';

interface CommonFormTextFieldProps {
  componentID: string;
  label: string;
  value: string;
  fieldError: boolean;
  helperText: string;
  fullHelpText: string;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
  textFieldDisabled?: boolean;
  required?: boolean;
  type?: string;
}

const CommonFormTextField = (props: CommonFormTextFieldProps) => {
  const {
    componentID,
    label,
    value,
    fieldError,
    helperText,
    fullHelpText,
    onChangeHandler,
    onBlurHandler,
    textFieldDisabled,
    required,
    type,
  } = props;

  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div className={classes.fieldWrap}>
      <div>
        <TextField
          disabled={textFieldDisabled}
          value={value}
          label={label}
          id={componentID}
          variant="outlined"
          className={classes.field}
          onChange={onChangeHandler}
          onBlur={onBlurHandler}
          error={fieldError}
          helperText={helperText}
          InputProps={{
            type: type,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  aria-label="show help"
                  onClick={handleClick}
                >
                  <Help />
                </IconButton>
              </InputAdornment>
            ),
          }}
          required={!!required}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: { maxWidth: 300 },
          }}
        >
          <Typography variant="body2" aria-label="detailed help" sx={{ p: 2 }}>
            {fullHelpText}
          </Typography>
        </Popover>
      </div>
    </div>
  );
};

CommonFormTextField.defaultProps = {
  textFieldDisabled: false,
  required: true,
  type: 'string',
};

export default CommonFormTextField;
