import { CommonRegionDropdown } from '../CommonFields';

interface SecondaryRegionDropdownPropType {
  data: string[];
  value: string;
  fieldError: boolean;
  errorText?: string;
  onChangeHandler: (event: React.ChangeEvent<{}>, value: string | null) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
  primaryRegion: string | undefined;
  azureRegionGroups: {
    zone: string;
    regions: string[];
  }[];
}

const SecondaryRegionDropdown = (props: SecondaryRegionDropdownPropType) => {
  const {
    data,
    value,
    fieldError,
    errorText,
    onChangeHandler,
    onBlurHandler,
    primaryRegion,
    azureRegionGroups,
  } = props;

  const primaryZone = azureRegionGroups?.find(
    (grp) => grp?.regions?.find((regionName) => regionName === primaryRegion)
  )?.zone;

  return (
    <CommonRegionDropdown
      componentID="secondaryRegion"
      label="Secondary Region"
      data={data}
      value={value}
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      fieldError={fieldError}
      helperText={
        fieldError && errorText
          ? errorText
          : 'Select a secondary region for deployment.'
      }
      fullHelpText="Select your secondary region from the dropdown. Secondary region provides redundancy, 
      fault tolerance and disaster recovery capabilities in case of disruptions or failures in primary region."
      getOptionDisabled={(option: string) => {
        const optionZone = azureRegionGroups.find((grp) =>
          grp.regions.find((regionName) => regionName === option)
        )?.zone;
        if (optionZone !== primaryZone) {
          return true;
        }
        return false;
      }}
    />
  );
};

SecondaryRegionDropdown.defaultProps = {
  errorText: undefined,
};

export default SecondaryRegionDropdown;
