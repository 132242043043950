import { Application } from '@costco-service-catalog/bff-types';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import MultiselectPDTableRow from './MultiselectPDTableRow';
import useStyles from './MultiselectPreviouslyDeployed.styles';
import useMultiselectPreviouslyDeployed from './useMultiselectPreviouslyDeployed';

interface MultiselectPreviouslyDeployedProps {
  onSelect: (value: string) => void;
  open: boolean;
  selectedValue: string;
  onClose: () => void;
}

interface MSPDGridProps {
  onSelect: (value: string) => void;
  selectedValue: string;
  data: Application[];
}

const MultiselectPreviouslyDeployedGrid = (props: MSPDGridProps) => {
  const { data, onSelect, selectedValue } = props;
  const { classes } = useStyles();

  return (
    <FormControl className={classes.tableContainer}>
      <FormLabel id="MultiselectPreviouslyDeployedGrid-radio-buttons-group-label" />
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={selectedValue}
        onChange={(e, v) => onSelect(v)}
      >
        <TableContainer className={classes.innerTableContainer}>
          <Table
            sx={{
              minWidth: 650,
              width: '100%',
            }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ paddingLeft: '1.5rem' }}>
                  Select
                </TableCell>
                <TableCell>App Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell align="right" sx={{ paddingRight: '1.5rem' }}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((data) => (
                <MultiselectPDTableRow
                  key={data.id}
                  data={data}
                  onSelect={onSelect}
                  selectedValue={selectedValue}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </RadioGroup>
    </FormControl>
  );
};

const MultiselectPreviouslyDeployed = (
  props: MultiselectPreviouslyDeployedProps
) => {
  const { onSelect, onClose, selectedValue, open } = props;
  const { error, data, loading } = useMultiselectPreviouslyDeployed();
  const { classes } = useStyles();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="lg"
      fullWidth
      scroll="paper"
    >
      <DialogTitle className={classes.dialogTitleContainer}>
        <span>Select a Previously Deployed Application</span>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.dialog}>
        {loading && (
          <div className={classes.loadingContainer}>
            <Typography paddingY={2} textAlign="center">
              Loading Previously Deployed Applications...
            </Typography>
            <CircularProgress />
          </div>
        )}
        {error && (
          <Typography color="error" paddingY={2} textAlign="center">
            An error occurred when getting previously deployed applications.
          </Typography>
        )}
        {data.length === 0 && !loading && (
          <Typography paddingY={2} textAlign="center">
            No previously deployed applications found.
          </Typography>
        )}
        {data.length > 0 && !loading && (
          <MultiselectPreviouslyDeployedGrid
            onSelect={onSelect}
            data={data}
            selectedValue={selectedValue}
          />
        )}
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonGroupContainer}>
          <div className={classes.clearDoneButton}>
            <div className={classes.clearButton}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  onSelect('');
                  onClose();
                }}
                disabled={selectedValue.length === 0}
              >
                Clear Selection
              </Button>
            </div>
            <div className={classes.doneButton}>
              <Button
                variant="contained"
                onClick={onClose}
                disabled={selectedValue.length === 0}
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default MultiselectPreviouslyDeployed;
