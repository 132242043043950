import {
  Application,
  BreakBlobLeaseInput,
} from '@costco-service-catalog/bff-types';
import _ from 'lodash';
import { FormikHelpers, useFormik } from 'formik';
import { object, string } from 'yup';
import { utilitiesPayloadType } from '../../../../pages/ApplicationSummaryPage/useApplicationSummaryPage';
import {
  useEnqueueSnackbar,
  useListApplications,
  useBreakTFStateBlobLease,
} from '../../../../hooks';

export type defaultUnlockStateFileFormValuesType = {
  selectedApplication?: Application;
  selectedSubscriptionName?: string;
  selectedStorageAccountName?: string;
  selectedContainerName?: string;
  selectedStateFileName?: string;
};

const useUnlockStateFileModal = (
  handleModalClose: () => void,
  popup: utilitiesPayloadType | undefined
) => {
  const { pushSnackbar } = useEnqueueSnackbar();
  const { data: listApplicationsData, loading: listApplicationsLoading } =
    useListApplications();

  const { breakTFStateBlobLease } = useBreakTFStateBlobLease();
  const initialApp = popup ? popup.data : undefined;

  const unlockStateFileValidationSchema = () =>
    object({
      selectedApplication: object().required('You must select an application.'),
      selectedSubscriptionName: string().required(
        'You must select a subscription.'
      ),
      selectedStorageAccountName: string().required(
        'You must select a Storage Account to continue.'
      ),
      selectedContainerName: string().required(
        'You must select a Container to continue.'
      ),
      selectedStateFileName: string().required(
        'You must select a state file to unlock.'
      ),
    });

  const formik = useFormik({
    initialValues: {
      selectedApplication: initialApp,
    },
    validationSchema: unlockStateFileValidationSchema(),
    onSubmit: async (
      values: defaultUnlockStateFileFormValuesType,
      helpers: FormikHelpers<defaultUnlockStateFileFormValuesType>
    ) => {
      const breakTFStateBlobLeaseInput: BreakBlobLeaseInput = {
        applicationId: values.selectedApplication?.id || '',
        subscriptionName: values.selectedSubscriptionName || '',
        storageAccountName: values.selectedStorageAccountName || '',
        containerName: values.selectedContainerName || '',
        blobName: values.selectedStateFileName || '',
      };

      const submitOptions = {
        variables: { input: breakTFStateBlobLeaseInput },
        onCompleted: () => {
          // This is intentional
        },
      };
      const submitUnlockStateFile = await breakTFStateBlobLease(submitOptions);
      if (
        !submitUnlockStateFile.errors ||
        submitUnlockStateFile.errors?.length === 0
      ) {
        pushSnackbar('Terraform state file has been unlocked successfully.', {
          variant: 'success',
        });
        handleModalClose();
      }
    },
  });

  const selectApplicationFieldError = formik.touched.selectedApplication
    ? Boolean(formik.errors.selectedApplication)
    : false;
  const selectedSubscriptionNameFieldError = formik.touched
    .selectedSubscriptionName
    ? Boolean(formik.errors.selectedSubscriptionName)
    : false;
  const selectedStorageAccountNameFieldError = formik.touched
    .selectedStorageAccountName
    ? Boolean(formik.errors.selectedStorageAccountName)
    : false;
  const selectedContainerNameFieldError = formik.touched.selectedContainerName
    ? Boolean(formik.errors.selectedContainerName)
    : false;
  const selectedStateFileNameFieldError = formik.touched.selectedStateFileName
    ? Boolean(formik.errors.selectedStateFileName)
    : false;

  const getSubscriptionOptions = (
    selectedApplication: Application | undefined
  ): string[] => {
    if (selectedApplication) {
      const validSubscriptions = selectedApplication.environments
        ?.map((env) => ({
          subscriptionName: env?.subscriptionName,
          deleteStatus: env?.deleteStatus,
          notReady: env?.notReady,
        }))
        .filter((env) => !env.deleteStatus && env.notReady !== true);
      const subscriptionNames = _.uniq(
        validSubscriptions?.map((s) => s.subscriptionName as string)
      );

      if (subscriptionNames) {
        return subscriptionNames;
      }
    }
    return [];
  };

  return {
    formik,
    selectApplicationFieldError,
    selectedSubscriptionNameFieldError,
    selectedStorageAccountNameFieldError,
    selectedContainerNameFieldError,
    selectedStateFileNameFieldError,
    listApplicationsData,
    listApplicationsLoading,
    getSubscriptionOptions,
  };
};

export default useUnlockStateFileModal;
