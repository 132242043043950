import { useEffect, useState } from 'react';
import {
  AzurePolicy,
  EcoSystemRequestInput,
} from '@costco-service-catalog/bff-types';
import { Info } from '@mui/icons-material';
import { Box, Checkbox, Switch, Tooltip, Typography } from '@mui/material';
import { useAzurePolicies } from '../../../hooks';
import { removePolicyModeSuffix } from '../OfferingForms/useAzureCloudNativeStarterKit';

interface AzurePolicyListProps {
  ecoSystemNP?: EcoSystemRequestInput;
  ecoSystemPD?: EcoSystemRequestInput;
  handlePolicyChange: (type: 'pd' | 'np', policyKey?: string) => void;
  handlePolicyModeChange: (
    type: 'pd' | 'np',
    mode: 'Audit' | 'Deny',
    policyKey?: string
  ) => void;
}

const AzurePolicyList = (props: AzurePolicyListProps) => {
  const {
    ecoSystemNP,
    ecoSystemPD,
    handlePolicyChange,
    handlePolicyModeChange,
  } = props;
  const {
    data: azurePolicyList,
    loading: azurePolicyListLoading,
    error: azurePolicyListError,
  } = useAzurePolicies();

  const [azurePolicyDisplayList, setAzurePolicyDisplayList] = useState<
    AzurePolicy[]
  >([]);

  const isPolicySelected = (type: 'np' | 'pd', policyKey?: string) => {
    const pdPolicies = ecoSystemPD?.enabledPolicies as string[] | undefined;
    const npPolicies = ecoSystemNP?.enabledPolicies as string[] | undefined;
    const pdPoliciesNoMode = pdPolicies?.map((v) => removePolicyModeSuffix(v));
    const npPoliciesNoMode = npPolicies?.map((v) => removePolicyModeSuffix(v));
    if (type === 'pd') {
      const policy = pdPoliciesNoMode?.find((v) => v === policyKey);
      if (policy) {
        return true;
      }
      return false;
    }
    if (type === 'np') {
      const policy = npPoliciesNoMode?.find((v) => v === policyKey);
      if (policy) {
        return true;
      }
      return false;
    }
    return false;
  };

  const checkPolicyMode = (
    policiesNoMode: string[],
    policies: string[],
    policyKey: string
  ) => {
    const policyKeyIndex = policiesNoMode.findIndex((v) => v === policyKey);
    if (policyKeyIndex > -1) {
      const selectedPolicy = policies.at(policyKeyIndex);
      if (selectedPolicy && selectedPolicy.includes('Audit')) {
        return 'Audit';
      }
      if (selectedPolicy && selectedPolicy.includes('Deny')) {
        return 'Deny';
      }
    }
    return 'Audit';
  };

  const determinePolicyMode = (type: 'np' | 'pd', policyKey?: string) => {
    const pdPolicies = ecoSystemPD?.enabledPolicies as string[] | undefined;
    const npPolicies = ecoSystemNP?.enabledPolicies as string[] | undefined;
    const pdPoliciesNoMode = pdPolicies?.map((v) => removePolicyModeSuffix(v));
    const npPoliciesNoMode = npPolicies?.map((v) => removePolicyModeSuffix(v));

    if (type === 'pd' && policyKey && pdPolicies && pdPoliciesNoMode) {
      return checkPolicyMode(pdPoliciesNoMode, pdPolicies, policyKey);
    }
    if (type === 'np' && policyKey && npPolicies && npPoliciesNoMode) {
      return checkPolicyMode(npPoliciesNoMode, npPolicies, policyKey);
    }

    return 'Audit';
  };

  const invertPolicyMode = (type: 'np' | 'pd', policyKey?: string) => {
    const currentMode = determinePolicyMode(type, policyKey);
    return currentMode === 'Audit' ? 'Deny' : 'Audit';
  };

  useEffect(() => {
    setAzurePolicyDisplayList(azurePolicyList);
  }, [azurePolicyList, azurePolicyListLoading]);

  return (
    <Box>
      <Box>
        <Box sx={{ paddingBottom: '0.5rem', padding: '1.1rem' }}>
          <Typography variant="h5">Application Policy Selection</Typography>
          <Box sx={{ paddingY: '1vh' }} />
          <Box maxWidth="650px" maxHeight="500px" sx={{ overflowY: 'auto' }}>
            <Typography variant="h6">Non Production Policies</Typography>
            <Typography variant="caption">
              The selected policies will be applied to all non production
              environments
            </Typography>

            {azurePolicyDisplayList.map((policy) => (
              <Box
                key={policy.key}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  paddingY: '5px',
                }}
              >
                <Box sx={{ paddingX: '10px' }}>
                  <Checkbox
                    data-testid="policy-checkbox-np"
                    checked={isPolicySelected(
                      'np',
                      policy.key as string | undefined
                    )}
                    onClick={() =>
                      handlePolicyChange('np', policy.key as string | undefined)
                    }
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>{policy.displayName} </Typography>
                    <Box sx={{ paddingY: '0.1rem', paddingX: '0.25rem' }} />
                    <Tooltip title={policy.description}>
                      <Info fontSize="small" htmlColor="gray" />
                    </Tooltip>
                  </Box>
                </Box>

                {isPolicySelected('np', policy.key as string | undefined) ? (
                  <Box sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="body2">Audit</Typography>
                      <Switch
                        data-testid="policy-switch-np"
                        checked={
                          determinePolicyMode(
                            'np',
                            policy.key as string | undefined
                          ) === 'Deny'
                        }
                        onClick={() => {
                          handlePolicyModeChange(
                            'np',
                            invertPolicyMode(
                              'np',
                              policy.key as string | undefined
                            ),
                            policy.key as string | undefined
                          );
                        }}
                      />
                      <Typography variant="body2">Deny</Typography>
                    </Box>
                  </Box>
                ) : undefined}
              </Box>
            ))}
          </Box>

          <Box sx={{ paddingY: '1vh' }} />

          <Box maxWidth="650px" maxHeight="500px" sx={{ overflowY: 'auto' }}>
            <Typography variant="h6">Production Policies</Typography>
            <Typography variant="caption">
              The selected policies will be applied to all production
              environments
            </Typography>
            {azurePolicyDisplayList.map((policy) => (
              <Box
                key={policy.key}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  paddingY: '5px',
                }}
              >
                <Box sx={{ paddingX: '10px' }}>
                  <Checkbox
                    data-testid="policy-checkbox-pd"
                    checked={isPolicySelected(
                      'pd',
                      policy.key as string | undefined
                    )}
                    onClick={() =>
                      handlePolicyChange('pd', policy.key as string | undefined)
                    }
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>{policy.displayName} </Typography>
                    <Box sx={{ paddingY: '0.1rem', paddingX: '0.25rem' }} />
                    <Tooltip title={policy.description}>
                      <Info fontSize="small" htmlColor="gray" />
                    </Tooltip>
                  </Box>
                </Box>{' '}
                {isPolicySelected('pd', policy.key as string | undefined) ? (
                  <Box sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="body2">Audit</Typography>
                      <Switch
                        data-testid="policy-switch-pd"
                        checked={
                          determinePolicyMode(
                            'pd',
                            policy.key as string | undefined
                          ) === 'Deny'
                        }
                        onClick={() => {
                          handlePolicyModeChange(
                            'pd',
                            invertPolicyMode(
                              'pd',
                              policy.key as string | undefined
                            ),
                            policy.key as string | undefined
                          );
                        }}
                      />
                      <Typography variant="body2">Deny</Typography>
                    </Box>
                  </Box>
                ) : undefined}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AzurePolicyList;

AzurePolicyList.defaultProps = {
  ecoSystemNP: undefined,
  ecoSystemPD: undefined,
};
