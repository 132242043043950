import { History } from '@mui/icons-material';
import { ApplicationListGrid, PageHeader } from '../../components';
import useStyles from './ApplicationListPage.styles';
import useApplicationListPage from './useApplicationListPage';

const ApplicationListPage = () => {
  const { classes } = useStyles();

  const { title, data, loading, count, handleRowClick } =
    useApplicationListPage();

  const formatCount = (count: any) => {
    let formattedCount = count;
    if (formattedCount === undefined) {
      formattedCount = 0;
    }
    return formattedCount;
  };

  return (
    <div className={classes.root}>
      <PageHeader
        icon={<History />}
        title={title}
        subtitle={`Total: ${loading ? '...' : formatCount(count)}`}
      />
      <div>
        <ApplicationListGrid rows={data} onRowClick={handleRowClick} />
      </div>
    </div>
  );
};

export default ApplicationListPage;
