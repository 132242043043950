import { CommonFormTextField } from '../CommonFields';

interface ApplicationDescriptionProps {
  value: string;
  fieldError: boolean;
  errorText?: string;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const ApplicationDescription = (props: ApplicationDescriptionProps) => {
  const { value, fieldError, errorText, onChangeHandler, onBlurHandler } =
    props;

  return (
    <CommonFormTextField
      componentID="applicationDescription"
      label="Application Description"
      value={value}
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      fieldError={fieldError}
      helperText={
        fieldError && errorText
          ? errorText
          : 'Give your application a name. This will be used for your ADO project name as well as part of some of your Azure resources.'
      }
      fullHelpText="Application Description is required and must not be more than 800 characters. It will be included with the Carts+ request for this Starter Kit."
    />
  );
};

ApplicationDescription.defaultProps = {
  errorText: undefined,
};

export default ApplicationDescription;
