import {
  AddToApplicationRequestInput,
  Application,
  StarterKitEnvironment,
} from '@costco-service-catalog/bff-types';
import { FormikHelpers, useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { object } from 'yup';
import { ROUTES } from '../../../../constants';
import {
  useEnqueueSnackbar,
  useListApplications,
  useSubmitAddToApplication,
} from '../../../../hooks';
import { utilitiesPayloadType } from '../../../../pages/ApplicationSummaryPage/useApplicationSummaryPage';

type defaultFormValuesType = {
  selectedApplication?: Application;
  selectedEnvironment?: StarterKitEnvironment;
};

const useDeleteEnvironmentModal = (
  handleModalClose: () => void,
  popup: utilitiesPayloadType | undefined
) => {
  const navigate = useNavigate();
  const { submitAddToApplication } = useSubmitAddToApplication();
  const { pushSnackbar } = useEnqueueSnackbar();
  const { data: applications, loading: listApplicationsLoading } =
    useListApplications();

  const [deleteEnvironmentDialogOpen, setDeleteEnvironmentDialogOpen] =
    useState(false);

  const initFormValues: defaultFormValuesType = {
    selectedApplication: popup ? popup.data : undefined,
    selectedEnvironment: popup ? popup.selectedEnvironment : undefined,
  };

  const listApplicationsData = applications.filter((x) => {
    if (x.isEphemeral || x.hasRunningPipelines || !x.starterKitRequests) {
      return false;
    }
    const landingZone = x.starterKitRequests
      .map(
        (starterKit) =>
          starterKit?.offerings?.find(
            (offering) => offering?.category === 'LandingZone'
          )
      )
      .filter((offering) => offering?.category === 'LandingZone');
    return landingZone && landingZone[0]?.status === 'Completed';
  });

  const handleDeleteEnvironmentClick = () => {
    setDeleteEnvironmentDialogOpen(true);
  };

  const defaultValidationSchema = () =>
    object({
      selectedApplication: object().required('You must select an Application.'),
      selectedEnvironment: object().required('You must select an environment.'),
    });

  const formik = useFormik({
    initialValues: initFormValues,
    validationSchema: defaultValidationSchema,
    enableReinitialize: Boolean(initFormValues.selectedApplication),
    onSubmit: async (
      values: defaultFormValuesType,
      helpers: FormikHelpers<defaultFormValuesType>
    ) => {
      const submitAddToApplicationInput: AddToApplicationRequestInput = {
        applicationId: values.selectedApplication?.id || '',
        offeringRequests: [
          {
            additionalParams: [
              {
                key: 'environmentName',
                value: values.selectedEnvironment?.name || '',
              },
            ],
            category: 'Utility',
            offeringName: 'Delete Environment',
            offeringShortName: 'deleteenv',
            offeringVersion: 'na',
          },
        ],
      };
      const submitOptions = {
        variables: { input: submitAddToApplicationInput },
        onCompleted: () => {},
      };
      const submitAddToApplicationRes = await submitAddToApplication(
        submitOptions
      );
      if (
        !submitAddToApplicationRes.errors ||
        submitAddToApplicationRes.errors?.length === 0
      ) {
        pushSnackbar('Delete Environment request has been submitted.', {
          variant: 'success',
        });
        handleModalClose();
        navigate(
          `${ROUTES.APPLICATION_SUMMARY}/${
            values.selectedApplication?.id || ''
          }`
        );
      }
    },
  });

  const handleDeleteEnvironmentDialogOk = async () => {
    setDeleteEnvironmentDialogOpen(false);
    formik.submitForm();
  };

  const selectApplicationFieldError = formik.touched.selectedApplication
    ? Boolean(formik.errors.selectedApplication)
    : false;
  const selectEnvironmentFieldError = formik.touched.selectedEnvironment
    ? Boolean(formik.errors.selectedEnvironment)
    : false;

  const handleDeleteEnvironmentDialogCancel = () => {
    setDeleteEnvironmentDialogOpen(false);
  };

  return {
    formik,
    selectEnvironmentFieldError,
    listApplicationsData,
    listApplicationsLoading,
    selectApplicationFieldError,
    deleteEnvironmentDialogOpen,
    handleDeleteEnvironmentDialogOk,
    handleDeleteEnvironmentDialogCancel,
    handleDeleteEnvironmentClick,
  };
};

export default useDeleteEnvironmentModal;
