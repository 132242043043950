import { Avatar, Chip, Typography } from '@mui/material';
import React from 'react';
import StatusChip from '../StatusChip/StatusChip';
import useStyles from './PageHeader.styles';

interface PageHeaderProps {
  children?: React.ReactNode;
  icon: JSX.Element;
  subtitle?: string | number | JSX.Element;
  title: string | number;
  sharedProject?: boolean;
  deleteStatus?: string;
}

const PageHeader = (props: PageHeaderProps) => {
  const { sharedProject, children, icon, subtitle, title, deleteStatus } =
    props;

  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.pageHeader}>
        {icon && (
          <div className={classes.pageHeaderIcon}>
            <Avatar className={classes.pageHeaderAvatar}>{icon}</Avatar>
          </div>
        )}
        <div className={classes.pageHeaderContent}>
          <div className={classes.pageHeaderLeft}>
            <div className={classes.pageHeaderTitleStack}>
              {title && (
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Typography variant="subtitle1" sx={{ fontSize: '.8rem' }}>
                  {subtitle}
                </Typography>
              )}
              <div>
                {sharedProject && (
                  <Chip label="Shared" variant="outlined" size="small" />
                )}
                {deleteStatus && <StatusChip label={deleteStatus} />}
              </div>
            </div>
          </div>
          {children && (
            <div className={classes.pageHeaderRight}>{children}</div>
          )}
        </div>
      </div>
    </div>
  );
};

PageHeader.defaultProps = {
  children: undefined,
  subtitle: undefined,
  sharedProject: false,
  deleteStatus: '',
};

export default PageHeader;
