import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  activeRow: {
    '& td': {
      fontWeight: 600,
    },
  },
  inactiveRow: {
    '& td': {
      fontWeight: 'normal',
    },
  },
  bold: {
    fontWeight: 600,
  },
  unbold: {
    fontWeight: 'normal',
  },
  container: {
    backgroundColor: theme.palette.background.default,
  },
  dialog: {
    margin: 0,
    padding: 0,
  },
  dialogTitleContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogContentContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  loadingContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  DialogActionContainer: {
    backgroundColor: theme.palette.background.default,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 10,
    position: 'sticky',
    bottom: 0,
    right: 0,
  },
  clearDoneButton: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  clearButton: { paddingRight: '1rem' },
  doneButton: { paddingRight: 2 },
  buttonGroupContainer: {
    display: 'flex',
    padding: 5,
    minWidth: '100%',
  },
  tableContainer: {
    width: '100%',
  },
  innerTableContainer: {
    padding: 0,
  },
}));

export default useStyles;
