import { Box, Icon, Paper, Typography, useTheme } from '@mui/material';
import {
  CellTowerOutlined,
  DnsOutlined,
  EngineeringOutlined,
  HubOutlined,
  RouterOutlined,
  LockOpenOutlined,
  PersonAdd,
  Delete,
  GridViewOutlined,
  StorageOutlined,
} from '@mui/icons-material';
import {
  AvailableUtilities,
  AvailableUtilitiesIconName,
} from '../../types/uiTypes';
import useStyles from './UtilityCard.styles';

type UtilityCardPropsType = {
  utility: AvailableUtilities;
  handleModalOpen: (variant: string) => void;
};

const UtilityIcon = (props: { iconName: AvailableUtilitiesIconName }) => {
  const { iconName } = props;
  if (iconName === 'Hub') return <HubOutlined />;
  if (iconName === 'CellTower') return <CellTowerOutlined />;
  if (iconName === 'Router') return <RouterOutlined />;
  if (iconName === 'DNS') return <DnsOutlined />;
  if (iconName === 'PersonAdd') return <PersonAdd />;
  if (iconName === 'Delete') return <Delete />;
  if (iconName === 'Unlock') return <LockOpenOutlined />;
  if (iconName === 'ECPNamespaceOnboarding') return <GridViewOutlined />;
  if (iconName === 'ECPQuayOnboarding') return <StorageOutlined />;
  return <EngineeringOutlined />;
};

const UtilityCard = (props: UtilityCardPropsType) => {
  const { utility, handleModalOpen } = props;
  const theme = useTheme();
  const { classes } = useStyles();

  return (
    <Box onClick={() => handleModalOpen(utility.utilityNameId)}>
      <Paper className={classes.cardStyling}>
        <Box className={classes.cardContentContainer}>
          <Box className={classes.topLeftIconContainer}>
            <UtilityIcon iconName={utility.utilityIconName} />
          </Box>
          <Box className={classes.cardContentStyling}>
            <Typography>{utility.utilityTitle}</Typography>
            <Box className={classes.constructionTapeDivider} />
            <Typography variant="body2">
              {utility.utilityDescription}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default UtilityCard;
