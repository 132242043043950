import { ApolloError, useQuery } from '@apollo/client';
import {
  AzurePoliciesResponse,
  AzurePolicy,
} from '@costco-service-catalog/bff-types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '..';
import { LIST_AZURE_POLICIES_QUERY } from '../../apollo/queries';
import { useGlobalState } from '../../globalState/useGlobalState';
import mockGetAzurePoliciesRes from './lookupHooksMocks/useAzurePolicies.mock';

const useAzurePolicies = () => {
  const { appSettings } = useGlobalState();
  if (appSettings.useUIMocks.get()) {
    return mockGetAzurePoliciesRes();
  }

  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getAzurePolicies: AzurePoliciesResponse;
  }>(LIST_AZURE_POLICIES_QUERY, {
    onError: (error: ApolloError) => handleErrorResponse(error),
  });
  const [policies, setPolicies] = useState<AzurePolicy[]>([]);

  useEffect(() => {
    if (!loading && data) {
      const extractPolicies: AzurePolicy[] | undefined = data.getAzurePolicies
        .response as AzurePolicy[];
      if (extractPolicies) {
        setPolicies(extractPolicies);
      }
    }
  }, [loading, data]);

  return {
    error,
    data: policies,
    loading,
  };
};

export default useAzurePolicies;
