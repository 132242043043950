import { ApolloError, ServerError } from '@apollo/client';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useEnqueueSnackbar } from '..';

const authTokenExpiredEvent = new Event('auth-token-expired');
const accessDeniedEvent = new Event('access-denied');

const useApolloErrors = () => {
  const appInsights = useAppInsightsContext();
  const { pushSnackbar } = useEnqueueSnackbar();

  const handleErrorResponse = (error: ApolloError) => {
    const serverError = error.networkError as ServerError;
    if (serverError && serverError.statusCode === 400 && serverError.result) {
      const result = serverError.result as Record<string, any>;
      const invalidError = result?.errors?.find((err: any) => err.extensions);
      if (invalidError) {
        switch (invalidError.extensions.code) {
          case 'UNAUTHENTICATED':
            document.dispatchEvent(authTokenExpiredEvent);
            break;
          case 'FORBIDDEN':
            document.dispatchEvent(accessDeniedEvent);
            break;
          default:
            break;
        }
      }
    }

    pushSnackbar(error.message, { variant: 'error' });

    appInsights.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
    });
  };

  return {
    handleErrorResponse,
  };
};

export { authTokenExpiredEvent, useApolloErrors };
