import { AadUser } from '@costco-service-catalog/bff-types';
import { CommonNonZUsersAutoComplete } from '../CommonFields';

interface AdditionalCollaboratorsProps {
  value: AadUser[];
  onChangeHandler: (event: React.ChangeEvent<{}>, value: AadUser[]) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const AdditionalCollaborators = (props: AdditionalCollaboratorsProps) => {
  const { value, onChangeHandler, onBlurHandler } = props;

  return (
    <CommonNonZUsersAutoComplete
      componentID="additionalCollaborators"
      label="Additional Collaborators"
      value={value}
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      fieldError={false}
      helperText="Select from the list or enter a user name and hit the Enter key to add user as additional collaborator."
      fullHelpText="Additional Collaborator will share the same privileges that a requester of an application have to view and manage the application."
    />
  );
};

export default AdditionalCollaborators;
