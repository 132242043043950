import {
  AddToApplicationRequestInput,
  Application,
  OfferingCategory,
  ReferenceArchitecture,
} from '@costco-service-catalog/bff-types';

const generateAddToApplication = (
  deployedApplication: Application,
  selectedOfferings: ReferenceArchitecture[]
): AddToApplicationRequestInput => {
  const offeringRequestInputs = selectedOfferings.map((offering) => ({
    category: offering.category as OfferingCategory,
    offeringName: offering.name as string,
    offeringRepoUrl: offering.repoUrl as string,
    offeringServiceNames: offering.applicationList as string,
    offeringShortName: offering.shortOfferingName as string,
    offeringVersion: offering.version as string,
    offeringVnetPeered: offering.vnetPeered as boolean,
  }));
  return {
    applicationId: deployedApplication.id,
    offeringRequests: offeringRequestInputs,
  };
};

export default generateAddToApplication;
