import {
  EnvironmentRequest,
  ReferenceArchitecture,
} from '@costco-service-catalog/bff-types';
import { Info } from '@mui/icons-material';
import { Box, Typography, useTheme } from '@mui/material';
import useStyles from '../CommonFields/Common.styles';

type CidrBlockInfoTextPropsType = {
  selectedOfferings: ReferenceArchitecture[] | undefined;
  selectedEnvs: EnvironmentRequest[];
};

const CidrBlockInfoText = (props: CidrBlockInfoTextPropsType) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const { selectedOfferings, selectedEnvs } = props;
  const containsPeeredLandingZone = selectedOfferings?.find(
    (o) => o.shortOfferingName === 'peerland'
  );
  const containsSubnetLargerThan24 = selectedEnvs?.find(
    (env) => typeof env.cidrBlockSize === 'number' && env.cidrBlockSize < 24
  );

  return (
    <div>
      {containsPeeredLandingZone && containsSubnetLargerThan24 ? (
        <div className={classes.fieldWrap}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
            <Box style={{ padding: '0.25rem' }}>
              <Info htmlColor={theme.palette.text.secondary} />
            </Box>
            <Box style={{ padding: '0.25rem' }}>
              <Typography>
                For any subnet larger than /24, extra Networking Team approval
                will be required.
              </Typography>
            </Box>
          </Box>
        </div>
      ) : undefined}
    </div>
  );
};

export default CidrBlockInfoText;
