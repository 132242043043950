import { ApolloError, useMutation } from '@apollo/client';
import { DELETE_ADDITIONAL_COLLABORATORS } from '../../apollo/queries';
import { useApolloErrors } from '../apolloHooks';

const useRemoveAdditionalCollaborators = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [deleteAdditionalCollaborators, { loading: deleteUsersLoading }] =
    useMutation(DELETE_ADDITIONAL_COLLABORATORS, {
      onError: (error: ApolloError) => handleErrorResponse(error),
    });

  return {
    deleteAdditionalCollaborators,
    loading: deleteUsersLoading,
  };
};

export default useRemoveAdditionalCollaborators;
