import { PaletteMode } from '@mui/material';
import { grey, red } from '@mui/material/colors';

const getDesignTokens = (mode: PaletteMode) => ({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: mode === 'dark' ? 'white' : 'black',
          '&.Mui-disabled': {
            opacity: '0.46',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&> .MuiOutlinedInput-notchedOutline': {
            borderColor: mode === 'dark' ? 'white' : 'black',
            background:
              'linear-gradient(rgba(255,255,0,0), rgba(0,100,255,0.1))',
          },
          '&.Mui-disabled > .MuiOutlinedInput-notchedOutline': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '&[class*="MuiOutlinedInput-root"]': {
            '& .MuiAutocomplete-endAdornment': {
              right: 50,
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '3px 5px',
        },
      },
    },
  },
  palette: {
    mode: mode,
    primary: {
      main: mode === 'dark' ? '#1996ff' : '#005daa',
      contrastText: '#ffffff',
    },
    secondary: {
      main: mode === 'dark' ? '#ffffff' : '#005daa',
      contrastText: mode === 'dark' ? grey[50] : '#ffffff',
    },
    error: {
      main: mode === 'dark' ? red[400] : red[800],
      contrastText: grey[50],
    },
  },
});

export default getDesignTokens;
