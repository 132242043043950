import {
  DependencyBaseSchema,
  DependencyInfoSchema,
  ReferenceArchitecture,
} from '@costco-service-catalog/bff-types';

export type GenerateReferenceArchitectureParams = {
  name: string;
  shortOfferingName: string;
  dependencies: DependencyInfoSchema[];
  conflicts: DependencyBaseSchema[];
};

const generateReferenceArchitecture = (
  params: GenerateReferenceArchitectureParams
) => {
  const { name, shortOfferingName, dependencies, conflicts } = params;
  const refArch: ReferenceArchitecture = {
    name,
    category: 'Application',
    version: '',
    ready: false,
    ephemeralRGSupported: false,
    shortOfferingName,
    description: '',
    type: 'RefArch',
    components: [],
    applicationList: '',
    autoselectOfferings: '',
    dependencies,
    conflicts,
  };
  return refArch;
};

export default generateReferenceArchitecture;
