import { gql } from '@apollo/client';

const LIST_EPHEMERAL_RGS_QUERY = gql`
  query Query {
    listEphemeralRgs {
      code
      status
      response {
        id
        applicationId
        offeringShortName
        status
        storyId
        createdDateTime
        expirationDateTime
      }
      count
    }
  }
`;

export default LIST_EPHEMERAL_RGS_QUERY;
