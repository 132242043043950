import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { CommonFormTextField } from '../../../FormComponents/CommonFields';
import useECPNamespaceOnboardingModal from './useECPNamespaceOnboardingtModal';

const ECPNamespaceOnboardingModal = (props: {
  handleModalClose: () => void;
}) => {
  const { handleModalClose } = props;
  const {
    formik,
    enterTeamAcronymFieldError,
    enterProjectNameFieldError,
    enterNamespaceNameFieldError,
    enterADGroupFieldError,
    enterADGroupDNFieldError,
    enterTeamEmailFieldError,
    enterCPUFieldError,
    enterRAMFieldError,
    enterStorageFieldError,
    enterRequestReasonFieldError,
    handleSubmitClick,
  } = useECPNamespaceOnboardingModal(handleModalClose);
  const {
    teamName,
    projectName,
    namespaceName,
    adGroup,
    adGroupDN,
    teamEmail,
    cpu,
    ram,
    storage,
    requestReason,
    additionalInformation,
  } = formik.values;

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>ECP Onboarding - Openshift</DialogTitle>
        <DialogContent>
          <Box sx={{ flex: 1 }}>
            <CommonFormTextField
              componentID="teamName"
              label="Team Acronym"
              value={teamName || ''}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              fieldError={enterTeamAcronymFieldError}
              helperText={
                enterTeamAcronymFieldError && formik.errors.teamName
                  ? formik.errors.teamName
                  : 'Please enter a three/four letter acronym ex: ecp, csat, etc'
              }
              fullHelpText="Team Acronym must be 3 or 4 characters with no spaces or special characters."
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CommonFormTextField
              componentID="projectName"
              label="Project Name"
              value={projectName || ''}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              fieldError={enterProjectNameFieldError}
              helperText={
                enterProjectNameFieldError && formik.errors.projectName
                  ? formik.errors.projectName
                  : 'Please enter Project Name'
              }
              fullHelpText="Please enter Project Name or Project Number."
              required={false}
            />
          </Box>
          <Box sx={{ flex: 1, padding: 2 }}>
            <FormLabel id="environment-radio-buttons-group-label">
              Environment
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="environment-radio-buttons-group-label"
              defaultValue="NP"
              name="radio-buttons-group"
              onChange={(e, checked) =>
                formik.setFieldValue('environmentType', checked)
              }
            >
              <FormControlLabel
                value="NP"
                control={<Radio />}
                label="Non-PRD"
              />
              <FormControlLabel value="PD" control={<Radio />} label="PRD" />
            </RadioGroup>
          </Box>
          <Box sx={{ flex: 1 }}>
            <CommonFormTextField
              componentID="namespaceName"
              label="Namespace Name"
              value={namespaceName || ''}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              fieldError={enterNamespaceNameFieldError}
              helperText={
                enterNamespaceNameFieldError && formik.errors.namespaceName
                  ? formik.errors.namespaceName
                  : 'Please enter Namespace Name. Examples: esd-adt01, csat-qat01'
              }
              fullHelpText="Use a 3 or 4 letter acronym representing your team, a dash, a 3 letter environment name, and a 2 digit numeric suffix. Examples: esd-adt01, csat-qat01"
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CommonFormTextField
              componentID="adGroup"
              label="AD Group Name"
              value={adGroup || ''}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              fieldError={enterADGroupFieldError}
              helperText={
                enterADGroupFieldError && formik.errors.adGroup
                  ? formik.errors.adGroup
                  : 'Please enter AD Group Name. Example: team-ldap-group'
              }
              fullHelpText="Example: team-ldap-group"
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CommonFormTextField
              componentID="adGroupDN"
              label="AD Group DN"
              value={adGroupDN || ''}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              fieldError={enterADGroupDNFieldError}
              helperText={
                enterADGroupDNFieldError && formik.errors.adGroupDN
                  ? formik.errors.adGroupDN
                  : 'Please enter AD Group DN. Example: CN=team-ldap-group,OU=Groups,OU=DomainLocal-NP,OU=Enterprise,DC=np,DC=costco,DC=com'
              }
              fullHelpText="Example: CN=team-ldap-group,OU=Groups,OU=DomainLocal-NP,OU=Enterprise,DC=np,DC=costco,DC=com"
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CommonFormTextField
              componentID="cpu"
              label="CPU"
              type="number"
              value={cpu || ''}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              fieldError={enterCPUFieldError}
              helperText={
                enterCPUFieldError && formik.errors.cpu
                  ? formik.errors.cpu
                  : 'Please enter CPU Capacity Required. Defaults to 1.'
              }
              fullHelpText="Defaults to 1."
              required={false}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CommonFormTextField
              componentID="ram"
              label="RAM"
              type="number"
              value={ram || ''}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              fieldError={enterRAMFieldError}
              helperText={
                enterRAMFieldError && formik.errors.ram
                  ? formik.errors.ram
                  : 'Please enter amount of RAM Required in GB. Defaults to 2.'
              }
              fullHelpText="Defaults to 2 GB."
              required={false}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CommonFormTextField
              componentID="storage"
              label="Storage"
              type="number"
              value={storage || ''}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              fieldError={enterStorageFieldError}
              helperText={
                enterStorageFieldError && formik.errors.storage
                  ? formik.errors.storage
                  : `Please enter amount of storage Required in GB. Defaults to 25.`
              }
              fullHelpText="Defaults to 25 GB."
              required={false}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CommonFormTextField
              componentID="teamEmail"
              label="Team Email"
              value={teamEmail || ''}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              fieldError={enterTeamEmailFieldError}
              helperText={
                enterTeamEmailFieldError && formik.errors.teamEmail
                  ? formik.errors.teamEmail
                  : 'Please enter Team Email'
              }
              fullHelpText="Please enter Team Email."
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CommonFormTextField
              componentID="requestReason"
              label="Reason for request"
              value={requestReason || ''}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              fieldError={enterRequestReasonFieldError}
              helperText={
                enterRequestReasonFieldError && formik.errors.requestReason
                  ? formik.errors.requestReason
                  : 'Please enter Reason for request.'
              }
              fullHelpText="Reason for this request."
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CommonFormTextField
              componentID="additionalInformation"
              label="Additional Information"
              value={additionalInformation || ''}
              onChangeHandler={formik.handleChange}
              onBlurHandler={formik.handleBlur}
              fieldError={false}
              helperText="Please enter any additional information about this request."
              fullHelpText="Any additional information about this request."
              required={false}
            />
          </Box>
          <DialogActions>
            <Button
              disabled={
                !(formik.isValid && (formik.dirty || formik.values.teamName)) ||
                formik.isSubmitting
              }
              variant="contained"
              type="button"
              onClick={handleSubmitClick}
              endIcon={
                formik.isSubmitting ? (
                  <CircularProgress size="14px" sx={{ marginLeft: '0.5rem' }} />
                ) : undefined
              }
            >
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Box>
  );
};

export default ECPNamespaceOnboardingModal;
