import { AdoBoardsProcess } from '@costco-service-catalog/bff-types';
import { CommonDropdown } from '../CommonFields';
import { useGetAdoBoardsProcesses } from '../../../hooks';

interface ADOProjectBoardsProcessProps {
  value: AdoBoardsProcess | null;
  onChangeHandler: (
    event: React.ChangeEvent<{}>,
    value: AdoBoardsProcess | null
  ) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
  fieldError: boolean;
  errorText?: string;
}

const ADOProjectBoardsProcess = (props: ADOProjectBoardsProcessProps) => {
  const { value, onChangeHandler, onBlurHandler, fieldError, errorText } =
    props;

  const { data, loading } = useGetAdoBoardsProcesses();
  return (
    <CommonDropdown
      componentID="adoProjectBoardsProcess"
      label="ADO Project Boards Process"
      data={data}
      value={value}
      loading={loading}
      required
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      getOptionLabel={(option) => option.displayName as string}
      getOptionSelected={(o, v) => o.name === v.name}
      fieldError={fieldError}
      helperText={
        fieldError && errorText
          ? errorText
          : 'Select your ADO Project Boards Process from the list.'
      }
      fullHelpText="Select the ADO Project Boards Process that you wish to apply to your new ADO project"
    />
  );
};

ADOProjectBoardsProcess.defaultProps = {
  errorText: undefined,
};

export default ADOProjectBoardsProcess;
