import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const createTelemetryService = (instrumentationKey: string) => {
  const reactPlugin = new ReactPlugin();

  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey,
      disableFetchTracking: false,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin],
    },
  });

  appInsights.loadAppInsights();

  return reactPlugin;
};

export default createTelemetryService;
