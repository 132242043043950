import { useGlobalState } from '../../../globalState/useGlobalState';
import { CommonEnvMultiSelect } from '../CommonFields';
import { ENVIRONMENTS_NAMES } from '../../../constants';

const prodEnvironmentsNames = ENVIRONMENTS_NAMES.PROD_ENVIRONMENTS_NAMES;

interface ProdEnvironmentsPropType {
  value: string[];
  fieldError: boolean;
  errorText?: string;
  onChangeHandler: (
    event: React.ChangeEvent<{}>,
    value: string[] | string | null
  ) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const ProdEnvironments = (props: ProdEnvironmentsPropType) => {
  const { appSettings } = useGlobalState();
  const { value, fieldError, errorText, onChangeHandler, onBlurHandler } =
    props;
  const maxPdEnvironments = parseInt(appSettings.maxPdEnvironments.get(), 10);

  return (
    <CommonEnvMultiSelect
      componentID="prodEnvironments"
      label={`Production Environment${maxPdEnvironments > 1 ? 's' : ''}`}
      data={prodEnvironmentsNames}
      value={maxPdEnvironments > 1 ? value : value[0]}
      valueMax={maxPdEnvironments}
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      fieldError={fieldError}
      helperText={
        fieldError && errorText
          ? errorText
          : 'Select from the list of available production environment names.'
      }
      fullHelpText={
        maxPdEnvironments > 1
          ? `Production environments to be built. You can have up to ${maxPdEnvironments} production environments.`
          : 'Production environment to be built.'
      }
    />
  );
};

ProdEnvironments.defaultProps = {
  errorText: undefined,
};

export default ProdEnvironments;
