import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: { width: '100%' },
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: '1900px',
    '& li': { padding: 0 },
    '& ul': { padding: 0 },
  },
  ul: {
    display: 'flex',
    flexDirection: 'column',
  },
  listSubheader: {
    backgroundColor: 'inherit',
    fontSize: '1.2rem',
    fontWeight: '500',
    color: theme.palette.text.primary,
  },
  listItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingRight: '2rem',
    paddingLeft: '1rem',
    borderRadius: '0.25rem',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
