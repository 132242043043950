import { gql } from '@apollo/client';

const SUBMIT_NEW_APPLICATION_REQUEST_MUTATION = gql`
  mutation submitApplicationRequest($input: ApplicationRequestInput) {
    submitApplicationRequest(input: $input) {
      status
      code
      response {
        id
      }
    }
  }
`;

export default SUBMIT_NEW_APPLICATION_REQUEST_MUTATION;
