import { useLocation } from 'react-router-dom';
import { useGlobalState } from '../../globalState/useGlobalState';
import { AvailableUtilities } from '../../types/uiTypes';
import { utilitiesPayloadType } from '../ApplicationSummaryPage/useApplicationSummaryPage';

const useUtilitiesPage = () => {
  const title = 'Utilities Page';
  const subTitle =
    'Additional tooling for Day 2 operations and post deployment configuration.';
  const location = useLocation();
  const { appSettings } = useGlobalState();
  const addEnvUtilityEnabled = appSettings.addEnvUtilityEnabled.get();
  const deleteEnvUtilityEnabled = appSettings.deleteEnvUtilityEnabled.get();
  const unlockStateFileUtilityEnabled =
    appSettings.unlockStateFileUtilityEnabled.get();
  const ecpOnboardingUtilitiesEnabled =
    appSettings.ecpOnboardingUtilitiesEnabled.get();

  const evaluatePopup = () => {
    const locationState = location.state as any;
    if (locationState?.utilitiesPayload?.showPopup) {
      return locationState.utilitiesPayload as utilitiesPayloadType;
    }
    return undefined;
  };

  const availableUtilities: AvailableUtilities[] = [
    {
      utilityNameId: 'AddVnetOrRegion',
      utilityTitle: 'Add VNet / Region',
      utilityIconName: 'Hub',
      utilityDescription:
        'Deploy an extra VNet into an Application Landing Zone in an Azure Region',
    },
  ];

  if (addEnvUtilityEnabled) {
    availableUtilities.push({
      utilityNameId: 'AddEnvironment',
      utilityTitle: 'Add Environment',
      utilityIconName: 'PersonAdd',
      utilityDescription: 'Add an Environment to an Application Landing Zone',
    });
  }

  if (deleteEnvUtilityEnabled) {
    availableUtilities.push({
      utilityNameId: 'DeleteEnvironment',
      utilityTitle: 'Delete Environment',
      utilityIconName: 'Delete',
      utilityDescription:
        'Delete an Environment from an Application Landing Zone',
    });
  }

  if (unlockStateFileUtilityEnabled) {
    availableUtilities.push({
      utilityTitle: 'Unlock State File',
      utilityDescription:
        'Unlock a terraform state file in Azure Blob Storage.',
      utilityIconName: 'Unlock',
      utilityNameId: 'UnlockStateFile',
    });
  }

  if (ecpOnboardingUtilitiesEnabled) {
    availableUtilities.push({
      utilityTitle: 'ECP Openshift Onboarding',
      utilityDescription:
        'Request a namespace in Enterprise Container Platforms Openshift Cluster',
      utilityIconName: 'ECPNamespaceOnboarding',
      utilityNameId: 'ECPNamespaceOnboarding',
    });
    availableUtilities.push({
      utilityTitle: 'ECP Quay Registry Onboarding',
      utilityDescription:
        'Request an organization in Enterprise Container Platforms Quay Registry',
      utilityIconName: 'ECPQuayOnboarding',
      utilityNameId: 'ECPQuayOnboarding',
    });
  }

  return {
    title,
    subTitle,
    availableUtilities,
    popup: evaluatePopup(),
  };
};

export default useUtilitiesPage;
