import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  fieldWrap: {
    padding: '1rem',
    maxWidth: '60rem',
    minWidth: '20rem',
  },
}));

export default useStyles;
