import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormGroup,
  IconButton,
} from '@mui/material';
import { EphemeralRg } from '@costco-service-catalog/bff-types';
import { Close, MoreTime } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Dates } from '../useExistingEphemeralResourceGroupsList';

type ExtendErgModalPropsType = {
  open: boolean;
  handleClose: () => void;
  offeringName: string;
  extensionDate: dayjs.Dayjs | null;
  handleChangeExtensionDate: (date: dayjs.Dayjs | null) => void;
  handleExtendConfirmation: () => void;
  ephemeralApp?: EphemeralRg;
  dates?: Dates;
};

const ExtendErgModal = (props: ExtendErgModalPropsType) => {
  const {
    open,
    handleClose,
    offeringName,
    extensionDate,
    dates,
    handleChangeExtensionDate,
    handleExtendConfirmation,
    ephemeralApp,
  } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      {dates ? (
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '5px',
            }}
          >
            <IconButton onClick={() => handleClose()}>
              <Close />
            </IconButton>
          </Box>
          <DialogTitle>Extend Expiration Date</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Change the expiration date of {offeringName} #
              {ephemeralApp?.storyId}.
            </DialogContentText>

            <Box>
              <DialogContentText>
                The current expiration date is set to{' '}
                {dates.expirationDate.toDate().toLocaleDateString()}.
              </DialogContentText>
              <Box sx={{ paddingY: '1rem' }}>
                <FormGroup>
                  <DatePicker
                    label="Extend Expiration Date"
                    value={extensionDate}
                    onChange={(newValue) => handleChangeExtensionDate(newValue)}
                    minDate={dayjs().add(1, 'day')}
                    maxDate={dates.defaultMaximumDate}
                  />
                  <Box>
                    <Button
                      onClick={() =>
                        handleChangeExtensionDate(dates.expirationDate)
                      }
                      disabled={
                        dates.expirationDate.valueOf() ===
                        extensionDate?.valueOf()
                      }
                    >
                      Reset
                    </Button>
                  </Box>
                </FormGroup>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box sx={{ padding: '5px' }}>
              <Button
                variant="contained"
                endIcon={<MoreTime />}
                disabled={
                  dates.expirationDate.valueOf() === extensionDate?.valueOf()
                }
                onClick={() => handleExtendConfirmation()}
              >
                Extend
              </Button>
            </Box>
          </DialogActions>
        </Box>
      ) : undefined}
    </Dialog>
  );
};

ExtendErgModal.defaultProps = {
  ephemeralApp: undefined,
  dates: undefined,
};

export default ExtendErgModal;
