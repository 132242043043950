import {
  Application,
  ReferenceArchitecture,
} from '@costco-service-catalog/bff-types';
import {
  Build,
  FlightLand,
  SettingsApplicationsOutlined,
} from '@mui/icons-material';
import { Divider } from '@mui/material';
import { DeployedRefArchComponentCards } from '../OfferingCardMulti/DeployedRefArchComponentCard';
import OfferingCardMulti from '../OfferingCardMulti/OfferingCardMulti';
import SectionHeader from '../SectionHeader/SectionHeader';
import useStyles from './MultiselectStarterKitSection.styles';

type MultiselectStarterKitSectionPropsType = {
  deployedApplication: Application | undefined;
  referenceArchitectures: ReferenceArchitecture[];
  displayedOfferings: ReferenceArchitecture[];
  selectedOfferings: ReferenceArchitecture[];
  selectedUpdates: ReferenceArchitecture[];
  addSelectedUpdate: (refArch: ReferenceArchitecture) => void;
  removeSelectedUpdate: (refArch: ReferenceArchitecture) => void;
  handleRefArchSelection: (refArch: ReferenceArchitecture) => void;
};

const MultiselectStarterKitSection = (
  props: MultiselectStarterKitSectionPropsType
) => {
  const {
    deployedApplication,
    referenceArchitectures,
    displayedOfferings,
    selectedOfferings,
    selectedUpdates,
    addSelectedUpdate,
    removeSelectedUpdate,
    handleRefArchSelection,
  } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.offeringsContainer}>
      <Divider className={classes.divider} />
      <SectionHeader
        icon={<FlightLand />}
        title="Landing Zone"
        subtitle="In Azure, every project begins with a landing zone, which serves as the foundation for building your infrastructure from the ground up."
      />
      <div className={classes.offeringSection}>
        {deployedApplication
          ? DeployedRefArchComponentCards(
              deployedApplication,
              'LandingZone',
              referenceArchitectures,
              selectedUpdates,
              addSelectedUpdate,
              removeSelectedUpdate
            )
          : undefined}
        {displayedOfferings
          ? displayedOfferings
              .filter((r) => r.category === 'LandingZone')
              .map((refArch) => (
                <OfferingCardMulti
                  refArch={refArch}
                  selectedReferenceArchitectures={selectedOfferings}
                  handleRefArchSelection={handleRefArchSelection}
                  key={refArch.id}
                />
              ))
          : undefined}
      </div>

      <Divider className={classes.divider} />
      <SectionHeader
        icon={<Build />}
        title="Core Infrastructure"
        subtitle="Add a Core Infrastructure starter kit into your Landing Zone. Core Infrastructure starter kits provide necessary resources for deploying application starter kits."
      />
      <div className={classes.offeringSection}>
        {deployedApplication
          ? DeployedRefArchComponentCards(
              deployedApplication,
              'CoreInfra',
              referenceArchitectures,
              selectedUpdates,
              addSelectedUpdate,
              removeSelectedUpdate
            )
          : undefined}
        {displayedOfferings
          ? displayedOfferings
              .filter((r) => r.category === 'CoreInfra')
              .map((refArch) => (
                <OfferingCardMulti
                  refArch={refArch}
                  selectedReferenceArchitectures={selectedOfferings}
                  handleRefArchSelection={handleRefArchSelection}
                  key={refArch.id}
                />
              ))
          : undefined}
      </div>

      <Divider className={classes.divider} />
      <SectionHeader
        icon={<SettingsApplicationsOutlined />}
        title="Applications"
        subtitle="Applications deliver the services that run your application code."
      />
      <div className={classes.offeringSection}>
        {deployedApplication
          ? DeployedRefArchComponentCards(
              deployedApplication,
              'Application',
              referenceArchitectures,
              selectedUpdates,
              addSelectedUpdate,
              removeSelectedUpdate
            )
          : undefined}
        {displayedOfferings
          ? displayedOfferings
              .filter((r) => r.category === 'Application')
              .map((refArch) => (
                <OfferingCardMulti
                  refArch={refArch}
                  selectedReferenceArchitectures={selectedOfferings}
                  handleRefArchSelection={handleRefArchSelection}
                  key={refArch.id}
                />
              ))
          : undefined}
      </div>
    </div>
  );
};

export default MultiselectStarterKitSection;
