import { CommonDropdown } from '../CommonFields';

interface ADOOrgDropdownPropType {
  data: string[];
  value: string | null;
  loading: boolean;
  fieldError: boolean;
  errorText?: string;
  onChangeHandler: (event: React.ChangeEvent<{}>, value: string | null) => void;
  onBlurHandler: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const ADOOrgDropdown = (props: ADOOrgDropdownPropType) => {
  const {
    data,
    value,
    loading,
    fieldError,
    errorText,
    onChangeHandler,
    onBlurHandler,
  } = props;

  return (
    <CommonDropdown
      componentID="existingAdoProjectOrg"
      label="ADO Org"
      data={data}
      value={value}
      loading={loading}
      required
      onChangeHandler={onChangeHandler}
      onBlurHandler={onBlurHandler}
      getOptionLabel={(option) => option as string}
      getOptionSelected={(o, v) => o === v}
      fieldError={fieldError}
      helperText={
        fieldError && errorText
          ? errorText
          : 'Select your ADO Org from the list.'
      }
      fullHelpText="Select the ADO Org that your Existing ADO Project resides in. Once you have selected your Org, you should be able to select your ADO Project below."
    />
  );
};

ADOOrgDropdown.defaultProps = {
  errorText: undefined,
};

export default ADOOrgDropdown;
