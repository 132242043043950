import { ApolloError, useMutation } from '@apollo/client';
import { useApolloErrors } from '..';
import { UPDATE_EPHEMERAL_RG_EXPIRATION_DATE_MUTATION } from '../../apollo/queries';

const useUpdateEphemeralRgExpirationDateExpirationDate = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [
    updateEphemeralRgExpirationDate,
    { loading: updateEphemeralRgExpirationDateLoading },
  ] = useMutation(UPDATE_EPHEMERAL_RG_EXPIRATION_DATE_MUTATION, {
    onError: (error: ApolloError) => handleErrorResponse(error),
  });

  return {
    updateEphemeralRgExpirationDate,
    loading: updateEphemeralRgExpirationDateLoading,
  };
};

export default useUpdateEphemeralRgExpirationDateExpirationDate;
