import { AccessTime, OpenInNew, Pending } from '@mui/icons-material';
import { Button, Chip, Tooltip } from '@mui/material';

interface WizStatusChipProps {
  status: string | null;
  baseWizProjectUrl: string | null;
  wizSubscriptionSlug: string | null;
}

const WizStatusChip = (props: WizStatusChipProps) => {
  const { status, baseWizProjectUrl, wizSubscriptionSlug } = props;

  if (wizSubscriptionSlug) {
    return (
      <Button
        size="small"
        variant="outlined"
        endIcon={<OpenInNew />}
        href={`${baseWizProjectUrl}${wizSubscriptionSlug}`}
        target="_blank"
        rel="noreferrer"
      >
        Wiz.io
      </Button>
    );
  }

  switch (status) {
    case 'Submitted':
      return (
        <Chip
          size="small"
          icon={<AccessTime />}
          color="primary"
          label="Wiz.io Provisioning Pending"
        />
      );
    case 'Started':
    case 'Failed':
      return (
        <Tooltip title="Note: Wiz.io provisioning can take several hours to complete.">
          <Chip
            size="small"
            icon={<Pending />}
            color="primary"
            label="Wiz.io Provisioning"
          />
        </Tooltip>
      );
    case 'FailedOverLimit':
      return (
        <Chip size="small" color="error" label="Wiz.io Provisioning Failed" />
      );
    default:
      return null;
  }
};

export default WizStatusChip;
