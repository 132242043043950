import { Box } from '@mui/material';
import { StorageAccountInfo } from '@costco-service-catalog/bff-types';
import { FormikErrors } from 'formik';
import { useGetStorageAccountsForSubscription } from '../../../../../hooks';
import { defaultUnlockStateFileFormValuesType } from '../useUnlockStateFileModal';
import CommonDropdown from '../../../../FormComponents/CommonFields/CommonDropdown';

export const getContainerNames = (
  data: StorageAccountInfo[],
  selectedStorageAccountName: string
) =>
  data
    .filter((v) => v.name === selectedStorageAccountName)
    .flatMap((s) => s.blobContainers as string[]);

const SelectContainerName = (props: {
  selectedSubscriptionName: string;
  selectedStorageAccountName: string;
  selectedContainerName: string | null;
  selectedContainerNameFieldError: boolean;
  errors: FormikErrors<defaultUnlockStateFileFormValuesType>;
  setFieldValue: (
    field: string,
    value: string | undefined,
    shouldValidate?: boolean
  ) =>
    | Promise<FormikErrors<defaultUnlockStateFileFormValuesType>>
    | Promise<void>;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
}) => {
  const {
    selectedSubscriptionName,
    selectedStorageAccountName,
    selectedContainerName,
    selectedContainerNameFieldError,
    errors,
    setFieldValue,
    handleBlur,
  } = props;
  const { data, loading } = useGetStorageAccountsForSubscription({
    subscriptionName: selectedSubscriptionName,
  });

  return (
    <Box sx={{ flex: 1 }}>
      <CommonDropdown
        componentID="selectedContainerName"
        label="Select a Storage Container Name"
        data={data ? getContainerNames(data, selectedStorageAccountName) : []}
        value={selectedContainerName || null}
        fieldError={selectedContainerNameFieldError}
        helperText={
          selectedContainerNameFieldError && errors.selectedContainerName
            ? errors.selectedContainerName
            : 'Select a Storage Container Name.'
        }
        fullHelpText="Please select a Storage Container Name from the dropdown menu."
        loading={loading}
        dropdownDisabled={!data}
        required
        noOptionsText="No Options Found."
        onChangeHandler={(e, value) => {
          setFieldValue('selectedContainerName', value || undefined);
          setFieldValue('selectedStateFileName', undefined);
        }}
        onBlurHandler={handleBlur}
        getOptionLabel={(option) => option || 'No Storage Container Name'}
        getOptionSelected={(o, v) => o === v}
      />
    </Box>
  );
};
export default SelectContainerName;
